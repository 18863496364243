import React, { Component } from 'react';
import Termspage from 'views/termspage';
import SiSideNav from 'components/sidenav';
import TopHeader from 'components/header';

class Terms extends Component {

    constructor(props) {
        super(props);
    }

    openModal = () => {
        alert("modal opened");
    }

    render() {
        return (
            <div className="root"> 
                <Termspage openModal={this.openModal} />
            </div>
        )
    }
}

export default Terms;