import React from 'react';
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import Cookies from 'js-cookie';
import Loader from '../components/loader';
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import Success from 'images/Success.svg';
import Error from 'images/Error.svg';
import mc from 'images/mc.svg';
import ab from 'images/ab.svg';
import uvodb from 'images/uvodb.svg';
import vodb from 'images/vodb.svg';
import ml from 'images/ml.svg';
import 'css/projectdetailaddpage.css';
import 'mdbreact/dist/css/mdb.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Input } from 'mdbreact';
// import { Test } from 'home.js';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { cross } from 'react-icons-kit/icomoon/cross'
import uniqid from 'uniqid';
import errorText from './../errorText';
import windowSize from 'react-window-size';
import Style from 'style-it';
import { hddO } from 'react-icons-kit/fa/hddO';
import IntegrationReactSelectNew from './IntegrationReactSelectNew';
import IntegrationReactSelect from './IntegrationReactSelect';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';

const buildingTypeList = [
    { label: "Home", value: "Home" },
    { label: "Commercial", value: "Commercial" },
    { label: "Hospitality", value: "Hospitality" },
];

const noOfFloors = [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
];

let masterBoxUnassignCount = 0;
let vodBoxUnassignCount = 0;
let uvodBoxUnassignCount = 1;
let audioBoxUnassignCount = 0;
let homeBoxUnassignCount = 0;
class ProjectDetailAddPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            okasBoxData: [],
            projectmanagerdata1: [],
            memberLicenseTemp: [],
            memberLicenseToUpload: [],
            projectManagerLocationData: [],
            managerNameData: [],
            memberLicenseData: [],
            kk: 'asdvhj',
            showSome: false,
            okasBox_type: "",
            building_type: "",
            building_name: "",
            owner_name: "",
            address: "",
            no_of_floors: "",
            no_of_users: "",
            no_of_rooms: "",
            project_manager: "",
            office_address: "",
            okasBox_id: "",
            owner_phone: "",
            owner_email: "",
            textvaluetest: [],
            textvaluetest1: [],
            textvaluetest2: [],
            textvaluetest3: [],
            textvaluetest4: [],
            textvaluetest5: [],
            checkboxes: {},
            okasboxid: '',
            registrationid: '',
            licenceid: "",
            okasBoxType: "",
            buildingType: "Home",
            buildingname: "",
            ownername: "",
            ownerphone: "",
            owneremail: "",
            buildingaddress: "",
            noOfFloors: "",
            nousers: "",
            norooms: "2",
            projectManager: "",
            officeAddress: "",
            okasboxid: "",
            noofCount: 0,
            error: '',
            nameerror: '',
            disabledvalue: '',
            totalokasboxcount: '',
            okasbox1: '',
            okasbox2: '',
            okasbox3: '',
            okasbox4: '',
            okasbox5: '',
            loader: '',
            currentPage: 1,
            todosPerPage: 10,
            successmsgmodaldata: localStorage.getItem('dataadded'),
            showmodels: '',
            modelname: '',
            modelxname: '',
            checkedBoxes: '',
            checkedBoxestemp: [],
            radio: false,
            radio1: false,
            reRenderManager: false,
            reRenderLocation: false,
            masterBoxUnassignCount: 0,
            vodBoxUnassignCount: 0,
            uvodBoxUnassignCount: 0,
            audioBoxUnassignCount: 0,
            homeBoxUnassignCount: 0,
        };
    }

    componentWillMount() {
        this.setState({
            buildingType: "Home"
        }, () => {
            console.log(this.state.buildingType)
        })
        this.unassignedMemberLicense()
        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                })
            }
                .bind(this),
            3000
        );


    }

    findCountBox = (type) => {
        switch (type) {
            case "Master Controller":
                console.log(masterBoxUnassignCount)
                return masterBoxUnassignCount
                break;
            case "VOD":
                console.log(vodBoxUnassignCount)
                return vodBoxUnassignCount
                break;
            case "UVOD Box":
                console.log(uvodBoxUnassignCount)
                return uvodBoxUnassignCount
                break;
            case "Audio":
                console.log(audioBoxUnassignCount)
                return audioBoxUnassignCount
                break;
            case "Home Theatre Box":
                console.log()
                return homeBoxUnassignCount
                break;
        }
    }

    crosserrorfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            error: '',
            nameerror: '',
        })
        this.errormsg();
    }

    crosssuccessfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            successmsgmodaldata: '',
        })
    }

    errormsg = () => {
        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                    error: '',
                })
            }
                .bind(this), 3000
        );
    }



    onopenmodel = (modelname, modelxname) => {

        if (modelxname === 'memberlicense') {
            this.setState({
                memberLicenseTemp: this.state.memberLicenseToUpload
            }, () => {
                this.setState({
                    showmodels: 'true',
                    modelname: modelname,
                    modelxname: modelxname,
                    checkedBoxes: this.state.checkedBoxestemp,
                    error: false,
                })
            })
        } else {
            this.setState({
                showmodels: 'true',
                modelname: modelname,
                modelxname: modelxname,
                checkedBoxes: this.state.checkedBoxestemp,
                error: false,
            })
        }
    }

    onclosemodel = (event) => {
        this.setState({
            showmodels: '',
        })
    }

    checkresltimebox(checkvalue) {
        let valursft;
        const listItems1 = Object.entries(this.state.checkedBoxes).map(([key, value]) => {
            if (value.name == checkvalue) {
                valursft = value.presentValue;
                return valursft
            }
        })
        return valursft;
    }


    handleChange(e) {
        this.setState({
            checkedBoxestemp: this.state.checkedBoxes,
        })

        let oldArray = this.state.checkedBoxes;
        let current = this.state.checkedBoxes;
        let textvalue = [];
        let textvalue1 = [];
        let textvalue2 = [];
        let textvalue3 = [];
        let textvalue4 = [];
        let textvalue5 = [];


        const listItems = Object.entries(oldArray).map(([key, value]) => {


            if (value.boxType == "Master Controller") {
                if (value.presentValue === true) {
                    textvalue1.push(value.name)
                    textvalue.push(value.name)
                    this.setState({
                        textvaluetest1: textvalue1,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest1: textvalue1,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "VOD Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue2.push(value.name)
                    this.setState({
                        textvaluetest2: textvalue2,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest2: textvalue2,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "UVOD Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue3.push(value.name)
                    this.setState({
                        textvaluetest3: textvalue3,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest3: textvalue3,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "Audio Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue4.push(value.name)
                    this.setState({
                        textvaluetest4: textvalue4,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest4: textvalue4,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "Home Theatre Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue5.push(value.name)
                    this.setState({
                        textvaluetest5: textvalue5,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest5: textvalue5,
                        textvaluetest: textvalue,
                    })
                }
            }
            this.onclosemodel()
        })
    }




    componentDidUpdate() {
        // console.log(this.state.textvalue);
    }
    componentDidMount() {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }


        fetch('/we/we/okastabledata/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxData => {
                this.setState({
                    okasBoxData
                }, () => {
                    this.renderTableData();
                    //   console.log(this.state.okasBoxData)
                })
            })
        fetch('/we/we/projectManagerApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(projectmanagerdata1 => {
                this.setState({
                    projectmanagerdata1
                }, () => {
                    this.renderProjectLocation();
                    this.renderTableData();
                    // console.log(this.state.projectmanagerdata1)
                })
            })

    }
    getChckeboxValue(event) {
        const value = event.target.value;
        // console.log(value)
    }

    renderTableData1 = (type, managerName) => {
        if (type === 'renderprojectdata') {
            var typee = 0;
            let office_locationunique = this.state.projectmanagerdata1;
            office_locationunique = _.uniqBy(office_locationunique, 'office_location');
            let xxx = office_locationunique.map((e, index) => {
                return (
                    <option key={index}>{e.office_location}</option>
                )
            });
            return xxx;
        }
        if (type === 'renderprojectdatalocation') {
            var typee = 0;
            let xxx = this.state.projectmanagerdata1.map((e, index) => {
                if (e.office_location === managerName) {
                    return (
                        <option key={index} value={e.id}>{e.first_name}</option>
                    )
                }
            });
            return xxx;
        }
    }

    renderProjectLocation = () => {
        let projectManagerLocationData = [];
        let office_locationunique = _.uniqBy(this.state.projectmanagerdata1, 'office_location');
        office_locationunique.forEach((location) => {
            projectManagerLocationData.push({
                label: location.office_location,
                value: location.office_location
            })
        })
        this.setState({
            projectManagerLocationData: projectManagerLocationData
        })
    }

    renderProjectManagerName = (projectData, locationName) => {
        let managerNameData = [];
        projectData.forEach((event) => {
            if (event.office_location === locationName) {
                managerNameData.push({
                    label: event.first_name,
                    value: event.id
                })
            }
        })
        if (managerNameData.length === 1) {
            this.setState({
                officeAddress: managerNameData[0].value,
                managerNameData
            })
        } else {
            this.setState({
                managerNameData
            })
        }
    }

    renderTableDataOfficeLocation = (managerName) => {
        let xxx = this.state.projectmanagerdata1.map((e, index) => {
            if (e.name === managerName) {
                return (
                    <option key={index}>{e.office_location}</option>
                )
            }
        });
        return xxx;
    }


    renderTableData = (type) => {
        console.log(type)
        if (this.state.okasBoxData.detail == 'Invalid token.') {
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }
        if (type === 'mastercontrollerbox') {
            var typee = 0;
            let count = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'Master Controller' && e.okasBox_assigned_to === 'unassigned') {
                    typee = typee + 1;
                    let dd = _.indexOf(this.state.textvalue1, e.okasBox_id);
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }
                                        }
                                    })
                                }}
                                checked={this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}

                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>

                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>

                    )
                }
            });
            masterBoxUnassignCount = count;
            console.log("masterBoxUnassignCount", masterBoxUnassignCount)
            if (count === 0) {
                if (this.props.windowWidth > 760) {
                    xx =
                        (<tr>
                            <td colSpan='4' className="text-center">
                                No box available in inventory
                    </td>
                        </tr>)

                } else {
                    xx = (
                        <div className="heading text-center">
                            No box available in inventory

                        </div>
                    )
                }

            }
            return xx;
        }
        else if (type === 'vodbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'VOD Box' && e.okasBox_assigned_to === 'unassigned') {
                    let x = e.okasBox_id
                    count++;
                    return (
                        <tr key={index}>
                            {this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }
                                        }
                                    })
                                }}
                                checked={this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}</td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            vodBoxUnassignCount = count
            console.log('vodBoxUnassignCount', vodBoxUnassignCount)
            if (count === 0) {
                if (this.props.windowWidth > 760) {
                    xx1 =
                        (<tr>
                            <td colSpan='4' className="text-center">
                                No box available in inventory
                    </td>
                        </tr>)

                } else {
                    xx1 = (
                        <div className="heading text-center">
                            No box available in inventory

                        </div>
                    )
                }

            }
            return xx1;
        }
        else if (type === 'UVOD Box') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "UVOD Box" && e.okasBox_assigned_to === 'unassigned') {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }
                                        }
                                    })
                                }}
                                checked={this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            uvodBoxUnassignCount = count

            if (count === 0) {
                if (this.props.windowWidth > 760) {
                    xx1 =
                        (<tr>
                            <td colSpan='4' className="text-center">
                                No box available in inventory
                    </td>
                        </tr>)

                } else {
                    xx1 = (
                        <div className="heading text-center">
                            No box available in inventory

                        </div>
                    )
                }

            }
            return xx1;
        }
        else if (type === 'musicbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "Audio Box" && e.okasBox_assigned_to === 'unassigned') {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }

                                        }
                                    })
                                }}
                                checked={this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }

                                                }
                                            })
                                        }}
                                        checked={this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            audioBoxUnassignCount = count;
            console.log('audioBoxUnassignCount', audioBoxUnassignCount)
            if (count === 0) {
                if (this.props.windowWidth > 760) {
                    xx1 =
                        (<tr>
                            <td colSpan='4' className="text-center">
                                No box available in inventory
                    </td>
                        </tr>)

                } else {
                    xx1 = (
                        <div className="heading text-center">
                            No box available in inventory

                        </div>
                    )
                }

            }
            return xx1;
        }
        else if (type === 'hometheaterbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "Home Theatre Box" && e.okasBox_assigned_to === 'unassigned') {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }

                                        }
                                    })
                                }}
                                checked={this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }

                                                }
                                            })
                                        }}
                                        checked={this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}

                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            homeBoxUnassignCount = count;
            console.log(homeBoxUnassignCount, "homeBoxUnassignCount")
            if (count === 0) {
                xx1 =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in inventory
                </td>
                    </tr>)

            }
            return xx1;
        }
    }

    openNav = () => {
        this.setState({
            showSome: true
        })
    }



    memberLicensehandler = () => {
        if (this.state.modelxname === 'memberlicense') {
            let someData = this.state.memberLicenseTemp.map((e, index) => {
                return <tr key={index}>
                    {this.props.windowWidth > 760 ? <td className={this.props.windowWidth > 760 ? "" : "checkbox-memberlicense"}><input type="checkbox" name={e.id}
                        onChange={(ee) => {
                            let memberLicenseTemp = this.state.memberLicenseToUpload;
                            let newValue = this.state.memberLicenseTemp.map((kk) => {
                                if (kk.uniqueId === e.uniqueId) {
                                    return { uniqueId: e.uniqueId, status: ee.target.checked }
                                } else {
                                    return kk
                                }
                            })
                            this.setState({
                                memberLicenseTemp: newValue
                            })

                        }}
                        checked={e.status}
                    /></td> : null}
                    <td className={this.props.windowWidth > 760 ? "" : "checkbox-memberlicense"}>
                        <span className="input-checkbox-mobile">
                            {this.props.windowWidth < 760 ? <input type="checkbox" name={e.id}
                                onChange={(ee) => {
                                    let memberLicenseTemp = this.state.memberLicenseToUpload;
                                    let newValue = this.state.memberLicenseTemp.map((kk) => {
                                        if (kk.uniqueId === e.uniqueId) {
                                            return { uniqueId: e.uniqueId, status: ee.target.checked }
                                        } else {
                                            return kk
                                        }
                                    })
                                    this.setState({
                                        memberLicenseTemp: newValue
                                    })

                                }}
                                checked={e.status}
                            /> : null}
                        </span>
                        {e.uniqueId}
                    </td>
                </tr>
            })
            return someData
        }
    }

    memberLicenseCheckHandler = (key) => {
        let apiSet = new Set(this.state.memberLicenseTemp);
        return apiSet.has(key)
    }

    unassignedMemberLicense = () => {
        this.setState({
            loader: true,
        })
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }

        fetch(`/we/we/weMemberLicenseAssignedApi/?userid=${localStorage.getItem('email')}`, {
            method: "GET",
            headers: headers,

        })
            .then(response => response.json())
            .then(memberLicenseData => {
                let memData = memberLicenseData.map((e) => {
                    let uniqueId = e.licenseNo;
                    return {
                        uniqueId: uniqueId,
                        status: false
                    }
                })
                this.setState({
                    loader: false,
                    memberLicenseData: memData,
                    memberLicenseTemp: memData,
                    memberLicenseToUpload: memData
                })
            })
    }

    memberLicenseCount = () => {
        var r = 0;
        this.state.memberLicenseToUpload.forEach((e) => {
            if (e.status) {
                r++
            }
        })
        return r
    }

    handleChangeMemberLicense = () => {
        this.setState({
            memberLicenseToUpload: this.state.memberLicenseTemp,
        })
        this.onclosemodel();
    }


    selectAllCheckedBox = (boxType) => {
        let currentBoxes = this.state.okasBoxData.filter((box) => box.okasBox_type.includes(boxType) && box.okasBox_assigned_to === 'unassigned');
        let checkedBoxes = this.state.checkedBoxes;
        let checkedBoxesCount = 0;

        Object.keys(checkedBoxes).map((e) => {
            if (checkedBoxes[e].boxType.includes(boxType) && checkedBoxes[e].presentValue) {
                checkedBoxesCount++
            }
        })
        return currentBoxes.length === checkedBoxesCount
    }

    selectAllCheckedBoxMemberLicense = (type) => {
        if (type === 'Member License') {
            let ml = this.state.memberLicenseTemp;
            let mlCount = 0;
            ml.forEach((e) => {
                if (e.status) {
                    mlCount++
                }
            })
            return mlCount === ml.length
        }
    }

    onSelectChange = (operationMode, valueSelect) => {
        if (valueSelect.label !== undefined) {
            console.log(valueSelect.value)
            this.setState({
                [operationMode]: valueSelect.value,
                error: false,
                [operationMode + "Error"]: false,
                reRenderManager: false
            }, () => {
                if (operationMode === "projectManager") {
                    let projectManager = this.state.projectManager;
                    this.setState({
                        projectManager: null
                    }, () => {
                        this.setState({
                            officeAddress: "",
                            projectManager: projectManager,
                            error: false,
                            officeAddressError: false,
                            reRenderManager: true
                        })
                        this.renderProjectManagerName(this.state.projectmanagerdata1, projectManager)
                    })

                }
            })

        }
    }


    onAddClickHandler = (buttonaction) => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')
        }
        let building_id = new Date().valueOf().toString();
        let building_type = this.state.buildingType;
        let building_name = this.state.buildingname.trim();
        let owner_name = this.state.ownername.trim();
        let owner_phone = this.state.ownerphone;
        let owner_email = this.state.owneremail;
        let address = this.state.buildingaddress;
        let no_of_floors = this.state.noOfFloors;
        let no_of_rooms = this.state.norooms;
        let project_managerlocat = this.state.projectManager;
        let project_manager = this.state.officeAddress;
        let okasBox_id = JSON.stringify(this.state.textvaluetest);
        okasBox_id = okasBox_id.replace("[", "");
        okasBox_id = okasBox_id.replace("]", "");
        let okas_box_number1 = this.state.textvaluetest1;
        let okas_box_number2 = this.state.textvaluetest2;
        let okas_box_number4 = this.state.textvaluetest4;
        let okas_box_number5 = this.state.textvaluetest5;
        let license_id = [];
        this.state.memberLicenseToUpload.forEach((license) => {
            if (license.status) {
                license_id.push(license.uniqueId)
            }
        })
        let no_of_users = 6 + license_id.length;
        let tt = {
            building_id,
            building_type,
            building_name,
            owner_name,
            owner_phone,
            owner_email,
            address,
            no_of_floors,
            no_of_users,
            project_manager,
            okasBox_id,
            license_id
        };
        if (errorText('name', building_type) === 'Error') {
            this.setState({ error: 'Please enter building type', buildingTypeError: 'errorfield selectlistss' })
        } else if (errorText('name', building_name) === 'Error') {
            this.setState({ error: 'Please enter building name', buildingnameerror: 'errorfield' })
        } else if (errorText('name', owner_name) === 'Error') {
            this.setState({ error: "Enter owner's name", ownernameerror: 'errorfield', })
        } else if (errorText('name', owner_name) === 'Error1') {
            this.setState({ error: "Please enter valid owner's name", ownernameerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error') {
            this.setState({ error: 'Enter mobile number', phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error1') {
            this.setState({ error: "Please enter Numeric Value in mobile number", phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error2') {
            this.setState({ error: "Please enter a valid mobile number of 10 digits", phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error3') {
            this.setState({ error: "Please enter a valid mobile number start from 7,8,9,10", phoneerror: 'errorfield', })
        } else if (errorText('email', owner_email) === 'Error') {
            this.setState({ error: "Enter an email", emailerror: 'errorfield', })
        } else if (errorText('email', owner_email) === 'Error1') {
            this.setState({ error: "Please enter a valid email example(XXXXXXX@XXXXXX.XXX)", emailerror: 'errorfield', })
        } else if (errorText('address', address) === 'Error') {
            this.setState({ error: 'Please enter a valid building address', buildingaddresserror: 'errorfield', })
        } else if (errorText('empty', no_of_floors) === 'Error') {
            this.setState({ error: 'Please select no. of floors', noOfFloorsError: 'errorfield selectlistss' })
        } else if (errorText('empty', no_of_users) === 'Error') {
            this.setState({ error: 'Please select no. of users', nouserserror: 'errorfield selectlistss' })
        } else if (errorText('empty', no_of_rooms) === 'Error') {
            this.setState({ error: 'Please select no. of rooms', noroomserror: 'errorfield selectlistss' })
        } else if (!this.state.radio) {
            this.setState({ error: 'Please Select Okas Box Type', noroomserror: 'errorfield selectlistss' })
        } else if ((errorText('okas_box_number1', okas_box_number1) === 'Error') && this.state.radio === 'mastercontrollerbox') {
            this.setState({ error: 'Please add a master controller', noroomserror: 'errorfield selectlistss' })
        } else if ((errorText('okas_box_number1', okas_box_number1) === 'Error1') && this.state.radio === 'mastercontrollerbox') {
            this.setState({ error: 'Please add only one master controller', noroomserror: 'errorfield selectlistss' })
        } else if (((okas_box_number2.length > 0 || okas_box_number4.length > 0) && okas_box_number1.length < 1) && this.state.radio === 'mastercontrollerbox') {
            this.setState({ error: 'Please add a master Box First', noroomserror: 'errorfield selectlistss' })
        } else if ((errorText('okas_box_number1', okas_box_number5) === 'Error') && this.state.radio === 'hometheaterbox') {
            this.setState({ error: 'Please add a Home Theater', noroomserror: 'errorfield selectlistss' })
        } else if ((errorText('okas_box_number1', okas_box_number5) === 'Error1') && this.state.radio === 'hometheaterbox') {
            this.setState({ error: 'Please add only one Home Theater', noroomserror: 'errorfield selectlistss' })
        } else if (errorText('empty', project_managerlocat) === 'Error') {
            this.setState({ error: "Please select project manager's location", projectManagerError: 'errorfield selectlistss' })
        } else if (errorText('empty', project_manager) === 'Error') {
            this.setState({ error: 'Please select project manager', officeAddressError: 'errorfield selectlistss' })
        } else {
            this.setState({ disabledvalue: 'disabled', })
            this.setState({ loader: true, })
            fetch('/we/we/projectBuildingApi/', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
                .then(response => response.json())
                .then(data => {
                    var dataadded = "dataadded";
                    localStorage.setItem("dataadded", dataadded);
                    if (buttonaction === 'add') {
                        const history = createHistory({ forceRefresh: true });
                        history.push('/projectdetail');

                    } else if (buttonaction === 'addanother') {
                        const history = createHistory({ forceRefresh: true });
                        history.push('/projectdetailadd');
                    }

                })
            this.setState({
                loader: true,
            })

        }
    }
    render() {
        return Style.it(`
            @media only screen and (max-width: 760px){
          td:nth-of-type(1):before { content: "BOX TYPE"; }
          td.checkbox-memberlicense:nth-of-type(1):before { content: "MEMBER LICENSE"; }
          td:nth-of-type(2):before { content: "SERIAL"; }
          td:nth-of-type(3):before { content: "EMAIL"; }
          td:nth-of-type(4):before { content: "ADDRESS"; }
          td:nth-of-type(5):before { content: "BOXES"; }
          td:nth-of-type(6):before { content: "DELETE"; }
        }`,

            <div className="selfcontainer"
                style={{
                    position: !this.props.redux.globalVar.backgroundScrollStop ? "fixed" : "absolute",
                    overflow: !this.props.redux.globalVar.backgroundScrollStop ? "hidden" : ""
                }}

            >
                {this.state.loader ? <Loader /> : null}
                <div className={this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >
                    <div className="inblksuccfull"><img src={Success} /></div>
                    <div className="inblksucc1">
                        SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                    </div>
                </div>
                <h3 className="m-mobile-addproject">Add New Project</h3>
                <h6 className="m-mobile-addproject">Please fill in the details to add new office location.</h6>
                <div className={this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  >
                    <div className="inblksucc"><img src={Error} /></div>
                    <div className="inblksucc1">
                        {this.state.error} !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                    </div>
                </div>
                <form action="javascript:void(0)"
                //  onSubmit={this.onAddClickHandler.bind(this,'add')}
                >
                    <div className="row">
                        <div className="rightboxforma col-lg-6 p-mobile-addproject" >
                            <div className="rightboxformdiv" style={{ marginTop: '5%' }}>
                                {this.state.showSome ? <span className="">BUILDING ID</span> : null}

                                {/* <input type="text" name="buildingid" onClick={this.openNav.bind(this)} placeholder="Building ID"
                    onChange={(e)=>{
                        this.setState({
                            buildingid: e.target.value
                        },()=>console.log(this.state.buildingid))

                    }}
                    />     <br/> */}
                                <div className="custom-size-select-mobile">
                                    <IntegrationReactSelectNew
                                        placeholder="Building Type"
                                        multipleSelect={false}
                                        content={buildingTypeList}
                                        onSelect={this.onSelectChange.bind(this, 'buildingType')}
                                        textFieldLabel="Building Type"
                                        errorText={this.state.buildingTypeError === '' ? 'Please select office location' : ''}
                                        onFocus={(e) => {

                                        }}
                                        haveDefault={true}
                                        defaultValue={[{ label: "Home", value: "Home" }]}
                                    />
                                </div>
                                <br />
                                <div className="custom-margin-addproject">
                                    <Input label="Building Name" type="text" value={this.state.buildingname} name="buildingname" placeholder="Building Name " className={this.state.buildingnameerror ? this.state.buildingnameerror : ""}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 100) {
                                                this.setState({
                                                    buildingname: e.target.value,
                                                    error: false,
                                                    buildingnameerror: false,
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <br />
                                <Input label="Owner Name" type="text" name="ownername" placeholder="Owner Name " value={this.state.ownername} className={this.state.ownernameerror ? this.state.ownernameerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                ownername: e.target.value,
                                                error: false,
                                                ownernameerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Owner Phone" type="text" name="ownerphone" value={this.state.ownerphone} placeholder="Owner Phone " className={this.state.ownerphoneerror ? this.state.ownerphoneerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10 && (e.target.value.match(/^[0-9\b]+$/) || e.target.value == '')) {
                                            this.setState({
                                                ownerphone: e.target.value,
                                                error: false,
                                                ownerphoneerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Owner Email" type="text" value={this.state.owneremail} name="owneremail" placeholder="Owner Email " className={this.state.owneremailerror ? this.state.owneremailerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                owneremail: e.target.value,
                                                error: false,
                                                owneremailerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Building Address" type="text" name="buildingaddress" value={this.state.buildingaddress} placeholder="Building Address" className={this.state.buildingaddresserror ? this.state.buildingaddresserror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                buildingaddress: e.target.value,
                                                error: false,
                                                buildingaddresserror: false,
                                            })
                                        }

                                    }}
                                />        <br />
                                <div className="custom-size-select-mobile">
                                    <IntegrationReactSelectNew
                                        placeholder="No. of Floors"
                                        multipleSelect={false}
                                        content={noOfFloors}
                                        onSelect={this.onSelectChange.bind(this, 'noOfFloors')}
                                        textFieldLabel="No. of Floors"
                                        errorText={this.state.noOfFloorsError === '' ? 'Please select number of floors' : ''}
                                        onFocus={(e) => { }}
                                    />
                                </div>
                                <br />
                                <div style={{ pointerEvents: "none", marginTop: '25px' }} >
                                    <Input label="No of Users" type="text" name="noOfUsers" value={6 + this.memberLicenseCount()} placeholder="No of Users" />
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className="leftboxforma col-lg-6 mobile-device-padding-zero">
                            <span className="headprojectaddbox">OKAS Boxes</span>
                            <div className="leftboxformdiv">
                                <div className="project-add-radio-option"
                                // style={{    padding: '0% 20%'}}
                                >
                                    <div>
                                        <input onClick={(e) => {
                                            this.setState({
                                                radio: 'mastercontrollerbox',
                                                checkedBoxes: '',
                                                textvaluetest: '',
                                                textvaluetest1: '',
                                                textvaluetest2: '',
                                                textvaluetest3: '',
                                                textvaluetest4: '',
                                                textvaluetest5: '',
                                                checkedBoxestemp: '',
                                            })
                                        }} style={{ width: '15px', height: '15px' }} checked={this.state.radio === 'mastercontrollerbox' ? true : false} label="Master Controller" type="radio" id="radio2" name="mastercontrollerbox" /><b style={{ marginLeft: "8px" }}>Multiple Box Solution</b>
                                    </div>
                                    <div >
                                        <span className="pull-rightd">
                                            <input onClick={(e) => {
                                                this.setState({
                                                    radio: 'hometheaterbox',
                                                    checkedBoxes: '',
                                                    textvaluetest: '',
                                                    textvaluetest1: '',
                                                    textvaluetest2: '',
                                                    textvaluetest3: '',
                                                    textvaluetest4: '',
                                                    textvaluetest5: '',
                                                    checkedBoxestemp: '',

                                                })
                                            }} style={{ width: '15px', height: '15px', pointerEvents: 'none' }} checked={this.state.radio === 'hometheaterbox' ? true : false} label="Home Theatre Box" type="radio" id="radio2" name="hometheaterbox" /><b style={{ marginLeft: "8px" }}>Single Box Solution</b>
                                        </span>
                                    </div>
                                </div>
                                {this.state.radio === 'mastercontrollerbox' ?
                                    <div style={{ minHeight: "190px" }} >
                                        <div className="un">
                                            <span className="icon-box"><img src={mc} /></span>
                                            Master Controller (
                            {this.state.textvaluetest1.length === 0 ? 0 : this.state.textvaluetest1.length}
                                            )
                                            </div>
                                        <div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Master Controller', 'mastercontrollerbox')}>Add</a></div>

                                        <div className="un">
                                            <span className="icon-box"><img src={vodb} /></span>
                                            VOD Box (
                        {this.state.textvaluetest2.length === 0 ? 0 : this.state.textvaluetest2.length}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'VOD', 'vodbox')}>Add</a></div>

                                        <div className="un"><span className="icon-box"><img src={uvodb} /></span>UVOD Box (
                        {this.state.textvaluetest3.length === 0 ? 0 : this.state.textvaluetest3.length}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'UVOD Box', 'UVOD Box')}>Add</a></div>

                                        <div className="un"><span className="icon-box"><img src={ab} /></span>Audio Box (
                        {this.state.textvaluetest4.length === 0 ? 0 : this.state.textvaluetest4.length}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Audio', 'musicbox')}>Add</a></div>
                                        <div className="un"><span className="icon-box"><img src={ml} /></span>Member License (
                        {this.memberLicenseCount()}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Additional Member', 'memberlicense')}>Add</a></div>
                                    </div>
                                    : null}
                                {this.state.radio === 'hometheaterbox' ?
                                    <div style={{ minHeight: "190px" }} >
                                        <div className="un"><span className="icon-box"><img src={mc} /></span>Home Theatre Box (
                            {this.state.textvaluetest5.length === 0 ? 0 : this.state.textvaluetest5.length}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Home Theatre', 'hometheaterbox')}>Add</a></div>
                                    </div>
                                    : null}
                                <div className="totalokasboxnumber">Total no. of OKAS Boxes  </div><div className="addbuttonasd"> {this.state.textvaluetest.length === 0 ? 0 : this.state.textvaluetest.length}  Boxes</div>
                            </div>

                            <div style={{ paddingTop: '10px', marginBottom: '20px' }}>
                                {/* <div className="md-form" style={{ width: '100%' }}>
                                    <select name="projectManager" placeholder="Project Manager" className={this.state.projectManagerError ? this.state.projectManagerError : "selectlistss"}
                                        onChange={(e) => {
                                            this.setState({
                                                projectManager: e.target.value,
                                                error: false,
                                                projectManagerError: false,
                                            })

                                        }}
                                    >
                                        <option className="selectlistfirstelement" value=''></option>
                                        {this.renderTableData1('renderprojectdata', this.state.projectManager)}
                                    </select>
                                    <label className={this.state.projectManager == '' ? null : 'active'}>Project Manager location</label>
                                </div> */}
                                <div style={{ marginBottom: '20px' }}>
                                    <IntegrationReactSelectNew
                                        placeholder="Project Manager Location"
                                        multipleSelect={false}
                                        content={this.state.projectManagerLocationData}
                                        onSelect={this.onSelectChange.bind(this, 'projectManager')}
                                        textFieldLabel="Project Manager Location"
                                        errorText={this.state.noOfFloorsError === '' ? 'Please select project manager location' : ''}
                                        onFocus={(e) => { }}
                                        reRenderComponent={this.state.reRenderLocation}
                                    />
                                </div>

                                {this.state.projectManager ? <div style={{ marginBottom: '20px' }}>
                                    <IntegrationReactSelect
                                        placeholder="Project Manager"
                                        multipleSelect={false}
                                        content={this.state.managerNameData}
                                        onSelect={this.onSelectChange.bind(this, 'officeAddress')}
                                        textFieldLabel="Project Manager"
                                        onFocus={(e) => { }}
                                    />
                                </div> : null}

                                {/* {this.state.projectManager ?
                                <div className="margin-project-manager-md">
                                    <div className="md-form" style={{ width: '100%' }}>
                                        <select name="officeAddress" placeholder="Office Address" className={this.state.officeAddressError ? this.state.officeAddressError : "selectlistss"}
                                            onChange={(e) => {
                                                this.setState({
                                                    officeAddress: e.target.value,
                                                    error: false,
                                                    officeAddressError: false,
                                                })
                                            }}
                                            >
                                            <option className="selectlistfirstelement" value=''></option>
                                            {this.renderTableData1('renderprojectdatalocation', this.state.projectManager)}
                                        </select>
                                        <label className={this.state.officeAddress == '' ? null : 'active'}>Project Manager </label>
                                    </div>
                                </div> : ""} */}
                                {this.state.projectManager ? <br></br> : ""}
                                {/* test */}
                                <input type="hidden" name="okasbox1" id="okasbox1" value={this.state.textvaluetest1} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox1: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox2" id="okasbox2" value={this.state.textvaluetest2} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox2: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox3" id="okasbox3" value={this.state.textvaluetest3} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox3: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox4" id="okasbox4" value={this.state.textvaluetest4} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox4: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox5" id="okasbox5" value={this.state.textvaluetest5} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox5: e.target.value
                                        })

                                    }}
                                />

                                {/* testend */}
                                <input type="hidden" name="okasboxid" id="okasboxid" value={this.state.textvaluetest} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasboxid: e.target.value
                                        })
                                    }}
                                />
                            </div>

                        </div>
                        <span className="fieldmend"></span>
                    </div>
                    {/* {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null }  */}

                    <div className="modal-footer  align-btn-mobile">
                        <Link to="/projectdetail"><button type="button" className="btn allbtn" data-dismiss="modal">Cancel</button> </Link>
                        <span className="hidden-xs hidden-sm">

                            <button type="button" className="btn addbtn allbtn" onClick={this.onAddClickHandler.bind(this, 'addanother')}>Add Another</button>
                        </span>
                        <button type="submit" disabled={this.state.disabledvalue || this.state.okasboxid ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this, 'add')}>Save</button>
                    </div>
                </form>

                {/* modals start */}
                {this.state.showmodels ?
                    <div className="selfmodal fade" id="mastercontrollerbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog"
                            style={{
                                width: this.props.windowWidth > 760 ? "50%" : "95%",
                            }}
                        >
                            <div className="selfmodal-content">
                                <div className="modal-header">
                                    <button type="submit" className="close" data-dismiss="modal"
                                        onClick={this.onclosemodel.bind(this)}
                                    >&times;</button>
                                    {this.state.modelxname !== 'memberlicense' ? <h3 className="modal-title">Add {this.state.modelname} {this.state.modelname === 'UVOD Box' ? "" : "Box"}
                                    </h3> : <h3 className="modal-title">Add {this.state.modelname}</h3>
                                    }
                                </div>
                                {this.state.modelxname !== 'memberlicense' ? <div className="modal-body">
                                    {this.findCountBox(this.state.modelxname) !== 0 ? <table className={this.props.windowWidth > 760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    ?                                            <input type="checkbox"
                                                        onChange={(ee) => {
                                                            let allBoxes = this.state.okasBoxData;
                                                            allBoxes = allBoxes.filter((box) => box.okasBox_assigned_to === 'unassigned' && box.okasBox_type.includes(this.state.modelname))
                                                            let x = { ...this.state.checkedBoxes };
                                                            allBoxes.map((event) => {
                                                                x[event.okasBox_id] = {
                                                                    'name': event.okasBox_id,
                                                                    'presentValue': ee.target.checked,
                                                                    'boxType': event.okasBox_type
                                                                }
                                                            })
                                                            this.setState({
                                                                checkedBoxes: x
                                                            })
                                                        }}
                                                        checked={this.selectAllCheckedBox(this.state.modelname)}
                                                    />
                                                </th>
                                                {/* <th>OKAS BOX NO.</th> */}
                                                <th>BOX TYPE</th>
                                                <th>SERIAL NO.</th>
                                            </tr></thead>
                                        <tbody>
                                            {this.renderTableData(this.state.modelxname)}
                                        </tbody>
                                    </table> : <div className="heading text-center">No box available in inventory</div>}
                                </div> : <div className="modal-body">
                                        {this.state.memberLicenseData.length !== 0 ? <table
                                            //  className="tablecontent tablecontentas"
                                            className={this.props.windowWidth > 760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input type="checkbox"
                                                            onChange={(ee) => {
                                                                let mlStatus = this.state.memberLicenseTemp;
                                                                let mlCountSelected = 0;
                                                                let mlStatusUpdated = mlStatus.map((ml) => {
                                                                    let temp = {
                                                                        ...ml,
                                                                        status: ee.target.checked
                                                                    }
                                                                    return temp
                                                                })
                                                                this.setState({
                                                                    memberLicenseTemp: mlStatusUpdated
                                                                })
                                                            }}
                                                            checked={this.selectAllCheckedBoxMemberLicense('Member License')}
                                                        />

                                                    </th>
                                                    <th>Member License</th>
                                                </tr></thead>
                                            <tbody>
                                                {this.state.memberLicenseData.length !== 0 ?
                                                    this.memberLicensehandler()
                                                    :
                                                    this.props.windowWidth > 760 ? <tr><td colSpan='2' className="text-center">
                                                        No member license available
                                    </td>
                                                    </tr> : <div className="heading text-center">No member license available</div>
                                                }
                                            </tbody>
                                        </table> : <div className="heading text-center">No member license available</div>}
                                    </div>}
                                {this.state.modelxname !== 'memberlicense' ? <div className="modal-footer">
                                    {this.findCountBox(this.state.modelname) !== 0 ? <React.Fragment><button type="button"
                                        onClick={this.onclosemodel.bind(this)}
                                        className="btn allbtn" data-dismiss="modal">Cancel</button>
                                        <button type="submit"
                                            onClick={this.state.modelxname !== 'memberlicense' ? this.handleChange.bind(this) : this.handleChangeMemberLicense.bind(this)}
                                            className="btn btn-primary allbtn" data-dismiss="modal">
                                            Add
                                </button></React.Fragment> : <button type="button"
                                            onClick={this.onclosemodel.bind(this)}
                                            className="btn allbtn" data-dismiss="modal">Close</button>}
                                </div> : <div className="modal-footer">
                                        {this.state.memberLicenseData.length !== 0 ? <React.Fragment>
                                            <button type="button"
                                                onClick={this.onclosemodel.bind(this)}
                                                className="btn allbtn" data-dismiss="modal">Cancel</button>
                                            <button type="submit"
                                                onClick={this.state.modelxname !== 'memberlicense' ? this.handleChange.bind(this) : this.handleChangeMemberLicense.bind(this)}
                                                className="btn btn-primary allbtn" data-dismiss="modal">
                                                Add
                                     </button>
                                        </React.Fragment> : <button type="button"
                                            onClick={this.onclosemodel.bind(this)}
                                            className="btn allbtn" data-dismiss="modal">Close</button>}
                                    </div>}
                            </div>
                        </div>
                    </div>

                    : null}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),
    };
};
export default windowSize(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailAddPage));
