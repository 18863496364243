import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core';

class  SnackBar extends React.Component{
  render(){
    return (       

      <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes = {{
        "root": "top-snackbar"
      }}
      variant="info"
      autoHideDuration={2000}
      open={this.props.open}
      onClose={() => {this.props.handleSnackbarClose()}}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.props.message}</span>}
      />  

        );
      }
      
}

export default withStyles({
  root: {
    top: "8px"
  }
})(SnackBar)