import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';



const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    color: 'red',

  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: '100%',
    width: '100%'
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      color="red"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      single: {
        value: this.props.haveDefault ? this.props.defaultValue[0].value : "",
        label: this.props.haveDefault ? this.props.defaultValue[0].label : "",
      },
      multi: null,
      changedValues: [],
    }
}



componentWillMount(){
  if(this.props.haveDefault){
    console.log('called from havedefault')
    const xx = [
      ...this.props.defaultValue    
    ].map(e => ({
      value: e.value,
      label: e.label,
    }));
    this.setState({
      single: xx
    },()=>{
      this.props.onSelect(this.state.single);
    }
  )
  }
}

componentWillReceiveProps(){
  if(this.props.reRenderComponent){
    this.setState({
      single: { },
      multi: null,
      changedValues: [],
    },()=>this.forceUpdate())
  }
}

handleChange = name => value => {
    this.setState({
      [name]: value,
    },()=>{
      if(!this.props.multipleSelect){
        this.props.onSelect(this.state.single);
        console.log(this.state)
      }else{
        var xx = [
          ...this.state.single
        ];
        this.props.onSelect(this.state.single);
      }
    });
  };

  render() {
    const { classes, theme } = this.props; 
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const suggestions = [
      ...this.props.content    
    ].map(suggestion => ({
      value: suggestion.value,
      label: suggestion.label,
    }));
    return (
      <div className={classes.root}>
      <NoSsr>
      <Select
      textFieldProps={{
        label: this.props.textFieldLabel,
        InputLabelProps: {
          shrink: true,
        },
      }}
      classes={classes}
      styles={selectStyles}
      options={suggestions}
      components={components}
      value={this.state.single}
      onChange={this.handleChange('single')}
      placeholder={this.props.placeholder || "Select Something"}
      isMulti={this.props.multipleSelect}      
      onFocus={()=>this.props.onFocus("clicked")}
      onMenuClose={()=>this.props.onFocus("selected")}
      
    />
    <div style={{"color": "red"}}>{this.props.errorText}</div>
  </NoSsr>
  </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);