
import React, { Component } from 'react'; 
import publicIp from 'public-ip';
import bg1 from 'images/bg1.png';
import bgvideo from 'images/bg3.mp4';
import logo from 'images/weokaslogin.svg';
import okaslogo from 'images/okaslogo.svg';
import okasweb from 'images/okasweb.svg';
import okasmail from 'images/okasmail.svg';
import { Input } from 'mdbreact'; 
import 'css/loginform.css'; 
import { Link } from 'react-router-dom';
import Loader from '../components/loader'; 
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import SnackBar from './SnackBar';
import errorText from './../errorText';

class LoginView extends Component { 
    constructor(props){
        super(props);
        this.state = { 
            error: '',
            nameerror: '',
            password:'',      
            confirmPassword: '', 
            username:'', 
            disabledvalue:'',
            loader:false,
            checkuserApidata:[],
            snackbarActive: false,
            snackbarMessage: '',
            errorMessage: '',
            linkActivationForm: true,
            confirmWindow: false,
            processed: false,            
            browserDetails: {
                locationApi: "Unknown"
            }
        };

    }
    confirmWindowClose = () =>{
        this.setState({
            confirmWindow: false
        })
    }


    setTokenandRedirect(data, username,usertype,reusername) {
        // this.props.successBarActivate(!this.props.redux.globalVar.successBarShow);

        localStorage.setItem("name", reusername);
        localStorage.setItem("email", username);
        localStorage.setItem("csrftoken", this.getCookie('csrftoken'))
        localStorage.setItem('auth', data.token);
        this.props.userNameStyleActivate(username)
        this.props.reuserNameStyleActivate(reusername)
        this.props.userTypeStyleActivate(usertype) 
        if(localStorage.getItem('auth')!==null){
            if(this.props.redux.globalVar.userTypeStyle==='2'){
                this.props.history.push('/projectdetail'); 
            }else if(this.props.redux.globalVar.userTypeStyle==='1'){
                // this.props.history.push('/dashboard');
                window.location.href="/dashboard";
            }
        }
    }

    getCookie(c_name)
    {
        if (document.cookie.length > 0)
        {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1)
            {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) c_end = document.cookie.length;
                return unescape(document.cookie.substring(c_start,c_end));
            }
        }
        return "";
    }

    checkuserApi = () =>{  
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        fetch('/we/we/userCheckApi/' ,{
                method: "GET",
                headers: headers
            })
        .then(response => response.json())
        .then(checkuserApidata => {
            this.setState({
                checkuserApidata,
                actualdata: checkuserApidata
            },()=>{ 
                this.props.userNameStyleActivate(this.state.checkuserApidata.user_name)
                this.props.reuserNameStyleActivate(this.state.checkuserApidata.first_name)
                this.props.userTypeStyleActivate(this.state.checkuserApidata.User_Type) 
               
                if(this.state.checkuserApidata.status!=='200'){
                    // alert('invalid authentication found');
                    localStorage.removeItem('auth');  
                    // this.props.history.push('/login')
                }else{
                    if(localStorage.getItem('auth')!==null){
                        if(this.state.checkuserApidata.User_Type==='2'){
                            this.props.history.push('/projectdetail'); 
                        }else if(this.state.checkuserApidata.User_Type==='1'){
                            this.props.history.push('/dashboard');
                        }
                    }
                }
            }) 
        })
    }




    componentWillMount() {  
(async () => {
    this.setState({      
            locationApi: await publicIp.v4() || await publicIp.v6()
        
    },()=>{
        fetch(`http://ip-api.com/json/${this.state.locationApi}?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,query`)
        .then(response =>response.json())
        .then(data => {
            this.setState({
                browserLocation: data
            })
        })
    })
})();
        this.browserDediction();
        this.checkuserApi();   
        this.checkExpirationLink()
        if(window.location.pathname.includes('session-timeout')){
            this.callError('Session Timeout')
        }else if(window.location.pathname.includes('user-activated')){

        }
    }

    callError = (message)=>{
        this.setState({
            errorMessage: message,
            loader:false
        },()=>{
            setTimeout(()=>{
                if(message==="You have a active session.Please go to our homepage."){
                    this.props.history.push('/dashboard');
                }
                this.setState({
                    errorMessage: ''
                })
            },4000)
    }
    )}


    onSubmitbutton  = (e) => {       
        // e.preventDefault();
        var usernamewa = this.state.username.trim();
        var passwordwa = this.state.password;
        var data = {
            "si_userName": this.state.username,
            "password": this.state.password,
        }

        var headers = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }
        // obtain-auth-token
        if(usernamewa.length < 1){
            this.callError("Please fill Username")
        }else if(passwordwa.length < 1){
            this.callError("Please fill Password")
        }else{
            // this.setState({
            //     loader: true ,  
            // })
  fetch("/we/loginview/", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        })
        .then((response) =>  response.json() ) 
        .then((data) => { 
                if(data.status == 400){
                    this.callError(data.message)
            }
                else if(data.status == 200) {
                    this.setTokenandRedirect(data, this.state.username,data.User_Type,data.si_name);
                }
                else{
                    this.callError("Incorrect Credentials")
                }
            this.setState({
                loader: false ,  
            })  
        })
        }
    }

    onSubmitNewAccount = () => {  
            if(this.state.password===this.state.confirmPassword && this.state.password!=="" && this.state.confirmPassword!==""){
                    if(this.state.linkActivationForm){
                        //do something usefull here
                        if(this.state.password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)){
                            this.activateNewUser()
                        }else{
                            this.callError("Password must contains at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and should be of length between 8 and 50 characters")    
                        }
                    }else{
                        this.callError("Activation link has expired or invalid")
                    }
            }else{
                this.callError("Password doesn't match.")
            }
        
    }

    activateNewUser = () =>{
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        let data = {
            "si_email": this.state.newUserEmail,
            "si_password": this.state.password,
            "activation_link":  window.location.pathname.slice(-16),
            "browserDetails": {
                ...this.state.browserDetails,
                locationApi: this.state.locationApi,
                browserLocation: this.state.browserLocation    
            }
        }
        this.setState({
            loader: true
        })
        if(localStorage.getItem("auth")==null){
        fetch(`/we/we/setPasswordApi/?url=${window.location.pathname.slice(-16)}` ,{
                method: "POST",
                headers: headers,
                body: JSON.stringify(data),
            })
        .then(response => response.json())
        .then(data=>{
            console.log(data)
            if(data.message==='success'){
                this.setState({
                    confirmWindow: true,
                    loader: false
                })
            }else{
                this.callError(data.data[0])
                this.setState({
                    linkActivationForm: false
                })
            }
        })
    }else{
        this.callError("You have a active session.Please go to our homepage.")
    }

    }

    checkExpirationLink = () =>{
        if(window.location.pathname.includes("/login/activateuser/") || window.location.pathname.includes("/login/forgotpassword/")){
            let TokenValue = 'Token '+localStorage.getItem("auth");
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': TokenValue
            }  
            fetch(`/we/we/setPasswordApi/?url=${window.location.pathname.slice(-16)}` ,{
                    method: "GET",
                    headers: headers
                })
            .then(response => response.json())
            .then(data=>{
                console.log(data)
                if(data.message==='success'){
                    this.setState({
                        newUserEmail: data.data[0].email
                    })

                }else{
                    this.callError(data.data[0])
                    this.setState({
                        linkActivationForm: false
                    })
                }
            })

        }
    }



    browserDediction = () =>{
        var objappVersion = navigator.appVersion; var objAgent = navigator.userAgent; var objbrowserName = navigator.appName; var objfullVersion = ''+parseFloat(navigator.appVersion); var objBrMajorVersion = parseInt(navigator.appVersion,10); var objOffsetName,objOffsetVersion,ix; 
        // In Chrome 
        if ((objOffsetVersion=objAgent.indexOf("Chrome"))!=-1) { objbrowserName = "Chrome"; objfullVersion = objAgent.substring(objOffsetVersion+7); } 
        // In Microsoft internet explorer
         else if ((objOffsetVersion=objAgent.indexOf("MSIE"))!=-1) { objbrowserName = "Microsoft Internet Explorer"; objfullVersion = objAgent.substring(objOffsetVersion+5); } 
         // In Firefox 
         else if ((objOffsetVersion=objAgent.indexOf("Firefox"))!=-1) { objbrowserName = "Firefox"; } 
         // In Safari
          else if ((objOffsetVersion=objAgent.indexOf("Safari"))!=-1) { objbrowserName = "Safari"; objfullVersion = objAgent.substring(objOffsetVersion+7); if ((objOffsetVersion=objAgent.indexOf("Version"))!=-1) objfullVersion = objAgent.substring(objOffsetVersion+8); } 
          // For other browser "name/version" is at the end of userAgent
           else if ( (objOffsetName=objAgent.lastIndexOf(' ')+1) < (objOffsetVersion=objAgent.lastIndexOf('/')) ) { objbrowserName = objAgent.substring(objOffsetName,objOffsetVersion); objfullVersion = objAgent.substring(objOffsetVersion+1); if (objbrowserName.toLowerCase()==objbrowserName.toUpperCase()) { objbrowserName = navigator.appName; } } 
           // trimming the fullVersion string at semicolon/space if present 
           if ((ix=objfullVersion.indexOf(";"))!=-1){
               objfullVersion=objfullVersion.substring(0,ix);
           }
             if ((ix=objfullVersion.indexOf(" "))!=-1) {
                 objfullVersion=objfullVersion.substring(0,ix);
                 objBrMajorVersion = parseInt(''+objfullVersion,10);
             }
         if (isNaN(objBrMajorVersion)) {
              objfullVersion = ''+parseFloat(navigator.appVersion); objBrMajorVersion = parseInt(navigator.appVersion,10); 
            } 
            let details = {
                browserName: objbrowserName,
                fullVersion: objfullVersion,
                majorVersion: objBrMajorVersion,
                appName: navigator.appName,
                userAgent: navigator.userAgent,
                os: this.osDediction(),
                date: {
                    date:new Date().toLocaleDateString(),
                    time: new Date().toLocaleTimeString()
                },

            };

            this.setState({
                browserDetails: details
            })

    }



    osDediction = () =>{
        var userAgent = window.navigator.userAgent;
       var platform = window.navigator.platform;
       var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
       var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
       var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        var os = "";
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os
    
    }


    onForgetPassword = () =>{
        if(errorText('email', this.state.registeredEmail)!=='Error'){
            this.setState({
                loader: true
            })
            let TokenValue = 'Token '+localStorage.getItem("auth");
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': TokenValue
            }  
            if(localStorage.getItem("auth")==null){            
            fetch(`/we/we/forgotPasswordApi/?email=${this.state.registeredEmail}` ,{
                    method: "GET",
                    headers: headers
                })
            .then(response => response.json())
            .then(data =>{
                console.log(data)
                if(data.message==="failure"){
                    this.callError(data.data[0])
                    this.setState({
                        loader: false
                    })
                }else if(data.message==="success"){
                    this.setState({
                        processed: true,
                        loader: false
                    })
                    if(data.data[0]==="Email sent twice."){
                        this.callError("Email sent again.")}
                }
            })
        }else{
            this.callError("You have a active session.Please go to our homepage.")
        }

        }else{
            this.callError("Not a valid email")
        }
    }

    confirmationWindow = () =>{
      let  formPage = (
            <React.Fragment>
                <img src={logo}  className="img-responsive centeriimgs"  style={{maxWidth: "400px"}}/>
            <div className="modal-body modfields" style={{paddingTop: '10px'}}>  
                <div className="content mt-5 centerheadingfd">Your password has been successfully updated.</div>
                <br/>
                <br/>              
                </div> 
                <div className="centerdiv">                           
                <button type="submit" className="btn allbtn"  style={{backgroundColor: "#15599F"}} value="Login" >
                <a style={{float: "center", paddingTop: '10px', color: "white"}} href="/login">
                    Login
                    </a>
                    </button> 
                </div>
            </React.Fragment>
        )
        if(this.state.confirmWindow){
            return formPage;
        }
    }

    typeOfLogin = () =>{
        let formPage ="";
        if(this.state.confirmWindow){
            return null
        }
        if(window.location.pathname==="/login" || window.location.pathname==="/login/session-timeout"){
            formPage = (
                <form className="loginForm" action="javascript:void(0)"  onSubmit={()=>this.onSubmitbutton.bind(this)} >
                <img src={logo}  className="img-responsive centeriimgs" style={{maxWidth: "400px"}} />
                <div className="content mt-5 centerheadingfd">Welcome! Please login to your account</div>
                {this.state.errorMessage!=='' ? <div style={{maxWidth: '400px'}} class="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div>: null}
                <div className="modal-body modfields" style={{paddingTop: '10px'}}>  
                <Input label="Username"  type="text"  name="username" id="name" placeholder="Name" className={this.state.nameerror ? this.state.nameerror : ""}   
                value={this.state.username} 
                onChange={(e)=>{
                    this.setState({
                        username: e.target.value 
                    })
                }}
                
                /><br/><br/>
                <Input type="password" name="password" label="Passwords" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.password}
                onChange={(e)=>{
                if(this.state.password.length<50){this.setState({password: e.target.value})}
                }}
                autoComplete="off"                 
                />
                <div > <a style={{float: "center",marginTop: "5px"}} href="/login/forget-password">Forgot Password?</a></div>
                <br/>  <br/>
                </div> 
                <div className="centerdiv">                           
                <button type="submit" className="btn allbtn" value="Login"   style={{backgroundColor: "#15599F"}}  onClick={this.onSubmitbutton.bind(this)} >Login</button> 
                </div>
               </form>              
            )
        }else if(window.location.pathname.includes('/login/activateuser')) {
            formPage = (
                <form className="loginForm" action="javascript:void(0)"  onSubmit={this.onSubmitNewAccount.bind(this)} >         
                <img src={logo}  className="img-responsive centeriimgs"  style={{maxWidth: "400px"}}/>
                <div className="content mt-5 centerheadingfd">Welcome! Please activate your new account</div>
                {this.state.errorMessage!=='' ? <div style={{maxWidth: '400px'}} class="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div>: null}
                <div className="modal-body modfields" style={{paddingTop: '10px'}}>  
                <Input type="password" name="password" label="New Password" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.password}
                onChange={(e)=>{
                    if(this.state.password.length<50){this.setState({password: e.target.value})}
                }}
                required
                autoComplete="off" 
                /><br/>  <br/>
                <Input type="password" name="confirmpassword" label="Confirm New Password" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.confirmPassword}
                onChange={(e)=>{
                    if(this.state.confirmPassword.length<50){this.setState({confirmPassword: e.target.value})}
                }}
                autoComplete="off" 
                required
                /><br/>  <br/> 
                </div> 
                <div className="centerdiv">                           
                <button type="submit" className="btn btn-primary allbtn  allbtnadd" value="Login" >Activate Account</button> 
                </div>
               </form>              
            )
        }else if(window.location.pathname.includes('/login/forgotpassword')) {
            formPage = (
                <form className="loginForm" action="javascript:void(0)"  onSubmit={this.onSubmitNewAccount.bind(this)} >         
                <img src={logo}  className="img-responsive centeriimgs"  style={{maxWidth: "400px"}}/>
                <div className="content mt-5 centerheadingfd text-center">Finally, choose a new password</div>
                {this.state.errorMessage!=='' ? <div style={{maxWidth: '400px'}} class="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div>: null}
                <div className="modal-body modfields" style={{paddingTop: '10px'}}>  
                <Input type="password" name="password" label="New password" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.password}
                onChange={(e)=>{
                    if(this.state.password.length<50){this.setState({password: e.target.value})}
                }}
                required
                autoComplete="off" 
                />
                 <h6 className="text-left">include at least 1 number and 1 special character.</h6>
                <br/>
                <Input type="password" name="confirmpassword" label="Retype new password" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.confirmPassword}
                onChange={(e)=>{
                    if(this.state.confirmPassword.length<50){this.setState({confirmPassword: e.target.value})}
                }}
                autoComplete="off" 
                required
                /><br/>  <br/> 
                </div> 
                <div className="centerdiv">                           
                <button type="submit" className="btn btn-primary allbtn  allbtnadd" value="Login" >Submit</button> 
                </div>
               </form>              
            )
        }else if(window.location.pathname.includes('/login/forget-password')) {
            formPage = (
                <form className="loginForm" action="javascript:void(0)"  onSubmit={this.onForgetPassword.bind(this)} >        
                <img src={logo}  className="img-responsive centeriimgs"  style={{maxWidth: "400px"}} />
                {this.state.errorMessage!=='' ? <div style={{maxWidth: '400px'}} class="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div>: null}
                {!this.state.processed ? <React.Fragment>
                <div className="modal-body modfields" style={{paddingTop: '10px'}}>  
                <div className="content mt-5 centerheadingfd">Let's find your account. Please enter the registered email address</div>
                <br/>
                <br/>
                <Input type="text" name="registeredEmail" label="Email Address" className={this.state.phoneerror ? this.state.phoneerror : ""}
                value={this.state.registeredEmail}
                onChange={(e)=>{
                    this.setState({
                        registeredEmail: e.target.value
                    }) 
                }}
                required
                autoComplete="off" 
                /><br/>  <br/>
                </div> 
                <div className="centerdiv">                           
                <button type="submit" className="btn allbtn"  style={{backgroundColor: "#15599F"}}value="Login" >Send Email</button> 
                <div style={{paddingTop: '20px',marginTop: "30px"}} > Already have an account? <a style={{float: "center", paddingTop: '10px'}} href="/login"> Please login</a></div>
                </div>
                </React.Fragment> : <React.Fragment>                
                <div className="modal-body modfields" style={{paddingTop: '10px'}} >  
                <div className="content mt-5 centerheadingfd" style={{marginBottom: "0px"}}>We just emailed you a link</div>
                <div style={{fontSize: '12px'}}>Please check your email and click the secure link.</div>
                <br/>                
                </div> 
                <div className="centerdiv">   
                <div>
                    <div>
                <button type="submit" className="btn allbtn" value="Login"  style={{backgroundColor: "#15599F"}}>Resend link</button>

                    </div>
                    <div style={{
    marginTop: "31px"
}}>

                <a href="" style={{float: 'center',marginTop: '20px',paddingTop: '20px'}} onClick={()=>{
                    this.setState({
                        processed: false,
                        registeredEmail: ""
                    })
                }}>Try different email</a> 
                </div>
                </div>
                <br/>
                <br/>
                <div style={{fontSize: '12px', float: 'center', marginTop: '40px',marginLeft: '2px'}}>If you don't see your mail in inbox, try to search in spam folder.</div>
                <div style={{fontSize: '12px', float: 'center',marginLeft: '2px'}}>Lost access to your email.<br/>Email at <a href="mailto:hello@okas.ai?Subject=Forget%20Password" target="_top">hello@okas.ai</a> </div>

                </div>
                </React.Fragment> }
               </form>              
            )
        }
        return formPage;

    }
    render () {
        return (
            <div className="row marginnone text-center"  style={{height: '100%'}}>                
                 <SnackBar open={this.state.snackbarActive} handleSnackbarClose={()=>{    this.setState({
      snackbarActive: false
    })}} message={this.state.snackbarMessage}/>
                {this.state.loader?<Loader/>:null}
                <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12 login-page-height box-shadow-login-sm" style={{display: "flex",alignItems: 'center',
  justifyContent: 'center'}}>
                    {/* <LoginForm onSubmit={this.submitForm}/> */}
                   <div className="col-lg-12 col-md-12 col-xs-12 col-sm-4 loginForm-width">
                        {!this.state.confirmWindow ? this.typeOfLogin() : null}
                        {this.confirmationWindow()}
                            </div>

                </div>
                <div className="col-lg-8 col-md-8 paddingnone hidden-xs hidden-sm login-page-height" >
                        {/* <img src={bg1} /> */}
<div id="myCarousel" className="carousel slide" data-ride="carousel"   style={{ height: '101%'}}>
    {/* <!-- Indicators --> */}

    {/* <!-- Wrapper for slides --> */}
    <div className="carousel-inner"  style={{ height: '100%'}} >
      <div className="item active"  style={{height: '100%'}} >
        {/* <img src={bg1} alt="CONTROL YOUR HOME"  /> */}
        <video autoPlay muted loop  style={{objectFit: "fill", height: '100%'}} >
             <source src={bgvideo} type="video/mp4"/>  
        </video>
        <div>
        <div className="carousel-caption" style={{width: '100%',display: 'flex',justifyContent: 'center',alignItems: 'center',paddingBottom: '0px'}}>
            <div>

          {/* <h3 className="logincarouselheading"  >CONTROL YOUR HOME</h3> */}
          {/* <p className="logincarouseltext">When tables get delivered for this weekend's party,<br/>
              open your garage remotely and close it once everything's been unloaded.
          </p> */}
            </div>
        </div>
        </div>
      </div>    
    </div> 
  </div>
</div>



                <div className="footer hidden-xs hidden-sm" style={{height: '90px'}}>
                    <div className="pull-left footer-left" >
                        OKAS understood that home is an exceptional space for people with class or taste for exclusivity.<br></br> 
                         Also each member has their preferences and    feel of living.
                        <p className="p1"> 
                            <a href="https://www.okas.ai/" target=" "><img src={okasweb}  className="img-responsive okasweb" align=""/></a>
                             
                            <a href="mailto:hello@okas.ai" target="_top"><img src={okasmail}  className="img-responsive okasmail" align=""/></a></p>
                   
                    </div>
                    <div className="pull-right footer-right ">
                        <p className="p4"><img src={okaslogo}  className="img-responsive" align="right"/></p><br></br>
                        <p className="p2"><a href="/terms" target=" ">Term & Conditions</a></p>
                        <p className="p3">© OKAS.Ai 2019. All Rights Reserved.</p>
                    </div>                  
                </div>
                <div className="hidden-lg hidden-md login-footer-height"  >                    
                    © OKAS.Ai 2019. All Rights Reserved.                   
                    </div>
                {this.state.confirmWindow && false ?   <div className="selfmodal fade in"   aria-hidden="false" data-backdrop="static" data-keyboard="false">
                    <div className="selfmodal-dialog" style={{width: "50%"}}> 
                        <div className="selfmodal-content">
                            <div className="modal-header">
                            {/* <button type="button" className="close" data-dismiss="modal"   onClick={this.confirmWindowClose.bind(this)}>&times;</button> */}
                                <h4 className="modal-title h3 text-center">Confirmation Message</h4>                       </div>
                            <div className="modal-body modfields">  
                            <span className="fa " >success icon</span>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="submit" className="btn btn-primary allbtn" onClick={()=>{
                                    window.location = "/login"
                                }}>Login Now</button>
                            </div> 
                        </div> 
                    </div>
                </div>  : null}
            </div>
        )
    }
}


const mapStateToProps = state  => {
    return {
      redux: state,
    };
};    
  
  
const mapDispatchToProps = dispatch => {
    return {
        successBarActivate: (data) => dispatch({type: ActionTypes.SUCCESSBAR, data: data }),
        // searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
        userNameStyleActivate: (data) => dispatch({type: ActionTypes.USERNAMESTYLE, data: data }),
        reuserNameStyleActivate: (data) => dispatch({type: ActionTypes.REUSERNAMESTYLE, data: data }),
        userTypeStyleActivate: (data) => dispatch({type: ActionTypes.USERTYPESTYLE, data: data }),
    };
};


export default connect(mapStateToProps, mapDispatchToProps) (LoginView);