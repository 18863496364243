import React, {Component} from 'react';
import Dashboard from 'containers/dashboard';
// import ProjectDetail from 'containers/projectdetail';
// import ProjectDetailPage from 'containers/projectdetailpage';
// import ProjectDetailAdd from 'containers/projectdetailadd';
// import ProjectManager from 'containers/projectmanager';
// import Regokas from 'containers/regokas';
// import Regokasassigned from 'containers/regokasassigned';
// import Regokasunassigned from 'containers/regokasunassigned';
import LoginView from 'containers/login';
import {Switch, BrowserRouter, Route} from 'react-router-dom';
import 'css/core.css';
import 'css/root.css';
// import OfficeLocation from './officelocation';
// import Support from 'containers/support';
// import Chat from 'containers/chat';
// import Help from 'containers/help';
// import DesignHelp from 'containers/design-help';
import Terms from 'containers/terms';
import {ProjectDetail,ProjectDetailPage,ProjectDetailAdd,ProjectDetailEdit,ProjectManager,Regokas,MemberLicense,Regokasassigned,Regokasunassigned,Support,Help,DesignHelp,OfficeLocation,Chat} from './../containers/Pages';



class Root extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Dashboard} />
                    <Route path='/login' component={LoginView} />
                    <Route path='/login/session-timeout' component={LoginView} />
                    <Route path='/login/activateuser' component={LoginView} />
                    <Route path='/dashboard' component={Dashboard} />
                    <Route path='/memberlicense' component={MemberLicense} />
                    <Route path='/regokas' component={Regokas} />
                    <Route path='/regokasunassigned' component={Regokasunassigned} />
                    <Route path='/regokasassigned' component={Regokasassigned} />
                    <Route path='/projectdetail' component={ProjectDetail} />
                    <Route path='/projectdetailpage' component={ProjectDetailPage} />
                    <Route path='/projectdetailadd' component={ProjectDetailAdd} />
                    <Route path='/projectdetailedit' component={ProjectDetailEdit} />
                    <Route path='/projectmanager' component={ProjectManager} /> 
                    <Route path='/officelocation' component={OfficeLocation} />
                    <Route path='/support' component={Support} />
                    <Route path='/chat' component={Chat} />
                    <Route path='/help' component={Help} />
                    <Route path='/design-help' component={DesignHelp} />
                    <Route path='/terms' component={Terms} />
                </Switch>
            </BrowserRouter>
        );
      }
}

export default Root;