import React, {Fragment} from 'react';  
import TopHeader from 'components/header';
import OfficeLocationPage from './../views/officelocationpage';
import ProjectDetailpage from './../views/projectdetailpage';  
import ProjectDetailPagepage from './../views/projectdetailpagepm'; 
import ProjectDetailAddpage from './../views/projectdetailaddpage'; 
import ProjectDetailEditpage from './../views/projectdetaileditpage'; 
import ProjectManagerpage from './../views/projectmanagerpage'; 
import Regokasbox from './../views/regokasbox';  
import MemberLicenseView from './../views/memberlicenseview';  
import Supportpage from './../views/supportpage'; 
import Helppage from './../views/helppage'; 
import DesignHelppage from './../views/design-helppage'; 
import Chatpage from './../views/chatpage';  
import Regokasboxunassigned from './../views/regokasboxunassignedpage'; 
import Regokasboxassigned from './../views/regokasboxassignedpage';
const PagePrivate = ({children}) => {
       return (
       <Fragment>
               <TopHeader />
               {children}
       </Fragment>
       );
   }


export const OfficeLocation = () =>
 <PagePrivate >
   <OfficeLocationPage/>
 </PagePrivate>

export const ProjectDetail = () =>
<PagePrivate >
  <ProjectDetailpage/>
</PagePrivate>

export const ProjectDetailPage = () =>
<PagePrivate >
  <ProjectDetailPagepage/>
</PagePrivate>

export const ProjectDetailAdd = () =>
<PagePrivate >
  <ProjectDetailAddpage/>
</PagePrivate>
export const ProjectDetailEdit = () =>
<PagePrivate >
  <ProjectDetailEditpage/>
</PagePrivate>

export const ProjectManager = () =>
<PagePrivate >
  <ProjectManagerpage/>
</PagePrivate>

export const Regokas = () =>
<PagePrivate >
  <Regokasbox/>
</PagePrivate>

export const MemberLicense = () =>
<PagePrivate >
  <MemberLicenseView/>
</PagePrivate>

export const Regokasassigned = () =>
<PagePrivate >
  <Regokasboxassigned/>
</PagePrivate>

export const Regokasunassigned = () =>
<PagePrivate >
  <Regokasboxunassigned/>
</PagePrivate>
 
export const Support = () =>
<PagePrivate >
  <Supportpage/>
</PagePrivate>

export const Help = () =>
<PagePrivate >
  <Helppage/>
</PagePrivate>

export const DesignHelp = () =>
<PagePrivate >
  <DesignHelppage/>
</PagePrivate>

export const Chat = () =>
<PagePrivate >
  <Chatpage/>
</PagePrivate> 