import React, {Component} from 'react';
import createHistory from 'history/createBrowserHistory'
import Icon from 'react-icons-kit';
import {home} from 'react-icons-kit/fa/home';
import {hddO} from 'react-icons-kit/fa/hddO';
import {buildingO} from 'react-icons-kit/fa/buildingO';
import {tasks} from 'react-icons-kit/fa/tasks';
import {users} from 'react-icons-kit/fa/users';
import {objectGroup} from 'react-icons-kit/fa/objectGroup'
import {share2} from 'react-icons-kit/icomoon/share2'
import {userPlus} from 'react-icons-kit/icomoon/userPlus'
import {office} from 'react-icons-kit/icomoon/office'
import {ic_help_outline} from 'react-icons-kit/md/ic_help_outline'
import {headphones} from 'react-icons-kit/icomoon/headphones'
import 'css/sidenav.css'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Sidenav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummyData: [
                "Element 1",
                "Element 2",
                "Element 3",
                "Element 4"
            ],
            selected: "dashboard",
            floorList: "collapse",
            showSome: true,
            usertype:localStorage.getItem('usertype'),
        }
    }

    componentWillMount(){
        if(window.innerWidth <601){
            this.setState({
                showSome: false,
            })
        }
        console.log(window.innerWidth );
        const history = createHistory();
        const location = history.location; 
        if(location.pathname==='/dashboard'){
            this.setState({
                selected: 'dashboard'
            })
        }else if(location.pathname==='/regOkas'){
            this.setState({
                selected: 'regOkas'
            })
        }else if(location.pathname==='/regOkasunassigned'){
            this.setState({
                selected: 'regOkas'
            })
        }else if(location.pathname==='/regOkasassigned'){
            this.setState({
                selected: 'regOkas'
            })
        }else if(location.pathname==='/projectdetail' || location.pathname==='/projectdetailadd'){
            this.setState({
                selected: 'projectdetail'
            })
        }else if(location.pathname==='/projectmanager'){
            this.setState({
                selected: 'projectmanager'
            })
        }else if(location.pathname==='/officelocation'){
            this.setState({
                selected: 'officelocation'
            })
        }else if(location.pathname==='/support'  || location.pathname==='/chat'){
            this.setState({
                selected: 'support'
            })
        }else if(location.pathname==='/help'  || location.pathname==='/help'){
            this.setState({
                selected: 'help'
            })
        }
    }

    setSelected = (selected) => {
        console.log(this.state.selected.includes("Element"))
        if(!selected.includes("Element")) {
            this.setState({
                floorList: "collapse"
            })
        }
        this.setState({
            selected: selected
        })
    }

    toggleFloors = () => {
        this.setState( prevState => {
            if(prevState.floorList==="collapse") {
                this.setState({
                    floorList: "collapse show"
                })
            }
            else {
                this.setState({
                    floorList: "collapse"
                })
            }
        })
    }
    openNav =()=> {
        this.setState({
            showSome: !this.state.showSome
        })
        // console.log('clcl');
        // this.setState(prevState => ({
        //     listOpen: !prevState.listOpen
        // }));
    }

    redirectPage = (page) => {
        console.log(page);
        const history = createHistory({forceRefresh: true});  
       history.push(page);
       

    }

    render() {
        return (
            <div> 
                  
               
               {this.state.showSome ?   <div className="sidenav"> 
               {window.innerWidth <801 ? <span className="qwer" onClick={this.openNav.bind(this)}>&#9776;</span> : null }

<ul> 
 
{this.state.usertype==='2' ?
    <span>
        <li><span className={this.state.selected==="regOkas" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('regOkas'); this.setSelected("regOkas")}}><div className="menuLabel"><Icon icon={hddO} size={22} /><span>Register dOKAS Box</span></div></span></li>
        <li><span className={this.state.selected==="projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('projectdetail'); this.setSelected("projectdetail")}}><div className="menuLabel"><Icon icon={share2} size={18} /><span>Project Detail</span></div></span></li> 
    </span>   
: 
    <span>   
        <li><span className={this.state.selected==="dashboard"?"sidenav-button selected":"sidenav-button"} onClick={() => {this.redirectPage('dashboard'); this.setSelected("dashboard")}}><div className="menuLabel"><Icon icon={home} size={22} /><span>Dashboard</span></div></span></li> 
        <li><span className={this.state.selected==="regOkas" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('regOkas'); this.setSelected("regOkas")}}><div className="menuLabel"><Icon icon={hddO} size={22} /><span>Register OKAS Box</span></div></span></li>
        <li><span className={this.state.selected==="officelocation" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('officelocation'); this.setSelected("officelocation")}}><div className="menuLabel"><Icon icon={office} size={22} /><span>Office Locations</span></div></span></li>
        <li><span className={this.state.selected==="projectmanager" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('projectmanager'); this.setSelected("projectmanager")}}><div className="menuLabel"><Icon icon={userPlus} size={18} /><span>Project Manager</span></div></span></li> 
        <li><span className={this.state.selected==="projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('projectdetail'); this.setSelected("projectdetail")}}><div className="menuLabel"><Icon icon={share2} size={18} /><span>Project Detail</span></div></span></li>
        <li><span className={this.state.selected==="support" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('support'); this.setSelected("support")}}><div className="menuLabel"><Icon icon={headphones} size={18} /><span>Support</span></div></span></li>
        <li>
            <span className={this.state.selected==="help" ? "sidenav-button selected" : "sidenav-button"} 
                onClick={() => {this.redirectPage('help'); this.setSelected("help")}}>
                <div className="menuLabel">
                    <Icon icon={ic_help_outline} size={18} />
                    <span>Help</span>
                </div>
            </span>
        </li>
        <li><div className="sidenav-okas-logo">
    <img src={require('images/okasLogo.png')}/>
</div></li>
        {/* <Link to="/officelocation/"  onClick={() => {this.setSelected("officelocation")}} >About</Link> */}
    </span>   
}
    
   
</ul>

{/* <div className="sidenav-okas-logo">
    <img src={require('images/okasLogo.png')}/>
</div> */}
</div> :
<div className="sidenav1"> 
<span className="qwer " onClick={this.openNav.bind(this)}>&#9776;</span>
</div>

}
          
            
            </div>
        )
    }
}

export default Sidenav;