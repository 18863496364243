import React from 'react';
import Sorting from './Sorting';
import {sort} from 'react-icons-kit/fa/sort';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import createHistory from 'history/createBrowserHistory' ; 
import 'css/regokas.css';  
import 'mdbreact/dist/css/mdb.css';
import { Input } from 'mdbreact'; 
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross'; 
import Loader from '../components/loader';
import { MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";
import Success from 'images/Success.svg';   
import Error from 'images/Error.svg';  
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types'; 
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { timingSafeEqual } from 'crypto';
import SearchBar from './SearchBar';
import windowSize from 'react-window-size';
import Style from 'style-it';

// class Regokasbox extends React.Component {
class Regokasbox extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            okasBoxData: [],
            kk: 'asdvhj',
            okasBoxType: '',
            registrationid: '',
            licenceid: '',
            error: '',
            nameerror: '',
            namess:'',
            sortedType:'',
            searchValue: '',
            actualdata:[],
            disabledvalue:'',
            successmsgmodaldata:localStorage.getItem('dataadded'),
            usertype:localStorage.getItem('usertype'),
            fatchokasdata:'',
            loader:'',
            showmodels:'',
            currentPage: 1,
            todosPerPage: 10,
            statuson:false,statustw:false,statusth:false,
            statusfo:false,statusfi:false,statussi:false,
            statusse:false,statusei:false,statusni:false,
            statuste:false, 
        };
        this.handleClickpage = this.handleClickpage.bind(this);
    } 
    state = {
        checkedA: true,
        checkedB: true,
        checkedF: true,
    }; 
    handleChange12 = name => event => {
        this.setState({ [name]: event.target.checked });
    };


//------------------------------------------------ error msg function start--------------------------------------------------------
    errormsg=()=>{
        // console.log('sdfdsfdfgdfgdfgdfgdfgdfgdfg')
        setTimeout(
            function() { 
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata:'',
                }) 
            }
            .bind(this),3000
        );
    }
//------------------------------------------------ error msg function end ------------------------------------------------

//------------------------------------------------ get data from id function start ------------------------------------------------
 
    checkregid = (edsf) => {   
        this.setState({ 
            licenceid : '',
            okasboxtype : '',
            fatchokasdata:'',
        })  
        let okasBox_serialNo = this.state.registrationid.trim();
        // console.log('data')
        if(okasBox_serialNo.length > 1){
            // console.log('data00')
            let TokenValue = 'Token '+localStorage.getItem("auth");
            var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')
            } 
            let okasBox_serialNo = this.state.registrationid.trim();
            let tt ={
                okasBox_serialNo
            };
            this.setState({
                loader: true ,  
            })

            fetch('/we/we/checkregid/' ,{
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
            .then(response => response.json()) 
            .then(data=>{  
                this.setState({
                    licenceid : data.okasBox_version,
                    okasboxtype : data.okasBox_type,
                    fatchokasdata:'1',
                })  
            }) 
            this.setState({
                loader: false ,  
            }) 
        } 
    }  
//------------------------------------------------ get data from id function end ------------------------------------------------
//------------------------------------------------ Delete Succ msg start//------------------------------------------------
    crosssuccessfullmsg = () => {   
        localStorage.removeItem('dataadded');
        this.setState({
            successmsgmodaldata:'',
        }) 
    }  
//------------------------------------------------ Delete Succ msg start//------------------------------------------------
 

    componentDidMount(){  
        const history = createHistory();
        const location = history.location; 
        var test = location.pathname.split("/");
        let filteredbyurl = test[2];  
        this.getallrealdata();
    }

    getallrealdata = () =>{
        this.setState({
            loader: true ,  
        })
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': TokenValue
        } 
   
        fetch('/we/we/okastabledata/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json())
        .then(okasBoxData => {
            // okasBoxData = okasBoxData.slice(0,10)
            this.setState({
                okasBoxData,
                actualdata: okasBoxData
            })
            this.setState({
            loader: false ,  
        })
        }) 
    }




// ---------------------------------Change password function start----------------------------------------------
    changepasswordmodal = (pmid123,pmname123) => {    
        this.setState({
            pmid: pmid123,
            pmname: pmname123
        }) 
    }
// ---------------------------------Change password function end----------------------------------------------



handleClickpage(event, position) {
    let pageNo = 0;
    if(position==='position'){
        pageNo = event
    }else{
        pageNo = event.target.id
    }
    this.setState({
      currentPage: Number(pageNo)
    });
    this.renderTableData('type1');
    this.renderTableData('pagination'); 
}


//------------------------------------------------ print main data(List view) function start----------------------------
    renderTableData = (type) => {
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }    

        ///-------------------------pagination start,,,,-----------------------------------------------
        let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo);  
        if(type==='pagination'){ 
            let pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage); i++) {
                pageNumbers.push(i);
            } 
            let renderPageNumbers = pageNumbers.map((number,i) => { 
                if ((this.state.currentPage-3 < i+1) && ((this.state.currentPage+3 > i+1 || 6 > i+1) )  ) {
                    return (
                        <li 
                        // style={{display: this.state.currentPage-2<-2 || this.state.currentPage+2>number? '' :'none'}} 
                        className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li> 
                    );
                }
            });
            return renderPageNumbers;
        }
        ///-------------------------pagination end----------------------------------------------------
        if(type==='type1'){
            var typee = 0; 
                let xx = currentTodos.map((e,index)=>{
                    // if(e.okas_box_number===1 && e.okas_box_number===qwerty  ){
                        let pmid=e.okas_box_number;
                        let pmname=e.okasBox_type;
                        typee = typee+1;
                        return (
                    
                    <tr key={index}> 
                        <td   className={this.props.windowWidth >760 ? "qwerty" : ""}>{(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+index+1}</td>
                        {/* <td   className={this.props.windowWidth >760 ? "qwerty" : ""}>{e.okas_box_number}</td> */}
                        <td>{e.okasBox_type}</td>
                        <td>{e.okasBox_serialNo}</td>
                        <td  className={this.props.windowWidth >760 ? "qwerty" : ""}>{e.okasBox_version}</td>
                        <td>{e.okasBox_assigned_to}</td> 
                        <td  className={this.props.windowWidth >760 ? "qwerty" : ""}>{e.created_at.substring(0, 10)} {e.created_at.substring(11, 19)}</td> 
                    {this.props.redux.globalVar.userTypeStyle==='2' ?
                            <td>{'IP:'+e.ip}<br/>{'SM:'+e.subnet_mask}<br/>{'GW:'+e.gateway}</td>
                    : 
                    ''
                    }

                    {this.props.redux.globalVar.userTypeStyle==='2' ?
                        <td><a className="button"  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.changepasswordmodal(pmid,pmname)}} ><b>Config</b></a></td>
                    : 
                        ''
                    } 
                    </tr>
                    )                       
            });
            return xx;
        }  
    }
//------------------------------------------------ print main data(List view) function end--------------------------------

// ----------------------------------------------submit change password start--------------------------------------------------
    onPasswordChangekHandler = (buttonaction, pmids) => {
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
          'X-CSRFToken': localStorage.getItem('csrftoken')
        }  
        let ipaddress = this.state.ipaddress; 
        let subnetmask = this.state.subnetmask; 
        let defaultgateway = this.state.defaultgateway; 
        let ip=ipaddress;
        let subnet_mask=subnetmask;
        let gateway=defaultgateway;
        // (/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(value) ? true : false)
        let tt ={
            ip,
            subnet_mask,
            gateway,
        };
        // console.log(tt)
        // console.log(pmids)
        if(ipaddress.length < 1){
        this.setState({
            error: 'Enter a IP Address' , 
            ipaddress:'errorfield' 
        }) 
        }else if(subnetmask.length < 1){
            this.setState({
                error: 'Enter a Subnet Mask' , 
                subnetmask:'errorfield' 
            }) 
        }else if(defaultgateway.length < 1){
            this.setState({
                error: 'Enter a Default Gateway' , 
                defaultgateway:'errorfield' 
            }) 
        }else { 
            if(buttonaction==='add') { 
                this.setState({
                    disabledvalue: 'disabled',
                }) 
            }
            fetch('/we/we/updateiprequest/'+pmids ,{
            method: "PUT",
            headers: headers,
            body: JSON.stringify(tt)
            })
            // .then(response => response.json()) 
            .then(response => {
                    response.json()
                if(response.status==200){ 
                    this.setState({
                        successmsgmodaldata:'dataadded',
                        successmodalstates:'add',
                        disabledvalue: '',
                    })  
                    this.getallrealdata(); 
                    this.oncolseClickHandler();
                    this.errormsg(); 
                    if(buttonaction==='add') {   
                        this.onclosemodel(); 
                        const history = createHistory({forceRefresh: true});  
                        history.push('/regOkas'); 
                    }else if(buttonaction==='addanother'){ 
                    } 
                }else{
                    alert('Action not completed.'); 
                } 
            })  
            .then(data=>{}) 
        } 
    } 
// ----------------------------------------------submit change password start--------------------------------------------------


    oncolseClickHandler = ( event) => {
        this.setState({
            okasboxtype: '' , 
            registrationid:'', 
            licenceid:'' 
        })   
    } 

// --------------------------------------------------------open model function start----------------------------

    onopenmodel = ( event) => {
        this.setState({
            showmodels: 'true' ,  
        })   
        this.props.searchBarActivate(false)
    } 

// --------------------------------------------------------open model function end----------------------------

// --------------------------------------------------------close model function start----------------------------
    
    onclosemodel = ( event) => {
        this.setState({
            showmodels: '' ,  
        }) 
        this.oncolseClickHandler();  
    } 

// --------------------------------------------------------close model function End-----------------------
onCloseSearchbar = () =>{
    this.props.searchBarActivate(false) 
}
 
// ----------------------------Submit all data in data base POST API Start--------------------------------------
    onAddClickHandler = (buttonaction ) => { 
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': TokenValue,
          'X-CSRFToken': localStorage.getItem('csrftoken')
        }  
        let okasBox_serialNo = this.state.registrationid.trim();
        let okasBox_version = this.state.licenceid.trim();
        let okasBox_type = this.state.okasboxtype.trim();
        let okas_box_number = new Date().valueOf().toString();  
        let tt =[{
            okasBox_serialNo,
            okasBox_version,
            okasBox_type,
            okas_box_number
        }]; 
        if(okasBox_type.length < 1){
            this.setState({
                error: 'Please Select aOkas Box Type id of okas box'  , 
                okasboxtypeerror:'errorfield selectlistss' 
            }) 
        }else if(okasBox_serialNo.length < 1){
            this.setState({
                error: 'Please enter a valid Serial Number of okas box' , 
                registrationiderror:'errorfield', 
            }) 
        }else  if(okasBox_version.length < 1){
            this.setState({
                error: 'Please enter a valid license id of okas box' , 
                licenceiderror:'errorfield'   
            }) 
        }else  if(okasBox_serialNo.length < 1){
            this.setState({
                error: 'Serial Number cannot blank' , 
                registrationiderror:'errorfield' 
            }) 
        }else  { 
            if(buttonaction==='add') { 
                this.setState({
                    disabledvalue: 'disabled',
                })
            }
            this.setState({
                loader: true ,  
            })
            fetch('/we/we/insertokasbox/' ,{
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
            .then(response =>{ response.json()
                if(response.status==200){ 
                    this.setState({
                        successmsgmodaldata:'dataadded',
                        successmodalstates:'add',
                        disabledvalue: '',
                    })  
                    this.getallrealdata(); 
                    this.oncolseClickHandler();
                    this.errormsg(); 
                    if(buttonaction==='add') {   
                        this.onclosemodel(); 

                    }else if(buttonaction==='addanother'){ 
                    } 
                }else{
                    alert('Action not completed.'); 
                } 
            }) 
            .then(data=>{ 
                this.setState({
                    loader: false ,  
                }) 
            }) 
        }
    } 
//----------------------------------------- Submit all data in data base POST API End----------------------------------------

 
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

 
//-------------------------------------------Render data after sorting Function Start----------------------------------------------
    restoreData = (data) => {
        this.setState({
            okasBoxData: data,
        })
    }
//-----------------------------------------------Render data after sorting Function End--------------------------------------------------


//------------------------------------------------------- sorting Function Start----------------------------------------------
    sortingFunc = (type,searchValue) => {   
        if(type!=="search"){
            this.props.searchBarActivate(false) 
        }
        if(type=="okas_box_number"){
            this.setState({ 
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statuson: !this.state.statuson,
            })
        }
        if(type=="okasBox_type"){
            this.setState({
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statustw: !this.state.statustw,
            })
        }
        if(type=="okasBox_serialNo"){
            this.setState({ 
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statusth: !this.state.statusth,
            })
        }
        if(type=="okasBox_version"){
            this.setState({ 
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statusfo: !this.state.statusfo,
            })
        }
        if(type=="okasBox_assigned_to"){
            this.setState({ 
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statusfi: !this.state.statusfi,
            })
        }
        if(type=="created_at"){
            this.setState({
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statussi: !this.state.statussi,
            })
        }
        if(type=="ip"){
            this.setState({ 
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, 
                statusse: !this.state.statusse,
            })
        } 
        if(this.state.sortedType!==type && type!=='search' ){ 
            // console.log(this.restoreData)
        this.setState({
            sortedType: type
        })
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }else if(type==='search'){ 
            type='search'; 
            let sendingData = this.state.actualdata.map((e)=>{
                let returnData = {
                    okasBox_type: e.okasBox_type,
                    okasBox_serialNo: e.okasBox_serialNo,
                    okasBox_version: e.okasBox_version,
                    okasBox_assigned_to: e.okasBox_assigned_to,
                    created_at: e.created_at.substring(0, 10) +' '+ e.created_at.substring(11, 19), 
                }
                return returnData
            }) 
            Sorting(this.restoreData, type, sendingData,searchValue); 
        }else {  
            type='reverse';
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }
    }
    //---------------------------------------------------------- sorting Function End--------------------------------------------------


    onSearchClick = () =>{
        this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
    } 
    

    render(){ 
        return Style.it(`
        @media only screen and (max-width: 760px){
      td:nth-of-type(1):before { content: "S.NO"; }
      td:nth-of-type(2):before { content: "BOX TYPE"; }
      td:nth-of-type(3):before { content: "SERIAL NO."; }
      td:nth-of-type(4):before { content: "BOX VERSION"; }
      td:nth-of-type(5):before { content: "STATUS"; }
      td:nth-of-type(6):before { content: "DATE OF PURCHASE
      "; }
    }`,
            <div className="selfcontainer "
            style={{
                position: !this.props.redux.globalVar.backgroundScrollStop ? "fixed" : "absolute",
                overflow: !this.props.redux.globalVar.backgroundScrollStop ? "hidden" : ""
            }}
            
            > 
                {this.state.loader?<Loader/>:null}
                <div className= {this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  > 
                    <div className="inblksuccfull"><img src={Success}  /></div>
                    <div className="inblksucc1">
                        SUCCESS !
                        <a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
                    </div> 
                </div> 
                {this.props.redux.globalVar.searchBarShow ?
                    <div className='row searchinput'>
                        {/* <input  type="text" ref={input => input && input.focus()} name="namess" id="namess" placeholder="Search" 
                            value={this.state.searchValue}
                            onChange={(e)=>{
                                this.setState({
                                    searchValue: e.target.value
                                    },()=>{
                                    this.sortingFunc('search',this.state.searchValue)
                                })
                            }}
                        />   */}
                        <SearchBar 
                        autoFocus
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', this.state.searchValue)
                          })
                        }
                        }
                        onRequestSearch={() => this.sortingFunc('search', this.state.searchValue)}
                        resetSearch = {(e)=>{
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', '')
                          })
                        }}
                        />
                    </div> 
                :''} 
                {this.props.redux.globalVar.userTypeStyle!=='200' ?'':    
                    <div className="addregOkasButton">
                        <a className="addregOkasButtonicon" data-toggle="modal" data-target="#regiokasbox" onClick={this.onopenmodel.bind(this)}>
                        <Icon icon={ iosPlusEmpty } size={42} style={{color: " #15599F"}} />   ADD NEW OKAS BOX 
                        </a> 
                    </div>
                }          
                <div className='row'>
                <h3 className="sentence-case">{this.props.redux.globalVar.reUserNameStyle}'s OKAS Box inventory</h3>
                <h6 className="mb-3 mt-0">Find the comprehensive view and details of all the boxes in your inventory.</h6>
                    <div className="tests">     
                        <table className={this.props.windowWidth >760 ? "table table-striped tablecontent":""}> 
                            <thead>                  
                            {this.props.redux.globalVar.userTypeStyle==='2' ? 
                                <tr> 
                                    <th className={this.props.windowWidth >760 ? "qwerty" : ""}>S.No.  
                                    </th>
                                    <th>Box Type 
                                    <Icon icon={this.state.statustw?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_type','')}}/>
                                    </th> 
                                    <th>Serial No<Icon icon={this.state.statusth?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_serialNo','')}}/></th>
                                    <th className={this.props.windowWidth >760 ? "qwerty" : ""}>Box Version<Icon icon={this.state.statusfo?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_version','')}}/></th>
                                    <th>Status<Icon icon={this.state.statusfi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_assigned_to','')}}/></th>  
                                    <th className={this.props.windowWidth >760 ? "qwerty" : ""}>DATE OF PURCHASE<Icon icon={this.state.statussi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('created_at','')}}/></th>  
                                    <th>Ips<Icon icon={this.state.statusse?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('ip','')}}/></th>
                                    <th  className="paddingright3">Config Ip Address</th>
                                </tr>
                            :  
                                <tr> 
                                    <th className={this.props.windowWidth >760 ? "qwerty" : ""} style={{width: "5%"}}>S.No  
                                    </th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "33%"}} >Box Type 
                                        <Icon icon={this.state.statustw?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_type','')}}/>
                                    </th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "33%"}}>Serial No.
                                        <Icon icon={this.state.statusth?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_serialNo','')}}/>
                                    </th>
                                    <th className={this.props.windowWidth >760 ? "qwerty" : ""} style={{width: "15%"}}>Box Version
                                        <Icon icon={this.state.statusfo?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okasBox_version','')}}/>
                                    </th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "33%"}}>Status
                                        <Icon icon={this.state.statusfi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('okasBox_assigned_to','')}}/>
                                    </th>
                                    <th   className="paddingright3 qwerty" style={{width: "20%"}}>DATE OF PURCHASE
                                        <Icon icon={this.state.statussi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('created_at','')}}/>
                                    </th>  
                                </tr>  
                            } 
                            </thead>
                            <tbody>
                                {this.renderTableData('type1')}
                            </tbody> 
                        </table>  
                        <div  className="pull-right">
                            {this.state.todosPerPage < this.state.okasBoxData.length ?
                                <ul className="pagination pull-right paginat">  
                                    <li> 
                                        <span className="">Showing {(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+1} -{this.state.currentPage*this.state.todosPerPage-this.state.okasBoxData.length<0?this.state.currentPage*this.state.todosPerPage:this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span> 
                                    </li>
                                    <li>
                                        <a style={{pointerEvents: this.state.currentPage===1 ? 'none': ''}} 
                                        onClick= {this.state.currentPage == -1+this.state.currentPage*this.state.todosPerPage ? null :  
                                        this.handleClickpage.bind(this,this.state.currentPage-1, 'position')
                                        }
                                        aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                        </a>
                                    </li> 
                                    {this.renderTableData('pagination')} 
                                    <li className="">
                                        <a className=""  style={{pointerEvents: Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage)===Number(this.state.currentPage) ? 'none': ''}}
                                        onClick= {this.state.currentPage*this.state.todosPerPage == this.state.okasBoxData.length ? null :  
                                        this.handleClickpage.bind(this,this.state.currentPage+1, 'position')
                                        }  

                                        aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            :null}
                        </div> 
                    </div>   
                </div>   
                {this.state.showmodels?
                    <div className="selfmodal fade"   aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog"> 
                            <div className="selfmodal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal"  onClick={this.onclosemodel.bind(this)}>&times;</button>
                                    <h4 className="modal-title">ADD OKAS BOX</h4> {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null } 
                                </div>
                                <div className="modal-body modfields">
                                <div className="md-form"> 
                                </div>  <br/>
                                    <Input label="Serial Number" type="text" name="registrationid" placeholder="Serial Number"  className={this.state.registrationiderror ? this.state.registrationiderror : ""}
                                    value={this.state.registrationid}
                                    onChange={(e)=>{
                                        this.setState({
                                            registrationid: e.target.value
                                        })

                                    }}
        
                                    onKeyUp={(e)=>{this.checkregid(e.target.value)}}
                                    />   <br/>

                                <Input label="OKAS Box Type" readOnly type="text" name="okasboxtype" placeholder="OKAS Box Type"   className={this.state.okasboxtypeerror ? this.state.okasboxtypeerror : ""}
                                    value={this.state.okasboxtype} 
                                        onChange={(e)=>{
                                            this.setState({
                                                okasBoxType: e.target.value
                                            })

                                        }}/>
                                        <br/>
                                    <Input label="License Id" type="text" readOnly name="licenceid" placeholder="License Id"  className={this.state.licenceiderror ? this.state.licenceiderror : ""}
                                    value={this.state.licenceid}
                                    onChange={(e)=>{
                                        this.setState({
                                            licenceid: e.target.value
                                        })
                                    }}
                                    />   <br/>   
        
                                    <span className="fieldmend"></span>
                                </div>
                                <div className="modal-footer"> 
                                    <button type="button" className="btn allbtn" data-dismiss="modal"  onClick={this.onclosemodel.bind(this)}>Cancel</button> 
                                    <button type="button"  disabled = {this.state.licenceid ? null : 'disabled'} className="btn addbtn allbtn"  onClick={this.onAddClickHandler.bind(this,'addanother')}>Add Another</button> 
                                    <button type="button"  disabled = {this.state.licenceid ? null : 'disabled'}  className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this,'add')}>Add</button>
                                </div>
                            </div> 
                        </div>
                    </div> 
        :''}  



                    {/*config modal start */}

                    <div className="modal fade" id="hometheaterbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog"> 
                            <div className="modal-content">
                                <div className="modal-header"> 
                                    <h4 className="modal-title">Config IP Form</h4> {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null } 
                                </div> 
                                <div className="modal-body modfields">                          
                                
                                    <Input label="IP Address" type="text" name="ipaddress" placeholder="IP Address"   value={this.state.ipaddress}
                                    onChange={(e)=>{
                                        this.setState({
                                            ipaddress: e.target.value
                                        },()=>console.log(this.state.ipaddress))

                                    }}
                                    />   <br/>
                                    <Input label="Subnet Mask" type="text" name="subnetmask" placeholder="Subnet Mask"  value={this.state.subnetmask}
                                    onChange={(e)=>{
                                        this.setState({
                                            subnetmask: e.target.value
                                        },()=>console.log(this.state.subnetmask))

                                    }}
                                    />   <br/>
                                    <Input label="Default Gateway" type="text" name="defaultgateway" placeholder="Default Gateway"  value={this.state.defaultgateway}
                                    onChange={(e)=>{
                                        this.setState({
                                            defaultgateway: e.target.value
                                        },()=>console.log(this.state.defaultgateway))

                                    }}
                                    />   <br/> 
                                        <span className="fieldmend"></span>
                                </div>
                                <div className="modal-footer">
                                <button type="button" className="btn allbtn" data-dismiss="modal"  onClick={this.oncolseClickHandler.bind(this)}>Cancel</button>  
                                <button type="button"  disabled = {this.state.disabledvalue ? this.state.disabledvalue : null}  className="btn btn-primary allbtn"  onClick={this.onPasswordChangekHandler.bind(this,'add',this.state.pmid)}>Update </button>
                                </div>
                            </div> 
                        </div> 
                </div>   
                    {/*config modal end */}  
            </div>
    );
}
    
}


const mapStateToProps = state  => {
    return {
      redux: state,
    };
  };
  
  
  const mapDispatchToProps = dispatch => {
    return {
      searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
      searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
      backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),

    };
  };
  

export default windowSize(connect(mapStateToProps, mapDispatchToProps) (Regokasbox));