import React from 'react';
import Sorting from './Sorting';
import { sort } from 'react-icons-kit/fa/sort';
import { hddO } from 'react-icons-kit/fa/hddO';
import { sortDown } from 'react-icons-kit/fa/sortDown';
import { sortUp } from 'react-icons-kit/fa/sortUp';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import Cookies from 'js-cookie';
import 'css/regokas.css';
import { plus } from 'react-icons-kit/iconic/plus'
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { cross } from 'react-icons-kit/icomoon/cross';
import { search } from 'react-icons-kit/icomoon/search';
import Loader from '../components/loader';
import { close } from 'react-icons-kit/ikons/close'
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import Add from 'images/headericon/Add.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Minus from 'images/Minus.svg';
import Plus from 'images/Plus.svg';
import Success from 'images/Success.svg';
import Error from 'images/Error.svg';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import IPCheck from 'ipcheck';
import checkIp from 'check-ip';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { download } from 'react-icons-kit/fa/download'

import { Input } from 'mdbreact';
import Edit from 'images/headericon/Edit.svg';
import downloadActive from 'images/headericon/downloadActive.svg';
import downloadUnactive from 'images/headericon/downloadUnactive.svg';
import SearchBar from './SearchBar';
import windowSize from 'react-window-size';
import mc from 'images/mc.svg';
import ab from 'images/ab.svg';
import uvodb from 'images/uvodb.svg';
import vodb from 'images/vodb.svg';
import ml from 'images/ml.svg';
import Style from 'style-it';
import SnackBar from './../containers/SnackBar';

class ProjectDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            okasBoxData: [],
            boxesForConfig: [],
            showmodels1ML: false,
            currentBuildingSelected: '',
            kk: 'asdvhj',
            namess: '',
            sortedType: '',
            searchValue: '',
            actualdata: [],
            statedata: '',
            hidebutton: true,
            rowOpenNumber: '',
            showRow: false,
            okasBoxDatalist: [],
            totbox: [],
            okasboxmodaletype: '',
            someNewOne: '',
            textvalue: [],
            okasbox1: [],
            realtimebuildingid: '',
            disabledvalue: '',
            successmsgmodaldata: localStorage.getItem('dataadded'),
            usertype: this.props.redux.globalVar.userTypeStyle,
            loader: '',
            currentPage: 1,
            todosPerPage: 10,
            currentConfigType: '',
            currentBoxSelected: '',
            currentProjectBoxDetails: [],
            ipError: false,
            subnetError: false,
            defaultError: false,
            showError: false,
            countForBoxAssign: 0,
            okasboxid: '',
            statuson: false, statustw: false, statusth: false,
            statusfo: false, statusfi: false, statussi: false,
            statusse: false, statusei: false, statusni: false,
            statuste: false,
            ipaddress: '',
            subnetmask: '',
            defaultgateway: '',
            projectDetailMobileView: false,
            snackbarActive: false,
            snackbarMessage: '',

        };
        this.handleClickpage = this.handleClickpage.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }




    componentWillMount() {
        this.checkuserApi();
        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                })
            }
                .bind(this),
            3000
        );


    }


    checkuserApi = () => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/userCheckApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(checkuserApidata => {
                this.setState({
                    checkuserApidata,
                    actualdata: checkuserApidata
                }, () => {
                    // this.props.userNameStyleActivate(this.state.checkuserApidata.user_name)
                    // this.props.reuserNameStyleActivate(this.state.checkuserApidata.first_name)
                    // this.props.userTypeStyleActivate(this.state.checkuserApidata.User_Type) 
                    if (this.state.checkuserApidata.status !== '200') {
                        localStorage.removeItem('auth');
                        // alert('invalid authentication found');
                        // this.props.history.push('/login') 
                        window.location = "/login";
                    } else {

                    }
                })
            })
    }





    //------------------------------------------------ Delete Succ msg start//------------------------------------------------
    crosssuccessfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            successmsgmodaldata: '',
        })
    }
    ////------------------------------------------------ Delete Succ msg end//------------------------------------------------




    handleChange(e) {
        //console.log(e.target.checked)
        //console.log(e.target.name)
        let oldArray = this.state.textvalue;
        let current = e.target.value;
        if (e.target.checked == true) {
            oldArray.includes(current) ? oldArray.pop(current) : oldArray.push(current);
            if (!oldArray.includes(current)) {
                this.setState({
                    textvalue: oldArray
                })

            }
        } else {
            oldArray = _.pull(oldArray, current);
            if (!oldArray.includes(current)) {
                this.setState({
                    textvalue: oldArray
                })
            }

        }
        this.setState({
            okasboxid: this.state.textvalue.join(),
        });

    }



    updateConfigIP = (buttonaction, pmids) => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
          'X-CSRFToken': localStorage.getItem('csrftoken')
        }

        let ipaddress = this.state.ipaddress;
        let subnetmask = this.state.subnetmask;
        let defaultgateway = this.state.defaultgateway;
        var ipError = checkIp(ipaddress);
        var subnetError = checkIp(subnetmask);
        var defaultError = checkIp(defaultgateway);
        let ip = ipaddress;
        let subnet_mask = subnetmask;
        let gateway = defaultgateway;
        let tt = {
            ip,
            subnet_mask,
            gateway,
        };

        if (ipError.isValid &&
            subnetError.isValid &&
            defaultError.isValid &&
            ipaddress !== '0.0.0.0' &&
            subnetmask !== '0.0.0.0' &&
            defaultgateway !== '0.0.0.0'
        ) {
            fetch('/we/we/okasBox_detail/' + pmids, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(tt)
            })
                .then(data => {
                    // console.log(data)
                    if (data.status == 200) {
                        // alert('Box configured successfully');
                        this.setState({
                            snackbarMessage: 'Box configured successfully.',
                            snackbarActive: true,                                      
                          })
                        // const history = createHistory({forceRefresh: true});  
                        // history.push('/projectdetail');   
                        this.getokasboxtabledata();
                        this.setState({
                            showmodels: false,
                        })
                    } else if (data.status == 400) {
                        // alert('IP has already given, please check');
                        this.setState({
                            snackbarMessage: 'IP has already given, please check.',
                            snackbarActive: true,                                      
                          })
                    } else {

                        // alert('Action not completed.'); 
                        this.checkuserApi();
                    }
                }
                )
        } else {
            this.setState({
                ipError: ipError.isValid,
                subnetError: subnetError.isValid,
                defaultError: defaultError.isValid,
                showError: true
            })
            if (this.state.ipaddress === '0.0.0.0') {
                this.setState({
                    ipError: false
                })
            }
            if (this.state.subnetmask === '0.0.0.0') {
                this.setState({
                    subnetError: false
                })
            }
            if (this.state.defaultgateway === '0.0.0.0') {
                this.setState({
                    defaultError: false
                })
            }

        }

    }


    componentDidMount() {
        this.setState({
            loader: true,
        })
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/projectBuildingApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxData => {
                console.log(okasBoxData)
                this.setState({
                    okasBoxData,
                    actualdata: okasBoxData
                }, () => {
                    this.renderTableData();
                    //console.log(this.state.okasBoxData);
                })
                this.setState({
                    loader: false,
                })
            })



        fetch('/we/we/okastabledata/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxDatalist => {
                this.setState({
                    okasBoxDatalist
                }, () => {
                    // this.getokasnumber();
                    //console.log(this.state.okasBoxDatalist)
                })
            })
    }
    // MasterController


    getokasboxtabledata = () => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/projectBuildingApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxData => {
                this.setState({
                    okasBoxData,
                    actualdata: okasBoxData
                }, () => {
                    this.renderTableData();
                    //console.log(this.state.okasBoxData);
                })
                this.setState({
                    loader: false,
                })
            })



        fetch('/we/we/okastabledata/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxDatalist => {
                this.setState({
                    okasBoxDatalist
                }, () => {
                    // this.getokasnumber();
                    //console.log(this.state.okasBoxDatalist)
                })
            })
    }

    getokaslist = (okasboxtype, buildingid, okasboxtypetoget) => {
        //console.log(buildingid)
        var typee = 0;
        let count = 0;
        let xx = this.state.okasBoxDatalist.map((x, index) => {
            if (x.okasBox_type === okasboxtype && x.okasBox_assigned_to === 'unassigned') {
                typee = typee + 1;
                count++
                return (
                    <tr key={index}>
                        <input type="hidden" value={buildingid} />
                        <input type="hidden" value={okasboxtypetoget} />
                        <td style={{ paddingLeft: '5%' }}><input type="checkbox" name={x.okasBox_type}
                            onChange={
                                this.handleChange
                            }
                            value={x.okasBox_id} /></td>
                        {/* <td style={{paddingLeft: '5%'}}>{x.okasBox_id}</td>  */}
                        <td>{x.okasBox_type}</td>
                        <td>{x.okasBox_serialNo}</td>

                    </tr>
                )
            }
        });
        // console.log(count)
        this.setState({
            okasboxmodaletype: okasboxtype
        })
        if (count === 0) {
            this.setState({
                someNewOne: (<tr >
                    <td colSpan='4' className="text-center">
                        No box available
                        </td>
                </tr>),
                countForBoxAssign: count
            })
        } else {
            this.setState({
                someNewOne: xx,
                countForBoxAssign: count
            })
        }
        this.setState({
            realtimebuildingid: buildingid
        })
        this.setState({
            realtimeokasboxtypetoget: okasboxtypetoget
        })
        return xx;
    }



    getokasnumber = (okasboxtypetoget, buildingid) => {
        var typee = 0;
        let totbox1 = 0;
        let totbox2 = 0;
        let totbox3 = 0;
        let totbox4 = 0;
        let totbox5 = 0;
        let currentBuildingData = this.state.okasBoxData.filter((e)=>e.building_id===buildingid)
        let xx = this.state.okasBoxDatalist.map((e, index) => {       
            let test = _.split(okasboxtypetoget, ',');
            for (let i = 0; i < test.length; i++) {
                if (test[i] == e.okasBox_id) {
                    if (e.okasBox_type === 'Master Controller') {
                        totbox1++;
                    }
                    if (e.okasBox_type === 'VOD Box') {
                        totbox2++;
                    }
                    if (e.okasBox_type === 'UVOD Box Box') {
                        totbox3++;
                    }
                    if (e.okasBox_type === 'Audio Box') {
                        totbox4++;
                    }
                    if (e.okasBox_type === 'Home Theatre Box') {
                        totbox5++;
                    }
                }
            }
        });
        return (
            <span>
                {totbox1 > 0 ?
                    <span>
                        <span>
                            <div className="">
                                {/* <Icon icon={hddO} size={20} /> */}
                                <span className="icon-box-detail"><img src={mc} /></span>
                                <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: this.getCountOfBoxType('Master Controller') === 0 ? 'none' : '',paddingLeft: "5px"  }} onClick={() => { this.setState({ currentConfigType: 'Master Controller', showmodels1: true }) }}>
                                    Master Controller({this.getCountOfBoxType('Master Controller')})
            </a>
                                {/* <a  className="button" style={{marginRight: '15%', float: 'right' }}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Master Controller',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> */}
                            </div>
                        </span>
                        <span>
                            <div className="">
                            <span className="icon-box-detail"><img src={vodb} /></span>
                                <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: this.getCountOfBoxType('VOD Box') === 0 ? 'none' : '',paddingLeft: "5px"  }} onClick={() => { this.setState({ currentConfigType: 'VOD Box', showmodels1: true }) }}>
                                    VOD Box({this.getCountOfBoxType('VOD Box')})
            </a>
                                {/* <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('VOD Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> */}
                            </div>
                        </span>
                        <span>
                            <div className="">
                            <span className="icon-box-detail"><img src={uvodb} /></span>
                                <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: this.getCountOfBoxType('UVOD Box') === 0 ? 'none' : '',paddingLeft: "5px"  }} onClick={() => { this.setState({ currentConfigType: 'UVOD Box', showmodels1: true }) }}>
                                    UVOD Box({this.getCountOfBoxType('UVOD Box')})
            </a>
                                {/* <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('UVOD Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> */}
                            </div>
                        </span>
                        <span>
                            <div className="">
                            <span className="icon-box-detail"><img src={ab} /></span>
                                <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: this.getCountOfBoxType('Audio Box') === 0 ? 'none' : '',paddingLeft: "5px"  }} onClick={() => { this.setState({ currentConfigType: 'Audio Box', showmodels1: true }) }}>
                                    Audio Box({this.getCountOfBoxType('Audio Box')})
            </a>
                                {/* <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Audio Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> */}
                            </div>
                        </span>
                        <span>
                            <div className="">
                            <span className="icon-box-detail"><img src={ml} /></span>
                                <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: currentBuildingData[0].license_id.length === 0 ? 'none' : '',paddingLeft: "5px" }} onClick={() => { this.setState({ currentConfigType: 'Member License', showmodels1ML: true }) }}>
                                    Member License({currentBuildingData[0].license_id.length})
            </a>
                            </div>
                        </span>
                    </span>
                    : null}
                {totbox5 > 0 ?
                    <span>
                        <div className="">
                        <span className="icon-box-detail"><img src={uvodb} /></span>
                            <a data-toggle="modal" data-target="#configip" style={{ pointerEvents: this.getCountOfBoxType('Home Theatre Box') === 0 ? 'none' : '',paddingLeft: "5px"  }} onClick={() => { this.setState({ currentConfigType: 'Home Theatre Box', showmodels1: true }) }}>
                                Home Theatre Box ({this.getCountOfBoxType('Home Theatre Box')})
            </a>
                            {/* <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Home Theatre Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a>  */}
                        </div>
                    </span>
                    : null}
            </span>

        )

    }



    onAddClickHandler = (buttonaction, realtimebuildingid, realtimeokasboxtypetoget) => {

        let TokenValue = 'Token ' + localStorage.getItem("auth");
        //console.log(TokenValue)
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
          'X-CSRFToken': localStorage.getItem('csrftoken')

        }
        let okasBox_id = realtimeokasboxtypetoget + ',' + this.state.okasboxid;
        //console.log(okasBox_id)
        let tt = { okasBox_id };
        if (buttonaction === 'add') {
            this.setState({
                disabledvalue: 'disabled',
            })

        }
        this.setState({
            loader: true,
        })
        fetch('/we/we/projectApi_detail/' + realtimebuildingid, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(tt)
        })
            .then(response => response.json())
            .then(data => {
                var dataadded = "dataadded";
                localStorage.setItem("dataadded", dataadded);
                if (buttonaction === 'add') {
                    const history = createHistory({ forceRefresh: true });
                    history.push('/projectdetail');
                }
            })
    }

    handleClickpage(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
        this.renderTableData('type1');
        this.renderTableData('pagination');
    }



    renderTableData = (type) => {
        let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo);

        if (type === 'pagination') {
            let pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length / this.state.todosPerPage); i++) {
                pageNumbers.push(i);
            }
            let renderPageNumbers = pageNumbers.map((number, i) => {
                if ((this.state.currentPage - 3 < i + 1) && ((this.state.currentPage + 3 > i + 1 || 6 > i + 1))) {
                    return (
                        <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li>
                    );
                }
            });
            return renderPageNumbers;
        }
        if (type === 'type1') {
            var typee = 0;
            let xx = currentTodos.map((e, index) => {                
                typee = typee + 1;
                let trrt = e.building_id;
                let dateconv = e.created_at;
                let okasboxnoofdatabase = e.okasBox_id;
                return (
                    <React.Fragment>
                        <tr>
                            <td className={this.props.windowWidth > 760 ?"padding-left-10px" : "qwerty"} style={{ maxWidth: '30px'}} >
                                {this.state.rowOpenNumber === index && this.state.showRow ?
                                    <img src={Minus} style={{ cursor: 'pointer' }} onClick={(e) => {
                                        this.setState({
                                            rowOpenNumber: index,
                                            showRow: !this.state.showRow,
                                            currentBuildingSelected: trrt
                                        })
                                    }} />
                                    :
                                    <img src={Plus} style={{ cursor: 'pointer'}} onClick={(x) => {
                                        this.setState({
                                            rowOpenNumber: index,
                                            showRow: true,
                                            currentBuildingSelected: trrt,
                                            currentProjectBoxDetails: e.box_details
                                        })
                                    }} />
                                }
                            </td>
                            <td className= {this.props.windowWidth > 1024 || this.props.windowWidth < 760 ? "": "qwerty" } >{(this.state.currentPage * this.state.todosPerPage) - this.state.todosPerPage + index + 1}</td>
                            <td>{e.building_name}</td>
                            <td>{e.owner_name}</td>
                            <td>{e.address}</td>
                            <td>{e.total_number_of_okas_box}</td>
                            <td>{e.project_manager}</td>
                            <td>{e.office_address}</td>
                            <td>{dateconv.substring(0, 10)} {dateconv.substring(11, 19)}</td>
                            {/* {this.props.redux.globalVar.userTypeStyle==='1' ? */}
                            <td className= {this.props.windowWidth > 1024 || this.props.windowWidth < 760 ? "": "qwerty" }><Link
                                to={{
                                    pathname: "/projectdetailedit",
                                    search: "?buildingid=" + e.building_id,
                                    state: { fromDashboard: true }
                                }}
                            ><img src={Edit} />
                            </Link></td>
                            <td className= {this.props.windowWidth > 1024 || this.props.windowWidth < 760 ? "": "qwerty" }>
 
                                 <img src={e.file===null ? downloadUnactive : e.file.length === 0 ? downloadUnactive : downloadActive } style={{ cursor: e.file===null ? 'not-allowed' : e.file.length === 0 ? 'not-allowed' : 'pointer' }}
                                    data-toggle={e.file===null ? 'dfs' : e.file.length === 0 ? 'tooltip' : 'dfs'} title={e.file===null ? '' : e.file.length === 0 ? "Project configuration is in progress" : ''} data-placement="left"
                                    onClick={() => {
                                        let TokenValue = 'Token ' + localStorage.getItem("auth");
                                        fetch(`/we/we/projectPdf?building_id=${e.building_id}`, {
                                            method: "GET",
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Authorization": TokenValue
                                            }
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                window.location.assign(data);
                                            })
                                    }}
                                />
                            </td>
                            <td 
                            style={{display:this.props.windowWidth < 760 ? "": "none" }} 
                            onClick={()=>{
                                this.setState({
                                    rowOpenNumber: index,
                                    showRow: true,
                                    currentBuildingSelected: trrt,
                                    currentProjectBoxDetails: e.box_details,
                                },()=>{
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
                                    this.setState({
                                        projectDetailMobileView: true
                                    })
                                })
                        }
                        }
                            >View</td>
                            {/* } */}
                            </tr>
                        {this.state.rowOpenNumber === index && this.state.showRow && this.props.windowWidth > 760 ? <React.Fragment>
                            <td colspan="11" style={{backgroundColor: "#F9F9F2", padding: "2%"}}>
                                <div className="row datasetleft" style={{minHeight: "150px"}}>
                                    <div className="col-lg-4 col-md-4 col-sm-4 h-100 pdbox1" style={{minHeight: "150px"}}>
                                        <b><span className="custom-font-partial-h"> Building Details</span> </b>
                                        <div className="">{/* Building ID -  */}{e.building_id}</div>
                                        <div className="senihading">{/* Building Name -  */}{e.building_name}</div>
                                        <div className="">{/* Building Address -  */}{e.address}</div>
                                        <br></br>
                                        <div className="senihading">{/* Owner Name -  */}{e.owner_name}</div>
                                        <div className=""> {/* Date -  */}{dateconv.substring(0, 10)} </div>
                                        <div className=""> {/* Time -  */}{dateconv.substring(11, 19)}</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 h-100 pdbox2" style={{minHeight: "150px"}}>
                                        <b className="">
                                        <span className="custom-font-partial-h"> Project Manager Detail</span>
                                            </b>
                                        <div className="senihading">  {/*  Project Manager Name -  */}{e.project_manager}</div>
                                        <div className="">   {/* Project Manager Location -  */}{e.office_address}</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 h-100" style={{minHeight: "150px"}}>
                                        <b className="">
                                        <span className="custom-font-partial-h">Okas Box Detail</span>
                                              </b>
                                        <div className="row ksdjfhsdk">
                                            {this.getokasnumber(okasboxnoofdatabase, e.building_id)}
                                            {/* <div className=""><a className="button"  data-toggle="modal" data-target="#hometheaterbox">Add More...</a></div> */}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </React.Fragment> : null}  
                    </React.Fragment>
                )
            });
            return xx;
        }
        else if (type === 'type2') {
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_id === 123) {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{e.okasBox_id}</td>
                            <td>{e.okasBox_type}</td>
                            <td>{e.okasBox_serialNo}</td>
                            <td>{e.okasBox_version}</td>
                            <td>{e.okasBox_assigned_to}</td>
                        </tr>
                    )
                }
            });
            return xx1;
        }
    }
    restoreData = (data) => {
        this.setState({
            okasBoxData: data,

        })
    }
    sortingFunc = (type, searchValue) => {
        if (type !== "search") {
            this.props.searchBarActivate(false)
        }


        if (type == "building_id") {

            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statuson: !this.state.statuson,
                rowOpenNumber: ''
            })
        }
        if (type == "building_name") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statustw: !this.state.statustw,
                rowOpenNumber: ''
            })
        }
        if (type == "owner_name") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusth: !this.state.statusth,
                rowOpenNumber: ''
            })
        }
        if (type == "address") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusfo: !this.state.statusfo,
                rowOpenNumber: ''
            })
        }
        if (type == "total_number_of_okas_box") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusfi: !this.state.statusfi,
                rowOpenNumber: ''
            })
        }
        if (type == "project_manager") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statussi: !this.state.statussi,
                rowOpenNumber: ''
            })
        }
        if (type == "office_address") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusse: !this.state.statusse,
                rowOpenNumber: ''
            })
        }
        if (type == "created_at") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusei: !this.state.statusei,
                rowOpenNumber: ''

            })
        }


        if (this.state.sortedType !== type && type !== 'search') {
            this.setState({
                sortedType: type
            })
            Sorting(this.restoreData, type, this.state.okasBoxData);
        } else if (type === 'search') {

            type = 'search';
            Sorting(this.restoreData, type, this.state.actualdata, searchValue);
        } else {

            type = 'reverse';
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }
    }


    onSearchClick = () => {
        this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
    }

    configBoxDetail = (e) => {
        let boxType = this.state.currentConfigType;
        let boxes = [];
        this.state.okasBoxData.forEach((innerElement) => {
            if (innerElement.building_id === this.state.currentBuildingSelected) {
                innerElement.box_details.forEach((inner) => {
                    if (boxType === inner.okasBox_type) {
                        boxes.push(inner)
                    }
                })
            }
        })
        return boxes
    }

    getCountOfBoxType = (boxType) => {
        let count = 0;
        this.state.currentProjectBoxDetails.forEach((e) => {

            if (e.okasBox_type === boxType) {
                count++
            }
        })
        return count
    }

    renderProjectdetailsMobileView = (type) =>{
        let projectDetails  = this.state.okasBoxData.filter((e)=>e.building_id===this.state.currentBuildingSelected)
        if(projectDetails.length!==0){
            return projectDetails[0][type]
        }{
            return "Not Known"
        }
    }

    render() {
        return Style.it(`
        @media only screen and (max-width: 760px){
      td:nth-of-type(2):before { content: "S.NO."; }
      td:nth-of-type(3):before { content: "PROJECT NAME"; }
      td:nth-of-type(4):before { content: "OWNER NAME"; }
      td:nth-of-type(5):before { content: "ADDRESS"; }
      td:nth-of-type(6):before { content: "BOXES"; }
      td:nth-of-type(7):before { content: "PROJECT MANAGER"; }
      td:nth-of-type(8):before { content: "AREA"; }
      td:nth-of-type(9):before { content: "INSTALLED D&T"; }
      td:nth-of-type(10):before { content: "EDIT"; }
      td:nth-of-type(11):before { content: "DOWNLOAD"; }
      td:nth-of-type(12):before { content: "VIEW"; }

      td.config-table:nth-of-type(1):before { content: "SERIAL NO."; }
      td.config-table:nth-of-type(2):before { content: "BOX VERSION"; }
      td.config-table:nth-of-type(3):before { content: "IPS"; }
      td.config-table:nth-of-type(4):before { content: " "; }
      td.config-table-ml:nth-of-type(1):before { content: "SERIAL NO."; }
      td.config-table-ml:nth-of-type(2):before { content: "MEMBER LICENSE"; }
    }`,
            <div>
                             <SnackBar open={this.state.snackbarActive} handleSnackbarClose={()=>{    this.setState({
      snackbarActive: false
    })}} message={this.state.snackbarMessage}/>
                <div className="selfcontainer"
                style={{
                    position: !this.props.redux.globalVar.backgroundScrollStop ? "fixed" : "absolute",
                    overflow: !this.props.redux.globalVar.backgroundScrollStop ? "hidden" : ""
                }} 

                >
                    {this.state.loader ? <Loader /> : null}
                    {this.props.redux.globalVar.searchBarShow ?
                        <div className='row searchinput'>
                    <SearchBar 
                        autoFocus
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', this.state.searchValue)
                          })
                        }
                        }
                        onRequestSearch={() => this.sortingFunc('search', this.state.searchValue)}
                        resetSearch = {(e)=>{
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', '')
                          })
                        }}
                        />
                        </div>
                        : ''}
                    {/* <div className="searchbar"> <Icon icon={search} />      Search Project Details</div> */}
                    {this.props.redux.globalVar.userTypeStyle === '2' ? '' :
                        <div className="addregOkasButton">
                            <Link to="projectdetailadd">
                                <div className="addregOkasButtonicon button-custom-height-size">
                                    <Icon icon={iosPlusEmpty} size={42} style={{ color: " #15599F" }} />
                                    <span className="hidden-xs hidden-sm button-color">ADD NEW PROJECT</span>  
                                    
                        </div>
                            </Link>
                    <div  className="hidden-xs hidden-md  hidden-lg button-color sm-heading-btn">Add New Project</div>
                        </div>
                    }
                    <div className='row'>
                    <h3>Dear {this.props.redux.globalVar.reUserNameStyle}, Listed below are the comprehensive details of each of your projects</h3> 
                    <h6 className="mb-3 mt-0">To add a new project click on, "Add New Project" </h6> 
                        <div className={this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >


                            <div className="inblksuccfull"><img src={Success} /></div>
                            <div className="inblksucc1">
                                SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                            </div>
                        </div>
                        <div className="tests">
                            <table className={this.props.windowWidth >760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}>
                                <thead>
                                   <tr>
                                        <th style={{ maxWidth: '30px' }}></th>
                                        <th className= {this.props.windowWidth > 1024 ? "": "qwerty" }>
                                        <div className="align-vertical ">
                                            <div>S.No.</div>                                         
                                        </div>
                                        </th >
                                        <th>
                                            <div className="align-vertical">
                                            <div>Project Name</div> 
                                            <div >
                                            <Icon icon={this.state.statustw ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('building_name', '') }} />
                                            </div>
                                            </div>
                                        </th>
                                        <th >
                                            <div className="align-vertical">
                                            <div> Owner Name</div> 
                                            <div>
                                            <Icon icon={this.state.statusth ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('owner_name', '') }} />
                                            </div>
                                            </div>
                                         </th> 
                                         <th >
                                             <div className="align-vertical">
                                            <div>Address</div> 
                                            <div>
                                            <Icon icon={this.state.statusfo ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('address', '') }} />
                                            </div>
                                             </div>
                                         </th> 
                                         <th>
                                             <div className="align-vertical">
                                            <div>Boxes</div> 
                                            <div>
                                            <Icon icon={this.state.statusfi ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('total_number_of_okas_box', '') }} />
                                            </div>
                                             </div>
                                         </th> 
                                         <th >
                                             <div className="align-vertical">
                                            <div>Project Manager</div> 
                                            <div>
                                            <Icon icon={this.state.statussi ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('project_manager', '') }} />
                                            </div>
                                             </div>
                                         </th> 
                                         <th>
                                             <div className="align-vertical">
                                            <div>AREA</div> 
                                            <div >
                                            <Icon icon={this.state.statusse ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('office_address', '') }} />
                                            </div>
                                             </div>
                                         </th> 
                                         <th >
                                         <div className="align-vertical">
                                            <div>installed D&T</div> 
                                            <div>
                                            <Icon icon={this.state.ei ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('created_at', '') }} />
                                            </div>
                                             </div>
                                         </th>   
                                         <th className= {this.props.windowWidth > 1024 ? "paddingright3": "qwerty" }>
                                             <div className="align-vertical">
                                             Edit
                                             </div>
                                             </th>
                                             <th className= {this.props.windowWidth > 1024 ? "paddingright3": "qwerty" }>
                                             <div className="align-vertical">
                                             Download
                                             </div>
                                             </th>
                                         </tr>
                                </thead>
                                <tbody>
                                {this.renderTableData('type1')}
                                </tbody>
                            </table>
                            <div className="pull-right">
                                {this.state.todosPerPage < this.state.okasBoxData.length ?
                                    <ul className="pagination pull-right paginat">
                                        <li>
                                            <span className="">Showing {(this.state.currentPage * this.state.todosPerPage) - this.state.todosPerPage + 1} -{this.state.currentPage * this.state.todosPerPage - this.state.okasBoxData.length < 0 ? this.state.currentPage * this.state.todosPerPage : this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span>
                                        </li>
                                        <li>
                                            <a
                                                onClick={this.state.currentPage == -1 + this.state.currentPage * this.state.todosPerPage ? null :
                                                    this.handleClickpage.bind(this, this.state.currentPage - 1)
                                                }
                                                aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </li>

                                        {this.renderTableData('pagination')}
                                        <li className="">
                                            <a className=""
                                                onClick={this.state.currentPage * this.state.todosPerPage == this.state.okasBoxData.length ? null :
                                                    this.handleClickpage.bind(this, this.state.currentPage + 1)
                                                }

                                                aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="hometheaterbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{this.state.okasboxmodaletype}</h4>
                                </div>
                                <div className="modal-body">
                                    <table className="tablecontent tablecontentas">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {/* <th style={{paddingLeft: '5%'}}>OKAS BOX NO.</th> */}
                                                <th>BOX TYPE</th>
                                                <th>SERIAL NO.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.someNewOne}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn allbtn" data-dismiss="modal" onClick={() => this.setState({ okasboxid: '', someNewOne: '', textvalue: [] })}>Cancel</button>
                                    <button type="submit" disabled={this.state.okasboxid === '' ? true : false} className="btn btn-primary allbtn"
                                        onClick={
                                            this.onAddClickHandler.bind(this, 'add', this.state.realtimebuildingid, this.state.realtimeokasboxtypetoget)
                                        }
                                    >Add</button>
                                    <input type="hidden" name="okasboxid" id="okasboxid" value={this.state.okasboxid} placeholder=""
                                        onChange={(e) => {
                                            this.setState({
                                                okasboxid: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showmodels1 ?
                    <div className="selfmodal fade modal-dialog-centered" id="configip" role="dialog" aria-hidden="true"  data-backdrop="static" data-keyboard="false" style={{zIndex: 1051}}>
                        <div className="selfmodal-dialog"
                        style={{width: this.props.windowWidth > 760 ? "70%" : "90%"}}
                        >
                            <div className="selfmodal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Config {this.state.currentConfigType}</h4>
                                </div>
                                <div className="modal-body">
                                    <table 
                                    className={this.props.windowWidth >760 ? " table table-striped tablecontent tablecontentas" : "mobile-device-margin-zero"}
                                     style={{ borderRadius: '5px' }}>
                                        <thead>
                                            <tr>
                                                <th>SERIAL NO.</th>
                                                <th>BOX VERSION</th>
                                                <th>IPS</th>
                                                {this.props.redux.globalVar.userTypeStyle === '2' ? <th>CONFIG IP</th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.configBoxDetail().map((e) => {
                                                return (
                                                    <tr>
                                                        <td className="config-table">{e.okasBox_serialNo}</td>
                                                        <td className="config-table">{e.okasBox_version}</td>
                                                        <td className="config-table">
                                                            {'IP:' + e.ip}<br />{'SM:' + e.subnet_mask}<br />{'GW:' + e.gateway}
                                                        </td>
                                                        {this.props.redux.globalVar.userTypeStyle === '2' ? <td 
                                                         className="config-table"
                                                        >
                                                            {/* <a data-toggle="modal" data-target="#configForm" style={{color: 'blue'}} onClick={()=>{this.setState({currentConfigType: 'Master Controller', currentBoxSelected: e.okasBox_id})}}>         */}
                                                            <a data-toggle="modal" data-target="#configForm" style={{ color: 'blue' }} onClick={() => { this.setState({ showmodels: true, currentConfigType: this.state.currentConfigType, currentBoxSelected: e.okasBox_id, ipaddress: e.ip, subnetmask: e.subnet_mask, defaultgateway: e.gateway, }) }}>
                                                                Config
                                </a>
                                                        </td> : null}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                    </table>
                                </div>
                                <div className="modal-footer  align-btn-mobile">
                                    <button type="submit" data-dismiss="modal" onClick={() => { this.setState({ showmodels1: false }) }} className="btn allbtn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
{/* start modals for member license */}

{this.state.showmodels1ML ?
                    <div className="selfmodal fade modal-dialog-centered" id="configip" role="dialog" aria-hidden="true" style={{zIndex:1051}} data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog" style={{width: this.props.windowWidth > 760 ? "50%" : "90%"}}>
                            <div className="selfmodal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Member License</h4>
                                </div>
                                <div className="modal-body">
                                    <table 
                                     className={this.props.windowWidth >760 ? " table table-striped tablecontent tablecontentas" : "mobile-device-margin-zero"}
                                     style={{ borderRadius: '5px' }}>
                                        <thead>
                                            <tr>
                                                <th>SERIAL NO.</th>
                                                <th>Member License</th>                                              
                                            </tr>
                                        </thead>
                                        <tbody>                                        
                                        {this.state.okasBoxData.filter((e)=>e.building_id===this.state.currentBuildingSelected)[0].license_id.map((e,index)=>{
                                          return  <tr>
                                                        <td className="config-table-ml">{index+1}</td>
                                                        <td  className="config-table-ml ">{e}</td>  
                                                    </tr>
                                        })}
                                                               
                                        </tbody>

                                    </table>
                                </div>
                                <div className="modal-footer  align-btn-mobile">
                                    <button type="submit" data-dismiss="modal" onClick={() => { this.setState({ showmodels1ML: false }) }} className="btn allbtn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
{/* end modals for member license */}

                {/*config modal start */}
                {this.state.showmodels ?
                    <div className="selfmodal fade modal-dialog-centered" id="configForm" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false" style={{zIndex: 1052}}
                    >
                        <div className="selfmodal-dialog" style={{width: this.props.windowWidth > 760 ? "50%" : "90%",}}>
                            <form action="javascript:void(0)">
                                <div className="selfmodal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Config IP Form</h4>
                                    </div>
                                    {this.state.showError ? <div style={{ marginRight: '10px', marginLeft: '10px' }}>            {!this.state.ipError ? <div className="alert alert-danger mx-2">IP error
</div> : null}
                                        {!this.state.subnetError ? <div className="alert alert-danger mx-2">Subnet Error </div> : null}
                                        {!this.state.defaultError ? <div className="alert alert-danger mx-2">default Error</div> : null} </div> : null}
                                    {/* <div><b>Okas Box Number : </b>{this.state.pmid}</div> */}

                                    <div className="modal-body modfields" style={{ paddingTop: '10px' }}>
                                        <Input label="IP Address" type="text" name="ipaddress" placeholder="IP Address" value={this.state.ipaddress}
                                            onChange={(e) => {
                                                this.setState({
                                                    ipaddress: e.target.value,
                                                    showError: false,
                                                }, () => console.log(this.state.ipaddress))

                                            }}
                                        />
                                        <br />
                                        <Input label="Subnet Mask" type="text" name="subnetmask" placeholder="Subnet Mask" value={this.state.subnetmask} error="something"
                                            onChange={(e) => {
                                                this.setState({
                                                    subnetmask: e.target.value,
                                                    showError: false
                                                }, () => {
                                                    console.log(checkIp(this.state.subnetmask))
                                                }
                                                )
                                            }}
                                        />   <br />
                                        <Input label="Default Gateway" type="text" name="defaultgateway" placeholder="Default Gateway" value={this.state.defaultgateway}
                                            onChange={(e) => {
                                                this.setState({
                                                    defaultgateway: e.target.value,
                                                    showError: false
                                                })
                                            }}
                                        />   <br />
                                        <span className="fieldmend"></span>
                                    </div>
                                    <div className="modal-footer  align-btn-mobile">
                                        <button type="button" className="btn allbtn" data-dismiss="modal" onClick={() => this.setState({
                                            ipaddress: '',
                                            showError: false,
                                            subnetmask: '',
                                            defaultgateway: '',
                                            showmodels: false
                                        })} >Cancel</button>
                                        <button type="submit" className="btn btn-primary allbtn" onClick={this.updateConfigIP.bind(this, 'add', this.state.currentBoxSelected)}>Update </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : ''}
                                            {this.state.projectDetailMobileView? 
    <div className="selfmodal fade in modal-dialog-centered"   aria-hidden="false" data-backdrop="static" data-keyboard="false">
        <div className="selfmodal-dialog"
         style={{
             width: this.props.windowWidth > 760 ? "50%" : "90%",
        }}
         > 
            <div className="selfmodal-content" style={{backgroundColor: "#F9F9F2", padding: "2%"}} >
                <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" 
                                    onClick={(e) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

                                        this.setState({
                                            rowOpenNumber: '',
                                            showRow: false,
                                            currentBuildingSelected: "",
                                            projectDetailMobileView: false
                                        })
                                    }} 
                >&times;</button>
                    <h4 className="modal-title">Project Details</h4>  
                </div>
                <div className="modal-body modfields">  
                <React.Fragment>
                {this.props.windowWidth < 760 ? <React.Fragment>
                            <div style={{backgroundColor: "#F9F9F2", padding: "2%"}}>
                                <div className="row datasetleft">
                                    <div className="col-xs-12 pdboxBottom p-2" >
                                        <b>Building Details</b>
                                        <div className="">{this.renderProjectdetailsMobileView('building_id')}</div>
                                        <div className="senihading">{this.renderProjectdetailsMobileView('building_name')}</div>
                                        <div className="">{this.renderProjectdetailsMobileView('address')}</div>
                                        <br></br>
                                        <div className="senihading">{this.renderProjectdetailsMobileView('owner_name')}</div>
                                        <div className="">{this.renderProjectdetailsMobileView('created_at').substring(0, 10)} </div>
                                        <div className="">{this.renderProjectdetailsMobileView('created_at').substring(11, 19)}</div>
                                    </div>
                                    <div className="col-xs-12 pdboxBottom" >
                                        <b className="">Project Manager Detail</b>
                                        <div className="senihading">{this.renderProjectdetailsMobileView('project_manager')}</div>
                                        <div className="">{this.renderProjectdetailsMobileView('office_address')}</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 h-100">
                                        <b className=""> Okas Box Detail </b>
                                        <div className="row ksdjfhsdk">
                                            {this.getokasnumber(this.renderProjectdetailsMobileView('okasBox_id'),this.renderProjectdetailsMobileView('building_id'))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : null}                         
                        </React.Fragment>
                </div>
                <div className="modal-footer align-btn-mobile">
                    <div type="button" className="btn allbtn" 
                    onClick={(e) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

                        this.setState({
                            rowOpenNumber: '',
                            showRow: false,
                            currentBuildingSelected: "",
                            projectDetailMobileView: false
                        })
                    }} 
                    >Close</div>       
                </div> 
            </div> 
        </div>
    </div>  
     :''}     

                    
                {/*config modal end */}
            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({ type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({ type: ActionTypes.SEARCHBARSTYLE, data: data }),
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),

    };
};


export default windowSize(connect(mapStateToProps, mapDispatchToProps) (ProjectDetailPage));
