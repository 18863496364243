import React from 'react';
import createHistory from 'history/createBrowserHistory';
// import Icon from 'react-icons-kit';
// import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty'; 
import Img1 from 'images/Download.png';
// import foot1 from 'images/Footstep1.svg';
// import foot2 from 'images/Footstep2.svg';
// import foot3 from 'images/Footstep3.svg';
// import foot4 from 'images/Footstep4.svg';
// import foot5 from 'images/Footstep5.svg';
// import foot6 from 'images/Footstep6.svg';
import foot7 from 'images/Footstep7.svg';
import scope from 'images/scope.svg';
// import footstep from 'images/Footstep.svg';
// import Cookies from 'js-cookie';
import _ from 'lodash';
import 'css/dashboardContent.css';
// import {hddO} from 'react-icons-kit/fa/hddO';
// import {share2} from 'react-icons-kit/icomoon/share2';
// import {headphones} from 'react-icons-kit/icomoon/headphones';
// import {ic_help_outline} from 'react-icons-kit/md/ic_help_outline';
import okasdesignstudiosmall from 'images/okasdesignstudiosmall.png';
import weokassmall from 'images/weokassmall.png';
// import {u231B} from 'react-icons-kit/noto_emoji_regular/u231B';
// import {list} from 'react-icons-kit/entypo/list';
// import {grid} from 'react-icons-kit/entypo/grid';
// import {download3} from 'react-icons-kit/icomoon/download3' 
import Help from 'images/headericon/Help.svg';  
import GridViewBlue from 'images/headericon/GridViewBlue.svg';  
// import Support from 'images/headericon/Support.svg';  
import GridViewGray from 'images/headericon/GridViewGray.svg';  
import ListViewBlue from 'images/headericon/ListViewBlue.svg';  
import ListViewGray from 'images/headericon/ListViewGray.svg';  
import RegisterdOkasBoxes from 'images/headericon/RegisterdOkasBoxes.svg';  
import RemainingOkasBoxes from 'images/headericon/RemainingOkasBoxes.svg';  
import CompleteProject from 'images/headericon/CompleteProject.svg';    
// import Add from 'images/headericon/Add.svg';  
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; 
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import windowSize from 'react-window-size';
import Style from 'style-it';




class DashboardContent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            okasBoxData: [],
            projectdet:[],
            kk: 'asdvhj',
            assignCount: 0,
            unAssignCount: 0,   
            totalBoxes: 0,  
            hideGridView: false,
            totalregboxnumber: 0,
            ticketdata1:[],
            ticketid:'',
            currentPage: 1,
            todosPerPage: 10,
            totalprojectdone:0,
            okasBoxDataUnassign: [],
            okasBoxDataAll: [],
        };
        this.handleClickpage = this.handleClickpage.bind(this);
    } 

    handleClickpage(event, position) {
        let pageNo = 0;
        if(position==='position'){
            pageNo = event
        }else{
            pageNo = event.target.id
        }

        this.setState({
          currentPage: Number(pageNo)
        });
        this.renderTableData('type1');
        this.renderTableData('pagination'); 
    } 

    componentWillMount(){
        this.fetchOkasBoxUnassign();
        this.fetchAllBox();
    }



    fetchOkasBoxUnassign = () => {
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': TokenValue
      }
      
      fetch('/we/we/okasunassigneddata/' ,{
              method: "GET",
              headers: headers
          })
      .then(response => response.json())
      .then(okasBoxData => {
          this.setState({
              okasBoxDataUnassign: okasBoxData
            })
      })
    }

    fetchAllBox = () =>{
        this.setState({
            loader: true ,  
        })
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': TokenValue
        } 
   
        fetch('/we/we/okastabledata/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json())
        .then(okasBoxData => {
            this.setState({
                okasBoxDataAll: okasBoxData,
            })
            this.setState({
            loader: false ,  
        })
        }) 
    }

    componentDidMount(){ 

        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
        }   
        fetch('/we/okastabledata/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json())
        .then(projectdet => { 
            const result = _.filter(projectdet, word => word.assigned_to!=='unassigned' );
            // const result = projectdet.filter(word => word.assigned_to!=='unassigned');  
            this.setState({
                totalregboxnumber : projectdet.length,
                assignCount: result.length,
                unAssignCount: projectdet.length -result.length, 
            })  
        }) 

        fetch('/we/we/ticketApi/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json()) 
        .then(ticketdata1 => {  
            if(ticketdata1[0]){
                this.setState({
                    ticketdata1:ticketdata1, 
                    ticketid:ticketdata1[0].ticket_id, 
                    ticketstatus:ticketdata1[0].status, 
                    ticketsubject:ticketdata1[0].subject, 
                    ticketdescription:ticketdata1[0].description, 
                },()=>{
                    this.renderTableData("ticketdata"); 
                    // console.log(this.state.ticketdata1)
                    // console.log(this.state.ticketid)
                })
                this.setState({
                    loader: false ,  
                })
            }else{
                this.setState({
                    ticketdata1:'', 
                    ticketid:'', 
                    ticketstatus:'', 
                    ticketsubject:'', 
                    ticketdescription:'', 
                })
                this.setState({
                    loader: false ,  
                })
            }
             
        })

            

        fetch('/we/we/projectBuildingApi/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json())
        .then(okasBoxData => {
            var totalBoxes = 0;
            okasBoxData.forEach((e)=>{
                totalBoxes = e.total_number_of_okas_box+ totalBoxes
            })
            // console.log(totalBoxes)
            this.setState({
                okasBoxData,
                totalBoxes,
                totalprojectdone : okasBoxData.length,
            },()=>{
            //   console.log(this.state.totalprojectdone)
            })
        })
    }
    

//this funtion is used to limit the word with it goes after the permissible limit.
// text is the text we want to apply function on
// limit is the limit after which we want to extract word from.
    sliceText = (text,limit) => {
         if(text.length <limit){
             return text
         }else{
             return text.substring(0,limit)+'...'
         }   
    }


    renderTableData = (type) => {
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }



                    
        let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo);  
        if(type==='pagination'){ 
            let pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage); i++) {
                pageNumbers.push(i);
            } 
            let renderPageNumbers = pageNumbers.map(number => {
                return (
                    <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li> 
                );
            });
            return renderPageNumbers;
        } 

        if(type==='type1'){ 
            var typee = 0;
            let xx = this.state.okasBoxData.map((e,index)=>{
                // if(e.okas_box_number===1 && e.okas_box_number===123  ){
                    typee = typee+1;
                    return (
                        <div className="col-sm-6 col-md-4 col-lg-4  mainthumbnailbig"  key={index}>
                            <div className="thumbnail mainthumbnail">
                                <div className="caption maincaption" style={{height: '200px'}}>
                                    {/* <span className="downloadicon"><Icon icon={download3} size={20} /></span>  */}
                                    <h3>{e.building_name}</h3>
                                    <p className="p11" style={{height: "30px"}}>{this.sliceText(e.address,40)}</p>
                                    <p className="p12"  style={{height: "30px",marginBottom: '5px'}}>{this.sliceText(e.project_manager,20)}</p>
                                    <p className="p13"  style={{height: "30px"}}>{this.sliceText(e.office_address,40)}</p>
                                </div>
                                <div>
                                    <h5>No. of OKAS Box - <b>{e.total_number_of_okas_box}</b></h5></div>
                            </div>
                        </div>
                    )
                // }            
            });
        return xx;
        }
        else if(type==='typetable'){ 
            var typee = 0;
            let xx = currentTodos.map((e,index)=>{
                // if(e.okas_box_number===1 && e.okas_box_number===123  ){
                    typee = typee+1;
                    return (
                        <tr key={index}>
                            <td>{e.building_name}</td>
                            <td>{e.address}</td>
                            <td>{e.project_manager}</td>
                            <td>{e.office_address}</td>
                            <td style={{width: '100px'}}>{e.total_number_of_okas_box}</td>
                            {/* <td><Icon icon={download3} size={20} /></td> */}
                        </tr> 
                    )
                // }            
            });
            return xx;
        } 
        else if(type==='type2'){
            let xx1 = this.state.okasBoxData.map((e,index)=>{
                if(e.okas_box_number===123){ 
                    return (
                        <div className="col-sm-6 col-md-3 mainthumbnailbig"  key={index}>
                            <div className="thumbnail mainthumbnail">
                                <div className="caption maincaption">
                                    <img src={Img1} alt="we.okas logo" className="navbar-right" />
                                    <h3>{e.owner_name}</h3>
                                    <p className="p11">D-147, Okhla Industrial Area Phase 1 Delhi-110020</p>
                                    <p className="p12">Project Manager</p>
                                    <p className="p13">Office Location</p>
                                </div>
                                <div>
                                    <h5>No. of OKAS Box - <b>10</b></h5>
                                </div>
                            </div>
                        </div>   
                    )
                }            
            });
            return xx1;
        }
    } 

    openchatbyid = (ticketva) =>{
        const history = createHistory({forceRefresh: true});   
        localStorage.setItem("ticketid", ticketva);
        history.push('/chat');
    }

    render(){ 
            return Style.it(`
            @media only screen and (max-width: 760px){
          td:nth-of-type(1):before { content: "NAME"; }
          td:nth-of-type(2):before { content: "ADDRESS"; }
          td:nth-of-type(3):before { content: "MANAGER"; }
          td:nth-of-type(4):before { content: "OFFICE LOCATION"; }
          td:nth-of-type(5):before { content: "BOXES"; }
        }
          `,
            <div className="container dashboardContainer dashboardContainer-Mobile"

            >
                <div className="dashboardLeft">
                    <div className="okasWelcomeInfo  text-center-mobile">
                        <div className="heading">  Hello {this.props.redux.globalVar.reUserNameStyle}! keep the good work going.</div> 
                        <h6>Grab the quick glance on the status of your projects from the dashboard.</h6>
                    </div>
                    <div className="row footstepdiv"> 
                        <img src={foot7} className="img-responsive"/> 
                    </div>
                    <div className="row "> 
                        <div className="totalboxcont"> 
                            <div className="col-md-9"> 
                                <div className="row ">
                                <div className="col-md-4 col-sm-4">
                                <a href="regOkas"> <div className="totalboxcont1 totalboxcount1left">
                                        <div className="totalboxcont11">
                                                <b>REGISTERED</b><br/><span>OKAS BOXES </span>
                                        </div>
                                        <div className="totalboxcont12">
                                                {this.state.okasBoxDataAll.length}
                                        </div>
                                        <div className="totalboxcont13"> 
                                        <img src={RegisterdOkasBoxes}/><br></br>
                                                {/* <i>VIEW</i> */}
                                        </div>
                                    </div></a>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                <a href="regOkasunassigned">  <div className="totalboxcont1 totalboxcount1middel1">
                                        <div className="totalboxcont11">
                                            <b>REMAINING </b><br/><span>OKAS BOXES </span>
                                        </div>
                                        <div className="totalboxcont12">
                                        {this.state.okasBoxDataUnassign.length}
                                        </div>
                                        <div className="totalboxcont13"> 
                                            <img src={RemainingOkasBoxes}/><br></br>
                                                {/* <i>VIEW</i> */}
                                        </div>
                                    </div></a>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                <a href="projectdetail"><div className="totalboxcont1 totalboxcount1middel2">
                                        <div className="totalboxcont11">
                                            <b>COMPLETE</b><br/><span>PROJECTS </span>
                                        </div>
                                        <div className="totalboxcont12">
                                                {/* {this.state.totalBoxes} */}
                                                {this.state.totalprojectdone}
                                        </div> 
                                        <div className="totalboxcont13"> 
                                            <img src={CompleteProject}/><br></br>
                                                {/* <i>VIEW</i> */}
                                        </div>
                                    </div></a>
                                </div> 
                                </div>
                                <div className="row conwidth totalboxcount1left">                    
                                    <div className="totalboxcont111 "> 
                                    <b>RECENTLY ADDED</b><br/><span> PROJECT</span>
                                    </div>
                                    <div className="pull-right listgridbutton">
                                        {this.state.hideGridView ?    
                                        <span>
                                            <span className=""><img src={GridViewBlue}/></span>  <a onClick={(e)=>{
                                                this.setState({
                                                    hideGridView: !this.state.hideGridView
                                            })
                                        }} ><span className="iconnotactive"><img src={ListViewGray}/></span></a>
                                    
                                        </span>
                                        
                                        :
                                        <span>
                                            <a onClick={(e)=>{
                                                this.setState({
                                                    hideGridView: !this.state.hideGridView
                                            })
                                        }} 
                                            
                                            ><span className="iconnotactive"><img src={GridViewGray}/></span></a>
                                            <span className=""> <img src={ListViewBlue}/></span>
                                            </span>  
                                            
                                        }
                                    </div>                    
                                    {this.state.hideGridView ? 
                                                <div className="row homegridprojectview">
                                                {
                                                    this.renderTableData('type1').length
                                                    ?
                                                    this.renderTableData('type1')
                                                    :
                                                    <span className="mainthumbnailbig">
                                                        <img src={scope} className="scopeimage1"/> 
                                                        <p  className="scopeline text-center">Note that it can not be centered if the width is set to 100% (full-width).</p>
                                                        <span className=" text-center"><Link to="/projectdetailadd"  className="btn scopebutton1">Add Project</Link></span>
                                                    </span>
                                                   
                                                } </div>    
                                    :        
                                        <div onResize className="col-sm-12 col-md-12 col-lg-12  mainthumbnailbig12 tests12 mobile-device-padding-zero" >  
                                            {this.renderTableData('typetable').length
                                                ?
                                                <table className={this.props.windowWidth >760 ? "table custom-striped-table-dashboard dashboardtablemain" :""}
                                                
                                                >
                                                    <thead>
                                                <tr>
                                                    <th>NAME</th>
                                                    <th>ADDRESS</th>
                                                    <th>MANAGER</th>
                                                    <th>OFFICE LOCATION</th>
                                                    <th   className="paddingright3" style={{width: '100px'}}>BOXES</th>
                                                    {/* <th>FILE</th> */}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderTableData('typetable')}
                                                </tbody>
                                                </table> 
                                                :
                                                <span>
                                                <img src={scope} className="scopeimage2"/> 
                                                <p  className="scopeline text-center">Note that it can not be centered if the width is set to 100% (full-width).</p>
                                                <span className=" text-center"><Link to="/projectdetailadd"  className="btn scopebutton">Add Project</Link></span>
                                            </span>
                                                } 
                                            
                                           
                                       
                                        <div  className="pull-right">
                                            {this.state.todosPerPage < this.state.okasBoxData.length ?
                                                    <ul className="pagination pull-right paginat">                            
                                                            <li> 
                                                                <span className="">Showing {(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+1} -{this.state.currentPage*this.state.todosPerPage-this.state.okasBoxData.length<0?this.state.currentPage*this.state.todosPerPage:this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span> 
                                                            </li>
                                                            <li>
                                                                <a 
                                                                onClick= {this.state.currentPage == -1+this.state.currentPage*this.state.todosPerPage ? null :  
                                                                        this.handleClickpage.bind(this,this.state.currentPage-1)
                                                                    }
                                                                aria-label="Previous">
                                                                    <span aria-hidden="true">&laquo;</span>
                                                                    <span className="sr-only">Previous</span>
                                                                </a>
                                                            </li> 
                                                            {this.renderTableData('pagination')} 
                                                            <li className="">
                                                                <a className=""  
                                                                onClick= {this.state.currentPage*this.state.todosPerPage == this.state.okasBoxData.length ? null :  
                                                                    this.handleClickpage.bind(this,this.state.currentPage+1)
                                                                }   
                                                                aria-label="Next">
                                                                <span aria-hidden="true">&raquo;</span>
                                                                <span className="sr-only">Next</span>
                                                                </a>
                                                            </li>
                                                    </ul>
                                            :null}
                                        </div> 
                                    </div>   
                                }  
                                </div>    
                            </div>  
                            <div className="col-md-3 supportsectionondashboard totalboxcount1right">
                                {/* <div className="menuLabel"><img src={Support}/><span><b>SUPPORT</b></span></div> 
                                    <a href="support" className="pull-right box-shdadow dd-flex alidgn-content-center">
                                    <img src={Add} className="box-shadow" style={{borderRadius: '50%', padding: '5px', backgroundColor: 'white'}}/>
                                    </a> 
                            {this.state.ticketid?
                                <span className="supportsect"> 
                                    <div className="card supportsectionondashboardcard">
                                        <div className="card-body">
                                            <h5 className="card-title ">Ticket No.: <span className="card-subtitle mb-2 text-muted">{this.state.ticketid} </span></h5>
                                            <h5 className="card-title">Status: <span className="card-subtitle mb-2 text-muted">{this.state.ticketstatus} </span></h5> 
                                            <h5 className="card-title"> <span className="card-subtitle mb-2 text-muted"></span></h5>  
                                            <p className="card-text"><b>Subject:  {this.state.ticketsubject}  </b><br/>{_.truncate(this.state.ticketdescription,{'length': 30,  'omission': '...'})}
                                                <br/>
                                                <a  style={{color: '#007bff'}} onClick={this.openchatbyid.bind(this,this.state.ticketid)} className="pull-right">more....</a>
                                            </p> 
                                        </div>
                                    </div>  
                                </span>:<span><br></br><br></br></span>} */}
                                {/* <span className="extraspace2br"><br></br><br></br></span> */}
                                <div className="menuLabel">
                                    <img src={Help}/><span style={{fontSize: '14px'}}><b>HELP</b></span>
                                </div>
                                <div className="card supportsectionondashboardcard">
                                    <div className="card-body"> 
                                        <img src={okasdesignstudiosmall} className="sidelogo12"/>
                                        <p className="card-text"> 
                                            <p> INTRODUCTION:-  OKAS is an innovative integration product which is able to fulfil your imagination. It is a Human centric integration device which will work according to user persona.OKAS Design Studio helps you to integrate, install and customise various offerings of this innovative product. </p>
                                                <Link to="/design-help">more....</Link>
                                            </p>  
                                        <img src={weokassmall} />
                                        <p className="card-text"> 
                                            <p>WE.OKAS is a comprehensive platform for OKAS partners to manage their inventory and track their sales in a more efficient way.</p>
                                            <p>Table of content:</p>
                                            <ol>
                                                <li>Aim</li>
                                                <li>Introduction</li>
                                                <li>Specification</li>
                                                <li>Features</li>
                                                <li>How to use</li>
                                            </ol>
                                            <Link to="/help">          more....</Link> 
                                        </p>  
                                    </div>
                                </div>                                 
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>
        );
    } 
}


const mapStateToProps = state  => {
    return {
      redux: state,
    };
};    
  
  
const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
        userNameStyleActivate: (data) => dispatch({type: ActionTypes.USERNAMESTYLE, data: data }),
        reuserNameStyleActivate: (data) => dispatch({type: ActionTypes.REUSERNAMESTYLE, data: data }),
        userTypeStyleActivate: (data) => dispatch({type: ActionTypes.USERTYPESTYLE, data: data }),
    };
};
  

export default windowSize(connect(mapStateToProps, mapDispatchToProps) (DashboardContent));
