import React from 'react';     
import 'css/help.css';  
import logo from 'images/weokaslogin.svg'; 
import designstudiologo from 'images/designstudiologo.svg'; 
import 'mdbreact/dist/css/mdb.css'; 
import weokas1 from 'images/weokas/weokas1.png'; 
import weokas2 from 'images/weokas/weokas2.png'; 
import weokas3 from 'images/weokas/weokas3.png'; 
import weokas4 from 'images/weokas/weokas4.png';  
import weokas6 from 'images/weokas/weokas6.png'; 
import weokas7 from 'images/weokas/weokas7.png'; 
import weokas8 from 'images/weokas/weokas8.png'; 
import weokas9 from 'images/weokas/weokas9.png'; 
import weokas10 from 'images/weokas/weokas10.png'; 
import weokas11 from 'images/weokas/weokas11.png'; 
import weokas12 from 'images/weokas/weokas12.png'; 
import weokas13 from 'images/weokas/weokas13.png'; 
import weokas14 from 'images/weokas/weokas14.png'; 
import weokas15 from 'images/weokas/weokas15.png'; 
import weokas16 from 'images/weokas/weokas16.png'; 
import design1 from 'images/weokas/design1.png'; 
import design2 from 'images/weokas/design2.png'; 
import design3 from 'images/weokas/design3.png'; 
import design4 from 'images/weokas/design4.png'; 
import design5 from 'images/weokas/design5.png'; 
import design6 from 'images/weokas/design6.png'; 
import design7 from 'images/weokas/design7.png'; 
import design8 from 'images/weokas/design8.png'; 
import design9 from 'images/weokas/design9.png'; 
import design10 from 'images/weokas/design10.png'; 
// import design1 from 'images/weokas/design1.png'; 
// import design1 from 'images/weokas/design1.png'; 
// import design1 from 'images/weokas/design1.png'; 
import design14 from 'images/weokas/design14.png'; 
import design15 from 'images/weokas/design15.png'; 
import design16 from 'images/weokas/design16.png'; 
import design17 from 'images/weokas/design17.png'; 
export default class Helppage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = { imageStatus: "loading" };
      }


componentWillMount(){ }
componentDidMount(){ } 



handleImageLoaded() {
    this.setState({ imageStatus: "loaded" });
  }
    render(){ 
        return ( 
            <div className="selfcontainer ">   
                <h2 className="supporva">Help</h2>  
                <div className="row">   
                    <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation"><a href="#weokas" aria-controls="weokas" role="tab" data-toggle="tab">We.OKAS</a></li>
                        <li role="presentation" className="active"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab">OKAS DESIGN STUDIO</a></li> 
                    </ul> 
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane" id="weokas">
                            <h1 className="text-center"> <img src={logo}/></h1>
                            <p><b>WE.OKAS is a comprehensive platform for OKAS partners to manage their inventory and track their sales in a more efficient way.</b></p>
                            <p><b>Table of content:</b></p>
                            <ol>
                                <li>Aim</li>
                                <li>Introduction</li>
                                <li>Specification</li>
                                <li>Features</li>
                                <li>How to use</li>
                            </ol>
                            <p><b>AIM:</b></p>
                            <ul>
                                <li>WE.OKAS aims to generate a platform for the System Integrator to use the services of the OKAS in a finger touch.</li>
                                <li>Providing a single solution for all the System Integrator to enable the OKAS world.</li>
                                <li>Providing support to the Design studio </li>
                                <li>Providing information about the officials of OKAS and there office location.</li>
                            </ul>
                            <p><b>INTRODUCTION:</b></p> 
                            <ul>
                                <li>We provide world class engineering solution to the home users.</li>
                                <li>WE.OKAS helps all the system integrator from all over the globe to manage the project and there project managers in a single platform.</li> 
                            </ul>
                            <p><b>SPECIFICATION AND FEATURES:</b></p> 
                            <ul>
                                <li>Help and support to the System Integrator and its team.</li>
                                <li>Information regarding the OKAS office location and team around the globe.</li>
                                <li>Management of hardware and the engineers.</li>
                                <li>Providing support and help related information. </li>
                            </ul>
                            <p><b>How to Use:</b></p> 
                            <ol>
                                <li>Dashboard</li>
                                <li>Office Location</li>
                                <li>Project Manager</li>
                                <li>Registered Okas Box</li>
                                <li>Projects Detail</li>
                                <li>Supports</li>
                                <li>Help</li>
                            </ol>
                            <img className="img-responsive" alt="we.okas" src={weokas1} onLoad={this.handleImageLoaded.bind(this)}/>
                            <p>A user of the we.okas must have to<b>Login credentials provided by OKAS Teamfirst.</b> </p>
                            <p>For log in to we.okas user must have:
                                <ol>
                                    <li>a username</li>
                                    <li>the password</li>
                                </ol>
                                    There is an option to <b>change the password.</b>
                                <br></br><br></br>
                                    After providing the username and password and then clicking the log in button available on the screen user will log in to we.okas.
                                <br></br><br></br>
                                    Correct Username and password will allow the user to proceed to the dashboard.
                            </p>
                            <img className="img-responsive" alt="we.okas" src={weokas2}/>
                            <ul>
                                <li>In the top right of the dashboard there will be<b> chat icon, bell icon, user logout hover.</b></li>
                                <li><b>Chat icon</b> will allow the user to chat with the we.okas chat support center.</li>
                                <li>In the chat support user can clear all the queries and problem related to projects.</li>
                                <li><b>Bell icon</b> will show user the notification related to we.okas and project.</li>
                                <li><b>User logout hover </b>will allow the user to show basic information related to the user.</li>
                                <li>The very first thing in the dashboard is the<b> flow chart of the project.</b></li>
                            </ul>
                            <p><b>Flow Of WE.OKAS:</b></p>
                            <ol>
                                <li>In the very first step user must add the office location with details.</li>
                                <li>Then after adding the office location user will add project manager(Person responsible for project).</li>
                                <li>Then must register the OKAS box inventory for project management. </li>
                                <li>Then will add project details for the unassigned OKAS box.</li>
                                <li>Then assign boxes to project on the right side of ‘Add New Project’ page.</li>
                                <li>Cheers we are ready to go.</li>
                            </ol>
                            <p><b>Tiles in the dashboard:</b></p>
                            <ol>
                                <li>Inventory will show the user the total number of boxes the user has registered till now.</li>
                                <li>Remaining will show the user the number of okas box that are left to be registered in that particular user’s quota.</li>
                                <li>Complete will show the user no of ongoing projects.</li>
                                <li>Recently will show all the recently added projects and its basic details.
                                    <ul>
                                        <li>There are two types of view style also available for the recently tab one is cover and another is list view.</li>
                                        <li>The right tab will have a support tab that allow user to raise the support ticket along with the help tab.</li>
                                    </ul>
                                </li>
                            </ol> 
                                <img className="img-responsive" alt="we.okas" src={weokas3}/>
                            <ul>
                                <li>Search tab on the top, will allow the user to search the office location.</li>
                                <li>The office location list will show all the available office under the user. </li>
                            </ul>
                                <img className="img-responsive" alt="we.okas" src={weokas4}/>   
                            <ul>
                                <li>The add office location will allow the user to add a new office location.  </li>
                                <li>On clicking on the add office location button user will be navigated to the add new office location form.</li>
                                <li>In the add new office location user will have to provide certain information related to the office location like name, phone, email, office location.</li>
                                <li>Name will have the name of the new office.</li>
                                <li>Phone will have the phone number of the new office.</li>
                                <li>Email will have the email address of the new office.</li>
                                <li>Office location will have the address of the new office. </li>
                                <li>In the form there will be three option available to the user close, add another and add.</li>
                                <li>Close will allow the user to close the form </li>
                                <li>Add another will allow the user to add another office location with the same above steps.</li>
                                <li>Add will allow the user to add successfully.</li> 
                            </ul>
                            <img className="img-responsive" alt="we.okas" src={weokas6}/>
                            <ul>
                                <li>Search tab on the top, will allow the user to search the project manager.</li>
                                <li>The project manager list will show all the available project manager under the user.</li>
                                <li>In the page there is a drag down available in the menu bar that will allow the user to search specific content in the available page.</li>
                            </ul>
                            <img className="img-responsive" alt="we.okas" src={weokas7}/>    
                            <ul>
                                <li>The add project manager will allow the user to add a new project manager.   </li>
                                <li>On clicking on the add project manager button user will be navigated to the add new project manager form </li>
                            </ul>
                            <p>Adding a new project manager one will have to provide certain information related to the project managers.</p>
                            <p><b>Field in Add new project manager:</b></p>
                            <ul>
                                <li>Name will have the name of the new project manager.</li>
                                <li>Phone will have the phone number of the new project manager.</li>
                                <li>Email will have the email address of the new project manager</li>
                                <li>Office location will have the address of the new project manager.</li>
                                <li>Password will have the password of the new project manager.</li>
                            </ul>
                            <p><b>In the form there will be three options available to the user cancel, add another and add.</b></p>
                            <ul>
                                <li>Cancel will allow the user to close the form </li>
                                <li>Add another will allow the user to add another project manager with the same above steps.</li>
                                <li>Add will allow the user to add successfully.</li> 
                            </ul> 
                            <img className="img-responsive" alt="we.okas" src={weokas8}/>
                            <ul>
                                <li>Search tab on the top, will allow the user to search the registered okas boxes.</li>
                                <li>The registered okas box list will show all the available registered okas box under the user.</li>
                                <li>In the page there is a drag down available in the menu bar that will allow the user to search specific content in the available page.</li>
                            </ul>   
                            <img className="img-responsive" alt="we.okas" src={weokas9}/>
                            <ul>   
                                <li>The registered okas box will allow the user to add a new okas boxes.  </li>
                                <li>On clicking on the add new okas box button user will be navigated to the register new box form.</li>
                                <li>In the add new okas box user will have to provide certain information related to the okas box like box type, registration id, license id, assigned, date and time.</li>
                                <li>Box type will describe the type of box it is like vod, master etc.</li>
                                <li>Registration id will have the unique registration id provided by the user to the box.</li>
                                <li>License id will have the license id associated with the box.</li>
                                <li>Assigned will have the name of the individual to whom the box is being assigned.</li>
                                <li>Date and time will have the specific date and time of the box registration.</li>
                            </ul>
                            <p>In the form there will be three option available to the user<b> cancel, add another and add.</b></p>
                            <ul>
                                <li>Cancel will allow the user to close the form </li>
                                <li>Add another will allow the user to add another okas box with the same above steps.</li>
                                <li>Add will allow the user to add successfully.</li>
                            </ul> 
                            <ul>
                                <li>In the top of this page there will be a search tab that will allow the user to search the<b> project details.</b></li>
                                <li>The<b> project details </b>list will show all the available projects under the user.</li>
                            </ul>
                            <img className="img-responsive" alt="we.okas" src={weokas10}/> 
                            <ul>   
                                <li>In the page there is a<b> drag down </b>available in the menu bar that will allow the user to search specific content in the available page.</li>
                            </ul>
                            <img className="img-responsive" alt="we.okas" src={weokas11}/>
                            <img className="img-responsive" alt="we.okas" src={weokas12}/>
                            <ul>
                                <li><b>add new project </b>will allow the user to add a new project.   </li>
                                <li>On clicking on the<b> add new project </b>button user will be navigated to the<b> add new project form.</b></li>
                                <li>In the form there will be number of information needed to fill the form like building id building type building name and many more.</li>
                                <li>There also available a suggestion box that allows the user to choose the type of okas box need in the project and accordingly the user will add the box </li>
                                <li>By clicking on the<b> save button </b>the project will be saved and<b> cancel button </b>will allow the user to discard the form. </li>
                            </ul>
                            <img className="img-responsive" alt="we.okas" src={weokas13}/>
                            <ul>
                                <li>In the project detail page by clicking on the project user will have add box button that allow the user to <b>edit the box configuration</b> and save it accordingly. </li> 
                            </ul> 
                            <img className="img-responsive" alt="we.okas" src={weokas14}/>
                            <img className="img-responsive" alt="we.okas" src={weokas15}/>
                            <img className="img-responsive" alt="we.okas" src={weokas16}/> 
                            <p>Through support and help user can raise a ticket of the issue being faced.</p>
                            <ul>
                                <li>By clicking on the<b> need help button  </b>user will be navigated to the need help form.</li>
                                <li>By providing help related information ticket will be raised.</li>
                            </ul>
                        </div>



                        <div role="tabpanel" className="tab-pane active" id="profile">
                            <h1 className="text-center">  <img src={designstudiologo}/> </h1>
                            <p><b>INTRODUCTION:-  OKAS is an innovative integration product which is able to fulfil your imagination. It is a Human centric integration device which will work according to user persona.OKAS Design Studio helps you to integrate, install and customise various offerings of this innovative product.</b></p>
                            <p>Product :- </p>
                            <p>
                                Master Box:- The master box controls all your devices of home. It can control Lighting(KNX, Rako , Bticino etc),Temperature Control(Daikin , Mitsubishi ,Toshiba etc.). <br></br><br></br>
                                VOD: VOD Box enables you to play a movie and stream videos from the server.<br></br><br></br>
                                AOD: AOD Box enables you to play a music from the server.
                            </p>
                            <p>OKAS Prerequisites :-</p>
                            <ol>
                                <li>
                                    Power for all OKAS Boxes:- 
                                    <ul>
                                        <li>ALL OKAS Boxes must be on UPS Power.</li>
                                    </ul>
                                </li>
                                <li>
                                    Network Infrastructures :-
                                    <ul>
                                        <li>Network Switch must be Full gigabyte.</li>
                                        <li>Entire house must have good WiFi coverage for iPad/iPhone to work with Okas.</li>
                                        <li>Good internet connection is a prerequisite for OKAS Integration.</li>
                                        <li>If you are using some of the devices which are getting power from  Ethernet like  Surveillance Camera, Door Phone etc. then you should use POE Switches.</li>
                                    </ul>
                                </li>
                                <li>
                                    Set Top Box :-
                                    <ul>
                                        <li>We recommended TataSky+ HD Set Top Box.</li>
                                    </ul>
                                </li>
                                <li>
                                    Panoramic Image for OKAS:-
                                    <ul>
                                        <li>iPad required panoramic room image of height XXXX px for Light and Curtain screen.
</li>
                                    </ul>
                                </li>
                                <li>
                                    IPAD , iPhone , ipod
                                    <ul>
                                        <li> iOS version should be iOS 9 and above.  </li>
                                        <li> iPad should iPad 3 and above. </li>
                                        <li> iPhone 5 and above. </li>
                                        <li> iPod should be 5th Generation and above. </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>OKAS could be configured with the help of Design Studio by following these simple steps:-</p>
                            <ol>
                                <li>Connect Master Okas Box via LAN to the network switch.</li>
                                <li>Open Web Browser In your Laptop and type IP address provided by WE.OKAS.</li>
                                <li>This will open a web page , enter your Username and password provided by </li>
                                <li>WE.OKAS (Contact OKAS Support Team if you face any issues.)</li>
                                <li>After Logging in to your account you have  access to OKAS DESIGN STUDIO where you can configure OKAS System.</li>
                                <li>On the dashboard you need to select Building which could be accessed by single click on Master Box.</li>
                                <li>In This section you can select a building Type like Hotel, Residential or Commercial. After that you can fill building name, Address, Inauguration date. You can also select number of floors and user as per your site. Now select a image of site . Press the ‘ADD’ button on bottom right side of the page to save the changes.</li>
                                <img className="img-responsive" alt="we.okas" src={design1}/>
                                <li>On Dashboard page you will find to Add Master OKAS Box. Press + button to add it. </li>
                                <img className="img-responsive" alt="we.okas" src={design2}/>
                                <li>When you press + Button another window will appear.</li>
                                <img className="img-responsive" alt="we.okas" src={design3}/>
                                <li>Provide a name to master box, Registration and license ID will be provided by OKAS team at the time of purchase. Fill that in specified location. Provide a IP to OKAS box.</li>
                                <li>Press Add button, You will get notification after successful registration.</li>
                                <li>Another Part of Building is Building configuration ,In which you can add Lights & temperature devices in project. When press add Utility it will ask you for add a device with it’s type ,make , model no., with control protocol(IP/RS232).</li>
                                <img className="img-responsive" alt="we.okas" src={design4}/>
                                <li>After That It Will Look like this. you can add more devices as per your requirement.</li>
                                <img className="img-responsive" alt="we.okas" src={design5}/>
                                <li>Next part of configuration is to add Floors & Room as per your site. Under Floor & Room Icon you have a button to ADD Floor, so you can add floor from there and give it a name like “Ground Floor”. Now add it and you are done with floor.</li>
                                <img className="img-responsive" alt="we.okas" src={design6}/>
                                <li>Under “Ground Floor” Tab you will find “ADD Room”. In “Add Room” Section you will give name and image of the Room. If this is a “Sub room” you can select it or for main room, it will be  “None”.</li>
                                <img className="img-responsive" alt="we.okas" src={design7}/>
                                <li>Now you can add all floor & all room in same manner.</li>
                                <li>Next Step to configure room devices like Lights ,Temperature ,AVP and TV. For this select room “Drawing Room” than select “Room Config”.</li>
                                <img className="img-responsive" alt="we.okas" src={design8}/>
                                <li>In above page you can add Light ,Moods ,Covers ,Temperature & Media service of Particular room. If you want to add “ROOM LIGHT”, Press “+” button for Lighting Configuration Page.</li>
                                <img className="img-responsive" alt="we.okas" src={design9}/>
                                <li>In above page you are able to add lights as per your requirement, just select a light Icon from “Icon Page” and drop it on image on desired location, after this step you will find a page to configure this particular light.</li>
                                <li>As per your Device Type of Lights, you have to enter the lighting detail of particular light. For Example If you select “KNX” as a device , So you have to enter lighting parameters as per your ETS program. For a Dimming Circuit you need to enter following Parameters: - a) Switching, b) Status Indication, c) Brightness Value , d) Brightness Value Indication.</li>
                                <li>After completing the configuration of particular light you can save it. Now follow the same step for all lighting circuit.</li>
                                <li>You can add “MOODS” also like Lights ,These are the Predefined Moods which we called from Lighting System.</li>
                                <li>OKAS MEDIA:-</li>
                                <li>ROOM SERVICE:-</li>
                                <li>If you have AV Integration in the same room, For that you have to open a new tab “Device Config”. In “Device Config” section you can add “OKAS MEDIA BOX” for Movie and Music and you can also control your AV equipment Like AVR,TV, Tatasky, etc. </li>
                                <img className="img-responsive" alt="we.okas" src={design10}/>
                                <li>If you want to add Okas Box than Select the “+” Button on screen. After that you can select the box type “VOD(Movies and Music or AOD (Music Only)”, You can give a name to OKAS box ,Registration ID and License Id Will provide By OKAS Team. Now give a Unique  IP (Internet Protocol) address to OKAS ,than press save button to finish your OKAS box setup.</li>
                                <img className="img-responsive" alt="we.okas" src={design10}/>
                                <li>After adding OKAS box in room you have option to add device of room like TV,Tata Sky etc. Press ADD button to add devices in room.</li>
                                <img className="img-responsive" alt="we.okas" src={design10}/>
                                <li>In next step you will have option to select device. first option is to select device type like TV, Projector, AVP. After adding AV equipment You can configure the device by pressing “Setting Button”. There you will find HDMI configuration of configured device.  </li>
                                <img className="img-responsive" alt="we.okas" src={design10}/>
                                <li>
                                    MACROS:-in Macro section you can make advanced scenario, For example if you want to trigger a Mood with function “TV On”. So there are three type of “ MACRO”.
                                        <br></br>
                                        (a)Device Macro:- Which is related to particular function of a device like  TV on, Source Switching of AVP.
                                        <br></br>
                                        (b)Time Macro:- Which is related to a time dependent macro , you can make on hour & day basis, sunrise & sunset basis. For example if you want to turn on your AV equipment at 19:00.
                                        <br></br>
                                        (c)Button Macro :- This macro will called on press of button, for example Play and pause function of Media or you want to create a mood for whole house.
                                </li>
                                <li>USER GROUP:- In this Section you can make user group to give permission to that    particular group of users. Like If you make a Guest User in this and give him access of lighting devices only , so a guest user will only have access of Lighting Pages, all other functionality will not work for him.</li>
                                <img className="img-responsive" alt="we.okas" src={design14}/>
                                <li>When you press “ADD USER GROUP” button you will have another window in which you are able to give permission.</li>
                                <img className="img-responsive" alt="we.okas" src={design15}/>
                                <li>MEMBERS:- Members function will play a important role in OKAS Integration because as per the members and permission assigned to that particular member you are able to get the GUI of IPAD.</li>
                                <img className="img-responsive" alt="we.okas" src={design16}/>
                                <li>As per the above configuration you have to provide the details of Member each and every part of configuration is compulsory on right hand side you have to provide image of Member. Now save the above details.</li>
                                <li>Next is to give permission of floors and rooms to that particular member. for this you have to go “ROOM & SERVICE ACCESS” Menu which is under the particular member page. This page contains the details of floor and room & type of user group, so you can make combination of “USER GROUP” and “ROOM ACCESS” to give permission to that member.</li>
                                <img className="img-responsive" alt="we.okas" src={design17}/>
                                <li>Now you can save it and its done you can configure your app to control your Home.</li>
                            </ol> 
                        </div> 
                    </div> 
                </div>  
            </div>
        );
    } 
} 
 