import React, { Component } from 'react';
import DashboardContent from 'views/dashboardcontent';
import SiSideNav from 'components/sidenav';
import TopHeader from 'components/header';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';

class Dashboard extends Component {

    constructor(props) {
        super(props);
    }

    openModal = () => {
        // alert("modal opened");
    }

    render() {
        return (
            <div className="root">
                <TopHeader history={this.props.history} />
                {/* <SiSideNav history={this.props.history} selected="dashboard" /> */}
                <DashboardContent openModal={this.openModal} />
            </div>
        )
    }
}
 

const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),
    };
};


export default connect(mapStateToProps, mapDispatchToProps) (Dashboard);