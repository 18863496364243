import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import * as ActionTypes from '../actions/types';
import { connect } from 'react-redux';
const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: '100%'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

class CustomizedInputBase extends React.Component {
  state = {
    l: ''
  }


  componentWillMount(){
    this.props.resetSearch(false)
  }

  componentWillUnmount(){
    this.props.resetSearch(false)

  }
  
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={1}>
        <IconButton className={classes.iconButton} aria-label="Menu">
          <SearchIcon />
        </IconButton>
        <InputBase className={classes.input} placeholder="Search" autoFocus
          onChange={(e) => this.props.onChange(e.target.value)}
        />
        <IconButton className={classes.iconButton} aria-label="Search"
          onClick={(e) => {
            console.log('sdffds')
            this.props.searchBarActivate(false)
            this.props.resetSearch(false)
          }}>
          <CloseIcon />
        </IconButton>
      </Paper>
    );
  }
}

CustomizedInputBase.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    redux: state,
  };
};



const mapDispatchToProps = dispatch => {
  return {
    searchBarActivate: (data) => dispatch({ type: ActionTypes.SEARCHBAR, data: data }),
    searchBarStyleActivate: (data) => dispatch({ type: ActionTypes.SEARCHBARSTYLE, data: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomizedInputBase));

