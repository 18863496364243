import React from 'react';
import Sorting from './Sorting';
import {sort} from 'react-icons-kit/fa/sort';
import {hddO} from 'react-icons-kit/fa/hddO';
import {sortDown} from 'react-icons-kit/fa/sortDown';
import {sortUp} from 'react-icons-kit/fa/sortUp';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import Cookies from 'js-cookie';
import 'css/regokas.css';
import {plus} from 'react-icons-kit/iconic/plus'
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross' ;
import {search} from 'react-icons-kit/icomoon/search'; 
import Loader from '../components/loader';
import {close} from 'react-icons-kit/ikons/close'
import {cancelCircle} from 'react-icons-kit/icomoon/cancelCircle';
import Add from 'images/headericon/Add.svg';  
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; 
import Minus from 'images/Minus.svg';   
import Plus from 'images/Plus.svg';   
import Success from 'images/Success.svg';   
import Error from 'images/Error.svg'; 
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import IPCheck from 'ipcheck';
import checkIp from 'check-ip';
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up'; 
import { Input } from 'mdbreact'; 
import SearchBar from './SearchBar'

 class ProjectDetailPagepm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            okasBoxData: [],
            boxesForConfig: [],
            kk: 'asdvhj',
            namess:'',
            sortedType:'',
            searchValue: '',
            actualdata:[],
            statedata:'',
            hidebutton:true, 
            rowOpenNumber: '',
            showRow: false, 
            okasBoxDatalist:[],
            totbox:[],
            okasboxmodaletype:'',
            someNewOne: '',
            textvalue: [],
            okasbox1:[],
            realtimebuildingid:'',
            disabledvalue:'',
            successmsgmodaldata:localStorage.getItem('dataadded'),
            usertype:localStorage.getItem('usertype'),
            loader:'',
            currentPage: 1,
            todosPerPage: 10,
            currentConfigType: '',
            currentBoxSelected: '',
            currentProjectBoxDetails: [],            
            ipError: false,
            subnetError: false,
            defaultError: false,
            showError: false,
            countForBoxAssign: 0,
            okasboxid: '',
            statuson:false,statustw:false,statusth:false,
            statusfo:false,statusfi:false,statussi:false,
            statusse:false,statusei:false,statusni:false,
            statuste:false, 
            ipaddress:'',
            subnetmask:'',
            defaultgateway:'',
            
        }; 
        this.handleClickpage = this.handleClickpage.bind(this);
        this.handleChange = this.handleChange.bind(this) ;
    }



    
    componentWillMount(){
        setTimeout(
            function() { 
                localStorage.removeItem('dataadded');
                this.setState({
                   successmsgmodaldata:'',
           }) 
            }
            .bind(this),
            3000
        );
    
         
    }
    
    
  //------------------------------------------------ Delete Succ msg start//------------------------------------------------
    crosssuccessfullmsg = () => {   
            localStorage.removeItem('dataadded');
                this.setState({
                successmsgmodaldata:'',
        }) 
    }  
    ////------------------------------------------------ Delete Succ msg end//------------------------------------------------

    

    
    handleChange(e) {
        //console.log(e.target.checked)
        //console.log(e.target.name)
        let oldArray = this.state.textvalue;
        let current = e.target.value; 
        if(e.target.checked==true){   
            oldArray.includes(current) ? oldArray.pop(current) : oldArray.push(current); 
            if(!oldArray.includes(current)){         
            this.setState({
                textvalue: oldArray
              })   
              
            }  
        }else{
            oldArray = _.pull(oldArray, current);          
            if(!oldArray.includes(current)){         
            this.setState({
                textvalue: oldArray
              })  
            }  
            
        }
        this.setState({ 
            okasboxid: this.state.textvalue.join(), 
        }) ;
       
      }



      updateConfigIP = (buttonaction, pmids) => {
        let TokenValue = 'Token '+localStorage.getItem("auth");
    var headers = {
      'Content-Type': 'application/json',
      'Authorization': TokenValue,
      'X-CSRFToken': localStorage.getItem('csrftoken')

  } 

        let ipaddress = this.state.ipaddress; 
        let subnetmask = this.state.subnetmask; 
        let defaultgateway = this.state.defaultgateway; 
        var ipError = checkIp(ipaddress);
        var subnetError = checkIp(subnetmask);
        var defaultError = checkIp(defaultgateway);
        let    ip=ipaddress;
        let    subnet_mask=subnetmask;
        let    gateway=defaultgateway;
        let tt ={
            ip,
            subnet_mask,
            gateway,
              };

if(ipError.isValid && 
   subnetError.isValid && 
   defaultError.isValid &&
   ipaddress !=='0.0.0.0' &&
   subnetmask !=='0.0.0.0' &&
   defaultgateway !== '0.0.0.0'
   ){
    fetch('/we/we/okasBox_detail/'+pmids ,{
        method: "PUT",
        headers: headers,
                    body: JSON.stringify(tt)
                })
                .then(data =>{console.log(data)
                    if(data.status==200){ 
                        alert('Box configured successfully'); 
                        // const history = createHistory({forceRefresh: true});  
                        // history.push('/projectdetail');   
                        this.getokasboxtabledata();
                        this.setState({
                            showmodels :false, 
                        })
                    }else if(data.status==400){ 
                        alert('IP has already given, please check');  
                    }else{
                        alert('Action not completed.'); 
                    } 
                }      
                )  
            }else{                
                this.setState({                        
                            ipError: ipError.isValid,
                            subnetError: subnetError.isValid,
                            defaultError: defaultError.isValid,       
                            showError: true                 
                    })
                if(this.state.ipaddress==='0.0.0.0'){
                    this.setState({
                        ipError :false
                    })
                }      
                if(this.state.subnetmask==='0.0.0.0'){
                    this.setState({
                        subnetError :false
                    })
                }
                if(this.state.defaultgateway==='0.0.0.0'){
                    this.setState({
                        defaultError :false
                    })
                }

            }
        
  }


    componentDidMount(){
        this.setState({
            loader: true ,  
        })
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        fetch('/we/we/projectBuildingApi/' ,{
                method: "GET",
                headers: headers
            })
        .then(response => response.json())
        .then(okasBoxData => {
            this.setState({
                okasBoxData,
                actualdata: okasBoxData
            },()=>{
                this.renderTableData();
                //console.log(this.state.okasBoxData);
            })
            this.setState({
                loader: false ,  
            })
        })



        fetch('/we/we/okastabledata/' ,{
            method: "GET",
            headers: headers
        }) 
    .then(response => response.json())
    .then(okasBoxDatalist => {
        this.setState({
            okasBoxDatalist
        },()=>{
            // this.getokasnumber();
            //console.log(this.state.okasBoxDatalist)
        })
    })
    }
    // MasterController


    getokasboxtabledata = () => {
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        fetch('/we/we/projectBuildingApi/' ,{
                method: "GET",
                headers: headers
            })
        .then(response => response.json())
        .then(okasBoxData => {
            this.setState({
                okasBoxData,
                actualdata: okasBoxData
            },()=>{
                this.renderTableData();
                //console.log(this.state.okasBoxData);
            })
            this.setState({
                loader: false ,  
            })
        })



        fetch('/we/we/okastabledata/' ,{
            method: "GET",
            headers: headers
        }) 
    .then(response => response.json())
    .then(okasBoxDatalist => {
        this.setState({
            okasBoxDatalist
        },()=>{
            // this.getokasnumber();
            //console.log(this.state.okasBoxDatalist)
        })
    })
    }
    
    getokaslist = (okasboxtype,buildingid,okasboxtypetoget) => {  
      //console.log(buildingid)
                    var typee = 0;
                    let count = 0;
                    let xx = this.state.okasBoxDatalist.map((x,index)=>{                        
                         if(x.okasBox_type===okasboxtype &&  x.okasBox_assigned_to==='unassigned'){
                            typee = typee+1;        
                            count++               
                            return (
                                <tr key={index}>
                                <input type="hidden" value={buildingid}/>
                                <input type="hidden" value={okasboxtypetoget}/>
                                    <td style={{paddingLeft: '5%'}}><input type="checkbox" name={x.okasBox_type}  
                                    onChange ={                              
                                                this.handleChange
                                            }   
                                        value={x.okasBox_id} /></td>
                                    <td style={{paddingLeft: '5%'}}>{x.okasBox_id}</td> 
                                    <td>{x.okasBox_type}</td>
                                    <td>{x.okasBox_serialNo}</td>
                                </tr> 
                        )
                     }            
                });
                console.log(count)
                this.setState({
                    okasboxmodaletype: okasboxtype
                })
                if(count===0){
                    this.setState({   
                        someNewOne: (<tr >
                        <td colSpan='4' className="text-center">
                        No box available
                        </td>
                        </tr>),
                        countForBoxAssign: count
                    })
                }else{
                    this.setState({
                        someNewOne: xx,
                        countForBoxAssign: count
                    })
                }
                this.setState({
                    realtimebuildingid: buildingid
                }) 
                this.setState({
                    realtimeokasboxtypetoget: okasboxtypetoget
                })
                    return xx;  
                
    }

    

    getokasnumber = (okasboxtypetoget,buildingid) => {  
        var typee = 0;
        let totbox1= 0;
        let totbox2= 0;
        let totbox3= 0;
        let totbox4= 0;
        let totbox5= 0;   
        let xx = this.state.okasBoxDatalist.map((e,index)=>{
        let test =  _.split(okasboxtypetoget, ','); 
        for(let i=0; i<test.length; i++){ 
            if(test[i]==e.okasBox_id){ 
                if( e.okasBox_type==='Master Controller'){
                    totbox1++; 
                }
                if( e.okasBox_type==='VOD Box'){
                    totbox2++;
                }
                if( e.okasBox_type==='UVOD Box Box'){
                    totbox3++;
                }
                if( e.okasBox_type==='Audio Box'){
                    totbox4++;
                }
                if( e.okasBox_type==='Home Theatre Box'){
                    totbox5++;
                } 
            }
        } 
    });
    return( 
        <span>
            <span>
            <div className="">
            <Icon icon={hddO} size={22} />
            <a data-toggle="modal" data-target="#configip" style={{pointerEvents: this.getCountOfBoxType('Master Controller')===0 ? 'none' : ''}} onClick={()=>{this.setState({currentConfigType: 'Master Controller'})}}>         
                Master Controller({this.getCountOfBoxType('Master Controller')})
            </a> 
            {/* <a  className="button" style={{marginRight: '15%', float: 'right' }}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Master Controller',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> */}
            </div>
            </span>    
            <span>
            <div className="">
            <Icon icon={hddO} size={22} />
            <a data-toggle="modal" data-target="#configip" style={{pointerEvents: this.getCountOfBoxType('VOD Box')===0 ? 'none' : ''}} onClick={()=>{this.setState({currentConfigType: 'VOD Box'})}}>         
                VOD Box({this.getCountOfBoxType('VOD Box')})        
            </a> 
            <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('VOD Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a>
            </div>
            </span> 
            <span>
            <div className="">
            <Icon icon={hddO} size={22} />
            <a data-toggle="modal" data-target="#configip" style={{pointerEvents: this.getCountOfBoxType('UVOD Box')===0 ? 'none' : ''}} onClick={()=>{this.setState({currentConfigType: 'UVOD Box'})}}>         
                UVOD Box({this.getCountOfBoxType('UVOD Box')})        
            </a> <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('UVOD Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a>
            </div>
            </span> 
            <span>
            <div className="">
            <Icon icon={hddO} size={22} />
            <a data-toggle="modal" data-target="#configip" style={{pointerEvents: this.getCountOfBoxType('Audio Box')===0 ? 'none' : ''}} onClick={()=>{this.setState({currentConfigType: 'Audio Box'})}}>         
                Audio Box({this.getCountOfBoxType('Audio Box')})  
            </a> 
            <a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Audio Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a>
            </div>
            </span> 
            <span>
            <div className="">
            <Icon icon={hddO} size={22} />
            <a data-toggle="modal" data-target="#configip" style={{pointerEvents: this.getCountOfBoxType('Home Theatre Box')===0 ? 'none' : ''}} onClick={()=>{this.setState({currentConfigType: 'Home Theatre Box'})}}>         
               Home Theatre Box ({this.getCountOfBoxType('Home Theatre Box')})        
            </a><a className="button" style={{marginRight: '15%', float: 'right'}}  data-toggle="modal" data-target="#hometheaterbox" onClick={(e)=>{this.getokaslist('Home Theatre Box',buildingid,okasboxtypetoget)}} > {this.props.redux.globalVar.userTypeStyle==='2' ? '' :   <b><img src={Add}/></b>}</a> 
            </div>
            </span>  
        </span>

        )

}



onAddClickHandler = ( buttonaction,realtimebuildingid,realtimeokasboxtypetoget ) => { 

    let TokenValue = 'Token '+localStorage.getItem("auth");
    //console.log(TokenValue)
    var headers = {
      'Content-Type': 'application/json',
      'Authorization': TokenValue,
      'X-CSRFToken': localStorage.getItem('csrftoken')

  }   
    let okasBox_id = realtimeokasboxtypetoget+','+this.state.okasboxid;
    //console.log(okasBox_id)
    let tt ={   okasBox_id  }; 
    if(buttonaction==='add') { 
        this.setState({
            disabledvalue: 'disabled',
        })
    
        }
        this.setState({
            loader: true ,  
        })      
          fetch('/we/we/projectApi_detail/'+realtimebuildingid ,{
            method: "PUT",
            headers: headers,
            body: JSON.stringify(tt)
        })
.then(response => response.json())
.then(data=>{  
var dataadded = "dataadded";
localStorage.setItem("dataadded", dataadded);
    if(buttonaction==='add') { 
        const history = createHistory({forceRefresh: true});  
        history.push('/projectdetail'); 
    } 
}) 
}

handleClickpage(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
    this.renderTableData('type1');
    this.renderTableData('pagination'); 
  }


    
    renderTableData = (type) => {
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
                localStorage.removeItem('username');
                this.props.history.push('/login')
        }



        
let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo); 

    if(type==='pagination'){ 
        let pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage); i++) {
            pageNumbers.push(i);
        } 
        let renderPageNumbers = pageNumbers.map(number => {
            return (
            <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li> 
            );
        });
        return renderPageNumbers;
    }



        if(type==='type1'){ 
            var typee = 0;
            let xx = currentTodos.map((e,index)=>{ 
                typee = typee+1;
                let trrt = e.building_id;
                let dateconv = e.created_at; 
                let okasboxnoofdatabase = e.okasBox_id;                
                
                // //console.log(okasboxnoofdatabase)
                return (
                    <tbody key={index}>  
                        <tr>                        
                        <td style={{minWidth: '5%'}} >
                                {this.state.rowOpenNumber===index && this.state.showRow ?
                                    <img src={Minus}   style={{cursor: 'pointer', margin:'0px 10px'}} onClick={(e)=>{ 
                                            this.setState({
                                                rowOpenNumber: index,
                                                showRow: !this.state.showRow,
                                                currentBuildingSelected: trrt
                                            })
                                        }} />
                                :
                                <img src={Plus}   style={{cursor: 'pointer', margin:'0px 10px'}} onClick={(x)=>{
                                    console.log(e) 
                                            this.setState({
                                                rowOpenNumber: index,
                                                // showRow: !this.state.showRow,
                                                showRow: true,
                                                currentBuildingSelected: trrt,
                                                currentProjectBoxDetails: e.box_details
                                            })
                                        }}  />
                                }
                            </td> 
                            <td>{index+1}</td>
                            {/* <td>{e.building_id}</td> */}
                            <td>{e.building_name}</td>
                            <td>{e.owner_name}</td>
                            <td>{e.address}</td>
                            <td>{e.total_number_of_okas_box}</td>
                            <td>{e.project_manager}</td>
                            <td>{e.office_address}</td> 
                            <td>{dateconv.substring(0, 10)} {dateconv.substring(11, 19)}</td> 
                        </tr>
                     
                        {this.state.rowOpenNumber===index && this.state.showRow ?  <tr> 
                            <td  colspan="11"> 
                                    <div className="row datasetleft">
                                    
                                        <div className="col-lg-4 pdbox1">
                                            <b>Building Details</b>
                                            <div className="">{/* Building ID -  */}{e.building_id}</div>
                                            <div className="senihading">{/* Building Name -  */}{e.building_name}</div>
                                            <div className="">{/* Building Address -  */}{e.address}</div>
                                            <br></br>
                                            <div className="senihading">{/* Owner Name -  */}{e.owner_name}</div>
                                            <div className=""> {/* Date -  */}{dateconv.substring(0, 10)} </div>
                                            <div className=""> {/* Time -  */}{dateconv.substring(11, 19)}</div>
                                        </div>
                                        <div className="col-lg-4 pdbox2">
                                            <b className="">  Project Manager Detail</b>
                                            <div className="senihading">  {/*  Project Manager Name -  */}{e.project_manager}</div>
                                            <div className="">   {/* Project Manager Location -  */}{e.office_address}</div>
                                        </div>
                                        <div className="col-lg-4">
                                        <b className=""> Okas Box Detail </b>
                                            <div className="row ksdjfhsdk"> 
                                                            {this.getokasnumber(okasboxnoofdatabase ,e.building_id)}
                                            {/* <div className=""><a className="button"  data-toggle="modal" data-target="#hometheaterbox">Add More...</a></div> */}
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-1">
                                            <Icon className="pull-right" icon={close} size={22}  onClick={(e)=>{ 
                                                this.setState({
                                                    rowOpenNumber: index,
                                                    showRow: !this.state.showRow
                                                })
                                            }}/>
                                        </div> */}
                                        
                                    </div>
                                </td>                     
                            </tr> : null}
                    </tbody>
                )         
            });
            return xx;
        }
        else if(type==='type2'){
            let xx1 = this.state.okasBoxData.map((e,index)=>{
                if(e.okasBox_id===123){
                    return (
                        <tr key={index}>
                    <td>{index+1}</td>
                    <td>{e.okasBox_id}</td>
                    <td>{e.okasBox_type}</td>
                    <td>{e.okasBox_serialNo}</td>
                    <td>{e.okasBox_version}</td>
                    <td>{e.okasBox_assigned_to}</td> 
                </tr>
                )
            }            
        });
        return xx1;
        } 
    }

    




        
  restoreData = (data) => {
    this.setState({
        okasBoxData: data,

    })
  }
   sortingFunc = (type,searchValue) => { 
    if(type!=="search"){
        this.props.searchBarActivate(false) 
    }
     

if(type=="building_id"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statuson: !this.state.statuson,
      })
}
if(type=="building_name"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statustw: !this.state.statustw,
      })
}
if(type=="owner_name"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusth: !this.state.statusth,
      })
}
if(type=="address"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusfo: !this.state.statusfo,
      })
}
if(type=="total_number_of_okas_box"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusfi: !this.state.statusfi,
      })
}  
if(type=="project_manager"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statussi: !this.state.statussi,
      })
} 
if(type=="office_address"){
    this.setState({ 
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusse: !this.state.statusse,
      })
}
if(type=="created_at"){
    this.setState({ 
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusei: !this.state.statusei,
      })
} 


     if(this.state.sortedType!==type && type!=='search' ){ 
       this.setState({
         sortedType: type
       })
     Sorting(this.restoreData, type, this.state.okasBoxData);
     }else if(type==='search'){ 

        type='search'; 
        Sorting(this.restoreData, type, this.state.actualdata,searchValue); 
      }else { 

       type='reverse';
     Sorting(this.restoreData, type, this.state.okasBoxData); 
     }
   }


   onSearchClick = () =>{
    this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
}

configBoxDetail = (e) => {
let boxType = this.state.currentConfigType;
let boxes = [];
    this.state.okasBoxData.forEach((innerElement)=>{
        if(innerElement.building_id===this.state.currentBuildingSelected){
            innerElement.box_details.forEach((inner)=>{
                if(boxType===inner.okasBox_type){
                    boxes.push(inner)
                }
            })
        }
    })
return boxes
}

getCountOfBoxType = (boxType) => {
   let count = 0;
   this.state.currentProjectBoxDetails.forEach((e)=>{

       if(e.okasBox_type===boxType){
           count++
       }
   })
   return count 
}


    render(){ 
         return (
            <React.Fragment>
            <div className="selfcontainer ">  
            {this.state.loader?<Loader/>:null}             
            {this.props.redux.globalVar.searchBarShow ?
                <div className='row searchinput'>
                    {/* <input  type="text"  ref={input => input && input.focus()} name="namess" id="namess" placeholder="Search" 
                        value={this.state.searchValue}
                        onChange={(e)=>{
                            this.setState({
                                searchValue: e.target.value
                                },()=>{
                                this.sortingFunc('search',this.state.searchValue)
                            })
                        }}
                    />   */}
                                            <SearchBar 
                        autoFocus
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', this.state.searchValue)
                          })
                        }
                        }
                        onRequestSearch={() => this.sortingFunc('search', this.state.searchValue)}
                        resetSearch = {(e)=>{
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', '')
                          })
                        }}
                        />
                </div> 
                 :''}
                {/* <div className="searchbar"> <Icon icon={search} />      Search Project Details</div> */}
                {this.props.redux.globalVar.userTypeStyle==='2' ? '':  
                <div className="addregOkasButton">
                    <Link to="projectdetailadd">
                        <div className="addregOkasButtonicon">
                            <Icon icon={ iosPlusEmpty } size={42} style={{color: " #15599F"}} />  
                            ADD NEW PROJECT
                        </div> 
                    </Link> 
                </div> 
                } 
                <div className='row'>     
                    <h3>Project Detail</h3> 
                    <div className= {this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >


<div className="inblksuccfull"><img src={Success}  /></div>
<div className="inblksucc1">
SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
</div> 
</div> 
                    <div className="tests">     
                    <table className="table table-striped  tablecontent "> 
                        <thead>
                            <tr> 
                                <th></th>
                                {/* <th>ID<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('building_id','')}}/></th> */}
                                <th>S.No
                                    {/* <Icon icon={this.state.on?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('building_id','')}}/> */}
                                    </th>
                                <th>Project Name<Icon icon={this.state.statustw?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('building_name','')}}/></th>
                                <th>Owner Name<Icon icon={this.state.statusth?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('owner_name','')}}/></th>
                                <th>Address<Icon icon={this.state.statusfo?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('address','')}}/></th>
                                <th>Boxes<Icon icon={this.state.statusfi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('total_number_of_okas_box','')}}/></th>
                                <th>Project Manager<Icon icon={this.state.statussi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('project_manager','')}}/></th>
                                <th>AREA<Icon icon={this.state.statusse?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('office_address','')}}/></th>
                                <th className="paddingright3">Installation D&T<Icon icon={this.state.ei?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('created_at','')}}/></th>
                            </tr>
                        </thead>  
                            {this.renderTableData('type1')}  
                    </table> 
                    <div  className="pull-right">
                    {this.state.todosPerPage < this.state.okasBoxData.length ?
                    <ul className="pagination pull-right paginat">                            
                               <li> 
                                <span className="">Showing {(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+1} -{this.state.currentPage*this.state.todosPerPage-this.state.okasBoxData.length<0?this.state.currentPage*this.state.todosPerPage:this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span> 
                                </li>
                                <li>
                                    <a 
                                      onClick= {this.state.currentPage == -1+this.state.currentPage*this.state.todosPerPage ? null :  
                                            this.handleClickpage.bind(this,this.state.currentPage-1)
                                        }
                                     aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                           
                        {this.renderTableData('pagination')} 
                            <li className="">
                            <a className=""  
                            onClick= {this.state.currentPage*this.state.todosPerPage == this.state.okasBoxData.length ? null :  
                                  this.handleClickpage.bind(this,this.state.currentPage+1)
                              }  
                            
                            aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                            </a>
                            </li>
                    </ul>
                    :null}
                    </div>
                    
                    </div>
                </div> 
                <div className="modal fade" id="hometheaterbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog"> 
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.state.okasboxmodaletype}</h4>
                            </div>
                            <div className="modal-body">
                                <table className="tablecontent tablecontentas">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{paddingLeft: '5%'}}>OKAS BOX NO.</th>
                                            <th>OKAS BOX TYPE</th>
                                            <th>REGISTRATION ID</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.someNewOne}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button"  className="btn allbtn" data-dismiss="modal" onClick={()=>this.setState({okasboxid: '',someNewOne: '', textvalue: []})}>Cancel</button> 
                                <button type="button"  disabled = {this.state.okasboxid==='' ? true : false}   className="btn btn-primary allbtn" 
                                onClick={ 
                                    this.onAddClickHandler.bind(this,'add',this.state.realtimebuildingid,this.state.realtimeokasboxtypetoget) 
                                }
                                >Add</button> 
                                <input type="hidden" name="okasboxid"  id="okasboxid"       value={this.state.okasboxid} placeholder=""     
                                onChange={(e)=>{
                                    this.setState({
                                        okasboxid: e.target.value
                                    }) 
                                }}               
                                />   
                            </div>
                        </div> 
                    </div> 
                </div>  
            </div>
              <div className="modal fade" id="configip" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog"> 
                  <div className="modal-content">
                      <div className="modal-header">                   
                          <h4 className="modal-title">Config {this.state.currentConfigType}</h4>
                      </div>
                      <div className="modal-body">
                          <table className=" table table-striped tablecontent tablecontentas" style={{borderRadius: '5px'}}>
                          <thead>
                              <tr>
                                  <th>REGISTRATION ID </th>
                                  <th>LICENSE ID</th>
                                  <th>IPS</th>
                            {this.props.redux.globalVar.userTypeStyle==='2' ?  <th>CONFIG IP</th>  : null}
                              </tr>
                              </thead>
                               <tbody>
                                 {this.configBoxDetail().map((e)=>{                                     
                                   return (
                                       <tr>
                                <td>{e.okasBox_serialNo}</td>
                                <td>{e.okasBox_version}</td>
                                <td>
                                    {'IP:'+e.ip}<br/>{'SM:'+e.subnet_mask}<br/>{'GW:'+e.gateway}
                                </td>                   
                                {this.props.redux.globalVar.userTypeStyle==='2' ?  <td>
                                {/* <a data-toggle="modal" data-target="#configForm" style={{color: 'blue'}} onClick={()=>{this.setState({currentConfigType: 'Master Controller', currentBoxSelected: e.okasBox_id})}}>         */}
                                <a data-toggle="modal" data-target="#configForm" style={{color: 'blue'}} onClick={()=>{this.setState({showmodels:true,currentConfigType: this.state.currentConfigType, currentBoxSelected: e.okasBox_id,ipaddress:e.ip,subnetmask:e.subnet_mask,defaultgateway:e.gateway,})}}>        
                                    Config        
                                </a>                                     
                                    </td> : null}                   
                            </tr>
                                       );  
                                 })}
                             </tbody>

                          </table>
                      </div>
                      <div className="modal-footer">
                          <button type="button"  data-dismiss="modal" className="btn btn-primary allbtn">Close</button>
                      </div>
                  </div> 
              </div>
          </div>  
          
            {/*config modal start */}
{this.state.showmodels?
            <div className="selfmodal fade" id="configForm" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="selfmodal-dialog"> 
                    <div className="selfmodal-content">
                        <div className="modal-header"> 
                            <h4 className="modal-title">Config IP Form</h4> 
                        </div>
               {  this.state.showError ? <div style={{marginRight: '10px', marginLeft: '10px'}}>            {!this.state.ipError ? <div className="alert alert-danger mx-2">IP error
</div> : null}
{!this.state.subnetError ? <div className="alert alert-danger mx-2">Subnet Error </div> : null} 
{!this.state.defaultError ? <div className="alert alert-danger mx-2">default Error</div> : null} </div> : null}
                        {/* <div><b>Okas Box Number : </b>{this.state.pmid}</div> */}
                        <div className="modal-body modfields">
                            <Input label="IP Address" type="text" name="ipaddress" placeholder="IP Address"  value={this.state.ipaddress}
                             onChange={(e)=>{
                                this.setState({
                                    ipaddress: e.target.value,
                                    showError: false,
                                },()=>console.log(this.state.ipaddress))

                            }}
                            /> 
                              <br/>
                            <Input label="Subnet Mask" type="text" name="subnetmask" placeholder="Subnet Mask"  value={this.state.subnetmask} error="something"
                             onChange={(e)=>{
                                this.setState({
                                    subnetmask: e.target.value,
                                    showError: false
                                },()=>{
                                    console.log(checkIp(this.state.subnetmask))
                                }
                                )
                            }}
                            />   <br/>
                            <Input label="Default Gateway" type="text" name="defaultgateway" placeholder="Default Gateway"  value={this.state.defaultgateway}
                             onChange={(e)=>{
                                this.setState({
                                    defaultgateway: e.target.value,
                                    showError: false
                                })
                            }}
                            />   <br/> 
                                <span className="fieldmend"></span>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn allbtn" data-dismiss="modal" onClick={()=>this.setState({

                          ipaddress: '',
showError: false,
subnetmask: '',
defaultgateway: '',
showmodels:false
                        })} >Cancel</button>  
                        <button type="button" className="btn btn-primary allbtn"  onClick={this.updateConfigIP.bind(this,'add',this.state.currentBoxSelected)}>Update </button>
                        </div>
                    </div> 
                </div>
            
        </div>  
:''}  
            {/*config modal end */} 
          </React.Fragment>
          

        );
    }  
} 

const mapStateToProps = state  => {
    return {
      redux: state,
    };
  };
  
  
  const mapDispatchToProps = dispatch => {
    return {
      searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
      searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
    };
  };
  

export default connect(mapStateToProps, mapDispatchToProps) (ProjectDetailPagepm); 