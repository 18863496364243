import React from 'react';
import Sorting from './Sorting';
import createHistory from 'history/createBrowserHistory';
import {sort} from 'react-icons-kit/fa/sort';
import 'mdbreact/dist/css/mdb.css';
import { Input } from 'mdbreact'; 
import Icon from 'react-icons-kit'; 
import _ from 'lodash';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty'; 
import 'css/regokas.css';
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross' 
import OkasSupport from 'images/headericon/OkasSupport.svg';
import {attachment} from 'react-icons-kit/entypo/attachment'
import {ic_send} from 'react-icons-kit/md/ic_send'
import User from 'images/headericon/User.svg';



// class Regokasbox extends React.Component {
export default class Termspage extends React.Component{
constructor(props){
    super(props);
    this.state = { 
        kk: 'asdvhj',
        subject:'',
        description:'',
        error: '',
        nameerror: '',
        namess:'',
        sortedType:'',
        searchValue: '',
        actualdata:[],
        disabledvalue:'',
        classshowofmodal:false, 
        successmsgmodaldata:localStorage.getItem('dataadded'),
        showmodels:'',
        showchat:'',
        searchbar:'',
        chatData:[],
        ticketvareal:'',    
        subjectvareal:'',   
        statusvareal:'',  
        message:[],  

    };

}

componentWillMount(){    

}
componentDidMount(){
   
  }
 
 



    
    render(){
        //  const { classes } = this.props;
     return (
         
     <div className="  "> 
        

       <h1 align="center"> Terms of Use</h1>

<p>PLEASE READ CAREFULLY THESE TERMS OF USE, AS THEY CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU AND MANNAN IMPEX PVT. LTD., AND GOVERN YOUR USE OF THIS WEBSITE AND THE INTERNET AND APPLICATION SERVICES ACCESSED VIA MANNAN AND MANNAN BRANDS. BY USING THE WEBSITE OR SUCH SERVICES, YOU ARE ACCEPTING AND AGREEING TO THESE TERMS ON BEHALF OF YOURSELF OR THE ENTITY YOU REPRESENT, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ACCEPT AND AGREE TO THESE TERMS ON BEHALF OF YOURSELF OR THE ENTITY YOU REPRESENT. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, YOU MAY NOT ACCESS, REGISTER FOR OR USE THE WEBSITE OR THE SERVICES ACCESSED THROUGH THE WEBSITE.</p>

<ol>
    <li>INTRODUCTION AND SCOPE
        <p>
            MANNAN IMPEX PVT. LTD. ("OKAS") sells hardware products (the "Hardware") and software, either embedded in the Hardware or distributed separately (the "Software" and together with the Hardware, the "OKAS System"). MANNAN IMPEX PVT. LTD. owns and operates this website and other websites of MANNAN IMPEX PVT. LTD. owned brands; e.g., OKAS®, Mannan.ai® (the "Website"), and provides access to certain internet and software application services related to the use of OKAS Systems and MANNAN IMPEX PVT. LTD.-owned brands (the "Services").
        </p>
        <p>
            These Terms of Use, together with the MANNAN IMPEX PVT. LTD. privacy policy, locates Hyperlink ("Privacy Policy"), form a binding legal agreement between you and OKAS (the "Agreement") that sets forth the terms and conditions under which OKAS agrees to make the Website and the Services available to you and which govern your use of the Website and the Services provided to you.
        </p>
        <p>
            For the avoidance of doubt, the OKAS End User License Agreement between you and OKAS (the "EULA") sets forth the terms and conditions that apply to your use of any Software, and the OKAS Limited Warranty provided with your Hardware (the "Warranty") sets forth the terms of the limited warranty that applies to the Hardware. A copy of the Warranty is available at Hyperlink , and a copy of the EULA is available at Hyperlink.
        </p>
        <p>
            OKAS may, in its sole discretion and without prior notice to you, add, delete or otherwise change the provisions of these Terms by posting on the Website a version of these Terms containing such changes. You should periodically check the Website for the most current version of these Terms. If you are dissatisfied with any such changes to these Terms, your sole recourse will be to cease use of the Website and the Services or any portion thereof, in accordance with Section 10 below.
        </p> 
    </li>

    <li>SERVICES
        <p>
            The features and functions of the Services are described in more detail in the applicable OKAS user documentation and guidelines available elsewhere on the Website. The Services include any additional features and functionality that OKAS may, in its sole discretion and from time to time, offer to you. OKAS may, in its sole discretion and without notice to you, add, delete or otherwise change features and functions of the Services at any time. If you are dissatisfied with any such changes to the Services, you may immediately cancel your use of such Services as provided in Section 10 below.
        </p> 
    </li>

    <li>ELIGIBILITY AND REGISTRATION
        <p>
            You may subscribe to the Services only if you have the legal capacity to form a binding contract with OKAS. In particular, if you are under eighteen (18) years of age, you may not register to use the Services, and must have someone in your household who is at least eighteen (18) years of age register to use the Services. You may not use the Services if OKAS has previously prohibited you from use of the Website and/or the Services.
        </p> 
        <p>
            You are required to register your OKAS System in order to use the Services. Use of the Services requires that your OKAS System be connected to an always-on, broadband Internet connection. During the registration process you may be asked to provide certain information (the "User Information") to OKAS such as: (a) your full name; (b) your mailing address; and (c) your e-mail address. You agree to provide accurate and complete User Information during the registration process. User Information shall also include any data collected by OKAS from time to time on the installation, settings and use of your OKAS System, including information obtained for use in data analytics or software development and maintenance services. OKAS respects the privacy of your User Information and will treat User Information in accordance with the Privacy Policy. You have the right to update your User Information, or if you would like to withdraw your consent to the collection, storage and use of your User Information, you may immediately cancel your use of the Services at any time as provided in Section 10 below or email your request to mail@; note that if you choose to withdraw your consent to the use of your User Information, the functionality of the OKAS products and systems may be impaired, or it may not work at all.
        </p> 
    </li>


    <li>LICENSE RIGHTS AND USE RESTRICTIONS
        <p>
            (a) Access and Use. Subject to the terms and conditions of this Agreement, any applicable limitations on the number of devices and payment of any applicable fees, OKAS grants you a non-transferable, non-exclusive, right (without the right to sublicense) to access and use the Services as provided to you by OKAS and solely for the purpose of controlling and monitoring your OKAS System (the "Permitted Purpose").
        </p>
        <p>
            (b) Certain Restrictions. Your use of the Services is subject to the following restrictions: you agree (i) not to license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Services; (ii) not to modify, make derivative works of, disassemble,
        </p>
        <p>
            reverse compile, or reverse engineer any part of the Services; (iii) not to access the Services in order to build a similar or competitive service, (iv) not to use the Services for any unlawful purpose, or for any purpose other than the Permitted Purpose; (v) that no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted, or transmitted in any form or by any means; (vi) not to upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer or communication network, computer, handheld mobile device, data, the Website, the Services, the Hardware, the Software, or any other system, device or property; (vii) not to interfere with, disrupt, or attempt to gain unauthorized access to, the servers or networks connected to the Website or the Services, or violate the regulations, policies, or procedures of such networks; (viii) not to access (or attempt to access) any of the Services by means other than through the interface that is provided by OKAS; and (ix) not to remove, obscure or alter any proprietary rights notices (including copyrights and trademark notices) which may be contained in or displayed in connection with the Website or the Services.
        </p>
        <p>
            (c) IN ADDITION, IN NO EVENT DOES OKAS AUTHORIZE YOU OR ANYONE ELSE TO USE THE SERVICES WHERE THE FAILURE OF THE SERVICES TO PERFORM CAN REASONABLY BE EXPECTED TO RESULT IN SIGNIFICANT PHYSICAL INJURY, OR IN LOSS OR PROPERTY, OR IN LOSS OF LIFE. ANY SUCH USE IS ENTIRELY AT YOUR OWN RISK, AND YOU AGREE TO INDEMNIFY AND HOLD OKAS HARMLESS FROM ANY AND ALL CLAIMS OR LOSSES RELATING TO SUCH UNAUTHORIZED USE.
        </p>
        <p>
            (d) Modification. OKAS reserves the right, at any time, to modify, suspend, or discontinue the Services or any part thereof, with or without notice. You agree that OKAS will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services or any part thereof.
        </p> 
    </li>


    <li>THIRD-PARTY CONTENT
        <p>
            The Services may give you the ability to access content, such as music or video services, television or other material, controlled or provided by third parties ("Third-Party Content"). You understand and acknowledge that: (a) Third-Party Content remains the property of the applicable third parties, who have the sole right to determine your rights to use such content; (b) OKAS is not responsible for, and has no editorial control over, any Third-Party Content, and OKAS does not sponsor or endorse any such content; and (c) OKAS has no control over the distribution of Third-Party Content. You agree that OKAS will have no liability to you or to any third parties, including without limitation to anyone else who uses your OKAS System, related to or arising out of to any Third-Party Content. You also agree that neither the Services will not be used to illegally copy, display or otherwise make use of Third-Party Content without authorization from the appropriate rights holder or in violation of applicable law. Unauthorized copying or distribution of copyrighted or trademarks may constitute an infringement of the copyright or trademark holders' intellectual property rights. In addition, steps intended to defeat or bypass security measures designed to prevent infringement of the intellectual property rights of others may be illegal under U.S. law or comparable foreign laws. OKAS
        </p>
        <p>
            reserves the right to terminate your right to access and use the Services and delete your account if OKAS believes in good faith that you have used the Services or your OKAS System to infringe upon the intellectual property rights of others.
        </p>
    </li>
    <li>SUBSCRIPTION FEES AND PAYMENT; TAXES
        <p>
            (a) Subscription Fees. Access to the Services is provided on a subscription basis and requires the payment of a subscription fee ("Subscription Fee"). You agree to pay any applicable Subscription Fee charged by OKAS in advance. Initial subscriptions are for a period of one (1) year following the activation of your account. If for any reason you need to reactivate a subscription, you may be charged a reactivation fee. If you register for the Services under a promotional Subscription Fee, some additional restrictions and limitations may apply.
        </p>
        <p>    
            (b) Payment. Access to your account will not be permitted until OKAS has verified that the credit card information is accurate and that your credit card account is in good standing. If you choose a subscription option with recurring payments, you agree that OKAS may automatically charge the subscription fee to your credit or charge card at the beginning of each billing period. OKAS reserves the right to suspend or terminate your access to the Services without notifying you upon rejection of any credit card charges or if your card issuer (or its agent or affiliate) seeks return of payments previously made to OKAS for the Services. Such rights are in addition to and not in lieu of any other legal right or remedies available to OKAS. OKAS reserves the right to refer your account to a third party for collection in the event of an ongoing default, and you agree to reimburse OKAS for any costs incurred by OKAS in the collection of amounts that are past due, including for any reasonable attorneys' fees and court costs.
        </p>
        <p>    
            (c) Changes in Subscription Fees. OKAS may, in its sole discretion, change fees from time to time and charge additional fees for any features and functionalities that are not a part of the initial Services covered by a Subscription Fee. OKAS will use commercially reasonable efforts to notify you (by email or such other method reasonably selected by OKAS) thirty (30) days in advance of any fee changes or new fees.
        </p>
        <p>    
            (d) Taxes. The amounts payable by you to OKAS hereunder are exclusive of any applicable sales, use, value added, excise or similar taxes that may apply to the transactions contemplated hereunder. Any such taxes shall be your responsibility and you agree to pay to OKAS any such taxes that OKAS is required to collect under applicable law.
        </p>
    </li>
    <li>INTERNET SERVICE PROVIDER CHARGES
        <p> 
            Access to and use of the Services requires the use of, and you are responsible for, an always-on broadband Internet connection. OKAS is not responsible for and does not make any assurances about the availability or functionality of any broadband Internet connection. Network protection for your Internet connection is strongly advised to protect your OKAS System against viruses and other types of harm. You are responsible for any service charges for your Internet connection incurred as a result of using or accessing the Website and/or the Services and
        </p>
        <p>      
            acknowledge and agree that you shall be solely responsible for all disputes with any Internet service provider related to the same.
        </p>   
    </li>  
    <li>CERTAIN OF YOUR REPRESENTATIONS AND WARRANTIES TO OKAS
        <p>
            You represent and warrant to OKAS that: (a) you are at least eighteen (18) years of age and are competent, and have the authority, to enter into this Agreement; (b) you have provided or will provide OKAS with User Information that is correct and complete; (c) you will not use the Services in violation of any applicable laws, regulations, or ordinances or for any illegal or unauthorized activities; (d) you take full responsibility for the actions of anyone to whom you disclose your password or login ID or whom you otherwise allow to access your account; and (e) you will never circumvent, compromise, nor attempt to circumvent or compromise any OKAS security measures in connection with the Services.
        </p>
    </li>
    <li> DEALERS ARE INDEPENDENT CONTRACTORS, NOT AGENTS OF OKAS
        
        <p>
            You are required to purchase your OKAS System from an authorized OKAS dealer or distributor ("Dealer") in your area of your choice. While such Dealers receive training regarding OKAS Systems, Dealers are independent contractors and service providers, and not employees or agents of OKAS. Accordingly, (i) OKAS makes no representations or warranties with respect to Dealers or their services and is in no way responsible for their acts or omissions, and (ii) Dealers have no right to bind OKAS legally or otherwise make commitments on behalf of OKAS. You are solely responsible to select a competent Dealer that meets your expectations. Such Dealer may offer additional installation, configuration or ongoing maintenance services. Any disputes, which may arise between you and your Dealer, are to be resolved between you and your Dealer.
        </p>
        
    </li>
    <li>TERM AND TERMINATION
        <p>
            These Terms will remain in full force and effect so long as you continue to use or access the Website and/or the Services, or until terminated in accordance with the provisions of this section. You may terminate your account and your right to use the Services for any reason, at any time. Your access to the Services and your account will be terminated upon your written or emailed request and any unused Subscription Fees that you have paid are nonrefundable. At any time OKAS may suspend or terminate your rights to use the Services, if OKAS in good faith believes that you have used the Services in violation of this Agreement. If you transfer or assign your OKAS System or any portion thereof to a new owner, your right to use the Services with respect to such products automatically terminates, and the new owner will have no right to use the Services under your account (as described below), and will be required to register for a separate account with OKAS. In addition, OKAS reserves the right to terminate your Services account and this Agreement for any reason or no reason, if OKAS gives you at least thirty (30) days advance notice of such termination. Upon any such termination of your account by OKAS, you will remain obligated to pay all outstanding fees and charges relating to your use of the Services prior to termination, but OKAS will return any unused Subscription Fees that you have paid if Your account is terminated for any reason other than Your breach of this Agreement.
        </p>
    </li>
    <li>OWNERSHIP
        <p>
            You acknowledge that all right title and interest in and to the Services, including all intellectual property rights therein and thereto, remain the exclusive property of OKAS and its licensors, and this Agreement grants to you no right or interest therein other than the limited rights expressly set forth herein, and OKAS and its licensors and suppliers reserve all rights not granted in this Agreement.
        </p>
    </li>
    <li>YOUR INDEMNIFICATION OF OKAS
        <p>
            You agree to defend, indemnify and hold harmless OKAS, and its officers, directors, employees, consultants, agents, and other representatives, from and against any and all suits, claims, actions, proceedings, damages, demands, injuries, liabilities, losses, costs and expenses (including reasonable attorneys' fees), arising from or related to: (a) your negligence or willful misconduct; (b) your use of the Services; (c) your breach of any of your representations, warranties, obligations, or covenants in this Agreement; and (d) your use of Third-Party Content.
        </p>
    </li>
    <li>WARRANTY DISCLAIMERS
        <p>
            (a) THE WEBSITE AND THE SERVICES ARE PROVIDED FOR YOUR CONVENIENCE, ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND, AND OKAS AND ITS LICENSORS AND SUPPLIERS EXPRESSLY DISCLAIM, TO THE GREATEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, AND NON-INFRINGEMENT.
        </p>
        <p>
            (b) WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, OKAS AND ITS LICENSORS AND SUPPLIERS MAKE NO WARRANTY THAT DEFECTS WILL BE CORRECTED OR THAT THE WEBSITE OR THE SERVICES: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE COMPATIBLE WITH YOUR COMPUTER OR MOBILE DEVICE; (III) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (IV) WILL BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OKAS, ANY DEALER OR THROUGH THE WEBSITE OR THE SERVICES SHALL CREATE ANY WARRANTY WITH RESPECT TO THE WEBSITE OR THE SERVICES. FURTHER, OKAS DOES NOT WARRANT THAT THE WEBSITE OR THE SERVICES OR THE OKAS SERVERS THAT PROVIDE YOU WITH DATA AND CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME THE ENTIRE RISK WITH RESPECT TO YOUR USE OF THE SERVICES. FURTHERMORE, OKAS MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO ANY PROFESSIONAL SERVICES PERFORMED BY ANY OKAS DEALERS.
        </p>
    </li>
    <li>LIMITATION OF LIABILITY
        <p>
            IN NO EVENT WILL OKAS OR ANY OF ITS SUPPLIERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY: (A) SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR
        </p>
        <p>
            FOR ANY COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, ARISING FROM OR RELATED TO OKAS'S PERFORMANCE OR FAILURE TO PERFORM IN ACCORDANCE WITH THIS AGREEMENT, OR YOUR USE OF THE WEBSITE AND/OR THE SERVICES, OR THE OPERATION OR USE OF THE SERVICES OR YOUR OKAS SYSTEM THROUGH YOUR ACCOUNT BY ANYONE ELSE, INCLUDING WITHOUT LIMITATION DAMAGES ARISING FROM LOSS OF DATA, PROGRAMMING OR THIRD PARTY CONTENT, LOSS OF REVENUE OR PROFITS, OR FOR BUSINESS INTERRUPTION; (B) FAILURE TO REALIZE SAVINGS OR OTHER BENEFITS, DAMAGE TO EQUIPMENT, AND CLAIMS AGAINST YOU BY ANY THIRD PERSON; (C) DAMAGES (REGARDLESS OF THEIR NATURE) FOR ANY DELAY OR FAILURE BY OKAS TO PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT DUE TO ANY CAUSE BEYOND OKAS'S REASONABLE CONTROL; OR (D) CLAIMS MADE A SUBJECT OF A LEGAL PROCEEDING AGAINST OKAS MORE THAN ONE YEAR AFTER ANY SUCH CAUSE OF ACTION FIRST AROSE. NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, OKAS'S AND ITS SUPPLIERS' TOTAL, CUMULATIVE LIABILITY ARISING FROM OR RELATED TO YOUR USE OF THE WEBSITE AND/OR THE SERVICES, WHETHER IN CONTRACT OR TORT OR OTHERWISE, SHALL NOT EXCEED THE FEES ACTUALLY PAID BY YOU TO OKAS OR OKAS'S AUTHORIZED RESELLER FOR THE SERVICES GIVING RISE TO LIABILITY IN THE SIX (6) MONTHS IMMEDIATELY PRIOR TO THE EVENT GIVING RISE TO LIABILITY. YOU AGREE THAT THE EXCLUSIONS AND LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 14 APPLY WHETHER A CLAIM IS ALLEGED AS A BREACH OF CONTRACT OR TORTIOUS CONDUCT, INCLUDING NEGLIGENCE, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ALSO AGREE THAT THE FOREGOING EXCLUSIONS AND LIMITATIONS OF LIABILITY ARE A REASONABLE ALLOCATION OF THE RISK BETWEEN THE PARTIES, FORM A MATERIAL PART OF THIS AGREEMENT, AND WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY LIMITED REMEDY PROVIDED TO YOU FAILS IN ITS ESSENTIAL PURPOSE.
        </p>
    </li>
    <li>EXCLUSIONS
        <p>
            Some jurisdictions do not allow the exclusion of implied warranties or the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you. This Agreement gives you specific legal rights, and you may also have other rights, which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under this Agreement will not apply to the extent prohibited by applicable law.
        </p>

    </li>
    <li>FORWARD-LOOKING STATEMENTS
        <p>
            This website contains express or implied forward-looking statements, which are based on current expectations of management. These statements relate to, among other things, our expectations regarding management's plans, objectives, and strategies. These statements are neither promises nor guarantees, but are subject to a variety of risks and uncertainties, many of which are beyond our control, and which could cause actual results to differ materially from those contemplated in these forward-looking statements. In particular, the risks and uncertainties include, among other things: changing market conditions, the introduction of new competitive products, challenges related to changing technologies, supply chain disruptions, unanticipated defects or deficiencies in the hardware, software or related services or changes in governmental regulations or requirements. OKAS assumes no obligation to update any
        </p>
        <p>
            forward-looking statements contained on the Website in the event of changing circumstances or otherwise and such statements are current only as of the date they are made.
        </p>
    </li>
    <li>UPDATES
        <p>
            OKAS may make improvements and/or changes to this Website at any time. Although we attempt to periodically update information on this website, the information, materials and services provided on or through this Website may occasionally be inaccurate, incomplete or out of date. OKAS does not have a duty to update information contained in this Website, and OKAS will not be liable for any failure to update such information. We make no representation as to the completeness, accuracy or correctness of any information on this Website, and we undertake no obligation to update or revise the information contained on this Website, whether as a result of new information, future events or circumstances or otherwise. It is your responsibility to verify any information contained in this Website before relying upon it.
        </p>
    </li>
    <li>GOVERNING LAW AND JURISDICTION; WAIVER OF JURY TRIAL
        <p>
            This Agreement and any claims arising hereunder will be governed by the laws of the State of Utah, excluding principles of conflict or choice of law. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms. To the extent permitted by applicable law, you and OKAS hereby irrevocably submit to the jurisdiction of any Utah state court or United States federal court, in either case sitting in Utah over any suit, action or other proceeding brought by you, a third party, or OKAS arising out of or relating to these Terms, and you and OKAS hereby irrevocably agree that all claims with respect to any such suit, action or other proceeding shall be heard and determined in such courts. THE PARTIES HEREBY UNCONDITIONALLY WAIVE THEIR RESPECTIVE RIGHTS TO A JURY TRIAL OF ANY CLAIM OR CAUSE OF ACTION ARISING DIRECTLY OR INDIRECTLY OUT OF, RELATED TO, OR IN ANY WAY CONNECTED WITH THE PERFORMANCE OR BREACH OF THIS AGREEMENT, AND/OR THE RELATIONSHIP THAT IS BEING ESTABLISHED AMONG THEM. The scope of this waiver is intended to be all encompassing of any and all disputes that may be filed in any court or other tribunal (including, without limitation, contract claims, tort claims, breach of duty claims, and all other common law and statutory claims). THIS WAIVER IS IRREVOCABLE, MEANING THAT IT MAY NOT BE MODIFIED EITHER ORALLY OR IN WRITING, AND THE WAIVER SHALL APPLY TO ANY SUBSEQUENT AMENDMENTS, RENEWALS, SUPPLEMENTS, OR MODIFICATIONS TO THIS AGREEMENT, AND RELATED DOCUMENTS, OR TO ANY OTHER DOCUMENTS OR AGREEMENTS RELATING TO THIS TRANSACTION OR ANY RELATED TRANSACTION. In the event of litigation, this Agreement may be filed as a written consent to a trial by the court.
        </p>
    </li>
    <li>MISCELLANEOUS
        <p>
            This Agreement represents the entire agreement between you and OKAS with respect to your use, and OKAS's provision, of the Website and the Services, and its terms supersede and replace any prior or contemporaneous written or oral statements by OKAS or its representatives, dealers, or resellers. If any provision of this Agreement is held by a court to be invalid or unenforceable under applicable law, such provision will be deemed modified to the extent
        </p>
        <p>
            necessary to render such provision valid and enforceable and the other provisions of this Agreement will remain in full force and effect. The official text of this Agreement or notice submitted hereunder shall be in English. In the event of any dispute concerning the construction or meaning of this Agreement, reference shall be made only to this Agreement as written in English and not to any translation into another language, and this Agreement will not be construed against the drafting party. You may not amend this Agreement except in a writing signed by OKAS. You may not assign, sublicense or transfer any of your rights and obligations under this Agreement to a third party without OKAS's prior written consent. Any attempted assignment, sublicense, or transfer without the prior written consent of OKAS shall be null and void. OKAS's failure to enforce any of the terms of the terms and conditions of this Agreement shall not constitute a waiver or relinquishment of OKAS's right to do so at any time. Sections 4(b), 6, 10, and 12 - 17 shall survive any termination or expiration of this Agreement.
        </p>
        <p>
            Copyright 2018 MANNAN IMPEX PVT. LTD. All rights reserved. All trademarks, logos, trade names and service marks ("Marks") displayed on the Website or the Services are the property of OKAS or of their respective holders. You are not permitted to use any of the Marks without the applicable prior written consent of OKAS or such respective holders. OKAS reserves the right to alter product and services offerings, specifications, and pricing at any time without notice, and is not responsible for typographical or graphical errors that may appear in this or in related documents.
        </p> 
    </li>


</ol>

 


    </div>
    );
}
    
}



// Regokasbox.propTypes = {
//     classes: PropTypes.object.isRequired,
//   };
  
//   export default withStyles(styles)(Regokasbox);
// export default Regokasbox;