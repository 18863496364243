import React from 'react';
import Sorting from './Sorting';
import {sort} from 'react-icons-kit/fa/sort';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import createHistory from 'history/createBrowserHistory' ;
import Test from 'views/test' ; 
import uniqid from 'uniqid'; 
import Cookies from 'js-cookie';
import 'css/regokas.css'; 
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import 'mdbreact/dist/css/mdb.css';
import { Input } from 'mdbreact';
import { MDBDataTable } from 'mdbreact';
 

import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import windowSize from 'react-window-size';
import Style from 'style-it';


// class Regokasbox extends React.Component {
class Regokasboxassigned extends React.Component{
constructor(props){
    super(props);
    this.state = {
        okasBoxData: [],
        kk: 'asdvhj',
        okasBoxType: '',
        registrationid: '',
        licenceid: '',
        error: '',
        nameerror: '',
        namess:'',
        sortedType:'',
        searchValue: '',
        actualdata:[],
        disabledvalue:'',

    };

}

oncolseClickHandler = ( event) => {
    this.setState({
        okasboxtype: '' , 
        registrationid:'', 
        licenceid:'' 
    })  
    const history = createHistory({forceRefresh: true});  
    history.push('/regOkas');
}


componentWillMount(){
    let cc = new Date().valueOf()
    console.log('Token '+localStorage.getItem("auth"));

   


}
componentDidMount(){

    const history = createHistory();
    const location = history.location;
    
    var test = location.pathname.split("/");
    let filteredbyurl = test[2];

    
    let TokenValue = 'Token '+localStorage.getItem("auth");
    var headers = {
      'Content-Type': 'application/json',
      'Authorization': TokenValue
  }
  
  fetch('/we/we/okasassigneddata/' ,{
          method: "GET",
          headers: headers
      })
  .then(response => response.json())
  .then(okasBoxData => {
      this.setState({
          okasBoxData,
          actualdata: okasBoxData
      })
  })
    }

    renderTableData = (type) => {
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
                localStorage.removeItem('username');
                this.props.history.push('/login')
        }
        if(type==='type1'){

            var typee = 0;
            let xx = this.state.okasBoxData.map((e,index)=>{
                // if(e.okas_box_number===1 && e.okas_box_number===123  ){
                    typee = typee+1;
                    return (
                        <tr key={index}>
                    {/* <td>{typee}</td> */}
                    <td>{e.okas_box_number}</td>
                    <td>{e.okas_box_type}</td>
                    <td>{e.registration_id}</td>
                    <td>{e.license_id}</td>
                    <td>{e.assigned_to}</td>
                </tr>
                )
            // }            
        });
        return xx;
    }
    else if(type==='type2'){
        let xx1 = this.state.okasBoxData.map((e,index)=>{
            if(e.okas_box_number===123){
                return (
                    <tr key={index}>
                <td>{index+1}</td>
                <td>{e.okas_box_number}</td>
                <td>{e.okas_box_type}</td>
                <td>{e.registration_id}</td>
                <td>{e.license_id}</td>
                <td>{e.assigned_to}</td>
            </tr>
            )
        }            
    });
    return xx1;
    }
        
    }


    onAddClickHandler = (buttonaction ) => { 
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': TokenValue,
          'X-CSRFToken': localStorage.getItem('csrftoken')
      } 
        let registration_id = this.state.registrationid.trim();
        let license_id = this.state.licenceid.trim();
        let okas_box_type = this.state.okasBoxType.trim();
        let okas_box_number = new Date().valueOf().toString(); 
        console.log(okas_box_number);
        let tt =[{
            registration_id,
            license_id,
            okas_box_type,
            okas_box_number
              }];

        if(okas_box_type.length < 1){
        this.setState({
            error: 'Okas Box Type cannot blank'  , 
            okasboxtypeerror:'errorfield selectlistss' 
        })
       
        
        }else  if(registration_id.length < 1){
            this.setState({
                error: 'Registration ID cannot blank' , 
                registrationiderror:'errorfield'
    
            })
        
        }else  if(license_id.length < 1){
        this.setState({
            error: 'License ID cannot blank' , 
            licenceiderror:'errorfield'  
            
        })
        
        }else  if(registration_id.length < 1){
            this.setState({
                error: 'Registration ID cannot blank' , 
                registrationiderror:'errorfield'
    
            })
        
        }else  { 
            this.setState({
                disabledvalue: 'disabled',
            })
            fetch('/we/we/insertokasbox/' ,{
            method: "POST",
            headers: headers,
            body: JSON.stringify(tt)
        })
            .then(response => response.json()) 
            if(buttonaction==='add') { 
                const history = createHistory({forceRefresh: true});  
                history.push('/regOkas');

            }else if(buttonaction==='addanother'){
                this.setState({
                    okasboxtype: '' , 
                    registrationid:'', 
                    licenceid:''  
                })  
            } 


 
}
  } 




  state = {
    name: 'Cat in the Hat',
    age: '',
    multiline: 'Controlled',
    currency: 'EUR',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };




  

  restoreData = (data) => {
    this.setState({
        okasBoxData: data,

    })
  }

   sortingFunc = (type,searchValue) => { 
    
     if(this.state.sortedType!==type && type!=='search' ){ 
       this.setState({
         sortedType: type
       })
     Sorting(this.restoreData, type, this.state.okasBoxData);
     }else if(type==='search'){ 

        type='search'; 
        Sorting(this.restoreData, type, this.state.actualdata,searchValue); 
      }else { 

       type='reverse';
     Sorting(this.restoreData, type, this.state.okasBoxData);
  
     }
   }


   onSearchClick = () =>{
    this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
}


    
        render(){ 
            return Style.it(`
            @media only screen and (max-width: 760px){
          td:nth-of-type(1):before { content: "S.NO"; }
          td:nth-of-type(2):before { content: "BOX TYPE"; }
          td:nth-of-type(3):before { content: "SERIAL NO."; }
          td:nth-of-type(4):before { content: "BOX VERSION"; }
          td:nth-of-type(5):before { content: "STATUS"; }
          td:nth-of-type(6):before { content: "DATE OF PURCHASE
          "; }
        }`,
         
     <div className="selfcontainer "> 

{this.props.redux.globalVar.searchBarShow ?
                <div className='row searchinput'>
                    <input  type="text"  name="namess" id="namess" placeholder="Search" 
                        value={this.state.searchValue}
                        onChange={(e)=>{
                            this.setState({
                                searchValue: e.target.value
                                },()=>{
                                this.sortingFunc('search',this.state.searchValue)
                            })
                        }}
                    />  
                </div> 
                 :''}
     {/* <Test data={this.state.okasBoxData}/> */}
     {this.state.usertype!=='200' ? 
''
:      <div className="addregOkasButton">
            <a className="addregOkasButtonicon" data-toggle="modal" data-target="#regiokasbox">
            <Icon icon={ iosPlusEmpty } size={42} style={{color: " #15599F"}} /> 
                 
                 ADD NEW OKAS BOX
              
            </a> 
        </div>  
     }
        <div className='row'>
           <h3>Okas Box Inventory</h3>
           <div className="tests">

            
            <table className={this.props.windowWidth >760 ? "table table-striped tablecontent":""}> 
                <thead>
                    <tr>
                    {/* <th>S No.</th> */}
                    <th>No.<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okas_box_number','')}}/></th>
                    <th>Type<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('okas_box_type','')}}/></th>
                    <th>Registration ID<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('registration_id','')}}/></th>
                    <th>Licence Id<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('license_id','')}}/></th>
                    <th   className="paddingright3">Assigned<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('assigned_to','')}}/></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableData('type1')}
                </tbody> 
            </table>
           </div>
        </div>   
            <div className="modal fade" id="regiokasbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog"> 
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
                            <h4 className="modal-title">ADD OKAS BOX</h4> {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null } 
                        </div>
                        <div className="modal-body modfields">
                        <div className="md-form">
                            <select  name="okasboxtype" placeholder="OKAS Box Type"   className={this.state.okasboxtypeerror ? this.state.okasboxtypeerror : "selectlistss"}
                            value={this.state.okasboxtype} 
                                onChange={(e)=>{
                                    this.setState({
                                        okasBoxType: e.target.value
                                    },()=>console.log(this.state.okasBoxType))

                                }}>
                                <option VALUE=""></option>
                                <option>Master Controller</option>
                                <option>VoD Box</option>
                                <option>Control Box</option>
                                <option>Music Box</option>
                                <option>Home Theater Box</option>
                                </select> 
                                <label className={this.state.okasBoxType=='' ?   null : 'active' }>Okas Box Type</label>
                        </div>  <br/>
                            <Input label="Registration Id" type="text" name="registrationid" placeholder="Registration Id"  className={this.state.registrationiderror ? this.state.registrationiderror : ""}
                            value={this.state.registrationid}
                              onChange={(e)=>{
                                this.setState({
                                    registrationid: e.target.value
                                },()=>console.log(this.state.registrationid))

                            }}
                            />   <br/>
                            <Input label="Licence Id" type="text" name="licenceid" placeholder="Licence Id"  className={this.state.licenceiderror ? this.state.licenceiderror : ""}
                             value={this.state.licenceid}
                              onChange={(e)=>{
                                this.setState({
                                    licenceid: e.target.value
                                },()=>console.log(this.state.licenceid))
                            }}
                            />   <br/>  

{/* <TextField
    id="standard-dense"
    label="Dense"
    className={classNames(classes.textField, classes.dense)}
    margin="dense"
/> */}<span className="fieldmend"></span>
                        </div>
                        <div className="modal-footer"> 
                            <button type="button" className="btn allbtn" data-dismiss="modal"  onClick={this.oncolseClickHandler.bind(this)}>Close</button> 
                            <button type="button" className="btn addbtn allbtn"  onClick={this.onAddClickHandler.bind(this,'addanother')}>Add Another</button> 
                            <button type="button"  disabled = {this.state.disabledvalue ? this.state.disabledvalue : null}  className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this,'add')}>Add</button>
                        </div>
                    </div> 
                </div>
            </div> 
    </div>
    );
}
    
}



const mapStateToProps = state  => {
    return {
      redux: state,
    };
  };
  
  
  const mapDispatchToProps = dispatch => {
    return {
      searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
      searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
    };
  };
  

export default windowSize(connect(mapStateToProps, mapDispatchToProps) (Regokasboxassigned));
