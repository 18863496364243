import _ from 'lodash'; 


export default function (errorType, errorText) {

     
if(errorType==='location'){ 
    if(errorText.length < 1){
        return 'Error'
    }
}else if(errorType==='phone'){ 
    if(errorText.length < 1){
        return 'Error'
    }
    if(errorText.match(/^[a-z]+$/)){
        // Numeric Value in mobile number
        return 'Error1'
    }
    if(!errorText.match(/^(\+\d{1,3}[-]?)?\d{10}$/)){
        // a valid mobile number of 10 digits
        return 'Error2'
    }
    if(!errorText.match(/^[6789]\d{9}$/)){
        // valid mobile number start from 7,8,9,10
        return 'Error3'
    }
    
}else if(errorType==='email'){ 
    if(errorText.length < 1){
        return 'Error'
    }
    if(!errorText.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        // valid email example(XXXXXXX@XXXXXX.XXX)
        return 'Error1'
    } 
    
}else if(errorType==='address'){ 
    if(errorText.length < 1){
        return 'Error'
    }  
}else if(errorType==='password'){ 
    if(errorText.length < 1){
        return 'Error'
    }
    if(!errorText.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)){
        // valid email example(XXXXXXX@XXXXXX.XXX)
        return 'Error1'
    } 
    
}else if(errorType==='name'){ 
    if(errorText.length < 1){
        return 'Error'
    }
    if(!errorText.match(/^[a-zA-Z ]*$/)){
        // Name cannot contain number value
        return 'Error1'
    }  
}else if(errorType==='okas_box_number1'){  
    if(errorText.length < 1){
        return 'Error'
    }
    if(errorText.length > 1){
        // Please add only one master controller
        return 'Error1'
    }   
}else if(errorType==='empty'){ 
    if(errorText.length < 1){
        return 'Error'
    } 
}else if(errorType==='okas_box_numcount'){ 
    if(errorText.length < 1){
        return 'Error'
    } 
}
    






 
}



