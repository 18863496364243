import React from 'react';
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import Cookies from 'js-cookie';
import Loader from '../components/loader';
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import Success from 'images/Success.svg';
import Error from 'images/Error.svg';
import 'mdbreact/dist/css/mdb.css';
import 'css/projectdetailaddpage.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Input } from 'mdbreact';
// import { Test } from 'home.js';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { cross } from 'react-icons-kit/icomoon/cross'
import uniqid from 'uniqid';
import errorText from './../errorText';
import * as ActionTypes from '../actions/types';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';
import Style from 'style-it';
import mc from 'images/mc.svg';
import ab from 'images/ab.svg';
import uvodb from 'images/uvodb.svg';
import vodb from 'images/vodb.svg';
import ml from 'images/ml.svg';
import IntegrationReactSelectNew from './IntegrationReactSelectNew';

let noOfFloors = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
];

class ProjectDetailEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            okasBoxData: [],
            projectmanagerdata1: [],
            memberLicenseTemp: [],
            memberLicenseToUpload: [],
            memberLicenseData: [],
            kk: 'asdvhj',
            showSome: false,
            okasBox_type: "",
            building_type: "",
            building_name: "",
            owner_name: "",
            address: "",
            no_of_floors: "",
            no_of_users: "",
            no_of_rooms: "",
            project_manager: "",
            office_address: "",
            okasBox_id: "",
            owner_phone: "",
            owner_email: "",
            textvaluetest: [],
            textvaluetest1: [],
            textvaluetest2: [],
            textvaluetest3: [],
            textvaluetest4: [],
            textvaluetest5: [],
            checkboxes: {},
            okasboxid: '',
            registrationid: '',
            licenceid: "",
            okasBoxType: "",
            buildingtype: "",
            buildingname: "",
            ownername: "",
            ownerphone: "",
            owneremail: "",
            buildingaddress: "",
            noOfFloors: "",
            nousers: "",
            norooms: "2",
            projectmanager: "",
            officeaddress: "",
            okasboxid: "",
            noofCount: 0,
            error: '',
            nameerror: '',
            disabledvalue: '',
            totalokasboxcount: '',
            okasbox1: '',
            okasbox2: '',
            okasbox3: '',
            okasbox4: '',
            okasbox5: '',
            loader: '',
            currentPage: 1,
            todosPerPage: 10,
            successmsgmodaldata: localStorage.getItem('dataadded'),
            showmodels: '',
            modelname: '',
            modelxname: '',
            checkedBoxes: '',
            checkedBoxestemp: [],
            radio: false,
            radio1: false,
            demobox1: 0,
            demobox2: 0,
            demobox3: 0,
            demobox4: 0,
            demobox5: 0,
        };
    }

    checkuserApi = (checkUserAdmin) => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/userCheckApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(checkuserApidata => {
                if (checkuserApidata.User_Type === '1') {
                    checkUserAdmin(true)
                } else {
                    checkUserAdmin(false)
                }
            })
    }

    componentWillMount() {
        console.log(this.props.redux.globalVar.userTypeStyle)
        this.checkuserApi((checkUserAdmin) => {
            if (checkUserAdmin) {
                this.unassignedMemberLicense();
            }
        });

        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                })
            }
                .bind(this),
            3000
        );


        var getParams = function (url) {
            var params = {};
            var parser = document.createElement('a');
            parser.href = url;
            var query = parser.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < 1; i++) {
                var pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
            return params;
        };

        // console.log(getParams(window.location.href).buildingid)
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        // fetch('/we/we/projectBuilding_DetailApi/'+getParams(window.location.href).buildingid ,{
        fetch('/we/we/projectBuildingApi_detail/' + getParams(window.location.href).buildingid, {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxDataget => {
                console.log(okasBoxDataget)
                this.setState({
                    okasBoxDataget,
                    buildingname: okasBoxDataget.building_name,
                    ownername: okasBoxDataget.owner_name,
                    ownerphone: okasBoxDataget.owner_no,
                    owneremail: okasBoxDataget.owner_email,
                    buildingaddress: okasBoxDataget.address,
                    noOfFloors: okasBoxDataget.no_of_floors,
                    nofloorstemp: okasBoxDataget.no_of_floors,
                    nousers: okasBoxDataget.no_of_users,
                    nouserstemp: okasBoxDataget.no_of_users,
                    buildingtype: okasBoxDataget.building_type,
                    okasboxid: okasBoxDataget.okasBox_id,
                    building_id: okasBoxDataget.building_id,
                    office_address: okasBoxDataget.office_address,
                    project_manager: okasBoxDataget.project_manager,
                    project_managerid: okasBoxDataget.pm_id,
                    memberLicenseIdToUpload: okasBoxDataget.licence_id
                }, () => {
                    this.renderTableData();
                    noOfFloors = [];
                    for(var x=this.state.noOfFloors;x<=5;x++){
                        noOfFloors.push({label:x ,value:x })
                    }


                    fetch('/we/we/okastabledata/', {
                        method: "GET",
                        headers: headers
                    })
                        .then(response => response.json())
                        .then(okasBoxData => {
                            this.setState({
                                okasBoxData
                            }, () => {
                                this.renderTableData();
                                this.renderTableDataedit('mastercontrollerbox')
                                this.renderTableDataedit('vodbox')
                                this.renderTableDataedit('UVOD Box')
                                this.renderTableDataedit('musicbox')
                                this.renderTableDataedit('hometheaterbox')
                            })
                        })


                })
            })
        //     console.log(this.props.location)
        //     let TokenValue = 'Token '+localStorage.getItem("auth");
        //     var headers = {
        //       'Content-Type': 'application/json',
        //       'Authorization': TokenValue
        //   } 
    }

    crosserrorfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            error: '',
            nameerror: '',
        })
        this.errormsg();
    }

    crosssuccessfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            successmsgmodaldata: '',
        })
    }

    errormsg = () => {
        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                    error: '',
                })
            }
                .bind(this), 3000
        );
    }
    onopenmodel = (modelname, modelxname) => {
        if (modelxname === 'memberlicense') {
            this.setState({
                memberLicenseTemp: this.state.memberLicenseToUpload
            }, () => {
                this.setState({
                    showmodels: 'true',
                    modelname: modelname,
                    modelxname: modelxname,
                    checkedBoxes: this.state.checkedBoxestemp,
                    error: false,
                })
            })
        } else {
            this.setState({
                showmodels: 'true',
                modelname: modelname,
                modelxname: modelxname,
                checkedBoxes: this.state.checkedBoxestemp,
                error: false,
            })
        }
    }

    onclosemodel = (event) => {
        this.setState({
            showmodels: '',
        })
    }

    checkresltimebox(checkvalue) {
        let valursft;
        const listItems1 = Object.entries(this.state.checkedBoxes).map(([key, value]) => {
            if (value.name == checkvalue) {
                valursft = value.presentValue;
                return valursft
            }
        })
        return valursft;
    }



    handleChange(e) {
        this.setState({
            checkedBoxestemp: this.state.checkedBoxes,
        })

        let oldArray = this.state.checkedBoxes;
        let current = this.state.checkedBoxes;
        let textvalue = [];
        let textvalue1 = [];
        let textvalue2 = [];
        let textvalue3 = [];
        let textvalue4 = [];
        let textvalue5 = [];


        const listItems = Object.entries(oldArray).map(([key, value]) => {


            if (value.boxType == "Master Controller") {
                if (value.presentValue === true) {
                    textvalue1.push(value.name)
                    textvalue.push(value.name)
                    this.setState({
                        textvaluetest1: textvalue1,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest1: textvalue1,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "VOD Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue2.push(value.name)
                    this.setState({
                        textvaluetest2: textvalue2,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest2: textvalue2,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "UVOD Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue3.push(value.name)
                    this.setState({
                        textvaluetest3: textvalue3,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest3: textvalue3,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "Audio Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue4.push(value.name)
                    this.setState({
                        textvaluetest4: textvalue4,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest4: textvalue4,
                        textvaluetest: textvalue,
                    })
                }
            }

            if (value.boxType == "Home Theatre Box") {
                if (value.presentValue === true) {
                    textvalue.push(value.name)
                    textvalue5.push(value.name)
                    this.setState({
                        textvaluetest5: textvalue5,
                        textvaluetest: textvalue,
                    })
                }
                if (value.presentValue === false) {

                    this.setState({
                        textvaluetest5: textvalue5,
                        textvaluetest: textvalue,
                    })
                }
            }



            // if(value.presentValue===true){  
            //     textvalue.push(value.name) 
            //         this.setState({ 
            //             textvaluetest: textvalue,
            //         })   
            //     if(value.boxType=="Master Controller"){  
            //         textvalue1.push(value.name)
            //         this.setState({ 
            //             textvaluetest1: textvalue1,
            //         })    
            //     } 
            //     if(value.boxType=="VOD Box"){  

            //         textvalue2.push(value.name)
            //         this.setState({ 
            //             textvaluetest2: textvalue2,
            //         })    
            //     } 
            //     if(value.boxType=="UVOD Box"){  
            //         textvalue3.push(value.name)
            //         this.setState({ 
            //             textvaluetest3: textvalue3,
            //         })    
            //     } 
            //     if(value.boxType=="Audio Box"){  
            //         textvalue4.push(value.name)
            //         this.setState({ 
            //             textvaluetest4: textvalue4,
            //         })    
            //     } 
            //     if(value.boxType=="Home Theatre Box"){  
            //         textvalue5.push(value.name)
            //         this.setState({ 
            //             textvaluetest5: textvalue5,
            //         })    
            //     } 

            // }  

            this.onclosemodel()
        })
    }




    componentDidUpdate() {
        // console.log(this.state.textvalue);




    }
    componentDidMount() {

        console.log(this.props.location)
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }

        fetch('/we/we/projectManagerApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(projectmanagerdata1 => {
                this.setState({
                    projectmanagerdata1
                }, () => {
                    this.renderTableData();
                    // console.log(this.state.projectmanagerdata1)
                })
            })

    }
    getChckeboxValue(event) {
        const value = event.target.value;
        // console.log(value)
    }



    renderTableDataedit = (type) => {
        if (type === 'mastercontrollerbox') {
            let count1 = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'Master Controller' && _.includes(this.state.okasboxid, e.okasBox_id)) {
                    count1++;
                    this.setState({
                        demobox1: this.state.demobox1 + count1
                    })
                }
            });
            return xx;
        }


        if (type === 'vodbox') {
            let count2 = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'VOD Box' && _.includes(this.state.okasboxid, e.okasBox_id)) {
                    count2++;
                    this.setState({
                        demobox2: this.state.demobox2 + count2
                    })
                }
            });
            return xx;
        }
        if (type === 'UVOD Box') {
            let count3 = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'UVOD Box' && _.includes(this.state.okasboxid, e.okasBox_id)) {
                    count3++;
                    this.setState({
                        demobox3: this.state.demobox3 + count3
                    })
                }
            });
            return xx;
        }
        if (type === 'musicbox') {
            let count = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'Audio Box' && _.includes(this.state.okasboxid, e.okasBox_id)) {
                    console.log('4')
                    count++;
                    this.setState({
                        demobox4: this.state.demobox4 + count
                    })
                }
            });
            return xx;
        }
        if (type === 'hometheaterbox') {
            let count = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'Home Theatre Box' && _.includes(this.state.okasboxid, e.okasBox_id)) {
                    console.log('5')
                    count++;
                    this.setState({
                        demobox5: this.state.demobox5 + count
                    })
                }
            });
            return xx;
        }

    }


    renderTableData1 = (type, managerName) => {
        if (type === 'renderprojectdata') {
            var typee = 0;
            let office_locationunique = this.state.projectmanagerdata1;
            office_locationunique = _.uniqBy(office_locationunique, 'office_location');
            let xxx = office_locationunique.map((e, index) => {
                return (
                    <option key={index}>{e.office_location}</option>
                )
            });
            return xxx;
        }
        if (type === 'renderprojectdatalocation') {
            var typee = 0;
            let xxx = this.state.projectmanagerdata1.map((e, index) => {
                if (e.office_location === managerName) {
                    return (
                        <option key={index} value={e.id}>{e.first_name}</option>
                    )
                }
            });
            return xxx;
        }
    }

    renderTableDataOfficeLocation = (managerName) => {
        let xxx = this.state.projectmanagerdata1.map((e, index) => {
            if (e.name === managerName) {
                return (
                    <option key={index}>{e.office_location}</option>
                )
            }
        });
        return xxx;
    }




    renderTableData = (type) => {

        if (this.state.okasBoxData.detail == 'Invalid token.') {
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }
        if (type === 'mastercontrollerbox') {
            var typee = 0;
            let count = 0;
            let tempcount = 0;
            let xx = this.state.okasBoxData.map((e, index) => {
                // console.log(e.okasBox_id)
                // console.log( this.state.okasboxid.split(',').length)
                // console.log([this.state.okasboxid].length)
                // console.log( _.includes(this.state.okasboxid,e.okasBox_id))
                if (e.okasBox_type === 'Master Controller' && (e.okasBox_assigned_to === 'unassigned' || _.includes(this.state.okasboxid, e.okasBox_id))) {
                    typee = typee + 1;
                    let dd = _.indexOf(this.state.textvalue1, e.okasBox_id);
                    let x = e.okasBox_id
                    if (_.includes(this.state.okasboxid, e.okasBox_id) === 'true') {

                    }

                    count++
                    return (
                        <tr key={index}>
                            {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td>
                                <input type="checkbox" name={e.okasBox_type}
                                    onChange={(ee) => {
                                        console.log(ee.target.checked);
                                        this.setState({
                                            checkedBoxes: {
                                                ...this.state.checkedBoxes,
                                                [x]: {
                                                    'name': x,
                                                    'presentValue': ee.target.checked,
                                                    'boxType': e.okasBox_type
                                                }
                                            }
                                        })
                                    }}
                                    checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                    value={e.okasBox_id} />
                            </td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}

                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth <= 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            console.log(ee.target.checked);
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}

                                </span>
                                {e.okasBox_type}
                            </td>
                            <td
                                className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}
                            >
                                {e.okasBox_serialNo}
                            </td>
                        </tr>

                    )
                }
            });
            if (count === 0) {
                xx =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in Inventory
                    </td>
                    </tr>)

            }
            return xx;
        }
        else if (type === 'vodbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === 'VOD Box' && (e.okasBox_assigned_to === 'unassigned' || _.includes(this.state.okasboxid, e.okasBox_id))) {
                    let x = e.okasBox_id
                    count++;
                    return (
                        <tr key={index}>
                            {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td>
                                <input type="checkbox" name={e.okasBox_type}
                                    onChange={(ee) => {
                                        console.log(ee.target.checked);
                                        this.setState({
                                            checkedBoxes: {
                                                ...this.state.checkedBoxes,
                                                [x]: {
                                                    'name': x,
                                                    'presentValue': ee.target.checked,
                                                    'boxType': e.okasBox_type
                                                }
                                            }
                                        })
                                    }}
                                    checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                    value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"} >
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth < 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            console.log(ee.target.checked);
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"} >{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            if (count === 0) {
                xx1 =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in Inventory
                    </td>
                    </tr>)

            }
            return xx1;
        }
        else if (type === 'UVOD Box') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "UVOD Box" && (e.okasBox_assigned_to === 'unassigned' || _.includes(this.state.okasboxid, e.okasBox_id))) {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }
                                        }
                                    })
                                }}
                                checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth < 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }
                                                }
                                            })
                                        }}
                                        checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            if (count === 0) {
                xx1 =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in Inventory
                </td>
                    </tr>)

            }
            return xx1;
        }
        else if (type === 'musicbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "Audio Box" && (e.okasBox_assigned_to === 'unassigned' || _.includes(this.state.okasboxid, e.okasBox_id))) {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    // console.log(ee.target.checked);
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }

                                        }
                                    })
                                }}
                                checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth < 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            // console.log(ee.target.checked);
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }

                                                }
                                            })
                                        }}
                                        checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            if (count === 0) {
                xx1 =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in Inventory
                </td>
                    </tr>)

            }
            return xx1;
        }
        else if (type === 'hometheaterbox') {
            let count = 0;
            let xx1 = this.state.okasBoxData.map((e, index) => {
                if (e.okasBox_type === "Home Theatre Box" && (e.okasBox_assigned_to === 'unassigned' || _.includes(this.state.okasboxid, e.okasBox_id))) {
                    let x = e.okasBox_id
                    count++
                    return (
                        <tr key={index}>
                            {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td><input type="checkbox" name={e.okasBox_type}
                                onChange={(ee) => {
                                    console.log(ee.target.checked);
                                    this.setState({
                                        checkedBoxes: {
                                            ...this.state.checkedBoxes,
                                            [x]: {
                                                'name': x,
                                                'presentValue': ee.target.checked,
                                                'boxType': e.okasBox_type
                                            }

                                        }
                                    })
                                }}
                                checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                value={e.okasBox_id} /></td> : null}
                            {/* <td>{e.okasBox_id}</td>  */}
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>
                                <span className="input-checkbox-mobile">
                                    {this.props.windowWidth < 760 ? <input type="checkbox" name={e.okasBox_type}
                                        onChange={(ee) => {
                                            console.log(ee.target.checked);
                                            this.setState({
                                                checkedBoxes: {
                                                    ...this.state.checkedBoxes,
                                                    [x]: {
                                                        'name': x,
                                                        'presentValue': ee.target.checked,
                                                        'boxType': e.okasBox_type
                                                    }

                                                }
                                            })
                                        }}
                                        checked={_.includes(this.state.okasboxid, e.okasBox_id) ? 'true' : this.checkresltimebox(x)}
                                        value={e.okasBox_id} /> : null}
                                </span>
                                {e.okasBox_type}
                            </td>
                            <td className={this.props.windowWidth > 760 ? "" : "checkbox-mobile"}>{e.okasBox_serialNo}</td>
                        </tr>
                    )
                }
            });
            if (count === 0) {
                xx1 =
                    (<tr>
                        <td colSpan='4' className="text-center">
                            No box available in Inventory
                </td>
                    </tr>)

            }
            return xx1;
        }
    }

    openNav = () => {
        this.setState({
            showSome: true
        })
    }

    memberLicensehandler = () => {
        if (this.state.modelxname === 'memberlicense') {
            let someData = this.state.memberLicenseTemp.map((e, index) => {
                return <tr key={index}>
                    {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <td className={this.props.windowWidth > 760 ? "" : "checkbox-memberlicense"} ><input type="checkbox" name={e.id}
                        onChange={(ee) => {
                            let memberLicenseTemp = this.state.memberLicenseToUpload;
                            let newValue = this.state.memberLicenseTemp.map((kk) => {
                                if (kk.uniqueId === e.uniqueId) {
                                    return { uniqueId: e.uniqueId, status: ee.target.checked }
                                } else {
                                    return kk
                                }
                            })
                            this.setState({
                                memberLicenseTemp: newValue
                            })

                        }}
                        checked={e.status}
                    /></td> : null}
                    <td className={this.props.windowWidth > 760 ? "" : "checkbox-memberlicense"}>
                        <span className="input-checkbox-mobile">
                            {this.props.windowWidth < 760 ? <input type="checkbox" name={e.id}
                                onChange={(ee) => {
                                    let memberLicenseTemp = this.state.memberLicenseToUpload;
                                    let newValue = this.state.memberLicenseTemp.map((kk) => {
                                        if (kk.uniqueId === e.uniqueId) {
                                            return { uniqueId: e.uniqueId, status: ee.target.checked }
                                        } else {
                                            return kk
                                        }
                                    })
                                    this.setState({
                                        memberLicenseTemp: newValue
                                    })

                                }}
                                checked={e.status}
                            /> : null}
                        </span>
                        {e.uniqueId}
                    </td>
                </tr>
            })
            return someData
        }
    }

    memberLicenseCheckHandler = (key) => {
        let apiSet = new Set(this.state.memberLicenseTemp);
        return apiSet.has(key)
    }

    unassignedMemberLicense = () => {
        this.setState({
            loader: true,
        })
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }

        fetch(`/we/we/weMemberLicenseAssignedApi/?userid=${localStorage.getItem('email')}`, {
            method: "GET",
            headers: headers,

        })
            .then(response => {
                console.log(response)
                return response.json()
            })
            .then(memberLicenseData => {
                console.log(memberLicenseData)
                let memData = memberLicenseData.map((e) => {
                    let uniqueId = e.licenseNo;
                    return {
                        uniqueId: uniqueId,
                        status: false
                    }
                })
                this.setState({
                    loader: false,
                    memberLicenseData: memData,
                    memberLicenseTemp: memData,
                    memberLicenseToUpload: memData
                })
            })
    }

    memberLicenseCount = () => {
        var r = 0;
        this.state.memberLicenseToUpload.forEach((e) => {
            if (e.status) {
                r++
            }
        })
        return r
    }

    handleChangeMemberLicense = () => {
        this.setState({
            memberLicenseToUpload: this.state.memberLicenseTemp,
        })
        this.onclosemodel();
    }


    assignedMemberLicense = () => {
        let data = this.state.okasBoxDataget.license_id.map((e, index) => {
            return (
                <tr key={index}>
                    {this.props.windowWidth > 760 ?
                        <td ><input
                            type="checkbox"
                            checked={true}
                        /></td> : null
                    }
                    <td className={this.props.windowWidth < 760 ? "checkbox-memberlicense" : ""}>
                        <span className="input-checkbox-mobile">
                            {this.props.windowWidth < 760 ?
                                <input
                                    type="checkbox"
                                    checked={true} /> : null}
                        </span>
                        {e}
                    </td>
                </tr>
            )
        })
        return data
    }

    selectAllCheckedBoxMemberLicense = (type) => {
        if (type === 'Member License') {
            let ml = this.state.memberLicenseTemp;
            let mlCount = 0;
            ml.forEach((e) => {
                if (e.status) {
                    mlCount++
                }
            })
            return mlCount === ml.length
        }
    }

    onSelectChange = (operationMode, valueSelect) => {
        if (valueSelect.label !== undefined) {
            this.setState({
                [operationMode]: valueSelect.value,
                error: false,
                [operationMode + "Error"]: false,
                reRenderManager: false
            })

        }
    }


    selectAllCheckedBox = (boxType) => {
        let currentBoxes = this.state.okasBoxData.filter((box) => box.okasBox_type.includes(boxType) && box.okasBox_assigned_to === 'unassigned');
        let checkedBoxes = this.state.checkedBoxes;
        let checkedBoxesCount = 0;

        Object.keys(checkedBoxes).map((e) => {
            if (checkedBoxes[e].boxType.includes(boxType) && checkedBoxes[e].presentValue) {
                checkedBoxesCount++
            }
        })
        return currentBoxes.length === checkedBoxesCount

    }


    onAddClickHandler = (buttonaction) => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')

        }
        // let building_id = new Date().valueOf().toString(); 
        let building_type = this.state.buildingtype;
        let building_name = this.state.buildingname.trim();
        let owner_name = this.state.ownername.trim();
        let owner_phone = this.state.ownerphone;
        let owner_email = this.state.owneremail;
        let address = this.state.buildingaddress;
        let no_of_floors = this.state.noOfFloors;
        let no_of_rooms = this.state.norooms;
        // let project_manager = this.state.projectmanager;
        let project_manager = this.state.project_manager;
        let pm_id = this.state.project_managerid;
        let okasBox_id = JSON.stringify(this.state.textvaluetest);
        okasBox_id = okasBox_id.replace("[", "");
        okasBox_id = okasBox_id.replace("]", "");
        // console.log(okasBox_id)

        if (okasBox_id) {
            okasBox_id = okasBox_id.concat(',');
            okasBox_id = okasBox_id.concat(this.state.okasboxid);
        } else {
            okasBox_id = this.state.okasboxid;
        }

        // console.log(okasBox_id)

        let okas_box_number1 = this.state.textvaluetest1;
        let okas_box_number2 = this.state.textvaluetest2;
        let okas_box_number4 = this.state.textvaluetest4;
        let okas_box_number5 = this.state.textvaluetest5;

        let license_id = this.state.okasBoxDataget.license_id;
        let countNewMemberLicense = 0;
        this.state.memberLicenseToUpload.forEach((license) => {
            if (license.status) {
                license_id.push(license.uniqueId)
                countNewMemberLicense++;
            }
        })

        let no_of_users = Number(this.state.nousers) + countNewMemberLicense;

        let tt = {
            // building_id,
            building_type,
            building_name,
            owner_name,
            owner_phone,
            owner_email,
            address,
            no_of_floors,
            no_of_users,
            project_manager,
            okasBox_id,
            license_id,
            pm_id
        };
        if (errorText('name', building_type) === 'Error') {
            this.setState({ error: 'Please enter building type', buildingtypeerror: 'errorfield selectlistss' })
        } else if (errorText('name', building_name) === 'Error') {
            this.setState({ error: 'Please enter building name', buildingnameerror: 'errorfield' })
        } else if (errorText('name', owner_name) === 'Error') {
            this.setState({ error: "Enter owner's name", ownernameerror: 'errorfield', })
        } else if (errorText('name', owner_name) === 'Error1') {
            this.setState({ error: "Please enter valid owner's name", ownernameerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error') {
            this.setState({ error: 'Enter mobile number', phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error1') {
            this.setState({ error: "Please enter Numeric Value in mobile number", phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error2') {
            this.setState({ error: "Please enter a valid mobile number of 10 digits", phoneerror: 'errorfield', })
        } else if (errorText('phone', owner_phone) === 'Error3') {
            this.setState({ error: "Please enter a valid mobile number start from 7,8,9,10", phoneerror: 'errorfield', })
        } else if (errorText('email', owner_email) === 'Error') {
            this.setState({ error: "Enter an email", emailerror: 'errorfield', })
        } else if (errorText('email', owner_email) === 'Error1') {
            this.setState({ error: "Please enter a valid email example(XXXXXXX@XXXXXX.XXX)", emailerror: 'errorfield', })
        } else if (errorText('address', address) === 'Error') {
            this.setState({ error: 'Please enter a valid building address', buildingaddresserror: 'errorfield', })
        } else if (errorText('empty', no_of_floors) === 'Error') {
            this.setState({ error: 'Please select no. of floors', noOfFloorsError: 'errorfield selectlistss' })
        } else if (errorText('empty', no_of_users) === 'Error') {
            this.setState({ error: 'Please select no. of users', nouserserror: 'errorfield selectlistss' })
        } else if (errorText('empty', no_of_rooms) === 'Error') {
            this.setState({ error: 'Please select no. of rooms', noroomserror: 'errorfield selectlistss' })
        } else if (okas_box_number1.length > 0) {
            this.setState({ error: 'You Can select only one master controller', noroomserror: 'errorfield selectlistss' })
        } else {
            this.setState({ disabledvalue: 'disabled', })
            this.setState({ loader: true, })
            fetch(`/we/we/projectBuildingApi_detail/${this.state.building_id}`, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(tt)
            })
                .then(response => response.json())
                .then(data => {
                    var dataadded = "dataadded";
                    localStorage.setItem("dataadded", dataadded);
                    if (buttonaction === 'add') {
                        const history = createHistory({ forceRefresh: true });
                        history.push('/projectdetail');

                    } else if (buttonaction === 'addanother') {
                        const history = createHistory({ forceRefresh: true });
                        history.push('/projectdetailadd');
                    }

                })
            this.setState({
                loader: true,
            })
        }
    }
    render() {
        return Style.it(`
            @media only screen and (max-width: 760px){
          td:nth-of-type(1):before { content: "BOX TYPE"; }
          td.checkbox-memberlicense:nth-of-type(1):before { content: "MEMBER LICENSE"; }
          td:nth-of-type(2):before { content: "SERIAL"; }
          td:nth-of-type(3):before { content: "EMAIL"; }
          td:nth-of-type(4):before { content: "ADDRESS"; }
          td:nth-of-type(5):before { content: "BOXES"; }
          td:nth-of-type(6):before { content: "DELETE"; }
        }`,

            <div className="selfcontainer"
            style={{
                position: !this.props.redux.globalVar.backgroundScrollStop ? "fixed" : "absolute",
                overflow: !this.props.redux.globalVar.backgroundScrollStop ? "hidden" : ""
            }}
            >
                {this.state.loader ? <Loader /> : null}
                <div className={this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >
                    <div className="inblksuccfull"><img src={Success} /></div>
                    <div className="inblksucc1">
                        SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                    </div>
                </div>
                <h3>Edit Project</h3>
                <div className={this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  >
                    <div className="inblksucc"><img src={Error} /></div>
                    <div className="inblksucc1">
                        {this.state.error} !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                    </div>
                </div>
                <form action="javascript:void(0)"
                //  onSubmit={this.onAddClickHandler.bind(this,'add')}
                >
                    <div className="row">
                        <div className="rightboxforma col-lg-6" style={{ marginTop: '28px' }}>
                            <div className="" style={{ marginTop: '5%' }}>
                                {this.state.showSome ? <span className="">BUILDING ID</span> : null}

                                <div className="p-0 custom-margin-mobile" style={{ marginBottom: "33px" }}>

                                    <Input label="Building ID" type="text" value={this.state.building_id} name="buildingtype" placeholder="Building Type "
                                        className={this.state.buildingnameerror ? this.state.buildingnameerror : ""}
                                        readonly='true' onChange={(e) => {
                                            if (e.target.value.length <= 100) {
                                                this.setState({
                                                    buildingtype: e.target.value,
                                                    error: false,
                                                    buildingnameerror: false,
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div className="p-0" style={{ marginBottom: "8px" }}>

                                    <Input label="Building Type" type="text" value={this.state.buildingtype} name="buildingtype" placeholder="Building Type "
                                        className={this.state.buildingnameerror ? this.state.buildingnameerror : ""}
                                        readonly='true' onChange={(e) => {
                                            if (e.target.value.length <= 100) {
                                                this.setState({
                                                    buildingtype: e.target.value,
                                                    error: false,
                                                    buildingnameerror: false,
                                                })
                                            }
                                        }}
                                    />
                                </div>

                                <br />
                                {/* <Input label="Building Type" type="text" name="buildingtype" placeholder="Building Type"  className={this.state.buildingtypeerror ? this.state.buildingtypeerror : ""}
                    onChange={(e)=>{
                        this.setState({
                            buildingtype: e.target.value
                        },()=>console.log(this.state.buildingtype))

                    }}
                    />   <br/> */}
                                <Input label="Building Name" type="text" value={this.state.buildingname} name="buildingname" placeholder="Building Name " className={this.state.buildingnameerror ? this.state.buildingnameerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                buildingname: e.target.value,
                                                error: false,
                                                buildingnameerror: false,
                                            })
                                        }
                                    }}
                                />   <br />
                                <Input label="Owner Name" type="text" name="ownername" placeholder="Owner Name " value={this.state.ownername} className={this.state.ownernameerror ? this.state.ownernameerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                ownername: e.target.value,
                                                error: false,
                                                ownernameerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Owner Phone" type="text" name="ownerphone" value={this.state.ownerphone} placeholder="Owner Phone " className={this.state.ownerphoneerror ? this.state.ownerphoneerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10 && (e.target.value.match(/^[0-9\b]+$/) || e.target.value == '')) {
                                            this.setState({
                                                ownerphone: e.target.value,
                                                error: false,
                                                ownerphoneerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Owner Email" type="text" value={this.state.owneremail} name="owneremail" placeholder="Owner Email " className={this.state.owneremailerror ? this.state.owneremailerror : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                owneremail: e.target.value,
                                                error: false,
                                                owneremailerror: false,
                                            })
                                        }
                                    }}
                                />      <br />
                                <Input label="Building Address" type="text" name="buildingaddress" value={this.state.buildingaddress} placeholder="Building Address" className={this.state.buildingaddresserror ? this.state.buildingaddresserror : ""}
                                    onChange={(e) => {
                                        console.log(e.target.value.length)
                                        if (e.target.value.length <= 100) {
                                            this.setState({
                                                buildingaddress: e.target.value,
                                                error: false,
                                                buildingaddresserror: false,
                                            })
                                        }
                                    }}
                                />     
                                {/* <div className="md-form">
                                    <select label="No. of Floors" type="number" name="noOfFloors" placeholder="No. of Floors"
                                        className={this.state.noOfFloorsError ? this.state.noOfFloorsError : "selectlistss"}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 100) {
                                                this.setState({
                                                    noOfFloors: e.target.value, error: false,
                                                    noOfFloorsError: false,
                                                })
                                            }
                                        }}>
                                        <option className="selectlistfirstelement" value=''></option>
                                        <option className={this.state.nofloorstemp > 1 ? "selectlistfirstelement" : null} selected={this.state.noOfFloors == 1 ? "true" : null}>01</option>
                                        <option className={this.state.nofloorstemp > 2 ? "selectlistfirstelement" : null} selected={this.state.noOfFloors == 2 ? "true" : null}>02</option>
                                        <option className={this.state.nofloorstemp > 3 ? "selectlistfirstelement" : null} selected={this.state.noOfFloors == 3 ? "true" : null}>03</option>
                                        <option className={this.state.nofloorstemp > 4 ? "selectlistfirstelement" : null} selected={this.state.noOfFloors == 4 ? "true" : null}>04</option>
                                        <option className={this.state.nofloorstemp > 5 ? "selectlistfirstelement" : null} selected={this.state.noOfFloors == 5 ? "true" : null}>05</option>
                                    </select>
                                    <label className={this.state.noOfFloors == '' ? null : 'active'}>No. of Floors</label>
                                </div>  */}
                                       {/* <br /> */}
                              
                                    {this.state.noOfFloors!=="" ? <IntegrationReactSelectNew
                                        placeholder="No. of Floors"
                                        multipleSelect={false}
                                        content={noOfFloors}
                                        onSelect={this.onSelectChange.bind(this, 'noOfFloors')}
                                        textFieldLabel="No. of Floors"
                                        errorText={this.state.noOfFloorsError === '' ? 'Please select number of floors' : ''}
                                        onFocus={(e) => { }}
                                        haveDefault={true}
                                        defaultValue={[{ label: this.state.noOfFloors, value: this.state.noOfFloors }]}
                                    /> : null}
                                
                                <div style={{ pointerEvents: "none",marginTop: '37px' }} >
                                    <Input label="No of Users" type="text" name="noOfUsers" value={this.state.nousers + this.memberLicenseCount()} placeholder="No of Users" />
                                </div>
                                {/* <div className="md-form">
                        <select label="No of Users" type="number" name="nousers" placeholder="No of Users "
                            className={this.state.nouserserror ? this.state.nouserserror : "selectlistss" }
                            onChange={(e)=>{
                            this.setState({
                            nousers: e.target.value,
                            error: false,
                            nouserserror: false,
                            })
                            }}
                            >
                            <option className="selectlistfirstelement" value=''></option>
                            <option className={this.state.nouserstemp>3?"selectlistfirstelement":null} selected={this.state.nousers==3?"true":null}>03</option>
                            <option className={this.state.nouserstemp>5?"selectlistfirstelement":null} selected={this.state.nousers==5?"true":null}>05</option>
                            <option className={this.state.nouserstemp>10?"selectlistfirstelement":null} selected={this.state.nousers==10?"true":null}>10</option> 
                        </select>
                        <label className={this.state.nousers=='' ? null : 'active' }>No of Users</label>
                    </div> */}
                                <br />
                            </div>
                        </div>
                        <div className="leftboxforma col-lg-6 mobile-device-padding-zero">
                            <span className="headprojectaddbox">OKAS Boxes</span>
                            <div className="leftboxformdiv">
                                {/* <div className="un" 
                            // style={{    padding: '0% 20%'}}
                        > 

                            <input onClick={(e)=>{
                                this.setState({
                                    radio: 'mastercontrollerbox',  
                                    checkedBoxes:'',
                                    textvaluetest:'',
                                    textvaluetest1:'',
                                    textvaluetest2:'',
                                    textvaluetest3:'',
                                    textvaluetest4:'',
                                    textvaluetest5:'',
                                    checkedBoxestemp:'', 
                                })
                            } } style={{width: '15px', height: '15px'}} checked={this.state.radio==='mastercontrollerbox' ? true : false} label="Master Controller" type="radio" id="radio2" name="mastercontrollerbox"/><b style={{marginLeft: "8px"}}>Multiple Box Solution</b>
                        <span className="pull-right">
                            <input onClick={(e)=>{
                                this.setState({
                                    radio: 'hometheaterbox', 
                                    checkedBoxes:'',
                                    textvaluetest:'',
                                    textvaluetest1:'',
                                    textvaluetest2:'',
                                    textvaluetest3:'',
                                    textvaluetest4:'',
                                    textvaluetest5:'',
                                    checkedBoxestemp:'',

                                })
                            } }  style={{width: '15px', height: '15px'}}  checked={this.state.radio==='hometheaterbox' ? true : false} label="Home Theatre Box" type="radio" id="radio2" name="hometheaterbox"/><b style={{marginLeft: "8px"}}>Single Box Solution</b>
</span>
                        </div> */}
                                {this.state.demobox1 ?
                                    <div style={{ minHeight: "190px" }} >
                                        <div className="un">
                                        <span className="icon-box"><img src={mc} /></span>
                                            Master Controller (
                            {this.state.textvaluetest1.length + this.state.demobox1 === 0 ? 0 : this.state.textvaluetest1.length + this.state.demobox1}
                                            )</div>
                                        <div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Master Controller', 'mastercontrollerbox')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>
                                        <div className="un"> <span className="icon-box"><img src={vodb} /></span>VOD Box (
                        {this.state.textvaluetest2.length + this.state.demobox2 === 0 ? 0 : this.state.textvaluetest2.length + this.state.demobox2}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'VOD', 'vodbox')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>

                                        <div className="un"><span className="icon-box"><img src={uvodb} /></span>UVOD Box (
                        {this.state.textvaluetest3.length + this.state.demobox3 === 0 ? 0 : this.state.textvaluetest3.length + this.state.demobox3}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'UVOD Box', 'UVOD Box')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>

                                        <div className="un"><span className="icon-box"><img src={ab} /></span>Audio Box (
                        {this.state.textvaluetest4.length + this.state.demobox4 === 0 ? 0 : this.state.textvaluetest4.length + this.state.demobox4}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Audio', 'musicbox')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>
                                        <div className="un"><span className="icon-box"><img src={ml} /></span>Member License (
                        {this.memberLicenseCount() + this.state.okasBoxDataget.license_id.length}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Additional Member', 'memberlicense')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>
                                    </div>
                                    : null}
                                {this.state.demobox5 ?
                                    <div style={{ minHeight: "190px" }} >
                                        <div className="un">Home Theatre Box (
                            {this.state.textvaluetest5.length + this.state.demobox5 === 0 ? 0 : this.state.textvaluetest5.length + this.state.demobox}
                                            )</div><div className="addbuttonasd"><a className="button" onClick={this.onopenmodel.bind(this, 'Home Theatre', 'hometheaterbox')}>{this.props.redux.globalVar.userTypeStyle === '1' ? 'Add' : 'View'}</a></div>
                                    </div>
                                    : null}
                                <div className="totalokasboxnumber">Total no. of OKAS Boxes  </div><div className="addbuttonasd"> {this.state.textvaluetest.length + this.state.okasboxid.split(',').length === 0 ? 0 : this.state.textvaluetest.length + this.state.okasboxid.split(',').length}  Boxes</div>               </div>
                            <div className="modal-body modfields " style={{ paddingTop: '10px' }}>
                                <div style={{ marginTop: "15px" }}>
                                    <div className="md-form">
                                        <Input label="Project Manager" type="text" value={this.state.project_manager} name="projectmanager" placeholder="Project Manager "
                                            className={this.state.projectmanagererror ? this.state.projectmanagererror : "selectlistss"}
                                            readonly='true' onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({
                                                        projectmanager: e.target.value,
                                                        error: false,
                                                        projectmanagererror: false,
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="md-form">
                                    <Input label="Office Address" type="text" value={this.state.office_address} name="officeaddress" placeholder="Office Address "
                                        className={this.state.officeaddresserror ? this.state.officeaddresserror : "selectlistss"}
                                        readonly='true' onChange={(e) => {
                                            this.setState({
                                                officeaddress: e.target.value,
                                                error: false,
                                                officeaddresserror: false,
                                            })
                                        }}
                                    />
                                </div>
                                <br />
                                {this.state.projectmanager ? <br></br> : ""}
                                {/* test */}
                                <input type="hidden" name="okasbox1" id="okasbox1" value={this.state.textvaluetest1} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox1: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox2" id="okasbox2" value={this.state.textvaluetest2} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox2: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox3" id="okasbox3" value={this.state.textvaluetest3} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox3: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox4" id="okasbox4" value={this.state.textvaluetest4} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox4: e.target.value
                                        })

                                    }}
                                />
                                <input type="hidden" name="okasbox5" id="okasbox5" value={this.state.textvaluetest5} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasbox5: e.target.value
                                        })

                                    }}
                                />

                                {/* testend */}
                                <input type="hidden" name="okasboxid" id="okasboxid" value={this.state.textvaluetest} placeholder=""
                                    onChange={(e) => {
                                        this.setState({
                                            okasboxid: e.target.value
                                        })
                                    }}
                                />
                            </div>

                        </div>
                        <br />

                        <span className="fieldmend"></span>
                    </div>
                    {/* {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null }  */}
                    <div className="modal-footer">
                        <Link to="/projectdetail"><button type="button" className="btn allbtn" data-dismiss="modal">Cancel</button> </Link>
                        {/* <button type="button"   className="btn addbtn allbtn"  onClick={this.onAddClickHandler.bind(this,'addanother')}>Add Another</button> */}
                        <button type="submit" disabled={this.state.disabledvalue || this.state.okasboxid ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this, 'add')}>Update</button>
                    </div>
                </form>
                {/* modals start */}
                {this.state.showmodels ?
                    <div className="selfmodal fade modal-dialog-centered" id="mastercontrollerbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog"
                            style={{
                                width: this.props.windowWidth > 760 ? "50%" : "95%",
                            }}
                        >
                            <div className="selfmodal-content">
                                <div className="modal-header">
                                    <button type="submit" className="close" data-dismiss="modal"
                                        onClick={this.onclosemodel.bind(this)}
                                    >&times;</button>
                                    {this.state.modelxname !== 'memberlicense' ? <h3 className="modal-title">Add {this.state.modelname} {this.state.modelname === 'UVOD Box' ? "" : "Box"}
                                        {/* <span className="breadcrum">Project Detail > Add New Project > Add New {this.state.modelname} Box</span> */}
                                    </h3> :
                                        <h3 className="modal-title">Add {this.state.modelname}
                                            {/* <span className="breadcrum">Project Detail > Add New Project > Add New {this.state.modelname}</span> */}
                                        </h3>}
                                </div>
                                {this.state.modelxname !== 'memberlicense' ? <div className="modal-body">
                                    <table className={this.props.windowWidth > 760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}>
                                        <thead>
                                            <tr>
                                                {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <th>
                                                    <input type="checkbox"
                                                        onChange={(ee) => {
                                                            let allBoxes = this.state.okasBoxData;
                                                            allBoxes = allBoxes.filter((box) => box.okasBox_assigned_to === 'unassigned' && box.okasBox_type.includes(this.state.modelname))
                                                            let x = { ...this.state.checkedBoxes };
                                                            allBoxes.map((event) => {
                                                                x[event.okasBox_id] = {
                                                                    'name': event.okasBox_id,
                                                                    'presentValue': ee.target.checked,
                                                                    'boxType': event.okasBox_type
                                                                }
                                                            })
                                                            this.setState({
                                                                checkedBoxes: x
                                                            })
                                                            this.selectAllCheckedBox(this.state.modelname)
                                                        }}
                                                        checked={this.selectAllCheckedBox(this.state.modelname)}
                                                    />
                                                </th> : null}
                                                {/* <th>OKAS BOX NO.</th> */}
                                                <th>BOX TYPE</th>
                                                <th>SERIAL NO.</th>
                                            </tr></thead>
                                        <tbody>
                                            {this.renderTableData(this.state.modelxname) ?
                                                this.renderTableData(this.state.modelxname)
                                                :
                                                <tr>
                                                    <td colSpan='4' className="text-center">
                                                        No box available in Inventory
                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div> : <div className="modal-body">
                                        <table
                                            className={this.props.windowWidth > 760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}
                                        >
                                            <thead>
                                                <tr>
                                                    {this.props.redux.globalVar.userTypeStyle === '1' && this.props.windowWidth > 760 ? <th>
                                                        <input type="checkbox"
                                                            onChange={(ee) => {
                                                                let mlStatus = this.state.memberLicenseTemp;
                                                                let mlCountSelected = 0;
                                                                let mlStatusUpdated = mlStatus.map((ml) => {
                                                                    let temp = {
                                                                        ...ml,
                                                                        status: ee.target.checked
                                                                    }
                                                                    return temp
                                                                })
                                                                this.setState({
                                                                    memberLicenseTemp: mlStatusUpdated
                                                                })
                                                            }}
                                                            checked={this.selectAllCheckedBoxMemberLicense('Member License')}
                                                        />
                                                    </th> : null}
                                                    <th className="text-center">Member License</th>
                                                </tr></thead>
                                            <tbody>
                                                {this.state.memberLicenseData.length === 0 && this.state.okasBoxDataget.license_id.length === 0 ?
                                                    <tr>
                                                        <td colSpan='2' className="text-center">
                                                            No member license available in inventory.
                                     </td>
                                                    </tr> : <React.Fragment>{this.assignedMemberLicense()}{this.memberLicensehandler()}</React.Fragment>
                                                }
                                            </tbody>
                                        </table>
                                    </div>}

                                <div className="modal-footer align-btn-mobile">
                                    {this.props.redux.globalVar.userTypeStyle === '1' ? <button type="button"
                                        onClick={this.onclosemodel.bind(this)}
                                        className="btn allbtn" data-dismiss="modal">Cancel</button> : <button type="submit"
                                            onClick={this.onclosemodel.bind(this)}
                                            className="btn btn-primary allbtn" data-dismiss="modal">Close</button>}
                                    {this.props.redux.globalVar.userTypeStyle === '1' ? <button type="submit"
                                        onClick={this.state.modelxname !== 'memberlicense' ? this.handleChange.bind(this) : this.handleChangeMemberLicense.bind(this)}
                                        className="btn btn-primary allbtn" data-dismiss="modal">
                                        Add
                            </button> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({ type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({ type: ActionTypes.SEARCHBARSTYLE, data: data }),
        userNameStyleActivate: (data) => dispatch({ type: ActionTypes.USERNAMESTYLE, data: data }),
        reuserNameStyleActivate: (data) => dispatch({ type: ActionTypes.REUSERNAMESTYLE, data: data }),
        userTypeStyleActivate: (data) => dispatch({ type: ActionTypes.USERTYPESTYLE, data: data }),
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),
    };
};


export default windowSize(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailEditPage));
