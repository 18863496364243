import React from 'react';
import Sorting from './Sorting';
import {sort} from 'react-icons-kit/fa/sort';
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up';
import 'mdbreact/dist/css/mdb.css';
import { Input, SelectInput, Select, SelectOptions, SelectOption } from 'mdbreact'; 
import Icon from 'react-icons-kit'; 
import _ from 'lodash';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty'; 
import 'css/regokas.css';
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross';
import {search} from 'react-icons-kit/icomoon/search'; 
import Delete from 'images/headericon/Delete.svg';   
import Delete1 from 'images/headericon/deletedeactive.svg';   
import Success from 'images/Success.svg';   
import Error from 'images/Error.svg';   
import IntegrationReactSelectNew from './IntegrationReactSelectNew';
import cities from './cities';
import Loader from '../components/loader';
import {cancelCircle} from 'react-icons-kit/icomoon/cancelCircle';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types'; 
import { withGlobalState } from 'react-globally';
import errorText from './../errorText';
import SearchBar from './SearchBar';
// import SnackBar from './SnackBar';
import SnackBar from './../containers/SnackBar';
import windowSize from 'react-window-size';
import Style from 'style-it';

// export default class OfficeLocation extends React.Component{
class OfficeLocation extends React.Component{ 
    constructor(props){
        super(props);
        this.state = {
            topHeightModal: '0%',
            okasBoxData: [],
            confirmWindow: false,
            kk: 'asdvhj',
            phone:'',
            name:'',
            email:'',
            officelocation:'',
            error: '',
            nameerror: '',
            namess:'',
            sortedType:'',
            searchValue: '',
            actualdata:[],
            disabledvalue:'',
            classshowofmodal:false, 
            successmsgmodaldata:localStorage.getItem('dataadded'),
            showmodels:'',
            searchbar:'',
            opensearch1:0,
            loader:'',
            currentPage: 1,
            todosPerPage: 10, 
            statuson:false,statustw:false,statusth:false,
            statusfo:false,statusfi:false,statussi:false,
            statusse:false,statusei:false,statusni:false,
            statuste:false, 
            indianCityWithState: [],
            showError: false,
            otherlocation:'',
            snackbarActive: false,
            snackbarMessage: '',
        };
        // this.handleClickpage = this.handleClickpage.bind(this);
    }

    confirmWindowClose = () =>{
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
        this.setState({
            confirmWindow: false
        })
    }

    onSelectChange = (operationMode,valueSelect) => {
        if(operationMode==='rating'){
          this.setState({
            SiRating: valueSelect.value,
            errorMessage: []
          })
        }
        if(operationMode==='relationStat'){
          this.setState({
            SiRelationStat: valueSelect.value,
            errorMessage: []
          })
        }
        if(operationMode==='siArea'){            
          this.setState({
            SiArea: valueSelect.value,
            errorMessage: [],
            officelocation:valueSelect.value,
            officelocationerror:false,
            error:false,
          })
        }
    }

    opensearch=(data)=> {
        if(data==0)
            this.setState({
                opensearch1: 1,
            });
        else
            this.setState({
                opensearch1:0,
            });
        // console.log(this.state.opensearch1)
    }

    componentWillMount(){  
        // console.log(TopHeader.state.opensearch) 
    }

    componentDidMount(){
        this.getallrealdata(); 
        let  cityNew = [];
        cities.map((e)=>{
            let newCity = {
                "label" : `${e.city} , ${e.state}`,
                "value" : `${e.city} , ${e.state}`
            }
            cityNew.push(newCity);
        })
        this.setState({
            indianCityWithState: cityNew
        },()=>console.log(this.state.indianCityWithState))   
    }
    
    getcitydata = () => {
        let  cityNew = [];
        cities.map((e)=>{
        let newCity = {
        "label" : `${e.city}, ${e.state}`,
        "value" : `${e.city}, ${e.state}`
        }
        cityNew.push(newCity);
        })
        this.setState({
        indianCityWithState: cityNew
        },()=>console.log(this.state.indianCityWithState))   
    }

//------------------------------------------------ error msg function start--------------------------------------------------------
    errormsg=()=>{ 
        setTimeout(
            function() { 
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata:'',
                    error:'',
                }) 
            }
            .bind(this),3000
        );
    }
    //------------------------------------------------ error msg function end ------------------------------------------------
    
    // ------------------------------------------------main data get API start------------------------------------------------
    getallrealdata = () =>{
        this.setState({
            loader: true ,  
        })
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        fetch('/we/we/locationApi/' ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json()) 
        .then(okasBoxData => {
            this.setState({
                okasBoxData,
                actualdata: okasBoxData
            },()=>{
                this.renderTableData(); 
            })
            this.setState({
                loader: false ,  
            })
        })

    } 
    //------------------------------------------------ main data get API end------------------------------------------------



    ondeleteChangekHandler = (pmids) => { 
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            confirmWindow: true         
        })
        if (true) { 
            this.setState({
                loader: true ,  
            })
            // console.log(pmids)
            let TokenValue = 'Token '+localStorage.getItem("auth");
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': TokenValue,
             'X-CSRFToken': localStorage.getItem('csrftoken')

            }  
            fetch('/we/we/locationApi_detail/'+this.state.officeLocationToDelete ,{
                method: "DELETE",
                headers: headers,
                // body: JSON.stringify(tt)
            })
            .then(response =>{ 
                response.json();
                if(response.status==200){ 
                    this.setState({
                        successmsgmodaldata:'dataadded',
                        successmodalstates:'add',
                        disabledvalue: '',
                    })  
                    this.getallrealdata(); 
                    this.oncolseClickHandler();
                    this.errormsg();  
                }else{
                    this.setState({
                        snackbarMessage: 'Office location can not deleted.',
                        snackbarActive: true,                                      
                      })
                    // alert('Office location can not deleted.'); 
                }
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
                this.setState({
                    loader: false ,  
                    confirmWindow: false,                   
                    
                }) 
            }) 
        }
    } 

    handleClickpage(number) { 
        this.setState({
          currentPage: number,
        });
        this.renderTableData('type1');
        this.renderTableData('pagination'); 
    }
    //------------------------------------------------ print main data(List view) function start----------------------------
    renderTableData = (type) => { 
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }     
        let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo);   
        if(type==='pagination'){ 
            let pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage); i++) {
                pageNumbers.push(i);
            } 
            let renderPageNumbers = pageNumbers.map((number,i) => { 
                if ((this.state.currentPage-3 < i+1) && ((this.state.currentPage+3 > i+1 || 6 > i+1) )  ) {
                    return ( 
                        <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage.bind(this,number)}>{number}</a></li> 
                    );
                }
            });
            return renderPageNumbers;
        }  
        if(type==='type1'){ 
            var typee = 0;
            let xx = currentTodos.map((e,index)=>{ 
                typee = typee+1;
               let offloc = e.ID;
                return (
                    <tr key={index}> 
                        {/* <td>{e.name}</td> */}
                        <td>{e.geo_location}</td>
                        <td>{e.phone}</td>
                        <td>{e.email}</td>
                        <td>{e.location}</td>
                        <td>{e.installed_box}</td>
                        {e.installed_box==0?
                            <td  className={this.props.windowWidth >760 ? "qwerty" : ""}><img src={Delete}   onClick={(e)=>{
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

                                this.setState({
                                    confirmWindow: true,
                                    officeLocationToDelete: offloc
                                })
                            }}   style={{cursor: 'pointer'}}/></td>
                        :
                            <td className={this.props.windowWidth >760 ? "qwerty" : ""}><img src={Delete1}/></td>
                        }
                    </tr>
                )         
            });
            return xx;
        } 
    } 
    //------------------------------------------------ print main data(List view) function end--------------------------------

    //------------------------------------------------ Delete Succ msg start//------------------------------------------------
    crosssuccessfullmsg = () => {   
            localStorage.removeItem('dataadded');
                this.setState({
                successmsgmodaldata:'',
        }) 
    }  

    crosserrorfullmsg = () => {   
        localStorage.removeItem('dataadded');
            this.setState({
                error:'',
                nameerror:'',
        }) 
    } 
    ////------------------------------------------------ Delete Succ msg end//------------------------------------------------

    oncolseClickHandler = ( event) => {
        this.setState({
            name: '' , 
            phone:'', 
            email:'', 
            officelocation:'', 
        })   
    } 
// --------------------------------------------------------open model function start----------------------------
    onopenmodel = ( event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
        this.setState({
            showmodels: 'true' ,  
        })   
        this.props.searchBarActivate(false)
        this.getcitydata();
    }
// --------------------------------------------------------open model function end----------------------------
 
// --------------------------------------------------------close model function start----------------------------
    onclosemodel = ( event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
        this.setState({
            showmodels: '',  
            error: '' , 
            name:'',
            phone:'',
            email:'',
            indianCityWithState:'',
            nameerror:'',
            phoneerror:'',
            emailerror:'',
            indianCityWithState:'', 
            officelocation:'',
        })   
    } 
// --------------------------------------------------------close model function End----------------------------

 
// ----------------------------Submit all data in data base POST API Start--------------------------------------
    onAddClickHandler = ( buttonaction) => {
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')
        }  
        let name = this.state.name.trim();
        let phone = this.state.phone.trim(); 
        let email = this.state.email.trim();
        let location = this.state.officelocation.trim()=='Other,'? this.state.otherlocation.trim() : this.state.officelocation.trim();
       
        
        let filterlocation =   _.filter(this.state.okasBoxData, ['geo_location', location]);   
        let filtername =   _.filter(this.state.okasBoxData,  ['name', name]   );   
        let tt ={
            name, phone,  email, location
        };   
        if(errorText('location', location)==='Error'){
            this.setState({ error: 'Please Select area', officelocationerror:'errorfield'  }) 
        }else if(errorText('phone', phone)==='Error'){
            this.setState({ error: 'Enter mobile number',phoneerror:'errorfield' ,  }) 
        }else if(errorText('phone', phone)==='Error1'){
            this.setState({ error: "Please enter Numeric Value in mobile number",phoneerror:'errorfield' , }) 
        }else if(errorText('phone', phone)==='Error2'){
            this.setState({  error: "Please enter a valid mobile number of 10 digits",phoneerror:'errorfield' , }) 
        }else if(errorText('phone', phone)==='Error3'){
            this.setState({  error: "Please enter a valid mobile number start from 7,8,9,10",phoneerror:'errorfield' , }) 
        }else if(errorText('email', email)==='Error'){
            this.setState({  error: "Enter an email",emailerror:'errorfield' , }) 
        }else if(errorText('email', email)==='Error1'){
            this.setState({ error: "Please enter a valid email example(XXXXXXX@XXXXXX.XXX)",emailerror:'errorfield' , }) 
        }else if(errorText('address', name)==='Error'){
            this.setState({ error: 'Please add address'  , addresserror:'errorfield' , }) 
        }else if((filterlocation.length == 0 && filtername.length > 0)||(filterlocation.length > 0 && filtername.length == 0)||(filterlocation.length == 0 && filtername.length == 0)){   
            this.setState({ disabledvalue: 'disabled', })  
            this.setState({ loader: true ,}) 
            fetch('/we/we/locationApi/' ,{  
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
            .then(response => {
                if(response.status===403){
                    window.location = "/login/session-timeout"
                }
                return response.json()
            })
            .then(response => { 
                console.log(response)
                if(response.message=='success'){ 
                    this.setState({
                        successmsgmodaldata:'dataadded',
                        successmodalstates:'add',
                        disabledvalue: '',
                        officelocation: '',
                        otherlocation: '',
                        phone:'',
                        email:'',
                        name :'',  
                        showmodels: 'false' ,   
                    })  
                    this.getcitydata();
                    this.getallrealdata(); 
                    this.oncolseClickHandler();
                    this.errormsg(); 
                    if(buttonaction==='add') {   
                        this.onclosemodel(); 

                    }else if(buttonaction==='addanother'){ 
                        this.onclosemodel(); 

                        this.onopenmodel(); 
                         
                    } 
                    this.setState({
                        loader: false ,  
                    })
                }else{
                    this.setState({
                        snackbarMessage: response.data[0],
                        snackbarActive: true,                                      
                            disabledvalue: '', 
                      })
                    // alert('Action not completed.'); 
                    // this.setState({ 
                    // }) 
                }
                this.setState({
                    loader: false ,  
                })
            })   
            .then(data=>{  })  
           
        // }
    }else{ 
            this.setState({
                error: 'Location already Exists with ' + name 
            })
        }  
    }  
    //----------------------------------------- Submit all data in data base POST API End----------------------------------------


    //-------------------------------------------Render data after sorting Function Start----------------------------------------------
    restoreData = (data) => {
        this.setState({
            okasBoxData: data, 
        })
    } 
    //-----------------------------------------------Render data after sorting Function End--------------------------------------------------


    //------------------------------------------------------- sorting Function Start----------------------------------------------
    sortingFunc = (type,searchValue) => {   
        if(type!=="search"){
            this.props.searchBarActivate(false) 
        }
        if(type=="geo_location"){
            this.setState({
                statuson: !this.state.statuson, 
                statustw:false,statusth:false,statusfo:false,
                statusfi:false,statussi:false,statusse:false,
                statusei:false,statusni:false,statuste:false, 
            })
        }
        if(type=="phone"){
            this.setState({
                statustw: !this.state.statustw,
                statuson:false,statusth:false,statusfo:false,
                statusfi:false,statussi:false,statusse:false,
                statusei:false,statusni:false,statuste:false, 
            })
        }
        if(type=="email"){
            this.setState({
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false, statusth: !this.state.statusth,
                
            })
        }
        if(type=="location"){
            this.setState({
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false,statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false,statusfo: !this.state.statusfo,
            })
        }
        if(type=="installed_box"){
            this.setState({
                statuson:false,statustw:false,statusth:false,
                statusfo:false,statusfi:false, statussi:false,
                statusse:false,statusei:false,statusni:false,
                statuste:false,statusfi: !this.state.statusfi,
            })
        } 
        if(this.state.sortedType!==type && type!=='search' ){ 
            this.setState({
                sortedType: type
            })
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }else if(type==='search'){  
            type='search'; 
            Sorting(this.restoreData, type, this.state.actualdata,searchValue); 
        }else {  
            type='reverse';
            Sorting(this.restoreData, type, this.state.okasBoxData); 
        }
    } 

    //---------------------------------------------------------- sorting Function End--------------------------------------------------

 
    render(){ 
        return Style.it(`
        @media only screen and (max-width: 760px){
      td:nth-of-type(1):before { content: "AREA"; }
      td:nth-of-type(2):before { content: "PHONE"; }
      td:nth-of-type(3):before { content: "EMAIL"; }
      td:nth-of-type(4):before { content: "ADDRESS"; }
      td:nth-of-type(5):before { content: "BOXES"; }
      td:nth-of-type(6):before { content: "DELETE"; }
    }`,
            <div className="selfcontainer"
            style={{
                position: !this.props.redux.globalVar.backgroundScrollStop && this.props.windowWidth < 760 ? "fixed" : "absolute",
                overflow: !this.props.redux.globalVar.backgroundScrollStop && this.props.windowWidth < 760 ? "hidden" : ""
            }} 
            
            > 
             <SnackBar open={this.state.snackbarActive} handleSnackbarClose={()=>{    this.setState({
      snackbarActive: false
    })}} message={this.state.snackbarMessage}/>
                {this.state.loader?<Loader/>:null} 
                {this.props.redux.globalVar.searchBarShow ?
                <div className='row searchinput'>
                    {/* <input  type="text"  ref={input => input && input.focus()} name="namess" id="namess" placeholder="Search" 
                        value={this.state.searchValue}
                        onChange={(e)=>{
                            this.setState({
                                searchValue: e.target.value
                                },()=>{
                                this.sortingFunc('search',this.state.searchValue)
                            })
                        }}
                    />   */}
                                            <SearchBar 
                        autoFocus
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', this.state.searchValue)
                          })
                        }
                        }
                        onRequestSearch={() => this.sortingFunc('search', this.state.searchValue)}
                        resetSearch = {(e)=>{
                          this.setState({
                            searchValue: e
                          }, () => {
                            this.sortingFunc('search', '')
                          })
                        }}
                        />
                </div> 
                 :''}
                {/* <div className="searchbar"><a onClick={() => this.opensearch(this.state.opensearch1)}> <Icon icon={search} /></a></div> */}

                <div className="addregOkasButton">
                    <a className="addregOkasButtonicon button-custom-height-size" data-toggle="modal" data-target="#regiokasbox" onClick={this.onopenmodel.bind(this)}>
                        <Icon icon={ iosPlusEmpty } size={40} style={{color: " #15599F"}} />  
                       <span className="hidden-xs hidden-sm button-color">ADD NEW OFFICE</span>  
                    </a> 
                    <div  className="hidden-xs hidden-md  hidden-lg button-color sm-heading-btn">Add New Office</div>
                </div> 
                <div className="row"> 
                    <h3>Dear {this.props.redux.globalVar.reUserNameStyle}, Listed below are your existing office locations</h3> 
                    <h6 className="mb-3 mt-0">To add a new location click on, "Add New Office" </h6> 
                    <div className= {this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  > 
                        <div className="inblksuccfull"><img src={Success}  /></div>
                        <div className="inblksucc1">
                            SUCCESS !
                            <a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
                        </div> 
                    </div>  
                    <div className="tests">       
                        <table className={this.props.windowWidth >760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"}> 
                            <thead>
                                <tr> 
                                    {/* <th>AREA<Icon icon={sort} size={16} className="pull-right" onClick={(e)=>{this.sortingFunc('name','')}}/></th> */}
                                    <th 
                                    style={{width: this.props.windowWidth >835 ? "20%" : "20%"}}
                                    >AREA<Icon icon={this.state.statuson?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('geo_location','')}}/></th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "15%"}}>PHONE<Icon icon={this.state.statustw?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('phone','')}}/></th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "25%"}}>EMAIL<Icon icon={this.state.statusth?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('email','')}}/></th>
                                    <th style={{width: this.props.windowWidth >835 ? "20%" : "25%"}}>ADDRESS<Icon icon={this.state.statusfo?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('location','')}}/></th>
                                    <th style={{width: this.props.windowWidth >835 ? "10%" : "15%"}}>BOXES<Icon icon={this.state.statusfi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16} className="pull-right"   onClick={(e)=>{this.sortingFunc('installed_box','')}}/></th>
                                    {/* <th>DATE & TIME<Icon icon={sort} size={16}  onClick={(e)=>{this.sortingFunc('created_at','')}}/></th> */}
                                    <th   className={this.props.windowWidth >760 ? "qwerty paddingright3" : "paddingright3"}>DELETE</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loader?<Loader/>:null}
                                {this.renderTableData('type1')}
                            </tbody> 
                        </table>
                        <div  className="pull-right">
                            {this.state.todosPerPage < this.state.okasBoxData.length ?
                            <ul className="pagination pull-right paginat">  
                                <li> 
                                    <span className="">Showing {(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+1} -{this.state.currentPage*this.state.todosPerPage-this.state.okasBoxData.length<0?this.state.currentPage*this.state.todosPerPage:this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span> 
                                </li> 
                                <li>
                                    <a 
                                      onClick= {this.state.currentPage == -1+this.state.currentPage*this.state.todosPerPage ? null :  
                                            this.handleClickpage.bind(this,this.state.currentPage-1)
                                        }
                                     aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li> 
                                {this.renderTableData('pagination')} in
                                <li className="">
                                    <a className=""  
                                        onClick= {this.state.currentPage*this.state.todosPerPage == this.state.okasBoxData.length ? null :  
                                            this.handleClickpage.bind(this,this.state.currentPage+1)
                                        }   
                                        aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                            :null}
                        </div> 
                    </div> 
                </div> 
                {this.state.showmodels? 
                <div className="selfmodal fade in modal-dialog-centered" style={{alignItems: "center",top: this.state.topHeightModal}}   aria-hidden="false" data-backdrop="static" data-keyboard="false">
                    <div className="selfmodal-dialog"
                     style={{
                         width: this.props.windowWidth > 760 ? "50%" : "90%",
                    }}
                     > 
                        <div className="selfmodal-content" >
                            <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"   onClick={this.onclosemodel.bind(this)}>&times;</button>
                                <h3 className="modal-title">Add New Office Location
                                </h3>  
                                <h6>Please fill in the details to add new office location.</h6>
                                <div className= {this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  > 
                                    <div className="inblksucc"><img src={Error}  /></div>
                                    <div className="inblksucc1">
                                        {this.state.error} !
                                        <a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-body modfields">  
                                <IntegrationReactSelectNew
                                    placeholder="Area"
                                    multipleSelect={false}
                                    content={this.state.indianCityWithState}
                                    onSelect={this.onSelectChange.bind(this, 'siArea') }
                                    textFieldLabel = "Area"
                                    errorText={this.state.officelocationerror==='' ? 'Please select office location' : ''}           
                                    onFocus={(e)=>{
                                        if(this.props.windowWidth===1024 && this.props.windowHeight===698){
                                            if(e==="clicked"){
                                                this.setState({
                                                    topHeightModal: "-30%"
                                                })
                                            }else if(e==="selected"){
                                                this.setState({
                                                    topHeightModal: "0%"
                                                })
                                            }
                                        }
                                    }}                        
                                />
                                <br/>
                                {this.state.officelocation.trim()=='Other,'?
                                <span>
                                <Input type="text" name="otherlocation" label="Other Location" className={this.state.otherlocation ? this.state.otherlocation : ""}
                                    value={this.state.otherlocation}
                                    onChange={(e)=>{
                                        // if(e.target.value.length<=10  && (e.target.value.match(/^[0-9\b]+$/) || e.target.value=='')){
                                        this.setState({
                                            otherlocation: e.target.value,  
                                            error: false, 
                                            otherlocationerror: false,
                                        }) 
                                    // }
                                    }}
                                />
                                <br/>
                                </span>:null}
                                <Input type="text" name="phone" label="Mobile Number" className={this.state.phoneerror ? this.state.phoneerror : ""}
                                    value={this.state.phone}
                                    onChange={(e)=>{
                                        if(e.target.value.length<=10  && (e.target.value.match(/^[0-9\b]+$/) || e.target.value=='')){
                                        this.setState({
                                            phone: e.target.value,  
                                            error: false, 
                                            phoneerror: false,
                                        }) 
                                    }
                                    }}
                                />
                                <br/>
                                <Input type="email" name="email" label="Office Email" className={this.state.emailerror ? this.state.emailerror : ""}
                                    value={this.state.email}
                                    onChange={(e)=>{
                                        this.setState({
                                            email: e.target.value,
                                            error: false, 
                                            emailerror: false,
                                        })
                                    }}  
                                />
                                <br/>  
                                <Input label="Address"  type="text"  name="name" id="name" placeholder="Name" required className={this.state.addresserror ? this.state.addresserror : ""}   
                                    value={this.state.name} 
                                    onChange={(e)=>{
                                        this.setState({
                                            name: e.target.value,
                                            error: false, 
                                            addresserror: false,
                                        }) 
                                    }}
                                />
                                <br/>  
                                <span className="fieldmend"></span>
                            </div>
                            <div className="modal-footer align-btn-mobile">
                                <div type="button" className="btn allbtn" onClick={this.onclosemodel.bind(this)}>Cancel</div>
                                <span className="hidden-xs hidden-sm">
                                <div  disabled = {this.state.disabledvalue ? this.state.disabledvalue : null}  className="btn addbtn allbtn"  onClick={this.onAddClickHandler.bind(this,'addanother')}>Add Another</div>
                                    </span>    
                                <div type="submit" disabled = {this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this,'add')}>Add</div>
                            </div> 
                        </div> 
                    </div>
                </div>  
                 :''}  
                 {this.state.confirmWindow ? <div className="selfmodal fade in modal-dialog-centered"   aria-hidden="false" data-backdrop="static" data-keyboard="false">
                    <div className="selfmodal-dialog " style={{width: "50%"}}> 
                        <div className="selfmodal-content ">
                            <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"   onClick={this.confirmWindowClose.bind(this)}>&times;</button>
                                <h4 className="modal-title h3 text-center">Delete confirmation</h4>  
                            </div>
                            <div className="modal-body modfields">  
                            <span className="fa " >Delete icon</span>
                            </div>
                            <div className="modal-footer text-center  align-btn-mobile">
                                <button type="button" className="btn allbtn" onClick={this.confirmWindowClose.bind(this)}>Cancel</button>                              
                                <button type="submit" disabled = {this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.ondeleteChangekHandler.bind(this)}>Delete</button>
                            </div> 
                        </div> 
                    </div>
                </div>  : null}
            </div>
        );
    }  
}


const mapStateToProps = state  => {
    return {
        redux: state,
    };
};
  
  
const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
        successBarActivate: (data) => dispatch({type: ActionTypes.SUCCESSBAR, data: data }),
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),
    };
};
  

export default windowSize(connect(mapStateToProps, mapDispatchToProps) (OfficeLocation));
