import 'css/topHeader.css';
import 'css/sidenav.css';
import logo from 'images/weokasmain.svg';
import bell from 'images/headericon/Notification1.svg';
import bell2 from 'images/headericon/Notification2.svg';
import comments from 'images/headericon/Chat.svg';
import logout from 'images/logout.svg';
import logoutGrey from 'images/logout-grey.svg';
import User from 'images/headericon/User.svg';
import Search from 'images/headericon/Search.svg';
import React, { Component } from 'react';
import createHistory from 'history/createBrowserHistory';
import 'css/sidenav.css';
import Support from 'images/headericon/Support.svg';
import { Link } from "react-router-dom";
import DashboardWhite from 'images/sidebar/DashboardWhite.svg';
import DashboardGray from 'images/sidebar/DashboardGray.svg';
import OfficeLocationWhite from 'images/sidebar/OfficeLocationWhite.svg';
import OfficeLocationGray from 'images/sidebar/OfficeLocationGray.svg';
import ProjectManagerWhite from 'images/sidebar/ProjectManagerWhite.svg';
import ProjectManagerGray from 'images/sidebar/ProjectManagerGray.svg';
import RegisterdOkasBoxWhite from 'images/sidebar/RegisterdOkasBoxWhite.svg';
import RegisterdOkasBoxGray from 'images/sidebar/RegisterdOkasBoxGray.svg';
import mlWhite from 'images/sidebar/mlWhite.svg';
import mlGray from 'images/sidebar/mlGray.svg';
import ProjectDetailWhite from 'images/sidebar/ProjectDetailWhite.svg';
import ProjectDetailGray from 'images/sidebar/ProjectDetailGray.svg';
import HelpWhite from 'images/sidebar/HelpWhite.svg';
import HelpGray from 'images/sidebar/HelpGray.svg';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import SnackBar from '../containers/SnackBar';
import windowSize from 'react-window-size';
import Style from 'style-it';


const sidelogoimage = {
    backgroundImage: "url(" + require('images/okasLogo.png') + ")",
    backgroundPosition: 'left 14px bottom 26px',

    // backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

class TopHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listOpen: false,
            notilistOpen: false,
            opensearch: 0,

            dummyData: [
                "Element 1",
                "Element 2",
                "Element 3",
                "Element 4"
            ],
            selected: "dashboard",
            floorList: "collapse",
            showSome: true,
            usertype: localStorage.getItem('usertype'),
            okasVersionApidata: [],
            checkuserApidata: [],
            snackbarActive: false,
            snackbarMessage: '',
            showMenuSmallDevices: false,
            showFullScreenNavbar: false
        }
    }

    okasVersionApi = () => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/okasVersionApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasVersionApidata => {
                this.setState({
                    okasVersionApidata,
                    actualdata: okasVersionApidata
                }, () => {
                    this.renderokasVersionApi();
                    console.log(this.state.okasVersionApidata)

                })
            })
    }


    renderokasVersionApi = () => {
        let xx = this.state.okasVersionApidata.map((e, index) => {
            return (
                <span key={index}>
                    {e.Application_Version}
                </span>
            )
        });
        return xx;
    }

    checkuserApi = () => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/userCheckApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(checkuserApidata => {
                console.log(checkuserApidata)
                this.setState({
                    checkuserApidata,
                    actualdata: checkuserApidata
                }, () => {
                    // console.log(this.state.checkuserApidata);

                    this.props.userNameStyleActivate(this.state.checkuserApidata.user_name)
                    this.props.reuserNameStyleActivate(this.state.checkuserApidata.first_name)
                    this.props.userTypeStyleActivate(this.state.checkuserApidata.User_Type)


                    if (this.state.checkuserApidata.status !== '200') {
                        localStorage.removeItem('auth');
                        // this.props.history.push('/login') 
                        window.location = "/login/session-timeout";
                    } else {
                        this.getallnotification();
                        this.okasVersionApi();
                    }
                })
            })
    }




    getallnotification() {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/notificationApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxData => {
                this.setState({
                    okasBoxData: okasBoxData,
                    noticount: okasBoxData.length
                }, () => {
                    this.renderTableData();
                    // console.log(this.state.okasBoxData)
                })
            })
    }


    renderTableData = () => {
        if (this.state.okasBoxData.detail == 'Invalid token.') {
            localStorage.removeItem('auth');
            this.props.history.push('/login')
        }
        var typee = 0;
        let xx = this.state.okasBoxData.map((e, index) => {
            return (
                <div key={index} className="notilist" onClick={this.opennotipage.bind(this, e.id, e.module_id)}>
                    <div class="popover-content">
                        <div class="notification-messages">
                            <div class="message-wrapper">
                                <div class="description">
                                    {e.message}
                                </div>
                                <div class="date pull-left">
                                    {/* {e.created_at} */}
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            )
        });
        return xx;
    }

    opennotipage = (id, module_id) => {
        this.getallnotification();
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
        }
        let tt = { id };
        fetch('/we/we/readNotificationApi/', {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tt)
        })
            .then(response => {
                response.json();
                const history = createHistory({ forceRefresh: true });
                localStorage.setItem("ticketid", module_id);
                history.push('/chat');
                this.props.history.push('/chat')
            })
            .then(data => { })
    }

    componentWillReceiveProps(prevProps,nextProps){
        console.log(prevProps)
    }

    componentDidMount(){
        // let r = document.getElementById("root");
        // console.log(r)

    }


    componentWillMount() {

        // const script = document.createElement("script"); 
        // script.id = "ze-snippet";
        // script.src = "https://static.zdassets.com/ekr/snippet.js?key=32338a0c-1ab0-46e8-9590-0cebb972e5c5";
        // script.async = false; 
        // document.head.appendChild(script);


        // const script1 = document.createElement("script");
        // script1.text = "zE(function() {name: 'kapil';email: 'kapil9056@gmail.com';});";
        // script1.async = true; 
        // document.head.appendChild(script1);



        //         var userName = localStorage.getItem('name'); 
        //   var userEmail = localStorage.getItem('email'); 

        this.checkuserApi();
        this.props.searchBarActivate(false)
        const history = createHistory();
        const location = history.location;
        if (localStorage.getItem('auth')) {
            if (this.props.redux.globalVar.userTypeStyle == 2) {
                if (
                    // location.pathname==='/regOkas' ||
                    location.pathname === '/projectdetail'
                    || location.pathname === '/support'
                    || location.pathname === '/chat'
                ) {

                } else {
                    // this.props.history.push('/projectdetail'); 
                }
            }
        } else {
            const history = createHistory({ forceRefresh: true });
            history.push('/login');
            this.props.history.push('/login')
        }



        if (window.innerWidth < 800) {
            this.setState({
                showSome: false,
            })
        }
        if (location.pathname === '/dashboard') {
            this.setState({
                selected: 'dashboard'
            })
        } else if (location.pathname === '/regOkas') {
            this.setState({
                selected: 'regOkas'
            })
        } else if (location.pathname === '/memberlicense') {
            this.setState({
                selected: 'memberlicense'
            })
        } else if (location.pathname === '/regOkasunassigned') {
            this.setState({
                selected: 'regOkas'
            })
        } else if (location.pathname === '/regOkasassigned') {
            this.setState({
                selected: 'regOkas'
            })
        } else if (location.pathname === '/projectdetail' || location.pathname === '/projectdetailadd' || location.pathname === '/projectdetailedit') {
            this.setState({
                selected: 'projectdetail'
            })
        } else if (location.pathname === '/projectmanager') {
            this.setState({
                selected: 'projectmanager'
            })
        } else if (location.pathname === '/officelocation') {
            this.setState({
                selected: 'officelocation'
            })
        } else if (location.pathname === '/support' || location.pathname === '/chat') {
            this.setState({
                selected: 'support'
            })
        } else if (location.pathname === '/help' || location.pathname === '/design-help') {
            this.setState({
                selected: 'help'
            })
        }
    }

    setSelected = (selected) => {
        if (!selected.includes("Element")) {
            this.setState({
                floorList: "collapse"
            })
        }
        this.setState({
            selected: selected
        })
    }

    toggleFloors = () => {
        this.setState(prevState => {
            if (prevState.floorList === "collapse") {
                this.setState({
                    floorList: "collapse show"
                })
            }
            else {
                this.setState({
                    floorList: "collapse"
                })
            }
        })
    }
    openNav = () => {
        this.setState({
            showSome: !this.state.showSome
        })
    }

    redirectPage = (page) => {
        // const history = createHistory({forceRefresh: true});  
        // history.push(page); 
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    togglenoti = () => {
        this.setState(prevState => ({
            notilistOpen: !prevState.notilistOpen
        }));
    }



    handleLogout = () => {
        fetch('/we/we/api/logoutview', {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "success") {
                    localStorage.clear()
                    localStorage.removeItem('auth');
                    localStorage.removeItem('username');
                    localStorage.removeItem('usertype');
                    localStorage.removeItem('email');
                    localStorage.removeItem('name');
                    // this.props.history.push('/login');
                    window.location = "/login";
                } else {
                    window.location = "/login/session-timeout";
                }
            })
        // this.props.history.push('/login')
    }


    onSearchClick = () => {
        this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
    }

    showMenuSmallDevices = () => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
        this.setState({
            showMenuSmallDevices: !this.state.showMenuSmallDevices
        })
    }

    render() {
        return (
            <div>
                {this.state.showMenuSmallDevices ? <div className="header-menubar-active-background" onClick={() => { 
                    console.log("sd")
                    this.showMenuSmallDevices() }}></div> : null}
                <SnackBar open={this.state.snackbarActive} handleSnackbarClose={() => {
                    this.setState({
                        snackbarActive: false
                    })
                }} message={this.state.snackbarMessage} />


                {/* //////////////////////////////////////start side bar///////////////////////////////////////////  */}
                {true ?
                    <div
                        // className="sidenav pull-left hidden-xs hidden-sm hide-md-landscape"
                        className={this.state.showMenuSmallDevices ? "sidenav pull-left custom-width-menubar" : "sidenav pull-left hidden-xs hidden-sm hide-md-landscape"}
                        style={sidelogoimage} >
                        {/* {window.innerWidth <801 ? <span className="qwer" onClick={this.openNav.bind(this)}>&#9776;</span> : null } */}
                        <ul>
                            {this.props.redux.globalVar.userTypeStyle === '2' ?
                                <span>
                                    <li><a href="/projectdetail" className={this.state.selected === "projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectdetail'); this.setSelected("projectdetail") }}><div className="menuLabel">
                                        {this.state.selected == 'projectdetail' ? <img src={ProjectDetailWhite} /> : <img src={ProjectDetailGray} />}
                                        <span>Project Detail</span></div></a></li>
                                </span>
                                :
                                <span>
                                    <li><a href="/dashboard" className={this.state.selected === "dashboard" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('dashboard'); this.setSelected("dashboard") }}><div className="menuLabel ">
                                        {this.state.selected == 'dashboard' ? <img src={DashboardWhite} /> : <img src={DashboardGray} />}
                                        <span>Dashboard</span></div></a></li>

                                    <li><a href="/regOkas" className={this.state.selected === "regOkas" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('regOkas'); this.setSelected("regOkas") }}><div className="menuLabel" style={{ width: "100%" }}>
                                        {this.state.selected == 'regOkas' ? <img src={RegisterdOkasBoxWhite} /> : <img src={RegisterdOkasBoxGray} />}
                                        <span>OKAS Box Inventory</span>
                                    </div></a></li>

                                    <li><a href="/memberlicense" className={this.state.selected === "memberlicense" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('memberlicense'); this.setSelected("memberlicense") }}><div className="menuLabel">
                                        {this.state.selected == 'memberlicense' ? <img src={mlWhite} /> : <img src={mlGray} />}
                                        <span>Member License</span></div></a></li>

                                    <li><a href="/officelocation" className={this.state.selected === "officelocation" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('officelocation'); this.setSelected("officelocation") }}><div className="menuLabel">
                                        {this.state.selected == 'officelocation' ? <img src={OfficeLocationWhite} /> : <img src={OfficeLocationGray} />}
                                        <span>Office Location</span></div></a></li>
                                    <li><a href="/projectmanager" className={this.state.selected === "projectmanager" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectmanager'); this.setSelected("projectmanager") }}><div className="menuLabel">
                                        {this.state.selected == 'projectmanager' ? <img src={ProjectManagerWhite} /> : <img src={ProjectManagerGray} />}
                                        <span>Project Manager</span></div></a></li>
                                    <li><a href="/projectdetail" className={this.state.selected === "projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectdetail'); this.setSelected("projectdetail") }}><div className="menuLabel">
                                        {this.state.selected == 'projectdetail' ? <img src={ProjectDetailWhite} /> : <img src={ProjectDetailGray} />}
                                        <span>Project Detail</span></div></a></li>

                                    {/* <li><span className={this.state.selected==="support" ? "sidenav-button selected" : "sidenav-button"} onClick={() => {this.redirectPage('support'); this.setSelected("support")}}><div className="menuLabel"><Icon icon={headphones} size={18} /><span>Support</span></div></span></li>  */}
                                    <li>
                                    <a href="/help" className={this.state.selected === "help" ? "sidenav-button selected" : "sidenav-button"}
                                            onClick={() => { this.redirectPage('help'); this.setSelected("help") }}>
                                            <div className="menuLabel">
                                                {this.state.selected == 'help' ? <img src={HelpWhite} /> : <img src={HelpGray} />}
                                                <span>Help</span>
                                            </div>
                                        </a>
                                    </li>
                                </span>
                            }
                        </ul>
                        {/* {this.renderokasVersionApi()} */}
                        {/* <div className="realversion">{this.renderokasVersionApi()} </div> */}
                    </div>
                    :
                    <div className="sidenav1">
                    </div>
                }
                {/* /////////////////////////////////////////////////////////sidebar//////////////////////////////         */}
                <div className="floatingTopHeader">
                    {true ? <div className="hidden-lg hidden-xs menu-hide-ipad-custom" style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        padding: "0 0px",
                        color: "#bcbccb",
                        margin: "auto 0 auto 10px",
                        paddingRight: "10px",
                        borderRight: "1px solid #BCBCCB"
                    }}
                        //  onClick={this.openNav.bind(this)}
                        onClick={() => { 
                            this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop)
                            this.showMenuSmallDevices()
                         }}
                    >
                        &#9776; Menu</div> : null}
                    <div className="leftSection">
                    <a href={this.props.redux.globalVar.userTypeStyle == 2 ? "/projectdetail" : "/dashboard"}><img src={logo} /></a>
                        {/* <div className="version">
                                V 0.1
                            </div> */}
                    </div>
                    <div className="rightSection" style={{ marginLeft: 'auto', position: 'relative', }}>
                        <div className="icons">
                            {createHistory().location.pathname === '/dashboard' || createHistory().location.pathname === '/help' || createHistory().location.pathname === '/design-help' || createHistory().location.pathname === '/chat' ?
                                null
                                :
                                <a onClick={this.onSearchClick.bind(this)}>
                                    <img src={Search} className="hidden-xs hidden-sm" />
                                </a>
                            }
                            {/* <Link to="/support" className="hidden-xs" >
                                <img src={comments} />
                            </Link> */}
                            {/* {this.state.noticount}      */}
                            {/* temprorary removal of notification   */}
                            {/* <a data-toggle="dropdown">{this.state.noticount > 1 ? <img src={bell2} onClick={() => this.togglenoti()}/> : <img src={bell} onClick={() => this.togglenoti()} />}</a> 
                           <span className="notiMenu dropdown-menu"> 
                                    {this.state.noticount > 1 ? this.renderTableData() : 'No new Notification'}
                            </span>                    */}
                        </div>
                    </div>
                    <div className="rightSection">
                            {this.props.redux.globalVar.userTypeStyle === '1' ?  
                        <React.Fragment>
                        <div id="myNav" class="overlay hidden-lg hidden-md hidden-sm" 
                        style={{ 
                            display: this.state.showFullScreenNavbar ? "block" : "none"                            
                             }}
  
                             >
                                <a href="javascript:void(0)" class="closebtn"
                                onClick={() => {
                                
                                    this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop)
                                    this.setState({
                                        showFullScreenNavbar: !this.state.showFullScreenNavbar
                                    })
                                }}>&times;</a>
                            <div class="overlay-content">
                                <a href="#">
                                <a href="/dashboard" className={this.state.selected === "dashboard" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('dashboard'); this.setSelected("dashboard") }}><div className="menuLabel ">
                                        {this.state.selected == 'dashboard' ? <img src={DashboardWhite} /> : <img src={DashboardGray} />}
                                        <span>Dashboard</span></div></a>
                                </a>
                                <a href="#"><a href="/regOkas" className={this.state.selected === "regOkas" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('regOkas'); this.setSelected("regOkas") }}><div className="menuLabel" style={{ width: "100%" }}>
                                        {this.state.selected == 'regOkas' ? <img src={RegisterdOkasBoxWhite} /> : <img src={RegisterdOkasBoxGray} />}
                                        <span>OKAS Box Inventory</span>
                                    </div></a></a>
                                <a href="#">
                                <a href="/memberlicense" className={this.state.selected === "memberlicense" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('memberlicense'); this.setSelected("memberlicense") }}><div className="menuLabel">
                                        {this.state.selected == 'memberlicense' ? <img src={RegisterdOkasBoxWhite} /> : <img src={RegisterdOkasBoxGray} />}
                                        <span>Member License</span></div></a>
                                </a>
                                <a href="#"><a href="/officelocation" className={this.state.selected === "officelocation" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('officelocation'); this.setSelected("officelocation") }}><div className="menuLabel">
                                        {this.state.selected == 'officelocation' ? <img src={OfficeLocationWhite} /> : <img src={OfficeLocationGray} />}
                                        <span>Office Location</span></div></a></a>
                                <a href="#"><a href="/projectmanager" className={this.state.selected === "projectmanager" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectmanager'); this.setSelected("projectmanager") }}><div className="menuLabel">
                                        {this.state.selected == 'projectmanager' ? <img src={ProjectManagerWhite} /> : <img src={ProjectManagerGray} />}
                                        <span>Project Manager</span></div></a></a>
                                <a href="#"><a href="/projectdetail" className={this.state.selected === "projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectdetail'); this.setSelected("projectdetail") }}><div className="menuLabel">
                                        {this.state.selected == 'projectdetail' ? <img src={ProjectDetailWhite} /> : <img src={ProjectDetailGray} />}
                                        <span>Project Detail</span></div></a></a>
                                <a href="#">
                                <a href="/help" className={this.state.selected === "help" ? "sidenav-button selected" : "sidenav-button"}
                                            onClick={() => { this.redirectPage('help'); this.setSelected("help") }}>
                                            <div className="menuLabel">
                                                {this.state.selected == 'help' ? <img src={HelpWhite} /> : <img src={HelpGray} />}
                                                <span>Help</span>
                                            </div>
                                        </a>
                                </a>
                                <a href="#" onClick={this.handleLogout}  className="sidenav-button">
                                <a className="sidenav-button" to="">
                                            <div className="menuLabel">     
                                                 <img src={logoutGrey} />                                        
                                                <span>Logout</span>
                                            </div>
                                        </a>
                                </a>
                            </div>
                        </div> </React.Fragment> :<React.Fragment>
                        <div id="myNav" class="overlay hidden-lg hidden-md hidden-sm" style={{
                             display: this.state.showFullScreenNavbar ? "block" : "none"
                              }} >
                                <a href="javascript:void(0)" class="closebtn"
                                onClick={() => {
                                    this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop)
                                    this.setState({
                                        showFullScreenNavbar: !this.state.showFullScreenNavbar
                                    })
                                }}>&times;</a>
                            <div class="overlay-content"> 
                                <a href="#"><a href="/projectdetail" className={this.state.selected === "projectdetail" ? "sidenav-button selected" : "sidenav-button"} onClick={() => { this.redirectPage('projectdetail'); this.setSelected("projectdetail") }}><div className="menuLabel">
                                        {this.state.selected == 'projectdetail' ? <img src={ProjectDetailWhite} /> : <img src={ProjectDetailGray} />}
                                        <span>Project Detail</span></div></a></a>
                                <a href="#">
                                <a href="/help" className={this.state.selected === "help" ? "sidenav-button selected" : "sidenav-button"}
                                            onClick={() => { this.redirectPage('help'); this.setSelected("help") }}>
                                            <div className="menuLabel">
                                                {this.state.selected == 'help' ? <img src={HelpWhite} /> : <img src={HelpGray} />}
                                                <span>Help</span>
                                            </div>
                                        </a>
                                </a>
                                <a className="sidenav-button" href="#" onClick={this.handleLogout}>
                                <a className="sidenav-button" >
                                            <div className="menuLabel">     
                                                 <img src={logoutGrey} />                                        
                                                <span>Logout</span>
                                            </div>
                                        </a>
                                </a>
                            </div>
                        </div> </React.Fragment>
                                  }
                        <div className="userDetails hidden-xs">
                            <div className="userIcon" onClick={() => this.toggleList()}>
                                <div className="userName  usernamedatas">
                                    {window.innerWidth < 801 ? null : this.props.redux.globalVar.reUserNameStyle}
                                    <img src={User} className="usermarginleft" />
                                </div>
                                {this.state.listOpen && <span>
                                    <ul className="userMenu">
                                        <li onClick={this.handleLogout}><img src={logout} />Logout</li>
                                    </ul>
                                </span>}
                            </div>
                        </div>
                        <div className="usermarginleft visible-xs"   onClick={() => {
                            this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop)
                                    this.setState({
                                        showFullScreenNavbar: !this.state.showFullScreenNavbar
                                    })
                                }}> &#9776;</div>
                    </div>
                </div>
                {/*/////////////////support button/////////////////////////////////////////////// */}
                {/* {this.state.selected == 'dashboard' ? null :
                    <div className="supportpart pull-right">
                        <a>
                            {this.state.selected == 'support' || this.state.selected == 'chat' ?
                                <Link to="/dashboard" className="suptext" onClick={() => { this.redirectPage('dashboard'); this.setSelected("dashboard") }}><div><img src={DashboardGray} className="suptext" /> <span>DASHBOARD</span></div></Link>
                                :
                                <Link to="/support" className="suptext" onClick={() => { this.redirectPage('support'); this.setSelected("support") }}><div><img src={Support} className="suptext" /> <span>  SUPPORT</span></div></Link>
                            }
                        </a>
                    </div>
                } */}
                {/* /////////////////support button///////////////////////////////////////////////*/}


            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({ type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({ type: ActionTypes.SEARCHBARSTYLE, data: data }),
        userNameStyleActivate: (data) => dispatch({ type: ActionTypes.USERNAMESTYLE, data: data }),
        reuserNameStyleActivate: (data) => dispatch({ type: ActionTypes.REUSERNAMESTYLE, data: data }),
        userTypeStyleActivate: (data) => dispatch({ type: ActionTypes.USERTYPESTYLE, data: data }),
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),
    };
};


export default windowSize(connect(mapStateToProps, mapDispatchToProps) (TopHeader));
