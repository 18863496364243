import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer} from 'redux-form';

import globalVar from './reducers/globalVar';

const reducer = combineReducers({
    globalVar,
    form: reduxFormReducer,

});
const store = createStore(reducer);

export default store;