import _ from 'lodash';
export default function (restoreData, type, data,searchValue) { 
   if(searchValue!==undefined){

      searchValue = searchValue.toLowerCase();
   }
   if(type!=='reverse' && type!=='search'){ 
    // console.log(type);/ 
       data = _.sortBy(data, [function(o) { 
           return o[type]; 
          //  return o[type].toLowerCase();
         }]);

      // data = _.sortBy(data, type); 
        //  console.log(1);
   }else if(type=='search'){
      data =  data.filter((element)=>{
     
      let dummyElement = _.valuesIn(element);
      
      dummyElement = dummyElement.toString().toLowerCase().replace(',','');  
      // console.log(dummyElement)
      if(dummyElement.includes(searchValue) === true){
      // if(dummyElement.indexOf(_.lowerCase(searchValue))>0){ 
// console.log("vod box9898989895,9898989895,ayushi,2019-02-19t10:29:12.886z,,,".includes("."))
          return element;         
      }
    })
    // console.log(2);

}else{
  //  console.log('21')
   data= _.reverse(data);
  //  console.log(3);

   }
   restoreData(data);
} 

