 //cites list
 
 const cities = [
  {
    "city": "Abohar",
    "state": "PUNJAB"
  },
  {
    "city": "Achalpur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Adilabad",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Adityapur",
    "state": "JHARKHAND"
  },
  {
    "city": "Adoni",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Agartala",
    "state": "TRIPURA"
  },
  {
    "city": "Agra",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Ahmadabad",
    "state": "GUJARAT"
  },
  {
    "city": "Ahmadnagar",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Aizawl",
    "state": "MIZORAM"
  },
  {
    "city": "Ajmer",
    "state": "RAJASTHAN"
  },
  {
    "city": "Akbarpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Akola",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Alandur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Alappuzha",
    "state": "KERALA"
  },
  {
    "city": "Aligarh",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Allahabad",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Alwar",
    "state": "RAJASTHAN"
  },
  {
    "city": "Ambala",
    "state": "HARYANA"
  },
  {
    "city": "Ambala Sadar",
    "state": "HARYANA"
  },
  {
    "city": "Ambarnath",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Ambattur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Ambikapur",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Ambur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Amravati",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Amreli",
    "state": "GUJARAT"
  },
  {
    "city": "Amritsar",
    "state": "PUNJAB"
  },
  {
    "city": "Amroha",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Anand",
    "state": "GUJARAT"
  },
  {
    "city": "Anantapur",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Anantnag",
    "state": "JAMMU & KASHMIR"
  },
  {
    "city": "Arrah",
    "state": "BIHAR"
  },
  {
    "city": "Asansol",
    "state": "WEST BENGAL"
  },
  {
    "city": "Ashoknagar Kalyangarh",
    "state": "WEST BENGAL"
  },
  {
    "city": "Aurangabad",
    "state": "BIHAR"
  },
  {
    "city": "Aurangabad",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Avadi",
    "state": "TAMIL NADU"
  },
  {
    "city": "Azamgarh",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Badlapur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Bagaha",
    "state": "BIHAR"
  },
  {
    "city": "Bagalkot",
    "state": "KARNATAKA"
  },
  {
    "city": "Bahadurgarh",
    "state": "HARYANA"
  },
  {
    "city": "Baharampur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bahraich",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Baidyabati",
    "state": "WEST BENGAL"
  },
  {
    "city": "Baleshwar Town",
    "state": "ORISSA"
  },
  {
    "city": "Ballia",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Bally",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bally City",
    "state": "WEST BENGAL"
  },
  {
    "city": "Balurghat",
    "state": "WEST BENGAL"
  },
  {
    "city": "Banda",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Bankura",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bansberia",
    "state": "WEST BENGAL"
  },
  {
    "city": "Banswara",
    "state": "RAJASTHAN"
  },
  {
    "city": "Baran",
    "state": "RAJASTHAN"
  },
  {
    "city": "Baranagar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Barasat",
    "state": "WEST BENGAL"
  },
  {
    "city": "Baraut",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Barddhaman",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bareilly",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Baripada Town",
    "state": "ORISSA"
  },
  {
    "city": "Barnala",
    "state": "PUNJAB"
  },
  {
    "city": "Barrackpur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Barshi",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Basirhat",
    "state": "WEST BENGAL"
  },
  {
    "city": "Basti",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Batala",
    "state": "PUNJAB"
  },
  {
    "city": "Bathinda",
    "state": "PUNJAB"
  },
  {
    "city": "Beawar",
    "state": "RAJASTHAN"
  },
  {
    "city": "Begusarai",
    "state": "BIHAR"
  },
  {
    "city": "Belgaum",
    "state": "KARNATAKA"
  },
  {
    "city": "Bellary",
    "state": "KARNATAKA"
  },
  {
    "city": "Bengaluru",
    "state": "KARNATAKA"
  },
  {
    "city": "Bettiah",
    "state": "BIHAR"
  },
  {
    "city": "Betul",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Bhadrak",
    "state": "ORISSA"
  },
  {
    "city": "Bhadravati",
    "state": "KARNATAKA"
  },
  {
    "city": "Bhadreswar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bhagalpur",
    "state": "BIHAR"
  },
  {
    "city": "Bhalswa Jahangir Pur",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Bharatpur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Bharuch",
    "state": "GUJARAT"
  },
  {
    "city": "Bhatpara",
    "state": "WEST BENGAL"
  },
  {
    "city": "Bhavnagar",
    "state": "GUJARAT"
  },
  {
    "city": "Bhilai Nagar",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Bhilwara",
    "state": "RAJASTHAN"
  },
  {
    "city": "Bhimavaram",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Bhind",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Bhiwadi",
    "state": "RAJASTHAN"
  },
  {
    "city": "Bhiwandi",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Bhiwani",
    "state": "HARYANA"
  },
  {
    "city": "Bhopal",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Bhubaneswar Town",
    "state": "ORISSA"
  },
  {
    "city": "Bhuj",
    "state": "GUJARAT"
  },
  {
    "city": "Bhusawal",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Bid",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Bidar",
    "state": "KARNATAKA"
  },
  {
    "city": "Bidhan Nagar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Biharsharif",
    "state": "BIHAR"
  },
  {
    "city": "Bijapur",
    "state": "KARNATAKA"
  },
  {
    "city": "Bikaner",
    "state": "RAJASTHAN"
  },
  {
    "city": "Bilaspur",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Bokaro Steel City",
    "state": "JHARKHAND"
  },
  {
    "city": "Bongaon",
    "state": "WEST BENGAL"
  },
  {
    "city": "Botad",
    "state": "GUJARAT"
  },
  {
    "city": "Brahmapur Town",
    "state": "ORISSA"
  },
  {
    "city": "Budaun",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Bulandshahr",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Bundi",
    "state": "RAJASTHAN"
  },
  {
    "city": "Burari",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Burhanpur",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Buxar",
    "state": "BIHAR"
  },
  {
    "city": "Champdani",
    "state": "WEST BENGAL"
  },
  {
    "city": "Chandannagar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Chandausi",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Chandigarh",
    "state": "CHANDIGARH"
  },
  {
    "city": "Chandrapur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Chapra",
    "state": "BIHAR"
  },
  {
    "city": "Chas",
    "state": "JHARKHAND"
  },
  {
    "city": "Chennai",
    "state": "TAMIL NADU"
  },
  {
    "city": "Chhattarpur",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Chhindwara",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Chikmagalur",
    "state": "KARNATAKA"
  },
  {
    "city": "Chilakaluripet",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Chitradurga",
    "state": "KARNATAKA"
  },
  {
    "city": "Chittaurgarh",
    "state": "RAJASTHAN"
  },
  {
    "city": "Chittoor",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Churu",
    "state": "RAJASTHAN"
  },
  {
    "city": "Coimbatore",
    "state": "TAMIL NADU"
  },
  {
    "city": "Cuddalore",
    "state": "TAMIL NADU"
  },
  {
    "city": "Cuttack",
    "state": "ORISSA"
  },
  {
    "city": "Dabgram",
    "state": "WEST BENGAL"
  },
  {
    "city": "Dallo Pura",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Damoh",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Darbhanga",
    "state": "BIHAR"
  },
  {
    "city": "Darjiling",
    "state": "WEST BENGAL"
  },
  {
    "city": "Datia",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Davanagere",
    "state": "KARNATAKA"
  },
  {
    "city": "Deesa",
    "state": "GUJARAT"
  },
  {
    "city": "Dehradun",
    "state": "UTTARAKHAND"
  },
  {
    "city": "Dehri",
    "state": "BIHAR"
  },
  {
    "city": "Delhi",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Delhi Cantonment",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Deoghar",
    "state": "JHARKHAND"
  },
  {
    "city": "Deoli",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Deoria",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Dewas",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Dhanbad",
    "state": "JHARKHAND"
  },
  {
    "city": "Dharmavaram",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Dhaulpur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Dhule",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Dibrugarh",
    "state": "ASSAM"
  },
  {
    "city": "Dimapur",
    "state": "NAGALAND"
  },
  {
    "city": "Dinapur Nizamat",
    "state": "BIHAR"
  },
  {
    "city": "Dindigul",
    "state": "TAMIL NADU"
  },
  {
    "city": "Dum Dum",
    "state": "WEST BENGAL"
  },
  {
    "city": "Durg",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Durgapur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Eluru",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "English Bazar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Erode",
    "state": "TAMIL NADU"
  },
  {
    "city": "Etah",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Etawah",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Faizabad",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Faridabad",
    "state": "HARYANA"
  },
  {
    "city": "Farrukhabad-cum-Fatehgarh",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Fatehpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Firozabad",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Firozpur",
    "state": "PUNJAB"
  },
  {
    "city": "Gadag-Betigeri",
    "state": "KARNATAKA"
  },
  {
    "city": "Gandhidham",
    "state": "GUJARAT"
  },
  {
    "city": "Gandhinagar",
    "state": "GUJARAT"
  },
  {
    "city": "Ganganagar",
    "state": "RAJASTHAN"
  },
  {
    "city": "Gangapur City",
    "state": "RAJASTHAN"
  },
  {
    "city": "Gangawati",
    "state": "KARNATAKA"
  },
  {
    "city": "Gaya",
    "state": "BIHAR"
  },
  {
    "city": "Ghazipur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Giridih",
    "state": "JHARKHAND"
  },
  {
    "city": "Godhra",
    "state": "GUJARAT"
  },
  {
    "city": "Gokal Pur",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Gonda",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Gondal",
    "state": "GUJARAT"
  },
  {
    "city": "Gondiya",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Gorakhpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Greater Hyderabad",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Greater Mumbai",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Greater Noida",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Gudivada",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Gulbarga",
    "state": "KARNATAKA"
  },
  {
    "city": "Guna",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Guntakal",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Guntur",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Gurgaon",
    "state": "HARYANA"
  },
  {
    "city": "Guwahati",
    "state": "ASSAM"
  },
  {
    "city": "Gwalior",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Habra",
    "state": "WEST BENGAL"
  },
  {
    "city": "Hajipur",
    "state": "BIHAR"
  },
  {
    "city": "Haldia",
    "state": "WEST BENGAL"
  },
  {
    "city": "Haldwani-cum-Kathgodam",
    "state": "UTTARAKHAND"
  },
  {
    "city": "Halisahar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Hanumangarh",
    "state": "RAJASTHAN"
  },
  {
    "city": "Haora",
    "state": "WEST BENGAL"
  },
  {
    "city": "Hapur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Hardoi",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Hardwar",
    "state": "UTTARAKHAND"
  },
  {
    "city": "Hassan",
    "state": "KARNATAKA"
  },
  {
    "city": "Hastsal",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Hathras",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Hazaribag",
    "state": "JHARKHAND"
  },
  {
    "city": "Hindaun",
    "state": "RAJASTHAN"
  },
  {
    "city": "Hindupur",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Hinganghat",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Hisar",
    "state": "HARYANA"
  },
  {
    "city": "Hoshangabad",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Hoshiarpur",
    "state": "PUNJAB"
  },
  {
    "city": "Hospet",
    "state": "KARNATAKA"
  },
  {
    "city": "Hosur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Hubli-Dharwad",
    "state": "KARNATAKA"
  },
  {
    "city": "Hugli-Chinsurah",
    "state": "WEST BENGAL"
  },
  {
    "city": "Ichalkaranji",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Imphal",
    "state": "MANIPUR"
  },
  {
    "city": "Indore",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Jabalpur",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Jagadhri",
    "state": "HARYANA"
  },
  {
    "city": "Jagdalpur",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Jaipur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Jalandhar",
    "state": "PUNJAB"
  },
  {
    "city": "Jalgaon",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Jalna",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Jalpaiguri",
    "state": "WEST BENGAL"
  },
  {
    "city": "Jamalpur",
    "state": "BIHAR"
  },
  {
    "city": "Jammu",
    "state": "JAMMU & KASHMIR"
  },
  {
    "city": "Jamnagar",
    "state": "GUJARAT"
  },
  {
    "city": "Jamshedpur",
    "state": "JHARKHAND"
  },
  {
    "city": "Jamuria",
    "state": "WEST BENGAL"
  },
  {
    "city": "Jaunpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Jehanabad",
    "state": "BIHAR"
  },
  {
    "city": "Jetpur Navagadh",
    "state": "GUJARAT"
  },
  {
    "city": "Jhansi",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Jhunjhunun",
    "state": "RAJASTHAN"
  },
  {
    "city": "Jind",
    "state": "HARYANA"
  },
  {
    "city": "Jodhpur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Junagadh",
    "state": "GUJARAT"
  },
  {
    "city": "Kadapa",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Kaithal",
    "state": "HARYANA"
  },
  {
    "city": "Kakinada",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Kalol",
    "state": "GUJARAT"
  },
  {
    "city": "Kalyani",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kamarhati",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kancheepuram",
    "state": "TAMIL NADU"
  },
  {
    "city": "Kanchrapara",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kanpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Kanpur City",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Karaikkudi",
    "state": "TAMIL NADU"
  },
  {
    "city": "Karawal Nagar",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Karimnagar",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Karnal",
    "state": "HARYANA"
  },
  {
    "city": "Kasganj",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Kashipur",
    "state": "UTTARAKHAND"
  },
  {
    "city": "Katihar",
    "state": "BIHAR"
  },
  {
    "city": "Khammam",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Khandwa",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Khanna",
    "state": "PUNJAB"
  },
  {
    "city": "Kharagpur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Khardaha",
    "state": "WEST BENGAL"
  },
  {
    "city": "Khargone",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Khora",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Khurja",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Kirari Suleman Nagar",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Kishanganj",
    "state": "BIHAR"
  },
  {
    "city": "Kishangarh",
    "state": "RAJASTHAN"
  },
  {
    "city": "Kochi",
    "state": "KERALA"
  },
  {
    "city": "Kolar",
    "state": "KARNATAKA"
  },
  {
    "city": "Kolhapur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Kolkata",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kollam",
    "state": "KERALA"
  },
  {
    "city": "Korba",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Kota",
    "state": "RAJASTHAN"
  },
  {
    "city": "Kozhikode",
    "state": "KERALA"
  },
  {
    "city": "Krishnanagar",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kulti",
    "state": "WEST BENGAL"
  },
  {
    "city": "Kumbakonam",
    "state": "TAMIL NADU"
  },
  {
    "city": "Kurichi",
    "state": "TAMIL NADU"
  },
  {
    "city": "Kurnool",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Lakhimpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Lalitpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Latur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Loni",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Lucknow",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Ludhiana",
    "state": "PUNJAB"
  },
  {
    "city": "Machilipatnam",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Madanapalle",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Madavaram",
    "state": "TAMIL NADU"
  },
  {
    "city": "Madhyamgram",
    "state": "WEST BENGAL"
  },
  {
    "city": "Madurai",
    "state": "TAMIL NADU"
  },
  {
    "city": "Mahbubnagar",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Mahesana",
    "state": "GUJARAT"
  },
  {
    "city": "Maheshtala",
    "state": "WEST BENGAL"
  },
  {
    "city": "Mainpuri",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Malegaon",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Malerkotla",
    "state": "PUNJAB"
  },
  {
    "city": "Mandoli",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Mandsaur",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Mandya",
    "state": "KARNATAKA"
  },
  {
    "city": "Mangalore",
    "state": "KARNATAKA"
  },
  {
    "city": "Mango",
    "state": "JHARKHAND"
  },
  {
    "city": "Mathura",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Maunath Bhanjan",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Medinipur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Meerut",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Mira Bhayander",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Miryalaguda",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Mirzapur-cum-Vindhyachal",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Modinagar",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Moga",
    "state": "PUNJAB"
  },
  {
    "city": "Moradabad",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Morena",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Morvi",
    "state": "GUJARAT"
  },
  {
    "city": "Motihari",
    "state": "BIHAR"
  },
  {
    "city": "Mughalsarai",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Muktsar",
    "state": "PUNJAB"
  },
  {
    "city": "Munger",
    "state": "BIHAR"
  },
  {
    "city": "Murwara",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Mustafabad",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Muzaffarnagar",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Muzaffarpur",
    "state": "BIHAR"
  },
  {
    "city": "Mysore",
    "state": "KARNATAKA"
  },
  {
    "city": "Nabadwip",
    "state": "WEST BENGAL"
  },
  {
    "city": "Nadiad",
    "state": "GUJARAT"
  },
  {
    "city": "Nagaon",
    "state": "ASSAM"
  },
  {
    "city": "Nagapattinam",
    "state": "TAMIL NADU"
  },
  {
    "city": "Nagaur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Nagda",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Nagercoil",
    "state": "TAMIL NADU"
  },
  {
    "city": "Nagpur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Naihati",
    "state": "WEST BENGAL"
  },
  {
    "city": "Nalgonda",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Nanded Waghala",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Nandurbar",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Nandyal",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Nangloi Jat",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Narasaraopet",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Nashik",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Navi Mumbai",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Navi Mumbai Panvel Raigarh",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Navsari",
    "state": "GUJARAT"
  },
  {
    "city": "Neemuch",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Nellore",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "New Delhi",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Neyveli",
    "state": "TAMIL NADU"
  },
  {
    "city": "Nizamabad",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Noida",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "North Barrackpur",
    "state": "WEST BENGAL"
  },
  {
    "city": "North Dum Dum",
    "state": "WEST BENGAL"
  },
  {
    "city": "Ongole",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Orai",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Osmanabad",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Ozhukarai",
    "state": "PUDUCHERRY"
  },
  {
    "city": "Palakkad",
    "state": "KERALA"
  },
  {
    "city": "Palanpur",
    "state": "GUJARAT"
  },
  {
    "city": "Pali",
    "state": "RAJASTHAN"
  },
  {
    "city": "Pallavaram",
    "state": "TAMIL NADU"
  },
  {
    "city": "Palwal",
    "state": ""
  },
  {
    "city": "Panchkula",
    "state": "HARYANA"
  },
  {
    "city": "Panihati",
    "state": "WEST BENGAL"
  },
  {
    "city": "Panipat",
    "state": "HARYANA"
  },
  {
    "city": "Panvel",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Parbhani",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Patan",
    "state": "GUJARAT"
  },
  {
    "city": "Pathankot",
    "state": "PUNJAB"
  },
  {
    "city": "Patiala",
    "state": "PUNJAB"
  },
  {
    "city": "Patna",
    "state": "BIHAR"
  },
  {
    "city": "Pilibhit",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Pimpri Chinchwad",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Pithampur",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Porbandar",
    "state": "GUJARAT"
  },
  {
    "city": "Port Blair",
    "state": "ANDAMAN & NICOBAR ISLANDS"
  },
  {
    "city": "Proddatur",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Puducherry",
    "state": "PUDUCHERRY"
  },
  {
    "city": "Pudukkottai",
    "state": "TAMIL NADU"
  },
  {
    "city": "Pune",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Puri",
    "state": "ORISSA"
  },
  {
    "city": "Purnia",
    "state": "BIHAR"
  },
  {
    "city": "Puruliya",
    "state": "WEST BENGAL"
  },
  {
    "city": "Rae Bareli",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Raichur",
    "state": "KARNATAKA"
  },
  {
    "city": "Raiganj",
    "state": "WEST BENGAL"
  },
  {
    "city": "Raigarh",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Raipur",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Rajahmundry",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Rajapalayam",
    "state": "TAMIL NADU"
  },
  {
    "city": "Rajarhat Gopalpur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Rajkot",
    "state": "GUJARAT"
  },
  {
    "city": "Rajnandgaon",
    "state": "CHHATTISGARH"
  },
  {
    "city": "Rajpur Sonarpur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Ramagundam",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Rampur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Ranchi",
    "state": "JHARKHAND"
  },
  {
    "city": "Ranibennur",
    "state": "KARNATAKA"
  },
  {
    "city": "Raniganj",
    "state": "WEST BENGAL"
  },
  {
    "city": "Ratlam",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Raurkela Industrial Township",
    "state": "ORISSA"
  },
  {
    "city": "Raurkela Town",
    "state": "ORISSA"
  },
  {
    "city": "Rewa",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Rewari",
    "state": "HARYANA"
  },
  {
    "city": "Rishra",
    "state": "WEST BENGAL"
  },
  {
    "city": "Robertson Pet",
    "state": "KARNATAKA"
  },
  {
    "city": "Rohtak",
    "state": "HARYANA"
  },
  {
    "city": "Roorkee",
    "state": "UTTARAKHAND"
  },
  {
    "city": "Rudrapur",
    "state": "UTTARAKHAND"
  },
  {
    "city": "S.A.S. Nagar",
    "state": "PUNJAB"
  },
  {
    "city": "Sagar",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Saharanpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Saharsa",
    "state": "BIHAR"
  },
  {
    "city": "Salem",
    "state": "TAMIL NADU"
  },
  {
    "city": "Sambalpur",
    "state": "ORISSA"
  },
  {
    "city": "Sambhal",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Sangli Miraj Kupwad",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Santipur",
    "state": "WEST BENGAL"
  },
  {
    "city": "Sasaram",
    "state": "BIHAR"
  },
  {
    "city": "Satara",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Satna",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Sawai Madhopur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Secunderabad",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Sehore",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Seoni",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Serampore",
    "state": "WEST BENGAL"
  },
  {
    "city": "Shahjahanpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Shamli",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Shikohabad",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Shillong",
    "state": "MEGHALAYA"
  },
  {
    "city": "Shimla",
    "state": "HIMACHAL PRADESH"
  },
  {
    "city": "Shimoga",
    "state": "KARNATAKA"
  },
  {
    "city": "Shivpuri",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Sikar",
    "state": "RAJASTHAN"
  },
  {
    "city": "Silchar",
    "state": "ASSAM"
  },
  {
    "city": "Siliguri",
    "state": "WEST BENGAL"
  },
  {
    "city": "Singrauli",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Sirsa",
    "state": "HARYANA"
  },
  {
    "city": "Sitapur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Siwan",
    "state": "BIHAR"
  },
  {
    "city": "Solapur",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Sonipat",
    "state": "HARYANA"
  },
  {
    "city": "South Dum Dum",
    "state": "WEST BENGAL"
  },
  {
    "city": "Srikakulam",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Srinagar",
    "state": "JAMMU & KASHMIR"
  },
  {
    "city": "Sujangarh",
    "state": "RAJASTHAN"
  },
  {
    "city": "Sultan Pur Majra",
    "state": "NCT OF DELHI"
  },
  {
    "city": "Sultanpur",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Surat",
    "state": "GUJARAT"
  },
  {
    "city": "Surendranagar Dudhrej",
    "state": "GUJARAT"
  },
  {
    "city": "Suryapet",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Tadepalligudem",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Tadpatri",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Tambaram",
    "state": "TAMIL NADU"
  },
  {
    "city": "Tenali",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Thane",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Thanesar",
    "state": "HARYANA"
  },
  {
    "city": "Thanjavur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Thiruvananthapuram",
    "state": "KERALA"
  },
  {
    "city": "Thoothukkudi",
    "state": "TAMIL NADU"
  },
  {
    "city": "Thrissur",
    "state": "KERALA"
  },
  {
    "city": "Tiruchirappalli",
    "state": "TAMIL NADU"
  },
  {
    "city": "Tirunelveli",
    "state": "TAMIL NADU"
  },
  {
    "city": "Tirupati",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Tiruppur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Tiruvannamalai",
    "state": "TAMIL NADU"
  },
  {
    "city": "Tiruvottiyur",
    "state": "TAMIL NADU"
  },
  {
    "city": "Titagarh",
    "state": "WEST BENGAL"
  },
  {
    "city": "Tonk",
    "state": "RAJASTHAN"
  },
  {
    "city": "Tumkur",
    "state": "KARNATAKA"
  },
  {
    "city": "Udaipur",
    "state": "RAJASTHAN"
  },
  {
    "city": "Udgir",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Udupi",
    "state": "KARNATAKA"
  },
  {
    "city": "Ujjain",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Ulhasnagar",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Uluberia",
    "state": "WEST BENGAL"
  },
  {
    "city": "Unnao",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Uttarpara Kotrung",
    "state": "WEST BENGAL"
  },
  {
    "city": "Vadodara",
    "state": "GUJARAT"
  },
  {
    "city": "Valsad",
    "state": "GUJARAT"
  },
  {
    "city": "Varanasi",
    "state": "UTTAR PRADESH"
  },
  {
    "city": "Vasai Virar City",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Vellore",
    "state": "TAMIL NADU"
  },
  {
    "city": "Veraval",
    "state": "GUJARAT"
  },
  {
    "city": "Vidisha",
    "state": "MADHYA PRADESH"
  },
  {
    "city": "Vijayawada",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Visakhapatnam",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Vizianagaram",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Warangal",
    "state": "ANDHRA PRADESH"
  },
  {
    "city": "Wardha",
    "state": "MAHARASHTRA"
  },
  {
    "city": "Yamunanagar",
    "state": "HARYANA"
  },
  {
    "city": "Yavatmal",
    "state": "MAHARASHTRA"
  }
];

export default cities;