import React from 'react';
import Sorting from './Sorting';
import Icon from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import createHistory from 'history/createBrowserHistory';
import Cookies from 'js-cookie';
import 'css/regokas.css';
import 'mdbreact/dist/css/mdb.css';
import _ from 'lodash';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { cross } from 'react-icons-kit/icomoon/cross'
import { sort } from 'react-icons-kit/fa/sort';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import Loader from '../components/loader';
import Edit from 'images/headericon/Edit.svg';
import Delete from 'images/headericon/Delete.svg';
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import Success from 'images/Success.svg';
import Error from 'images/Error.svg';
import Delete1 from 'images/headericon/deletedeactive.svg';
import errorText from './../errorText';
import SearchBar from './SearchBar';
import windowSize from 'react-window-size';
import Style from 'style-it';
import IntegrationReactSelectNew from './IntegrationReactSelectNew';

import {
    Input,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption
} from 'mdbreact';

import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';

class ProjectManagerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            okasBoxData: [],
            okasBoxDatalocation: [],
            kk: 'asdvhj',
            password: '',
            name: '',
            email: '',
            phone: '',
            office_location: '',
            officeLocation: '',
            error: '',
            nameerror: '',
            namess: '',
            sortedType: '',
            searchValue: '',
            actualdata: [],
            disabledvalue: '',
            successmsgmodaldata: localStorage.getItem('dataadded'),
            showmodels: '',
            showmodels1: '',
            loader: '',
            passwordi: false,
            currentPage: 1,
            todosPerPage: 10,
            statuson: false, statustw: false, statusth: false,
            statusfo: false, statusfi: false, statussi: false,
            statusse: false, statusei: false, statusni: false,
            statuste: false,
            disableAddButton: false,
            confirmWindow: false,
            projectManagerToDelete: ''

        };
        this.handleClickpage = this.handleClickpage.bind(this);
    }


    crosserrorfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            error: '',
        })

    }

    confirmWindowClose = () => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            confirmWindow: false
        })
    }

    oncolseClickHandler = (event) => {
        this.setState({
            name: '',
            phone: '',
            email: '',
            officeLocation: '',
            password: '',
        })
        this.onclosemodel1()
    }

    componentWillMount() {
    }

    errormsg = () => {
        setTimeout(
            function () {
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata: '',
                    error: '',
                })
            }
                .bind(this), 3000
        );
    }

    crosssuccessfullmsg = () => {
        localStorage.removeItem('dataadded');
        this.setState({
            successmsgmodaldata: '',
        })
    }

    componentDidMount() {
        this.getallrealdata();
    }

    getallrealdata = () => {
        this.setState({
            loader: true,
        })
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }
        fetch('/we/we/projectManagerApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())
            .then(okasBoxData => {
                this.setState({
                    okasBoxData,
                    actualdata: okasBoxData
                }, () => {
                    this.renderTableData();
                    console.log(this.state.okasBoxData)
                })
                this.setState({
                    loader: false,
                })
            })


        fetch('/we/we/locationApi/', {
            method: "GET",
            headers: headers
        })
            .then(response => response.json())

            .then(okasBoxDatalocation => {
                this.setState({
                    okasBoxDatalocation
                }, () => {
                    this.renderTableDatalocation();
                    this.renderLocation();
                    console.log(this.state.okasBoxDatalocation);
                })
            })
    }



    renderTableDatalocation = (type) => {
        if (type === 'location') {
            var typee = 0;
            let xx = this.state.okasBoxDatalocation.map((e, index) => {
                // console.log(e)
                typee = typee + 1;
                return (
                    <option key={index} value={e.ID}>{e.location}</option>
                )
            });
            return xx;
        }
    }

    renderLocation = () => {
        let LocationData = [];
        this.state.okasBoxDatalocation.forEach((e) => {
            LocationData.push({
                label: e.location,
                value: e.ID.toString()
            })
        })
        this.setState({
            LocationData: LocationData
        })
    }



    changepasswordmodal = (pmid123, pmname123) => {
        this.setState({
            pmid: pmid123,
            pmname: pmname123
        })
        this.onopenmodel1()
    }



    handleClickpage(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
        this.renderTableData('type1');
        this.renderTableData('pagination');
    }




    renderTableData = (type) => {
        if (this.state.okasBoxData.detail == 'Invalid token.') {
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }

        let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo);

        if (type === 'pagination') {
            let pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length / this.state.todosPerPage); i++) {
                pageNumbers.push(i);
            }
            let renderPageNumbers = pageNumbers.map((number, i) => {
                if ((this.state.currentPage - 3 < i + 1) && ((this.state.currentPage + 3 > i + 1 || 6 > i + 1))) {
                    return (
                        <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li>
                    );
                }
            });
            return renderPageNumbers;
        }

        if (type === 'type1') {
            var typee = 0;
            let xx = currentTodos.map((e, index) => {
                typee = typee + 1;
                let pmid = e.id;
                let pmname = e.first_name;
                return (
                    <tr key={index}>
                        <td className={this.props.windowWidth > 760 ? "qwerty" : ""}>{(this.state.currentPage * this.state.todosPerPage) - this.state.todosPerPage + index + 1}</td>
                        {/* <td>{e.id}</td> */}
                        <td>{e.first_name}</td>
                        <td >{e.phone}</td>
                        <td>{e.email}</td>
                        <td>{e.office_location}</td>
                        <td>{e.total_installed_box}</td>
                        <td className={this.props.windowWidth > 760 ? "qwerty" : ""}><a className="button" data-toggle="modal" data-target="#hometheaterbox" onClick={(e) => { this.changepasswordmodal(pmid, pmname) }} ><img src={Edit} /></a></td>
                        {e.total_installed_box == 0 ?
                            <td className={this.props.windowWidth > 760 ? "qwerty" : ""}><img src={Delete} onClick={(e) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);
                                this.setState({
                                    confirmWindow: true,
                                    projectManagerToDelete: pmid
                                })
                            }} style={{ cursor: 'pointer' }} /></td>
                            :
                            <td className={this.props.windowWidth > 760 ? "qwerty" : ""}><img src={Delete1} /></td>
                        }
                    </tr>
                )
            });
            return xx;
        }
    }

    onPasswordChangekHandler = (buttonaction, pmids) => {
        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')

        }
        let password = this.state.password.trim();
        let tt = {
            password
        };

        if (errorText('password', password) === 'Error') {
            this.setState({ error: 'Enter a password', nameerror: 'errorfield' })
        } else if (errorText('password', password) === 'Error1') {
            this.setState({ error: 'Password must contains at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and should be of length between 8 and 50 characters', nameerror: 'errorfield' })
        } else {
            if (buttonaction === 'add') {
                this.setState({
                    disabledvalue: 'disabled',
                })
            }
            this.setState({
                loader: true,
            })
            fetch('/we/we/projectManagerApi_detail/' + pmids, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(tt)
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        successmsgmodaldata: 'dataadded',
                        successmodalstates: 'add',
                        disabledvalue: '',
                    })
                    this.getallrealdata();
                    this.oncolseClickHandler();
                    this.errormsg();
                    if (buttonaction === 'add') {
                        this.onclosemodel1();
                    }
                    this.setState({
                        loader: false,
                    })
                })
        }
    }


    ondeleteChangekHandler = () => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            confirmWindow: true
        })
        if (true) {
            this.setState({
                loader: true,
            })
            let TokenValue = 'Token ' + localStorage.getItem("auth");
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': TokenValue,
                'X-CSRFToken': localStorage.getItem('csrftoken')

            }
            fetch('/we/we/projectManagerApi_detail/' + this.state.projectManagerToDelete, {
                method: "DELETE",
                headers: headers,
                // body: JSON.stringify(tt)
            })
                // .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.status == 200) {
                        this.setState({
                            successmsgmodaldata: 'dataadded',
                            successmodalstates: 'add',
                            disabledvalue: '',
                        })
                        this.getallrealdata();
                        this.oncolseClickHandler();
                        this.errormsg();


                    } else {
                        alert('Project Manager can not deleted.');
                    }
                    this.setState({
                        loader: false,
                    })
                })

        }
    }
    //     checkmobile = (event) =>{
    //         let datasskey = event.keyCode;         // Get the Unicode value
    //   let datassval = String.fromCharCode(datasskey);
    //           console.log(datasskey)
    //           console.log(datassval)
    //         if(datassval.match(/^[0-9]+$/)){
    //             this.setState({
    //                 phone:datassval,
    //             })
    //             console.log('1')  
    //         }else{
    //             this.setState({
    //                 phone:this.state.phone,
    //             })
    //             console.log('2')   
    //         }
    //         // let olddatass = datass;
    //         console.log(this.state.phone)
    //       }



    onopenmodel = (event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            showmodels: 'true',
        })
        this.props.searchBarActivate(false)
    }

    onclosemodel = (event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            showmodels: '',
            error: '',
            name: '',
            phone: '',
            email: '',
            password: '',
            nameerror: '',
            phoneerror: '',
            emailerror: '',
            passworderror: '',
            disableAddButton: false
        })
    }


    onopenmodel1 = (event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);        
        this.setState({
            showmodels1: 'true',
        })
    }

    onclosemodel1 = (event) => {
        this.props.backgroundScrollStop(!this.props.redux.globalVar.backgroundScrollStop);

        this.setState({
            showmodels1: '',
        })
    }

    setpasswordi = (buttonaction) => {
        this.setState({
            passwordi: !this.state.passwordi,
        })
    }

    onAddClickHandler = (buttonaction) => {
        this.setState({
            nameerror: '',
            phoneerror: '',
            emailerror: '',
            passworderror: '',
            disableAddButton: true,
        })


        let TokenValue = 'Token ' + localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken'),
        }

        let password = this.state.password.trim();
        let name = this.state.name.trim();
        let email = this.state.email.trim();
        let phone = this.state.phone.trim();
        let office_location = this.state.officeLocation.trim();
        let emailidexist = _.filter(this.state.okasBoxData, { email });
        console.log(emailidexist);
        let phonenoexist = _.filter(this.state.okasBoxData, { phone });
        let tt = {
            password,
            name,
            email,
            phone,
            office_location,
        };
        if (errorText('name', name) === 'Error') {
            this.setState({ error: 'Enter a name', nameerror: 'errorfield' })
        } else if (errorText('name', name) === 'Error1') {
            this.setState({ error: 'Name cannot contain number value', nameerror: 'errorfield', })
        } else if (errorText('phone', phone) === 'Error') {
            this.setState({ error: 'Enter mobile number', phoneerror: 'errorfield', })
        } else if (errorText('phone', phone) === 'Error1') {
            this.setState({ error: "Please enter Numeric Value in mobile number", phoneerror: 'errorfield', })
        } else if (errorText('phone', phone) === 'Error2') {
            this.setState({ error: "Please enter a valid mobile number of 10 digits", phoneerror: 'errorfield', })
        } else if (errorText('phone', phone) === 'Error3') {
            this.setState({ error: "Please enter a valid mobile number start from 7,8,9,10", phoneerror: 'errorfield', })
        } else if (phonenoexist.length > 1) {
            this.setState({ error: 'Mobile Number already exist', phoneerror: 'errorfield' })
        } else if (emailidexist.length > 1) {
            this.setState({ error: 'Email already exist', emailerror: 'errorfield' })
        } else if (errorText('email', email) === 'Error') {
            this.setState({ error: "Enter an email", emailerror: 'errorfield', })
        } else if (errorText('email', email) === 'Error1') {
            this.setState({ error: "Please enter a valid email example(XXXXXXX@XXXXXX.XXX)", emailerror: 'errorfield', })
        } else if (errorText('name', office_location) === 'Error') {
            this.setState({ error: "Please select office location", locationerror: 'selectlistss errorfield', })
        } else if (errorText('password', password) === 'Error') {
            this.setState({ error: 'Enter a password', passworderror: 'errorfield' })
        } else if (errorText('password', password) === 'Error1') {
            this.setState({ error: 'Password must contain(lowercase letter,capital (uppercase) letter, number and Minimum 8 characters)', passworderror: 'errorfield' })
        } else {
            this.setState({ disabledvalue: 'disabled', })
            this.setState({ loader: true, })
            fetch('/we/we/projectManagerApi/', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
                .then(response => {
                    response.json();
                    console.log(response.status)
                    if (response.status == 200) {
                        this.setState({
                            successmsgmodaldata: 'dataadded',
                            successmodalstates: 'add',
                            disabledvalue: '',
                        })
                        this.getallrealdata();
                        this.oncolseClickHandler();
                        this.errormsg();
                        if (buttonaction === 'add') {
                            this.onclosemodel();
                        } else if (buttonaction === 'addanother') {
                            this.setState({
                                showmodels: 'true',
                                error: '',
                                name: '',
                                phone: '',
                                email: '',
                                password: '',
                                officeLocation: ''
                            })
                        }
                        this.setState({
                            loader: false,
                        })
                    } else {
                        alert('Email already exists');
                        this.setState({
                            password: '',
                            name: '',
                            email: '',
                            phone: '',
                            officeLocation: '',
                            disabledvalue: '',
                        })
                    }
                    this.setState({
                        loader: false,
                    })
                })
                .then(data => { })

        }
    }



    restoreData = (data) => {
        this.setState({
            okasBoxData: data,
        })
    }


    sortingFunc = (type, searchValue) => {


        if (type !== "search") {
            this.props.searchBarActivate(false)
        }

        if (type == "first_name") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statuson: !this.state.statuson,
            })
        }
        if (type == "phone") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statustw: !this.state.statustw,
            })
        }
        if (type == "email") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusth: !this.state.statusth,
            })
        }
        if (type == "office_location") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusfo: !this.state.statusfo,
            })
        }
        if (type == "total_installed_box") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statusfi: !this.state.statusfi,
            })
        }
        if (type == "ID") {
            this.setState({
                statuson: false, statustw: false, statusth: false,
                statusfo: false, statusfi: false, statussi: false,
                statusse: false, statusei: false, statusni: false,
                statuste: false,
                statussi: !this.state.statussi,
            })
        }


        if (this.state.sortedType !== type && type !== 'search') {
            this.setState({
                sortedType: type
            })
            Sorting(this.restoreData, type, this.state.okasBoxData);
        } else if (type === 'search') {
            type = 'search';
            Sorting(this.restoreData, type, this.state.actualdata, searchValue);
        } else {
            type = 'reverse';
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }
    }




    onSearchClick = () => {
        this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
    }

    onSelectChange = (operationMode, valueSelect) => {
        if (valueSelect.label !== undefined) {
            this.setState({
                [operationMode]: valueSelect.value,
                error: false,
                locationerror: false,
            })

        }
    }




    render() {
        return Style.it(`
        @media only screen and (max-width: 760px){
      td:nth-of-type(1):before { content: "S.NO"; }
      td:nth-of-type(2):before { content: "NAME"; }
      td:nth-of-type(3):before { content: "PHONE"; }
      td:nth-of-type(4):before { content: "EMAIL"; }
      td:nth-of-type(5):before { content: "AREA"; }
      td:nth-of-type(6):before { content: "BOXES"; }
      td:nth-of-type(7):before { content: "EDIT"; }
      td:nth-of-type(8):before { content: "DELETE"; }
    }`,
            <div className="selfcontainer"
            style={{
                position: !this.props.redux.globalVar.backgroundScrollStop &&  this.props.windowWidth < 760 ? "fixed" : "absolute",
                overflow: !this.props.redux.globalVar.backgroundScrollStop && this.props.windowWidth < 760 ? "hidden" : ""
            }} 
            
            >
                {this.state.loader ? <Loader /> : null}

                {this.props.redux.globalVar.searchBarShow ?
                    <div className='row searchinput'>
                        {/* <input  type="text"  ref={input => input && input.focus()}  name="namess" id="namess" placeholder="Search" 
                        value={this.state.searchValue}
                        onChange={(e)=>{
                            this.setState({
                                searchValue: e.target.value
                                },()=>{
                                this.sortingFunc('search',this.state.searchValue)
                            })
                        }}
                    />   */}
                        <SearchBar
                            autoFocus
                            value={this.state.searchValue}
                            onChange={(e) => {
                                this.setState({
                                    searchValue: e
                                }, () => {
                                    this.sortingFunc('search', this.state.searchValue)
                                })
                            }
                            }
                            onRequestSearch={() => this.sortingFunc('search', this.state.searchValue)}
                            resetSearch={(e) => {
                                this.setState({
                                    searchValue: e
                                }, () => {
                                    this.sortingFunc('search', '')
                                })
                            }}
                        />
                    </div>
                    : ''}
                <div className="addregOkasButton" >
                    <a className="addregOkasButtonicon button-custom-height-size" data-toggle="modal" data-target="#myModal" onClick={this.onopenmodel.bind(this)}>
                        <Icon icon={iosPlusEmpty} size={42} style={{ color: " #15599F" }} />
                        <span className="hidden-xs hidden-sm button-color"> ADD PROJECT MANAGER</span> </a>
                    <div className="hidden-xs hidden-md  hidden-lg button-color sm-heading-btn">Add Project Manager</div>

                </div>
                <div className="row">
                    <h3>Dear {this.props.redux.globalVar.reUserNameStyle}, Listed below are your existing Project Managers</h3>
                    <h6 className="mb-3 mt-0">To add a new Project Manager click on, "Add Project Manager" </h6>
                    <div className={this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >
                        <div className="inblksuccfull"><img src={Success} /></div>
                        <div className="inblksucc1">
                            SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                        </div>
                    </div>

                    <div className="tests">
                        <table className={this.props.windowWidth > 760 ? "table table-striped tablecontent" : "mobile-device-margin-zero"} id="example" >
                            <thead>
                                <tr>
                                    {/* <th>S No.</th> */}
                                    <th className={this.props.windowWidth > 760 ? "qwerty" : ""}
                                        style={{
                                            paddingRight: "24px",
                                            width: this.props.windowWidth > 835 ? "6%" : ""
                                        }}>S.No
                                    {/* <Icon icon={this.state.si?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('ID','')}}/>  */}
                                    </th>
                                    <th style={{ width: this.props.windowWidth > 835 ? "20%" : "20%" }}>Name<Icon icon={this.state.statuson ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('first_name', '') }} /></th>
                                    <th style={{ width: this.props.windowWidth > 835 ? "10%" : "15%" }} >Phone<Icon icon={this.state.statustw ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('phone', '') }} /></th>
                                    <th style={{ width: this.props.windowWidth > 835 ? "20%" : "25%" }}>email<Icon icon={this.state.statusth ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('email', '') }} /></th>
                                    <th style={{ width: this.props.windowWidth > 835 ? "15%" : "20%" }}>AREA<Icon icon={this.state.statusfo ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('office_location', '') }} /></th>
                                    <th style={{ width: this.props.windowWidth > 835 ? "15%" : "15%" }}>Boxes<Icon icon={this.state.statusfi ? ic_keyboard_arrow_up : ic_keyboard_arrow_down} size={16} className="pull-right" onClick={(e) => { this.sortingFunc('total_installed_box', '') }} /></th>
                                    {/* <th>DATE & TIME<Icon icon={sort} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('total_installed_box','')}}/></th>  */}
                                    <th
                                        className={this.props.windowWidth > 760 ? "qwerty" : ""}
                                        style={{ width: this.props.windowWidth > 835 ? "7%" : "" }}

                                    >EDIT</th>
                                    <th
                                        className={this.props.windowWidth > 760 ? "qwerty paddingright3" : "paddingright3"}
                                        style={{ width: this.props.windowWidth > 835 ? "7%" : "" }}

                                    >DELETE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTableData('type1')}
                            </tbody>
                        </table>
                        <div className="pull-right">
                            {this.state.todosPerPage < this.state.okasBoxData.length ?
                                <ul className="pagination pull-right paginat">

                                    <li>
                                        <span className="">Showing {(this.state.currentPage * this.state.todosPerPage) - this.state.todosPerPage + 1} -{this.state.currentPage * this.state.todosPerPage - this.state.okasBoxData.length < 0 ? this.state.currentPage * this.state.todosPerPage : this.state.okasBoxData.length}  of {this.state.okasBoxData.length} enteries</span>
                                    </li>

                                    <li>
                                        <a
                                            onClick={this.state.currentPage == -1 + this.state.currentPage * this.state.todosPerPage ? null :
                                                this.handleClickpage.bind(this, this.state.currentPage - 1)
                                            }
                                            aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>

                                    {this.renderTableData('pagination')}
                                    <li className="">
                                        <a className=""
                                            onClick={this.state.currentPage * this.state.todosPerPage == this.state.okasBoxData.length ? null :
                                                this.handleClickpage.bind(this, this.state.currentPage + 1)
                                            }

                                            aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                                : null}
                        </div>

                    </div> </div>
                {this.state.showmodels ?
                    <div className="selfmodal fade in modal-dialog-centered" id="myModal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog"
                            style={{
                                width: this.props.windowWidth > 760 ? "60%" : "90%",
                            }}
                        >
                            <form action="javascript:void(0)">
                                <div className="selfmodal-content"  >
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.onclosemodel.bind(this)}>&times;</button>
                                        <h3 className="modal-title">Add New Project Manager
                            {/* <span className="breadcrum">Project Manager > Add New Project Manager</span> */}
                                        </h3>
                                        <h6>Please fill in the details to add new project manager.</h6>

                                        <div className={this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  >
                                            <div className="inblksucc"><img src={Error} /></div>
                                            <div className="inblksucc1">
                                                {this.state.error} !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                                            </div>
                                        </div>
                                        {/*    {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null } */}
                                    </div>
                                    <div className="modal-body modfields modal-body-pm" style={{ paddingTop: '10px'}}>
                                        <Input label="Name" type="text" name="name" placeholder="Name" className={this.state.nameerror ? this.state.nameerror : ""}
                                            value={this.state.name}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({
                                                        name: e.target.value,
                                                        error: false,
                                                        nameerror: false,
                                                    }, () => console.log(this.state.name))
                                                }
                                            }}
                                        />   <br />
                                        <Input label="Phone" type="text" name="phone" placeholder="Phone" className={this.state.phoneerror ? this.state.phoneerror : ""}
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 10 && (e.target.value.match(/^[0-9\b]+$/) || e.target.value == '')) {
                                                    this.setState({
                                                        phone: e.target.value,
                                                        error: false,
                                                        phoneerror: false,
                                                    })
                                                }
                                            }}
                                        />   <br />
                                        <Input label="Email" type="text" name="email" placeholder="Email" className={this.state.emailerror ? this.state.emailerror : ""}
                                            value={this.state.email}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({
                                                        email: e.target.value,
                                                        error: false,
                                                        emailerror: false,
                                                    }, () => console.log(this.state.email))
                                                }
                                            }}
                                        />   <br />
                                        {/* <div className="md-form" style={{ width: '100%' }} >
                                            <select name="officeLocation" placeholder="OKAS Box Type" className={this.state.locationerror ? this.state.locationerror : "selectlistss"}
                                                value={this.state.officeLocation}
                                                onChange={(e) => {
                                                    this.setState({
                                                        officeLocation: e.target.value,
                                                        error: false,
                                                        locationerror: false,
                                                    }, () => console.log(this.state.officeLocation))
                                                }}
                                            >
                                                <option className="selectlistfirstelement" VALUE=""></option>
                                                {this.renderTableDatalocation('location')}
                                            </select>
                                            <label className={this.state.officeLocation === '' ? null : 'active'}>Office Location</label>
                                        </div>  */}
                                      
                                        <IntegrationReactSelectNew
                                    placeholder="Office Location"
                                    multipleSelect={false}
                                    content={this.state.LocationData}
                                    onSelect={this.onSelectChange.bind(this, 'officeLocation') }
                                    textFieldLabel = "Office Location"
                                    errorText={this.state.officelocationerror==='' ? 'Please select office location' : ''}           
                                    onFocus={(e)=>{
                                        if(this.props.windowWidth===1024 && this.props.windowHeight===698){
                                            if(e==="clicked"){
                                                this.setState({
                                                    topHeightModal: "-30%"
                                                })
                                            }else if(e==="selected"){
                                                this.setState({
                                                    topHeightModal: "0%"
                                                })
                                            }
                                        }
                                    }}                        
                                />
                                        <br />
                                        <Input label="Password" type={this.state.passwordi ? "text" : "password"} name="password" placeholder="Password" className={this.state.passworderror ? this.state.passworderror : ""}
                                            value={this.state.password}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 50) {
                                                    this.setState({
                                                        password: e.target.value,
                                                        error: false,
                                                        passworderror: false,
                                                    }, () => console.log(this.state.password))
                                                }
                                            }}
                                        />
                                        <a onClick={this.setpasswordi.bind(this)} className="pull-right">{this.state.passwordi ? "Hide" : "Show"} password </a>
                                        <br />
                                        <span className="fieldmend"></span>
                                    </div>
                                    <div className="modal-footer align-btn-mobile">
                                        <div type="button" className="btn allbtn" data-dismiss="modal" onClick={this.onclosemodel.bind(this)}>Cancel</div>
                                        <span className="hidden-xs hidden-sm">
                                            <div type="button" disabled={this.state.disabledvalue ? this.state.disabledvalue : null} className="btn addbtn allbtn" onClick={this.onAddClickHandler.bind(this, 'addanother')}>Add Another</div>
                                        </span>
                                        <div type="submit" disabled={this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this, 'add')}>Add</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : ''}



                {/*change password modal start */}
                {this.state.showmodels1 ?
                    <div className="selfmodal fade modal-dialog-centered" id="hometheaterbox" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="selfmodal-dialog">
                            <form action="javascript:void(0)"
                            //  onSubmit={this.onAddClickHandler.bind(this,'add')}
                            >
                                <div className="selfmodal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.oncolseClickHandler.bind(this)}>&times;</button>
                                        <h4 className="modal-title">Change Project Manager Password</h4>
                                        <div className={this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  >
                                            <div className="inblksucc"><img src={Error} /></div>
                                            <div className="inblksucc1">
                                                {this.state.error} !
<a className="successmsgcross"><Icon icon={cross} size={20} onClick={(e) => { this.crosserrorfullmsg(this) }} /></a>
                                            </div>
                                        </div>
                                        {/* {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null } */}
                                    </div>
                                    <div className="modal-body modfields" style={{ paddingTop: '10px' }}>
                                        {/* <div>{this.state.pmid}</div> 
                            {/* <div><b>Project Manager : </b>{this.state.pmname}</div> */}
                                        <Input label="password" type={this.state.passwordi ? "text" : "password"} name="password" placeholder="Name"
                                            onChange={(e) => {
                                                this.setState({
                                                    password: e.target.value
                                                }, () => console.log(this.state.password))

                                            }}
                                        />
                                        <a onClick={this.setpasswordi.bind(this)} className="pull-right">{this.state.passwordi ? "Hide" : "Show"} password </a>
                                        <br />
                                        <span className="fieldmend"></span>
                                    </div>
                                    <div className="modal-footer align-btn-mobile">
                                        <button type="button" className="btn allbtn" data-dismiss="modal" onClick={this.oncolseClickHandler.bind(this)}>Cancel</button>
                                        <button type="submit" disabled={this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onPasswordChangekHandler.bind(this, 'add', this.state.pmid)}>Change </button>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                    : ''}
                {this.state.confirmWindow ? <div className="selfmodal fade in modal-dialog-centered" aria-hidden="false" data-backdrop="static" data-keyboard="false">
                    <div className="selfmodal-dialog" style={{ width: "50%" }}>
                        <div className="selfmodal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" onClick={this.confirmWindowClose.bind(this)}>&times;</button>
                                <h4 className="modal-title h3 text-center">Delete confirmation</h4>
                            </div>
                            <div className="modal-body modfields">
                                <span className="fa " >Delete icon</span>
                            </div>
                            <div className="modal-footer text-center align-btn-mobile">
                                <button type="button" className="btn allbtn" onClick={this.confirmWindowClose.bind(this)}>Cancel</button>
                                <button type="submit" disabled={this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.ondeleteChangekHandler.bind(this)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/*change password modal end */}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        redux: state,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({ type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({ type: ActionTypes.SEARCHBARSTYLE, data: data }),
        backgroundScrollStop: (data) => dispatch({ type: ActionTypes.SCROLLSTOP, data: data }),

    };
};


export default windowSize(connect(mapStateToProps, mapDispatchToProps)(ProjectManagerPage)); 