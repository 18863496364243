import React from 'react';
import Sorting from './Sorting';
import createHistory from 'history/createBrowserHistory';
import {sort} from 'react-icons-kit/fa/sort';
import 'mdbreact/dist/css/mdb.css';
import { Input } from 'mdbreact'; 
import Icon from 'react-icons-kit'; 
import _ from 'lodash';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty'; 
import 'css/regokas.css';
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross' 
import OkasSupport from 'images/headericon/OkasSupport.svg';
import {attachment} from 'react-icons-kit/entypo/attachment'
import {ic_send} from 'react-icons-kit/md/ic_send'
import User from 'images/headericon/User.svg';
import FileBase64 from 'react-file-base64';




// class Regokasbox extends React.Component {
export default class Chatpage extends React.Component{
constructor(props){
    super(props);
    this.state = { 
        kk: 'asdvhj',
        subject:'',
        description:'',
        error: '',
        nameerror: '',
        namess:'',
        sortedType:'',
        searchValue: '',
        actualdata:[],
        disabledvalue:'',
        classshowofmodal:false, 
        successmsgmodaldata:localStorage.getItem('dataadded'),
        showmodels:'',
        showchat:'',
        searchbar:'',
        chatData:[],
        ticketvareal:'',    
        subjectvareal:'',   
        statusvareal:'',  
        message:[],  
        attechment:[],
        livechat:'',
        isAttachment: false,
        currentAttachment: '',

    };

}



componentWillMount(){
    // const history = createHistory();
    // const location = history.location; 
    // let pathtot = location.pathname;
    // let chatgetid = pathtot.substring(6,20);  
    this.openChat();
}

openChat = () => {
    let chatgetid = localStorage.getItem("ticketid");
    this.onopenchat(chatgetid);
    // console.log(chatgetid)    
}

refreshPage = () => {
    const history = createHistory({forceRefresh: true});   
    history.push('/chat');
}


componentDidMount(){
   
  }
 
  getFiles(files){
    this.setState({ attechment: files }) 
  }
  
handleChangechat(e) {
    var livechats = e.target.value;
    var max_chars = 250;
    var currntlentgh = max_chars - livechats.length
    this.setState({
        livechat: e.target.value,
        chars_left: currntlentgh,
    });   
}


 
// --------------------------------------------------------open chat function start----------------------------
onopenchat = (ticketva) => {
    this.setState({ 
        ticketvareal: ticketva, 
    })   
    
    let TokenValue = 'Token '+localStorage.getItem("auth");
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': TokenValue
    } 
    let tt ={  };
    fetch('/we/we/chatDataApi/'+ticketva ,{
        method: "GET",
        headers: headers, 
        })
    .then(response => response.json())     
    .then(chatData => {

        this.setState({
            chatData:chatData,           
            ticketvareal:chatData.ticket_id,    
            subjectvareal:chatData.subject,   
            statusvareal:chatData.status,  
            message:chatData.message,  
                    },()=>{ 
                       
            console.log(this.state.chatData)
        })
        this.renderChatData();
        this. scrolltodown();
    }) 
    
}


toDataURL =(url, callback) => {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onload = function() {
       var fileReader = new FileReader();
          fileReader.onloadend = function() {
             callback(fileReader.result);
          }
          fileReader.readAsDataURL(httpRequest.response);
    };
    // console.log(url)
    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob';
    httpRequest.send();
  }
  
  imgUrlToBase64 =(url, type) =>{
      if(type==='2'){
          url = '/media/'+url;
        //   console.log(url) 
          this.toDataURL(url, (dataUrl) => {
            //   console.log(dataUrl);
              this.setState({
                  supportmainimg: dataUrl,
                },()=>console.log(this.state.supportmainimg))
            })  
        }else{
            url = '/mediaa/'+url;
            // console.log(url)
          this.toDataURL(url, (dataUrl) => {
            //   console.log(dataUrl);
              this.setState({
                  supportmainimg: dataUrl,
                },()=>console.log(this.state.supportmainimg))
            })
        } 
  }



opensupportimage = (attachment,action,type) => {
    // console.log(attachment)
    if(action=='open'){
        this.imgUrlToBase64(attachment,type);
    } 
    this.setState({
        showsupportimg: !this.state.showsupportimg,
    }); 
}
            
//------------------------------------------------ print main data(List view) function start----------------------------
renderChatData = () => {
         let xx = this.state.message.map((e,index)=>{ 
            //  console.log(e.user_id)
            //  console.log(localStorage.getItem('username'))
             if(localStorage.getItem('username')==e.user_id)
                return ( 
                       <div className="row" key={index} >
                       {e.message || e.attachment?
                       <span>
                            <img src={User} className="imgscal fixed-bottom"/> 
                            <div className="containeras">  
                                    <div className="card-body" > 
                                        <p className="card-text" style={{ wordWrap: 'break-word'}}>{e.message}</p> 
                                        <a className="viewmassage"  onClick={this.opensupportimage.bind(this,e.attachment,'open', e.user_type)}>{e.attachment}</a>
                                    </div>  
                            </div></span>
                          :null}   
                        </div> 
                )   
            else      
                return (
                    <div className='Pull-right row' key={index}>
                        <img src={OkasSupport} className="right"/>
                        <div className="darker"  style={{ wordWrap: 'break-word'}}>
                            <p>{e.message}</p> 
                            <a className="viewmassage"  onClick={this.opensupportimage.bind(this,e.attachment,'open',  e.user_type)}>{e.attachment}</a>
                        </div>
                    </div>  
                )   
    });
    return xx; 
     
  
}



// ----------------------------Submit all data in data base POST API Start--------------------------------------
onSendChatMsgHandler = (val) => { 
    let TokenValue = 'Token '+localStorage.getItem("auth");
    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': TokenValue,
        'X-CSRFToken': localStorage.getItem('csrftoken')
    }   
    if(this.state.livechat.trim() || this.state.isAttachment || val){ 
    fetch('/we/we/ticketReplyApi/' ,{
            method: "POST",
            headers: headers, 
            body: JSON.stringify({
                message_reply: this.state.livechat.trim(),
                ticket_id: this.state.ticketvareal,
                is_attachment: this.state.isAttachment ? 'True' : 'False',
                attachment: this.state.currentAttachment,
                status:val,
            })            
        })
        .then(response => { 
            response.json();
            if(response.status==200){ 
                this.setState({
                    livechat:'',
                        attechment: [{
                            name: ''
                        }]
                })  
                // console.log(this.state.showchat.scrollHeight)
                this.onopenchat(this.state.ticketvareal,this.state.subjectvareal,this.state.statusvareal);  
                
                this.setState({
                    isAttachment: '',
                }); 
            }else{  
                this. scrolltodown(); 
                alert('message can not blank.');
            }
        })   
        .then(data=>{  })  
       this. scrolltodown();
    }
}  
//----------------------------------------- Submit all data in data base POST API End----------------------------------------


scrolltodown(){
    let height = document.documentElement.scrollHeight;
    let addheight = height-500;  
    window.scrollTo(0,addheight)
}

  restoreData = (data) => {
    this.setState({
        okasBoxData: data,

    })
  }
   sortingFunc = (type,searchValue) => {
    // console.log(searchValue)
    
     if(this.state.sortedType!==type && type!=='search' ){ 
       this.setState({
         sortedType: type
       })
     Sorting(this.restoreData, type, this.state.okasBoxData);
     }else if(type==='search'){ 

        type='search'; 
        Sorting(this.restoreData, type, this.state.actualdata,searchValue); 
      }else { 

       type='reverse';
     Sorting(this.restoreData, type, this.state.okasBoxData);
  
     }
   }


   getBase64 = (event) => {
    var input = event.target;      
    var reader = new FileReader();
    reader.onload = () => {
      this.setState({
        currentAttachment: reader.result,
        isAttachment: true,
      })      
    }
    reader.readAsDataURL(input.files[0]);  
  };



  toDataURL =(url, callback) => {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onload = function() {
       var fileReader = new FileReader();
          fileReader.onloadend = function() {
             callback(fileReader.result);
          }
          fileReader.readAsDataURL(httpRequest.response);
    };
    // console.log(url)
    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob';
    httpRequest.send();
  }
    
    render(){
     return (
         
     <div className="selfcontainer ">  
         {this.state.showsupportimg? 
                <div className="supportdiv">
                    <a className="supportclosediv" onClick={this.opensupportimage.bind(this,'text','close')}>X</a>
                    <div className="supportimgdiv">
                        {/* <img src="http://192.168.0.21:8000/media/00745b6a-1a4.png" /> */}
                        <img src={this.state.supportmainimg}  className="center"/>
                    </div>
                </div>
            :null}
        <span className="chatbox">    
    <span className="fixpositionheader">  
        {this.state.statusvareal=="Closed"?
                <div className="addregOkasButtonas" style={{width : "80%"}}>
                    <a className="addregOkasButtonicon" data-toggle="modal" data-target="#regiokasbox" 
                    // onClick={this.onopenmodel.bind(this)}
                    onClick={this.onSendChatMsgHandler.bind(this,'Open')}
                    >
                        <Icon icon={ iosPlusEmpty } size={42} style={{color: " #15599F"}}  />  
                        REOPEN 
                    </a> 
                </div> 
        :null}                
        <h2 className="">SUPPORT - "{this.state.ticketvareal}"</h2>  
        <h4><b>SUBJECT - {this.state.subjectvareal}.</b></h4>
    </span>
    <div className="row chatfullbox">
    
    {this.renderChatData()} 
                <div className="pull-right" ><span  className="buttonfirstlast">Status : </span><span className="buttonlast" >{this.state.statusvareal}</span></div> 
    </div> 
<form className="loginForm" action="javascript:void(0)"  onSubmit={this.onSendChatMsgHandler.bind(this,'Close')} >   
      
   <div className="livechatcss"> 
    <span className="livechatcssinlineblock" style={{width: '2.5%', textAlign: 'center', verticalAlign: 'center'}}>
    <div style={{width: '5%'}}>
                                 <label htmlFor="dd" style={{pointerEvents: "all", cursor: 'pointer' }}>
    <Icon icon={ attachment } size={26} style={{color: "#bcbccb"}} style={{ marginLeft: "12px", marginRight: "12px"}} />
                                </label>
                                    <input id="dd" type='file' accept=".jpg,.png" className="inputHideButton"  onChange={this.getBase64.bind(this)} />
                            </div>
    </span>       
    <span className="livechatcssinlineblock livechatcssinputtextwidth" style={{ width: '94%',marginLeft: "1%"}}>
        <Input type="textarea" rows="1" name="livechat"   value={this.state.livechat}  wrap="hard"  
            onChange={this.handleChangechat.bind(this)} 
            // onKeyPress={e => {
            //     if (e.charCode==13) {
            //         this.onSendChatMsgHandler.bind('Close')
            //     }
            //   }}
              
            style={{width: '100%',marginBottom: "-16px"}}   
        />
        
    </span>        
   
    <span className="livechatcssinlineblock "  style={{width: '2.5%', textAlign: 'center', verticalAlign: 'center'}}>
    <button type="submit" style={{   border: 'none',
    background: '#f0f0f7'}}  onClick={this.onSendChatMsgHandler.bind(this,'Close')}> <Icon  type="submit"  icon={ ic_send }  size={30} style={{color: "#15599F"}} 
         
        /></button> 
     </span>         
   </div>
   </form>
    </span> 
    </div>
    );
}
    
}


