import React from 'react';
import Sorting from './Sorting';
import createHistory from 'history/createBrowserHistory';
import {sort} from 'react-icons-kit/fa/sort';
import 'mdbreact/dist/css/mdb.css';
import { Input } from 'mdbreact'; 
import Icon from 'react-icons-kit'; 
import _ from 'lodash';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty'; 
import 'css/regokas.css';
import 'css/support.css';
import {cancelCircle} from 'react-icons-kit/icomoon/cancelCircle';
import Success from 'images/Success.svg';   
import Error from 'images/Error.svg'; 
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {cross} from 'react-icons-kit/icomoon/cross'
import User from 'images/user.png';
import Supportokas from 'images/supportokas.png';
import {attachment} from 'react-icons-kit/entypo/attachment';
import {ic_send} from 'react-icons-kit/md/ic_send';
import Loader from '../components/loader';
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { connect } from 'react-redux';
import * as ActionTypes from '../actions/types';
import FileBase64 from 'react-file-base64';

class OfficeLocation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            okasBoxData: [],
            kk: 'asdvhj',
            subject:'',
            description:'',
            error: '',
            nameerror: '',
            namess:'',
            sortedType:'',
            searchValue: '',
            actualdata:[],
            disabledvalue:'',
            classshowofmodal:false, 
            successmsgmodaldata:localStorage.getItem('dataadded'),
            showmodels:'',
            showchat:'',
            searchbar:'',
            chatData:[],
            ticketvareal:'',    
            subjectvareal:'',   
            statusvareal:'',     
            loader:'',
            topic:'',
            attechment:[],
            currentPage: 1,
            statuson:false,statustw:false,statusth:false,
            statusfo:false,statusfi:false,statussi:false,
            statusse:false,statusei:false,statusni:false,
            statuste:false, 
            todosPerPage: 10,
            showsupportimg:false,
            supportmainimg:'',
            usersname:'',
            usersemail:'',
            checkuserApidata:'',
            openTicket:'',
            openSubject:'',
            openDescription:'',
            openStatus:'',
        };
        this.handleClickpage = this.handleClickpage.bind(this);

    }

    componentWillMount(){  
        this.checkuserApi();
        
      
    }
    getFiles(files){
        this.setState({ attechment: files }) 
      }


    componentDidMount(){
        // this.getallrealdata();
        // console.log(document.documentElement.scrollHeight+100) 
        // console.log(document.documentElement.scrollHeight) 
        // window.scrollTo(0,document.documentElement.scrollHeight)  



        this.interval = setInterval(() => {
            // alert('Server Error.'); 
          }, 1000);



    }
    scrolltodown(){
        let height = document.documentElement.scrollHeight;
        let addheight = height+500; 
        window.scrollTo(0,addheight)
    }

    crosserrorfullmsg = () => {   
        localStorage.removeItem('dataadded');
            this.setState({
                error:'',
                nameerror:'',
        }) 
    } 

//------------------------------------------------ error msg function start--------------------------------------------------------
    errormsg=()=>{ 
        setTimeout(
            function() { 
                localStorage.removeItem('dataadded');
                this.setState({
                    successmsgmodaldata:'',
                    error:'',
                }) 
            }.bind(this),3000
        );
    }
    //------------------------------------------------ error msg function end ------------------------------------------------
   
    checkuserApi = () =>{ 
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        fetch('/we/we/userCheckApi/' ,{
                method: "GET",
                headers: headers
            })
        .then(response => response.json())
        .then(checkuserApidata => {
            this.setState({
                checkuserApidata,
                actualdata: checkuserApidata
            },()=>{ 
                this.getallrealdata();  
            }) 
        })
    }

    // ------------------------------------------------main data get API start------------------------------------------------
    getallrealdata = () =>{
        this.setState({
            loader: true ,  
        })
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': TokenValue
        }  
        let email_id = this.state.checkuserApidata.user_name;    
        // let email_id = this.props.redux.globalVar.reUserNameStyle;    
        fetch('/we/we/zendeskApi/?userEmaiId='+email_id ,{
            method: "GET",
            headers: headers
        })
        .then(response => response.json()) 
        .then(okasBoxData => {
            // console.log(okasBoxData)
            this.setState({
                okasBoxData,
                actualdata: okasBoxData
            },()=>{
                this.renderTableData(); 
                // console.log(this.state.okasBoxData)
            })
            this.setState({
                loader: false ,  
            })
        })
        // console.log(this.state.okasBoxData)
    }
    
    //------------------------------------------------ main data get API end------------------------------------------------

    handleClickpage(event) {
        this.setState({
          currentPage: Number(event.target.id)
        });
        this.renderTableData('type1');
        this.renderTableData('pagination'); 
      }




      toDataURL =(url, callback) => {
        var httpRequest = new XMLHttpRequest();
        httpRequest.onload = function() {
           var fileReader = new FileReader();
              fileReader.onloadend = function() {
                 callback(fileReader.result);
              }
              fileReader.readAsDataURL(httpRequest.response);
        };
        httpRequest.open('GET', url);
        httpRequest.responseType = 'blob';
        httpRequest.send();
      }
      
      imgUrlToBase64 =(url) =>{ 
        url = '/media/'+url;
        console.log(url) 
        this.toDataURL(url, (dataUrl) => {
          console.log(dataUrl);
          this.setState({
            supportmainimg: dataUrl,
          },()=>console.log(this.state.supportmainimg))
         })  
      }
      

    opensupportimage = (attachment,action) => {
        if(action=='open'){
            this.imgUrlToBase64(attachment);
        } 
        this.setState({
            showsupportimg: !this.state.showsupportimg,
        }); 
    }

     

    openModelWithTicketId = (ticketId,subject,description,status) => { 
        this.setState({ //the error happens here
            openTicket: ticketId,
            openSubject: subject,
            openDescription: description,
            openStatus: status,
        });
    }



    //------------------------------------------------ print main data(List view) function start----------------------------
    renderTableData = (type) => { 
        if(this.state.okasBoxData.detail=='Invalid token.'){
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            this.props.history.push('/login')
        }

   

        
        
let indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
let indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
let currentTodos = this.state.okasBoxData.slice(indexOfFirstTodo, indexOfLastTodo); 

    if(type==='pagination'){ 
        let pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.okasBoxData.length /  this.state.todosPerPage); i++) {
            pageNumbers.push(i);
        } 
        let renderPageNumbers = pageNumbers.map(number => {
            return (
            <li className={this.state.currentPage == number ? "active" : null}><a id={number} onClick={this.handleClickpage}>{number}</a></li> 
            );
        });
        return renderPageNumbers;
    }





        if(type==='type1'){ 
            var typee = 0;
            let xx = currentTodos.map((e,index)=>{ 
                typee = typee+1;
                let ticketsid= "/chat/"+e.ticket_id;
                
                  
                return (
                    <tr key={index} data-toggle="modal" data-target="#myModal" onClick={this.openModelWithTicketId.bind(this,e.id,e.subject,e.description,e.status)}> 
                        <td> {e.id} </td>
                        <td>{e.subject}</td>
                        {/* <td>{e.description}</td>  */}
                        {/* <td> 
                        {e.attachment!=='False' ? <a className="viewmassage" onClick={this.opensupportimage.bind(this,e.attachment,'open')}>view</a> : 'No attachment'}
                            </td> */}
                        <td>{e.status}</td>
                        {/* <td><a  className="viewmassage" onClick={this.redirectionchat.bind(this,e.ticket_id)}>view</a></td> */}
                        {/* <td><a  onClick={this.onopenchat.bind(this,e.ticket_id,e.subject,e.status)}>view</a></td> */}
                        {/* <td><a  href={ticketsid} >view</a></td> */}
                        {/* <td>{e.created_at.substring(0, 10)} {e.created_at.substring(11, 19)}</td> */}
                    </tr>
                )         
            });
            return xx;
        } 
    } 
    //------------------------------------------------ print main data(List view) function end--------------------------------

    //------------------------------------------------ Delete Succ msg start//------------------------------------------------
    crosssuccessfullmsg = () => {   
            localStorage.removeItem('dataadded');
                this.setState({
                successmsgmodaldata:'',
        }) 
    }  
    ////------------------------------------------------ Delete Succ msg end//------------------------------------------------

    oncolseClickHandler = ( event) => {
        this.setState({
            subject: '' ,  
            description: '' ,  
        })   
    } 
// --------------------------------------------------------open model function start----------------------------
onopenmodel = ( event) => {
    this.setState({
        showmodels: 'true' ,  
    })   
    this.props.searchBarActivate(false)
}
// --------------------------------------------------------open model function end----------------------------
redirectionchat = (ticketva) =>{
    const history = createHistory({forceRefresh: true});   
    localStorage.setItem("ticketid", ticketva);
    history.push('/chat');
}
// --------------------------------------------------------open chat function start----------------------------
onopenchat = (ticketva,subjectva,statusva) => {
    this.setState({
        loader: true ,  
    })
    this.setState({
        showchat: 'true' , 
        ticketvareal: ticketva,
        subjectvareal: subjectva,
        statusvareal:statusva,
    })   
    
    let TokenValue = 'Token '+localStorage.getItem("auth");
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': TokenValue
    } 
    let tt ={  };
    fetch('/we/we/chatdata/'+ticketva ,{
        method: "GET",
        headers: headers, 
        })
    .then(response => response.json())  
    .then(chatData => {
        this.setState({
            chatData,
            actualdata: chatData,
        },()=>{ 
            console.log(this.state.chatData)
        })
        this.setState({
            loader: false ,  
        })
    })
   
    
    
}


            
//------------------------------------------------ print main data(List view) function start----------------------------
renderChatData = (type) => { 
    console.log(this.state.chatData)   
        let counts = this.state.chatData.length
        var typee = 0;  
         let xx = this.state.chatData.map((e,index)=>{   
            typee = typee+1;
             if(localStorage.getItem('username')==e.user_id)
                return ( 
                       <div className="row" key={index} >
                            <img src={User} className="imgscal fixed-bottom"/> 
                            <div className="containeras">  
                                    <div className="card-body" > 
                                        <p className="card-text">{e.message}</p> 
                                    </div>  
                            </div>
                             
                        </div> 
                )   
            else      
                return (
                    <div className='Pull-right row' key={index}>
                        <img src={Supportokas} className="right"/>
                        <div className="darker">
                            <p>{e.message}</p> 
                        </div>
                    </div>  
                )   
    });
    return xx; 
     
  
}
//------------------------------------------------ print main data(List view) function end--------------------------------

// --------------------------------------------------------open chat function end----------------------------
// --------------------------------------------------------close chat function start----------------------------
onclosechat = ( event) => {
    this.setState({
        showchat: '' ,  
    })   
} 
// --------------------------------------------------------close chat function End----------------------------

// --------------------------------------------------------close model function start----------------------------
    onclosemodel = ( event) => {
        this.setState({
            showmodels: '' ,  
            error: '' ,  
            topic:'',
            attechment:'',
        })   
        this.oncolseClickHandler();
    } 
// --------------------------------------------------------close model function End----------------------------
 
// ----------------------------Submit all data in data base POST API Start--------------------------------------
onSendChatMsgHandler = (val) => { 
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')
        }   
        let tt;
            let ticket_id = this.state.ticketvareal; 
            let subjectvareal = this.state.subjectvareal; 
            let statusvareal 
        
            if(val=='Open'){ 
                statusvareal = val;
                let status = val;  
                 tt ={
                    ticket_id,
                    status, 
                } 
            }else{ 
                let message_reply = this.state.livechat; 
                statusvareal = this.state.statusvareal;
                 tt ={
                    ticket_id,
                    message_reply, 
                } 
            }
            console.log(tt)
            fetch('/we/we/ticketreply/' ,{
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
            .then(response => { 
                response.json();
                if(response.status==200){ 
                    this.setState({
                        livechat:'',
                    })  
                    // this.getallrealdata(); 
                    // this.oncolseClickHandler();
                    // this.errormsg();   
                    // this.setState({
                    //     statusvareal:'Open',
                    // })
                    console.log(this.state.showchat.scrollHeight)
                    this.onopenchat(ticket_id,subjectvareal,statusvareal);  
                    
                }else{ 
                    this. scrolltodown(); 
                    alert('Server Errors.');
                }
            })   
            .then(data=>{  })  
           this. scrolltodown();
    }  
    //----------------------------------------- Submit all data in data base POST API End----------------------------------------

 
// ----------------------------Submit all data in data base POST API Start--------------------------------------
    onAddClickHandler = ( buttonaction) => { 
         console.log(this.state.attechment.length)
        
        let TokenValue = 'Token '+localStorage.getItem("auth");
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': TokenValue,
            'X-CSRFToken': localStorage.getItem('csrftoken')
        }  
        let tt ={}
        let subject = this.state.subject.trim();
        let body = this.state.description.trim();  
        let userName = this.state.checkuserApidata.first_name;  
        let email_id = this.state.checkuserApidata.user_name;   
        // let attachment = this.state.attechment[0].base64;  
        
            tt ={
                subject,
                body, 
                userName,
                email_id,
            };
            console.log(tt) 
        // }else{ 
        //     let attachment = '';  
        //     tt ={
        //         subject,
        //         description, 
        //         topic, 
        //     }; 
        // }
        

        // console.log(attechment);
        // let tt ={
        //     subject,
        //     description, 
        //     topic,
        //     attachment,
        // }; 

        if(subject.length < 1){
            this.setState({
                error: 'Please select Subject' , 
                subjecterror:'errorfield', 
            })     
        }else if(body.length < 1){
            this.setState({
                error: 'Please enter Topic' , 
                topicerror:'errorfield', 
            })      
        }else {   
            
        this.setState({
            loader: true ,  
        })
       
            fetch('/we/we/zendeskApi/' ,{
                method: "POST",
                headers: headers,
                body: JSON.stringify(tt)
            })
            .then(response => { 
                response.json();
                if(response.status==200){ 
                    this.setState({
                        successmsgmodaldata:'dataadded',
                        successmodalstates:'add',
                        disabledvalue: '',
                    })  
                    this.getallrealdata(); 
                    this.oncolseClickHandler();
                    this.errormsg(); 
                    if(buttonaction==='add') {   
                        this.onclosemodel();  
                    }else if(buttonaction==='addanother'){ 
                    } 
                }else{
                    alert('Server Error.'); 
                }
            })   
            .then(data=>{  })
            this.setState({
                loader: false ,  
            })  
        }   
    }  
    //----------------------------------------- Submit all data in data base POST API End----------------------------------------


    //-------------------------------------------Render data after sorting Function Start----------------------------------------------
    restoreData = (data) => {
        this.setState({
            okasBoxData: data, 
        })
    } 
    //-----------------------------------------------Render data after sorting Function End--------------------------------------------------


    //------------------------------------------------------- sorting Function Start----------------------------------------------
    sortingFunc = (type,searchValue) => {  
        if(type!=="search"){
            this.props.searchBarActivate(false) 
        } 
if(type=="name"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statuson: !this.state.statuson,
      })
}
if(type=="phone"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statustw: !this.state.statustw,
      })
}
if(type=="topic"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusth: !this.state.statusth,
      })
}
if(type=="attachment"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusfo: !this.state.statusfo,
      })
}
if(type=="location"){
    this.setState({
        statuson:false,statustw:false,statusth:false,
        statusfo:false,statusfi:false,statussi:false,
        statusse:false,statusei:false,statusni:false,
        statuste:false, 
        statusfi: !this.state.statusfi,
      })
} 



        if(this.state.sortedType!==type && type!=='search' ){ 
            this.setState({
                sortedType: type
            })
            Sorting(this.restoreData, type, this.state.okasBoxData);
        }else if(type==='search'){  
            type='search'; 
            Sorting(this.restoreData, type, this.state.actualdata,searchValue); 
        }else {  
            type='reverse';
            Sorting(this.restoreData, type, this.state.okasBoxData); 
        }
    } 

    //---------------------------------------------------------- sorting Function End--------------------------------------------------


    onSearchClick = () =>{
        this.props.searchBarActivate(!this.props.redux.globalVar.searchBarShow)
    }




    
    render(){ 
        return (
            <div className="selfcontainer "> 
            
            {this.state.showsupportimg? 
                <div className="supportdiv">
                    <a className="supportclosediv" onClick={this.opensupportimage.bind(this,'text','close')}>X</a>
                    <div className="supportimgdiv">
                        {/* <img src="http://192.168.0.21:8000/media/00745b6a-1a4.png" /> */}
                        <img src={this.state.supportmainimg}  className="center"/>
                    </div>
                </div>
            :null}

            {this.state.loader?<Loader/>:null} 
            {this.state.showchat? '':<span>
                
                {this.props.redux.globalVar.searchBarShow ?
                <div className='row searchinput'>
                    <input  type="text"  ref={input => input && input.focus()} name="namess" id="namess" placeholder="Search" 
                        value={this.state.searchValue}
                        onChange={(e)=>{
                            this.setState({
                                searchValue: e.target.value
                                },()=>{
                                this.sortingFunc('search',this.state.searchValue)
                            })
                        }}
                    />  
                </div> 
                 :''}
                <div className="addregOkasButton">
                    <a className="addregOkasButtonicon" data-toggle="modal" data-target="#regiokasbox" onClick={this.onopenmodel.bind(this)}>
                        <Icon icon={ iosPlusEmpty } size={42} style={{color: " #15599F"}} />  
                        TICKET
                    </a> 
                </div>  
                <div className="row"> 
                    <h3>Support</h3> 
                    <div className= {this.state.successmsgmodaldata ? 'successmsgbox' : 'successmsgbox successmsgboxnone'}  >


<div className="inblksuccfull"><img src={Success}  /></div>
<div className="inblksucc1">
SUCCESS !
<a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
</div> 
</div>  
                    <div className="tests">     
                    <table className="table table-striped tablecontent"> 
                        <thead>
                            <tr> 
                                <th style={{width: '10%'}}>TICKET NO.<Icon icon={this.state.statuson?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('id','')}}/></th>
                                <th style={{width: '20%'}}>SUBJECT<Icon icon={this.state.statustw?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('subject','')}}/></th>
                                {/* <th style={{width: '30%'}}>description<Icon icon={this.state.statusth?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('description','')}}/></th> */}
                                {/* <th style={{width: '20%'}}>ATTACHMENT<Icon icon={this.state.statusfo?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('attachment','')}}/></th> */}
                                <th style={{width: '10%'}}>STATUS<Icon icon={this.state.statusfi?ic_keyboard_arrow_up:ic_keyboard_arrow_down} size={16}  className="pull-right"  onClick={(e)=>{this.sortingFunc('status','')}}/></th>
                                {/* <th style={{width: '10%'}} className="paddingright3">HISTORY</th> */}
                                {/* <th>DATE & TIME<Icon icon={sort} size={16}  onClick={(e)=>{this.sortingFunc('installed_box','')}}/></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableData('type1')}   
                        </tbody> 
                    </table>
                    <div  className="pull-right">
                    {this.state.todosPerPage < this.state.okasBoxData.length ?
                    <ul className="pagination pull-right paginat">                            
                                <li> 
                                <span className="">Showing {(this.state.currentPage*this.state.todosPerPage)-this.state.todosPerPage+1} -{this.state.currentPage*this.state.todosPerPage}  of {this.state.okasBoxData.length} enteries</span> 
                                </li>
                                <li>
                                    <a 
                                      onClick= {this.state.currentPage == -1+this.state.currentPage*this.state.todosPerPage ? null :  
                                            this.handleClickpage.bind(this,this.state.currentPage-1)
                                        }
                                     aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>                           
                        {this.renderTableData('pagination')} 
                            <li className="">
                            <a className=""  
                            onClick= {this.state.currentPage*this.state.todosPerPage == this.state.okasBoxData.length ? null :  
                                  this.handleClickpage.bind(this,this.state.currentPage+1)
                              }   aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                            </a>
                            </li>
                    </ul>
                    :null}
                    </div>
                </div> </div> 
                </span>
                }
                {this.state.showmodels? 
                
                <div className="selfmodal fade in"   aria-hidden="false" data-backdrop="static" data-keyboard="false" >
                
                    <div className="selfmodal-dialog"> 
                        <div className="selfmodal-content">
                            <div className="modal-header">
                            <button type="submit" className="close"  onClick={this.onclosemodel.bind(this)} >&times;</button>
                            {/* onClick={this.oncolseClickHandler.bind(this)} */}
                            <h4 className="modal-title">SUPPORT TICKET  <span className="breadcrum">Support > Need Help</span></h4>

                            <div className= {this.state.error ? 'errormsgbox' : 'errormsgbox errormsgboxnone'}  > 
                                    <div className="inblksucc"><img src={Error}  /></div>
                                    <div className="inblksucc1">
                                        {this.state.error} !
                                        <a className="successmsgcross"><Icon icon={cross} size={20}  onClick={(e) => {this.crosserrorfullmsg(this)}}/></a>
                                    </div>
                                </div> 
                              {/* {this.state.error!=='' ? <div className="alert alert-danger">{this.state.error}</div> : null }  */}
                            </div>
                            <div className="modal-body modfields">  
                            <div className="md-form">
                            <select  name="subject" className={this.state.locationerror ? this.state.locationerror+"serdard" : "selectlistss serdard"}
                            // value={this.state.officelocation}
                            onChange={(e)=>{
                                this.setState({
                                    subject: e.target.value,  
                                    error: false, 
                                },()=>console.log(this.state.subject))  
                            }}
                            >
                                <option  className="selectlistfirstelement"  value=""></option> 
                                <option  className="">Design Studio</option> 
                                <option  className="">Adding New Devices - OKAS BOX</option> 
                                <option  className="">Adding Members - OKAS BOX</option> 
                                <option  className="">A device is not working</option> 
                                <option  className="">iPad/ iPhone Application</option> 
                                <option  className="">We.OKAS</option> 
                                <option  className="">Others</option>   
                                {/* {this.renderTableDatalocation('location')} */}
                            </select>
                            <label className={this.state.subject=='' ?   null : 'active' }>Subject</label>
                              </div> <br/> 
                            
                           

                            {/* <FileBase64
                            type="file"  name="attechment"  
                            value={this.state.attechment}
                            multiple={ true }
                            onDone={ this.getFiles.bind(this) } />   */}

                            {/* <input type="file"  name="attechment"  
                                value={this.state.attechment}
                                onChange={(e)=>{
                                    this.setState({
                                        attechment: e.target.value
                                    })
                                }}  
                            /> */}
                            {/* <br/><br/> */}


                            <Input type="textarea" rows="4" cols="50" name="description" label="Description" className={this.state.descriptionerror ? this.statedescriptionerror+"serdard" : "serdard"}
                                value={this.state.description}
                                onChange={(e)=>{
                                    this.setState({
                                        description: e.target.value,  
                                        error: false,  
                                    })
                                }}  
                            /><br/>  
                            
                            <span className="fieldmend"></span>
                            </div>
                            <div className="modal-footer">
                            {/* onClick={this.oncolseClickHandler.bind(this)} */}
                            <button type="button" className="btn allbtn"onClick={this.onclosemodel.bind(this)}>Cancel</button>
                            <button type="submit" disabled = {this.state.disabledvalue ? this.state.disabledvalue : null} className="btn btn-primary allbtn" onClick={this.onAddClickHandler.bind(this,'add')}>Send</button>
                            </div> 
                            
                        </div> 
                    </div>
                </div>  
                 :''}
  
 
 <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h3 class="modal-title">Ticket No : {this.state.openTicket}</h3><hr></hr>
          <h4 class="modal-title">Subject : {this.state.openSubject}</h4>
        </div>
        <div class="modal-body">
          <p>{this.state.openDescription}</p>
        </div>
        <hr></hr>
        <div class="modal-footer">
          Status : {this.state.openStatus}
        </div>
      </div>
    </div>
  </div>  

            </div>
        );
    }  
}

const mapStateToProps = state  => {
    return {
      redux: state,
    };
  };
  
  
  const mapDispatchToProps = dispatch => {
    return {
        searchBarActivate: (data) => dispatch({type: ActionTypes.SEARCHBAR, data: data }),
        searchBarStyleActivate: (data) => dispatch({type: ActionTypes.SEARCHBARSTYLE, data: data }),
        userNameStyleActivate: (data) => dispatch({type: ActionTypes.USERNAMESTYLE, data: data }),
        reuserNameStyleActivate: (data) => dispatch({type: ActionTypes.REUSERNAMESTYLE, data: data }),
        userTypeStyleActivate: (data) => dispatch({type: ActionTypes.USERTYPESTYLE, data: data }),
    };
  };
  

export default connect(mapStateToProps, mapDispatchToProps) (OfficeLocation); 