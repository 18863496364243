import { SEARCHBAR, SUCCESSBAR, USERNAMESTYLE, REUSERNAMESTYLE, USERTYPESTYLE, TICKETID, AUTH, SCROLLSTOP } from './../actions/types';

const INTIAL_STATE = {
  searchBarShow: false,
  successBarShow: false,
  successBarShow: '',
  successBarShow: '',
  userNameStyle: '',
  reUserNameStyle: '',
  userTypeStyle: '',
  backgroundScrollStop: true
}

export default function (state = INTIAL_STATE, action) {
  switch (action.type) {
    case SEARCHBAR:
      // console.log(action.data)
      return { ...state, searchBarShow: action.data };

    case SUCCESSBAR:
      // console.log(action.data)
      return { ...state, successBarShow: action.data };

    case USERTYPESTYLE:
      // console.log(action.data)
      return { ...state, userTypeStyle: action.data };

    case REUSERNAMESTYLE:
      // console.log(action.data)
      return { ...state, reUserNameStyle: action.data };

    case USERNAMESTYLE:
      // console.log(action.data)
      return { ...state, userNameStyle: action.data };

    case TICKETID:
      // console.log(action.data)
      return { ...state, ticketId: action.data };

    case AUTH:
      // console.log(action.data)
      return { ...state, auth: action.data };

    case SCROLLSTOP:
      if (action.data) {
        document.getElementById('root').style.overflow = 'scroll';
      } else {
        document.getElementById('root').style.overflow = 'hidden';
      }

      return { ...state, backgroundScrollStop: action.data };

  }
  return state;
}
